import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../stores/hooks/hooks";
// store
import {
  clearScenario,
  setActiveWindow,
} from "../../../../../../stores/slice/treeBotSlice";
import {
  deleteScenario,
  fetchDialogById,
} from "../../../../../../stores/thunks/botsThunk";
// icon
import { ReactComponent as DeleteIcon } from "../../../../../../assets/icons/delete.svg";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
// components
import { BlackWrapper } from "../../../../../../components/atoms/blackWrapper/BlackWrapper";
import { ButtonCustom } from "../../../../../../components/atoms/button/ButtonCustom";

export const DeleteScenarioWrapper = () => {
  // hooks
  const dispatch = useAppDispatch();
  // state
  const [text, setText] = useState("");
  // store
  const scenario = useAppSelector((state) => state.treeBot.scenario);
  const dialogById = useAppSelector((state) => state.treeBot.dialogById);
  // effect
  useEffect(() => {
    if (scenario) {
      setText(scenario.text);
    }
  }, [scenario]);
  // callback
  const deleteScenarioCallback = () => {
    if (scenario) {
      dispatch(
        deleteScenario({
          id: scenario.id,
          callback: () => {
            dispatch(fetchDialogById({id:dialogById?.id || ""} ));
            dispatch(setActiveWindow(null));
            dispatch(clearScenario());
          },
        })
      );
    }
  };

  const cancelDeleteCallback = () => {
    if (scenario) {
      scenario.reply_type
        ? dispatch(setActiveWindow("editFreeText"))
        : dispatch(setActiveWindow("editButtons"));
    } else {
      dispatch(setActiveWindow(null));
    }
  };

  return (
    <BlackWrapper>
      <div className="min-h-[500px] flex items-center justify-center">
        <div className="flex flex-col gap-[60px] items-center">
          <div className="w-[41px] h-[41px]">
            <DeleteIcon className="w-[41px] h-[41px]" />
          </div>
          <p className="font-Inter font-bold text-xl text-white">
            {textData.deleteScenario.title}
          </p>
          <div className="flex justify-center items-center gap-3 mt-14">
            <ButtonCustom
              variant="primary"
              handleClick={cancelDeleteCallback}
              title={textData.btns.cancelBtn}
            />
            <ButtonCustom
              variant="secondary"
              disabled={!text}
              handleClick={deleteScenarioCallback}
              title={textData.btns.yesBtn}
            />
          </div>
        </div>
      </div>
    </BlackWrapper>
  );
};
