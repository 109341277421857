import React from "react";
// hooks
import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../stores/hooks/hooks";
// data
import { textData } from "../../../../../../../assets/translations/en/textData";
// helpers
import { getIconsComponents } from "../../../../../../../helpers/iconsComponents/iconsComponents";
//components
import { MarketplaceMoreActionsMenu } from "./marketplaceMoreActionsMenu/MarketplaceMoreActionsMenu";
import { ButtonCustom } from "../../../../../../../components/atoms/button/ButtonCustom";
import { TagBadge } from "./tagBadge/TagBadge";
// types
import { MarketPlaceToolsItemType } from "../../../../../../../stores/types/marketplaceTypes";
// thunk
import {
  addToolToClone,
  setMarketplaceLike,
  setMarketplaceUnLike,
} from "../../../../../../../stores/thunks/marketplaceThunk";
// slice
import {
  setIsLoadingTool,
  setToolIdForScroll,
} from "../../../../../../../stores/slice/marketplaceSlice";
// image
import notFoundImage from "../../../../../../../assets/img/notFoundImage.png";

type Props = {
  data: MarketPlaceToolsItemType;
  customRef: any;
  index: number;
};

export const MarketplaceItem = ({ data, customRef, index }: Props) => {
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // store
  const installedTools = useAppSelector(
    (state) => state.marketplace.installedTools
  );
  const installedToolNow = useAppSelector(
    (state) => state.marketplace.installedToolNow
  );
  const isLoadingAddToll = useAppSelector(
    (state) => state.marketplace.isLoadingAddToll
  );
  const favoriteTools = useAppSelector(
    (state) => state.marketplace.favoriteTools
  );
  const toolById = useAppSelector((state) => state.marketplace.toolById);

  // helpers
  const setLikeMarketplaceCard = (e: any) => {
    e.stopPropagation();
    if (favoriteTools.includes(data.id)) {
      dispatch(
        setMarketplaceUnLike({
          body: { id: data.id },
          like: favoriteTools.includes(data.id),
        })
      );
    } else {
      dispatch(
        setMarketplaceLike({
          body: { id: data.id },
          like: favoriteTools.includes(data.id),
        })
      );
    }
  };

  const handleButtonClick = (e: any) => {
    e.stopPropagation();
    if (installedTools.includes(data.id)) {
      dispatch(setToolIdForScroll(data.id));
      navigate("/clones");
    } else {
      dispatch(setIsLoadingTool(data.id));
      dispatch(addToolToClone({ tool_id: data.id }));
    }
  };

  const handleRedirect = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`${data.id}/`);
  };

  return (
    <div
      ref={toolById && toolById.id === data.id ? customRef : undefined}
      className={`relative cursor-pointer flex items-center justify-center rounded-3xl p-[1px] border transition duration-300 ease-in-out ${
        data.is_sponsored ? "p-0.5 bg-marketplaceBorder" : "bg-dark-450/15"
      } hover:border-secondary-green hover:bg-none  active:bg-secondary-green`}
    >
      <div
        onClick={handleRedirect}
        className={`h-full bg-black cursor-pointer flex flex-col justify-between flex-1 px-6 pb-6 pt-4 rounded-3xl transition duration-300 ease-in-out marketplace-item-tooltip`}
      >
        <div className="flex items-center justify-between">
          <div className="w-2/3">
            <div className="flex">
              {data.users_installed &&
                data.users_installed.length > 0 &&
                data.users_installed.slice(-3).map((user, index) => (
                  <div
                    className={`flex justify-center items-center border-2 rounded-full w-[34px] h-[34px] overflow-hidden bg-secondary-green ${
                      index > 0 ? "ml-[-10px]" : ""
                    }`}
                    key={`${user.id}user_installed`}
                  >
                    <span className="text-lg text-white font-bold font-Inter uppercase leading-none">
                      {user.full_name
                        .split(" ")
                        .map((item) => item.slice(0, 1))
                        .join("") || "AH"}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          {data.is_sponsored && !installedTools.includes(data.id) && (
            <div className="flex items-center justify-between">
              <div className="py-2 px-3 border border-dark-170 rounded-md relative">
                <p className="text-xs font-Roboto font-medium leading-4 bg-gradient-to-br from-secondary-green via-violet-300 to-violet-200 text-transparent bg-clip-text capitalize">
                  {textData.marketplacePage.sponsored}
                </p>
              </div>
            </div>
          )}
          {installedTools.includes(data.id) &&
            getIconsComponents({
              name: "greenCheckmark",
              className: "flex items-center justify-between",
            })}
          <MarketplaceMoreActionsMenu itemId={data.id} />
        </div>
        <div className="mt-2.5 rounded-[10px] overflow-hidden relative h-[254px]">
          <img
            src={data.image_tool || notFoundImage}
            alt={data.title}
            className={`block w-full h-full object-center ${
              data.image_tool ? "object-cover" : "object-contain"
            }`}
          />
          {data.verified_by_aphid && (
            <div className="flex absolute bottom-[8px] right-[5px] ">
              {getIconsComponents({ name: "aphidCo", className: "w-4 h-4" })}
              <div className="flex flex-col items-center gap-2">
                {getIconsComponents({ name: "aphidApproved" })}
                <span className="font-Inter font-bold text-xxxs capitalize">
                  {textData.marketplacePage.aphid}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="flex-1 bg-transparent py-4">
          <p className="font-Inter font-normal text-2xxs line-clamp-4 leading-3.5">
            {data.description}
          </p>
        </div>
        <div className="grid grid-cols-2 gap-3 mt-4 flex-1">
          <div className="flex flex-col gap-1.5">
            <div className="overflow-hidden">
              <p className="font-Inter overflow-hidden font-extrabold text-lg leading-6 whitespace-nowrap text-ellipsis">
                {data.title}
              </p>
            </div>
            <div className="flex gap-1.5 items-center">
              {getIconsComponents({ name: "ubxp" })}
              {data.pricing ? (
                <div className="font-Inter text-sm font-bold leading-[150%]">
                  <span>{data.pricing}</span>
                  <span className="uppercase">
                    {textData.marketplacePage.ubxp}
                  </span>
                </div>
              ) : (
                <span className="capitalize font-Inter text-sm font-bold leading-[150%]">
                  {textData.marketplacePage.free}
                </span>
              )}
            </div>
          </div>
          {data.tags && data.tags.length > 0 && (
            <div className="grid grid-cols-2 gap-1 ">
              {data.tags.map((tag) => (
                <TagBadge
                  key={tag + data.id.toString() + Math.random().toString()}
                  data={tag}
                />
              ))}
            </div>
          )}
        </div>
        <div className="flex items-center justify-between mt-3">
          {installedToolNow === data.id ? (
            <div className="w-[117px] rounded-[50px] py-2 pl-4 pr-10 xl:py-4 xl:pl-7 xl:pr-10 flex items-center gap-4 border border-dark-700">
              {getIconsComponents({ name: "droplet" })}
              <span className="font-Inter text-md font-bold leading-[75%] text-white">
                -1
              </span>
            </div>
          ) : (
            <ButtonCustom
              spinner={isLoadingAddToll === data.id}
              type="button"
              className={`${
                installedTools.includes(data.id) ? "" : "px-9 xl:px-[44px]"
              } ${index === 1 ? "marketplace-add-btn-tooltip" : ""}`}
              variant={
                installedTools.includes(data.id) ? "tertiary" : "secondary"
              }
              title={
                installedTools.includes(data.id)
                  ? textData.btns.openInClonesMarketplace
                  : textData.btns.addMarketplace
              }
              handleClick={handleButtonClick}
            />
          )}
          <div className="flex items-center gap-2">
            <button
              type="button"
              className={`text-lg flex items-center leading-none hover:text-secondary-green`}
              onClick={setLikeMarketplaceCard}
            >
              {favoriteTools.includes(data.id) ? "❤" : "♡"}
            </button>
            <span className="font-Inter font-bold text-sm leading-5">
              {data.likes}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
