// components
import { SignInForm } from "../signInFormWrapper/SignInForm";
import { SignInContent } from "../signInContent/SignInContent";
import { Footer } from "../../../../components/molecules/footer/Footer";
import { TwoStepVerificationModal } from "../twoStepVerificationModal/signInTwoStepVerificationModal";

export const SignInContainer = () => {
  return (
    <div className="min-h-screen">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <SignInContent />
        <SignInForm />
        <TwoStepVerificationModal />
      </div>
      <Footer />
    </div>
  );
};
