import { useEffect, useState } from "react";

export const useTour = (storageKey: string, event: string) => {
  // state
  const [runTour, setRunTour] = useState<boolean>(false);

  // effect to listen to sidebar tour completion
  useEffect(() => {
    const handleTourFinished = () => {
      const isShownSidebar = localStorage.getItem("isShownSidebar");
      if (
        isShownSidebar === "true" &&
        localStorage.getItem(storageKey) !== "true"
      ) {
        setRunTour(true);
      }
    };

    window.addEventListener(event, handleTourFinished);

    return () => {
      window.removeEventListener(event, handleTourFinished);
    };
  }, [storageKey, event]);

  // effect for the case when sidebar tour is already finished
  useEffect(() => {
    const isShownSidebar = localStorage.getItem("isShownSidebar");

    if (
      isShownSidebar === "true" &&
      localStorage.getItem(storageKey) !== "true"
    ) {
      setRunTour(true);
    }
  }, [storageKey]);

  // tooltip callback
  const handleJoyrideCallback = (data: any) => {
    const { status } = data;
    const finishedStatuses = ["finished"];

    if (finishedStatuses.includes(status)) {
      localStorage.setItem(storageKey, "true");
      setRunTour(false);
    }
  };

  // helper to reset the tour
  const resetTour = () => {
    localStorage.removeItem(storageKey);
    setRunTour(true);
  };

  return { runTour, handleJoyrideCallback, resetTour };
};
