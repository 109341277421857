import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../../../stores/hooks/hooks";
// store
import { setActiveWindow } from "../../../../../../stores/slice/treeBotSlice";
import { editDialog } from "../../../../../../stores/thunks/botsThunk";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
import { welcomeMessageFieldData } from "./mockData";
// components
import { BlackWrapper } from "../../../../../../components/atoms/blackWrapper/BlackWrapper";
import { ButtonCustom } from "../../../../../../components/atoms/button/ButtonCustom";
import { FieldCustom } from "../../../../../../components/atoms/field/FieldCustom";

export const WelcomeMessageWrapper = () => {
  const dispatch = useAppDispatch();
  // location
  const params = useParams();
  const dialogId: string = params.id as string;
  // store
  const dialogById = useAppSelector((state) => state.treeBot.dialogById);
  // state
  const [welcomeMessage, setWelcomeMessageLocal] = useState("");
  // effect
  useEffect(() => {
    setWelcomeMessageLocal(dialogById?.start_message || "");
  }, [dialogById]);
  // callback
  const handleCallbackSave = () => {
    dispatch(
      editDialog({ id: dialogId, body: { start_message: welcomeMessage } })
    );
    dispatch(setActiveWindow(null));
  };
  return (
    <BlackWrapper title={textData.welcomeMessage.title}>
      <div>
        <div className="hidden lg:block">
          <FieldCustom
            field={welcomeMessageFieldData}
            value={welcomeMessage}
            setFieldValue={(value: string) => setWelcomeMessageLocal(value)}
            variant="dark"
            rows={3}
          />
        </div>
        <div className="lg:hidden">
          <FieldCustom
            field={welcomeMessageFieldData}
            value={welcomeMessage}
            setFieldValue={(value: string) => setWelcomeMessageLocal(value)}
            variant="dark"
            rows={2}
          />
        </div>
        <div className="flex justify-start xl:justify-center items-center gap-4 mt-4 lg:mt-8 xl:mt-14">
          <ButtonCustom
            variant="primary"
            handleClick={() => dispatch(setActiveWindow(null))}
            title={textData.btns.btnDiscard}
          />
          <ButtonCustom
            disabled={!welcomeMessage}
            variant="secondary"
            handleClick={handleCallbackSave}
            title={textData.btns.btnSave}
          />
        </div>
      </div>
    </BlackWrapper>
  );
};
