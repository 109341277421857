import React from "react";
// data
import { footerData } from "../../mockData";
import { textData } from "../../../../../assets/translations/en/textData";
// icons
import { ReactComponent as TranslateIcon } from "../../../../../assets/icons/translate.svg";
import { ReactComponent as SettingIcon } from "../../../../../assets/icons/setting.svg";
// components
import { LinkCustom } from "../../../../atoms/linkCustom/LinkCustom";

export const  FooterPrivacyWrapper = () => {
  return (
    <div className="flex justify-between items-center p-3 xl:p-8 border-t border-primary-grey">
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-4">
          {footerData.privacyLinks.map((item) => (
            <LinkCustom
              key={item.path + Math.random() * 100}
              variant="default"
              link={item.path}
              title={item.title}
              local={item.local}
            />
          ))}
        </div>
        <p className="font-Inter font-medium text-xs leading-4 text-white text-opacity-50"></p>
      </div>
      <div className="flex justify-between items-center gap-4">
        <div className="flex justify-between items-center gap-1">
          <p className="font-Inter font-bold text-sm leading-5 text-white">
            {textData.footer.english}
          </p>
          <TranslateIcon />
        </div>
        <div className="rounded-full border border-primary-grey p-[14px] bg-transparent">
          <SettingIcon />
        </div>
      </div>
    </div>
  );
};
