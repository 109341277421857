// components
import { SignUpContent } from "./signUpContent/SignUpContent";
import { SignUpForm } from "./signUpFormWrapper/SignUpForm";
import { Footer } from "../../../../components/molecules/footer/Footer";

export const SignUpContainer = () => {
  return (
    <div className="min-h-screen">
      <div className="grid grid-cols-1 md:grid-cols-20">
        <SignUpContent />
        <SignUpForm />
      </div>
      <Footer noPrivacy />
    </div>
  );
};
