export const getScriptData = (body) => {
  return `
      const APH_ID_DIALOG_UNIQ_ID = "${body.APH_ID_DIALOG_UNIQ_ID}"; 
      const APH_ID_BOT_UNIQ_ID =  "${body.APH_ID_BOT_UNIQ_ID}"; 
      const APH_ID_BASE_URI =  "${body.APH_ID_BASE_URI}";
      const APH_ID_BASE_URI_STATIC = "${body.REACT_APP_PUBLIC_BASE_URL_STATIC}"
   `;
};

export const createScriptData = (body) => {
  return `<script>${getScriptData(body)}</script>
          <script src="https://storage.googleapis.com/aphid-staging_chat-bot/static/scripts/chat.js"></script>
    <link rel="stylesheet" href="https://storage.googleapis.com/aphid-staging_chat-bot/static/css/styles.css" />`;
};
