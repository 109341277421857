import React, { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../stores/hooks/hooks";
// location
import { useParams } from "react-router-dom";
// store
import { setActiveWindow } from "../../../../../../stores/slice/treeBotSlice";
import {
  createScenarioReload,
  editScenarioReload,
} from "../../../../../../stores/thunks/botsThunk";
// icon
import { ReactComponent as PenIcon } from "../../../../../../assets/icons/pen.svg";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
import { freeTextDataFieldData } from "./mockData";
// components
import { BlackWrapper } from "../../../../../../components/atoms/blackWrapper/BlackWrapper";
import { ButtonCustom } from "../../../../../../components/atoms/button/ButtonCustom";
import { FieldCustom } from "../../../../../../components/atoms/field/FieldCustom";

export const FreeTextWrapper = () => {
  // hooks
  const dispatch = useAppDispatch();
  // location
  const params = useParams();
  const dialogId: string = params.id as string;
  // state
  const [text, setText] = useState("");
  // store
  const scenario = useAppSelector((state) => state.treeBot.scenario);
  const parentReply = useAppSelector((state) => state.treeBot.parentReply);
  const activeWindow = useAppSelector((state) => state.treeBot.activeWindow);
  // effect
  useEffect(() => {
    if (scenario) {
      setText(scenario.text);
    }
  }, [scenario]);

  return (
    <BlackWrapper
      title={textData.freeTextWrapper.title}
      icon={<PenIcon />}
      deleteCallback={() => dispatch(setActiveWindow("deleteScenario"))}
      className="flex flex-col"
    >
      <div className="flex-grow overflow-y-auto">
        <FieldCustom
          field={freeTextDataFieldData}
          value={text}
          setFieldValue={(value: string) => setText(value)}
          variant="dark"
          className="min-h-[98px] mb-8"
        />
      </div>
      <div className="flex justify-center items-center gap-3 mt-6">
        <ButtonCustom
          variant="primary"
          handleClick={() => {
            setText("");
            if (activeWindow === "editFreeText") {
              dispatch(setActiveWindow(null));
            } else {
              dispatch(setActiveWindow("properties"));
            }
          }}
          title={textData.btns.btnDiscard}
        />
        <ButtonCustom
          variant="secondary"
          disabled={!text}
          handleClick={() => {
            if (scenario) {
              dispatch(
                editScenarioReload({
                  id: scenario.id,
                  dialogId: dialogId,
                  body: { text, reply_type: 1 },
                })
              );
            } else if (parentReply) {
              dispatch(
                createScenarioReload({
                  dialog: dialogId,
                  text,
                  reply_type: 1,
                  parent_reply: parentReply,
                })
              );
            } else {
              dispatch(
                createScenarioReload({
                  dialog: dialogId,
                  text,
                  reply_type: 1,
                })
              );
            }
            dispatch(setActiveWindow(null));
          }}
          title={textData.btns.btnSave}
        />
      </div>
    </BlackWrapper>
  );
};
