import React, {Fragment, useEffect} from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
// store
// data
import { textData } from "../../../../assets/translations/en/textData";
// components
import { ModalWrapper } from "../../../../components/molecules/modalWrapper/ModalWrapper";
import {setChangePaymentModalOpen} from "../../../../stores/slice/userProfileSlice";
import {Spinner} from "../../../../components/atoms/spinner/Spinner";
import {setDefaultPaymentMethodById} from "../../../../stores/slice/paymentsSlice";
import PaypalIcon from "../../../../assets/icons/paypal.svg";
import VisaIcon from "../../../../assets/icons/visa.svg";
import CardIcon from "../../../../assets/icons/card.svg";
import {paymentMethodMap} from "../mappings";
import MasterCardIcon from "../../../../assets/icons/mastercard.svg";
import AddPaymentWrapper from "../addPaymentWrapper/addPaymentWrapper";
import {editUserProfile} from "../../../../stores/thunks/userProfileThunk";
import {getIconsComponents} from "../../../../helpers/iconsComponents/iconsComponents";

export const ChangePaymentModal = () => {
  // hooks
  const dispatch = useAppDispatch();
  // store
  const changePaymentModalOpen = useAppSelector((state) => state.userProfile.changePaymentModalOpen);
  const paymentMethods = useAppSelector((state) => state.payments.paymentMethods);
  const defaultPaymentMethodById = useAppSelector((state) => state.payments.defaultPaymentMethodById);

  // state
  const isLoading = useAppSelector((state) => state.payments.isLoading);

  useEffect(() => {
    if (!changePaymentModalOpen) {

    }
  }, [changePaymentModalOpen]);

  return (
    <Fragment>
      <ModalWrapper
        open={changePaymentModalOpen}
        onClose={() => dispatch(setChangePaymentModalOpen(false))}
        title=""
      >
        <div className="flex flex-col">
          <h3 className="font-Inter text-center font-extrabold text-xl leading-6 text-white mb-10">
            {textData.settings.changePayment}
          </h3>
          <button
            className="p-[14px] absolute top-[20px] right-[30px] border-primary-grey border-[1px] z-[1] rounded-full"
            onClick={() => dispatch(setChangePaymentModalOpen(false))}>
            {getIconsComponents({name: 'close', className: "fill-primary-light"})}
          </button>

          <AddPaymentWrapper editMode={true} type='card' />
          <AddPaymentWrapper editMode={true} type='paypal' />
          {paymentMethods?.map((paymentMethod, index) => (
            <div className={`my-2 ${index !== (paymentMethods?.length - 1) ? "border-b border-primary-grey" : ""}`}
                 key={index}>
              <div className="flex my-2 py-2">
                <div
                  className="flex justify-center items-center w-[46px] h-[46px] mr-4 border border-dark-250 rounded-full">
                  {paymentMethod.payment_method_type === 1 ?
                    <img src={PaypalIcon} alt="paypal" className="h-fit"/> :
                    paymentMethod.card_brand === 'visa' ?
                      <img src={VisaIcon} alt="visa" className="h-fit"/> :
                    paymentMethod.card_brand === 'mastercard' ?
                      <img src={MasterCardIcon} alt="mastercard" className="h-fit"/> :
                      <img src={CardIcon} alt="card" className="h-fit"/>
                  }
                </div>
                <div className="flex flex-col text-left gap-1.5">
                  <label className="block font-Inter text-xs font-medium leading-4 opacity-50">{paymentMethodMap[paymentMethod.payment_method_type]}</label>
                  {paymentMethod.card_last_numbers && <p>...{paymentMethod.card_last_numbers}</p>}
                  {paymentMethod.paypal_payer_email && <p className="w-[200px] overflow-hidden overflow-ellipsis">{paymentMethod.paypal_payer_email}</p>}
                </div>
                <div className="flex ml-auto w-[80px] items-center justify-center">
                  {defaultPaymentMethodById === paymentMethod.id ?
                    <p className="text-xs text-white">{textData.settings.primary}</p> :
                    <button
                      className="text-xs text-blue-500"
                      type="button"
                      onClick={() => {
                        dispatch(setDefaultPaymentMethodById(paymentMethod.id));
                        dispatch(editUserProfile({default_payment_method: paymentMethod.id}));
                        dispatch(setChangePaymentModalOpen(false));
                      }}>
                      {textData.settings.makePrimary}
                    </button>
                  }
                </div>
              </div>
            </div>
          ))}
        </div>
        {isLoading && <Spinner absolute/>}
      </ModalWrapper>
    </Fragment>
  );
};
