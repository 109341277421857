import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../../stores/hooks/hooks";
import { Link, useLocation } from "react-router-dom";
// types
import { SideBarDataItemType } from "../mockDataTypes";
// data
import { sideBarIconsData } from "../mockData";
// slice
import { setLogOutShow } from "../../../../../../stores/slice/userAuthSlice";

type Props = {
  data: SideBarDataItemType;
  full: boolean;
  tooltipClass?: string;
};
export const SideBarItem = ({ data, full, tooltipClass = "" }: Props) => {
  // hooks
  const dispatch = useAppDispatch();
  const location = useLocation();
  // state
  const [state, setState] = useState<
    "default" | "hover" | "active" | "disabled"
  >("default");
  // effect
  useEffect(() => {
    if (data.icon !== "logOut" && !data.link) {
      setState("disabled");
    } else {
      const pathRegex = new RegExp(`^/${data.link}`);
      const isActive =
        data.icon !== "logOut" && pathRegex.test(location.pathname);
      isActive ? setState("active") : setState("default");
    }
  }, [location.pathname, data]);

  const styles = {
    active: "text-secondary-green",
    hover: "text-secondary-green",
    default: "text-white",
    disabled: "text-gray-200",
  };

  if (data.link) {
    return (
      <Link
        to={`/${data.link}`}
        onMouseOver={() =>
          state !== "disabled" && (state === "active" || setState("hover"))
        }
        onMouseOut={() =>
          state !== "disabled" && (state === "active" || setState("default"))
        }
        className={`w-full py-2.5 px-7 flex mb-2.5 gap-4 items-center cursor-pointer ${
          full ? "justify-start" : "justify-center"
        } ${tooltipClass}`}
      >
        <div className="w-4 h-4">{sideBarIconsData[data.icon][state]}</div>
        {full && (
          <p
            className={`text-sm capitalize font-Inter font-normal leading-none ${styles[state]}`}
          >
            {data.title}
          </p>
        )}
      </Link>
    );
  }

  return (
    <button
      type="button"
      disabled={data.icon !== "logOut"}
      onClick={() => dispatch(setLogOutShow(true))}
      onMouseOver={() =>
        state !== "disabled" && (state === "active" || setState("hover"))
      }
      onMouseOut={() =>
        state !== "disabled" && (state === "active" || setState("default"))
      }
      className={`w-full py-2.5 px-7 flex mb-2.5 gap-4 items-center cursor-pointer disabled:cursor-default ${
        full ? "justify-start" : "justify-center"
      } ${tooltipClass}`}
    >
      <div className="w-4 h-4">{sideBarIconsData[data.icon][state]}</div>
      {full && (
        <p
          className={`text-sm capitalize font-Inter font-normal leading-none ${styles[state]}`}
        >
          {data.title}
        </p>
      )}
    </button>
  );
};
