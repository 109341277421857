import { instance, instanceFile } from "../axios/axiosConfig";
// types
import {
  ClonesDataItemTypes,
  CreateCloneBodyType,
  CreateDialogBodyType,
  DuplicateCloneDialogType,
  EditCloneBodyType,
  EditDialogBodyType,
  UploadAvatarBody,
} from "../types/botsTypes";
import {
  checkDialogLimit,
  duplicateClone,
  editeUserReplies,
  uploadAvatar,
} from "../thunks/botsThunk";
import {
  ScenarioBodyType,
  ScenarioEditBodyType,
  UserRepliesBodyType,
  UserRepliesEditBodyType,
} from "../types/treeBotTypes";

const userAuthApi = {
  createDialog(body: CreateDialogBodyType) {
    return instance.post(`/bots/dialogs/`, body);
  },
  getDialogs() {
    return instance.get(`/bots/dialogs/`);
  },
  getDialogById(query: string) {
    return instance.get(`/bots/dialogs/${query}/`);
  },
  editDialog(body: EditDialogBodyType) {
    return instance.patch(`/bots/dialogs/${body.id}/`, body.body);
  },
  deleteDialog(id: string) {
    return instance.delete(`/bots/dialogs/${id}/`);
  },
  duplicateDialog(body: DuplicateCloneDialogType) {
    return instance.post(`/bots/dialogs/${body.pk}/copy/`, body);
  },
  createClone(body: CreateCloneBodyType) {
    return instance.post(`/bots/clones/`, body);
  },
  editClone(body: EditCloneBodyType) {
    return instance.patch(`/bots/clones/${body.id}/`, body.body);
  },
  deleteClone(id: string) {
    return instance.delete(`/bots/clones/${id}/`);
  },
  duplicateClone(body: DuplicateCloneDialogType) {
    return instance.post(`/bots/clones/${body.pk}/copy/`, body);
  },
  getClones() {
    return instance.get(`/bots/clones/`);
  },
  getClonesLimits() {
    return instance.get(`/bots/clones/limits`);
  },
  checkDialogLimit(id:string) {
    return instance.get(`/bots/clones/${id}/limits/`);
  },
  uploadAvatar(body: UploadAvatarBody) {
    return instanceFile.post(`/utils/upload_image/`, body.body, body.options);
  },
  createScenario(body: ScenarioBodyType) {
    return instance.post(`/bots/scenarios/`, body);
  },
  editScenario(body: ScenarioEditBodyType) {
    return instance.patch(`/bots/scenarios/${body.id}/`, body.body);
  },
  deleteScenario(id: string) {
    return instance.delete(`/bots/scenarios/${id}/`);
  },
  createUserReplies(body: UserRepliesBodyType) {
    return instance.post(`/bots/user_replies/`, body);
  },
  editeUserReplies(body: UserRepliesEditBodyType) {
    return instance.patch(`/bots/user_replies/${body.id}/`, body.body);
  },
  deleteUserReplies(body: { id: string }) {
    return instance.delete(`/bots/user_replies/${body.id}/`);
  },
};

export default userAuthApi;
