import React from "react";
// store
import { forgotPassword } from "../../../../../../stores/thunks/userAuthThunk";
// hooks
import { useAppDispatch } from "../../../../../../stores/hooks/hooks";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
import { forgotPasswordData } from "./mockData";
// icon
import IdLogo from "../../../../../../assets/icons/alphaIdColored.svg";
//types
import { ForgotPasswordValuesType } from "./mockDataTypes";
// components
import { ButtonCustom } from "../../../../../../components/atoms/button/ButtonCustom";
import { Formik } from "formik";
import { FieldFormik } from "../../../../../../components/atoms/fieldFormik/FieldFormik";
import { LinkCustom } from "../../../../../../components/atoms/linkCustom/LinkCustom";

export const ForgotPasswordForm = () => {
  // hooks
  const dispatch = useAppDispatch();
  return (
    <section className="grow justify-center pt-[25px] md:pt-[70px] xl:pt-[116px] pr-[40px] xl:pr-[76px] pb-[86px] md:pb-[100px] xl:pb-[276px] pl-4 md:pl-[55px] xl:pl-[80px] bg-black order-1 md:order-2">
      <LinkCustom
        link="/"
        icon="aphidLogoLarge"
        variant="default"
        className="flex items-center justify-center md:hidden mb-10"
      />
      <Formik
        validationSchema={forgotPasswordData.validate}
        initialValues={forgotPasswordData.initialValues}
        onSubmit={(values: ForgotPasswordValuesType) =>
          dispatch(forgotPassword(values))
        }
      >
        {({ handleSubmit, values, setFieldValue, errors, touched }) => (
          <form onSubmit={handleSubmit} className="xl:max-w-[490px]">
            <div className="">
              <div className="flex items-center gap-1 sm:gap-3 mb-1 xl:mb-3">
                <h1 className="font-Inter font-extrabold text-6xl xl:text-8xl leading-7 text-white capitalize">
                  {textData.forgotPasswordPage.title}
                </h1>
                <img src={IdLogo} alt="colored logo" />
              </div>
              <p className="font-Inter text-xxs xl:text-sm text-primary-light mb-3 xl:mb-5">
                {textData.forgotPasswordPage.description}
              </p>
              <p className="font-Inter text-xxs xl:text-sm leading-4 md:leading-5 mb-[70px] xl:mb-[100px]">
                {textData.forgotPasswordPage.note}
              </p>
              <div className="flex flex-col gap-4 mb-[76px] xl:mb-[108px]">
                {forgotPasswordData.fields.map((field) => (
                  <FieldFormik
                    key={field.name + field.label}
                    field={field}
                    value={values[field.name]}
                    setFieldValue={(e) =>
                      setFieldValue(field.name, e.target.value)
                    }
                    variant="dark"
                    touched={touched[field.name]}
                    errors={errors[field.name]}
                    labelClass="text-xs font-medium leading-4"
                    className="bg-transparent pt-3 pb-3 pr-5 pl-5 text-sm text-white font-bold leading-5 placeholder:text-sm placeholder:font-bold"
                  />
                ))}
              </div>
              <ButtonCustom
                variant="secondary"
                type="submit"
                title={textData.btns.sendBtn}
                className="w-full"
              />
            </div>
          </form>
        )}
      </Formik>
    </section>
  );
};
