import * as React from 'react';
import usePagination from '@mui/material/usePagination';
import LeftIcon from '../../../assets/icons/left.svg';
import RightIcon from '../../../assets/icons/right.svg';

type Props = {
  count: number | undefined;
  page: number | undefined;
  onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
}

export default function UsePagination({count, page, onChange}: Props) {
  const { items } = usePagination({
    count: count,
    page: page,
    onChange: onChange
  });

  return (
    <nav>
      <ul className="flex">
        {items.map(({ page, type, selected, ...item }: any, index: React.Key | null | undefined) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = (<div className="w-[40px] h-[40px] flex pt-[6px] justify-center">…</div>);
          } else if (type === 'previous') {
            children = (
              <button
                className="w-[40px] h-[40px] mr-5 rounded-full border border-dark-250 disabled:opacity-75 flex items-center justify-center"
                type="button"
                {...item}
              >
                <img src={LeftIcon} alt="left"/>
              </button>
            );
          } else if (type === 'next') {
            children = (
              <button
                className="w-[40px] h-[40px] ml-5 rounded-full border border-dark-250 disabled:opacity-75 flex items-center justify-center"
                type="button"
                {...item}
              >
                <img src={RightIcon} alt="right"/>
              </button>
            );
          } else if (type === 'page') {
            children = (
              <button
                className={`w-[40px] h-[40px] rounded-full font-bold text-xs 
                            ${selected && "bg-secondary-green text-black"}
                            flex items-center justify-center`}
                type="button"
                {...item}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button type="button" {...item}>
                {type}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
}