// types
import { StorageNameDataType } from './storageNameTypes';

export const getStorageName: StorageNameDataType = {
  accessToken: 'access_token',
  refreshToken: 'refresh_token',
  serviceAuth: 'service_auth',
  chatBotId: 'aph_uniq_id_chat-bot-id',
  subscriptionPlans: 'subscription-plans'
};
