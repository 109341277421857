import { createValidate } from "../../../../../helpers/validation/validationHelper";
//types
import { SignUpDataType } from "./mockDataTypes";
import { textData } from "../../../../../assets/translations/en/textData";

export const signUpData: SignUpDataType = {
  validate: createValidate(["email", "password", "password2", "full_name", "username"]),
  fields: [
    {
      type: "text",
      label: textData.fields.fullName.label,
      placeholder: textData.fields.fullName.placeholder,
      name: "full_name",
    },
    {
      type: "email",
      label: textData.fields.email.label,
      placeholder: textData.fields.email.placeholder,
      name: "email",
    },
    {
      type: "text",
      label: textData.fields.userName.label,
      placeholder: textData.fields.userName.placeholder,
      name: "username",
    },
    {
      type: "password",
      label: textData.fields.password.label,
      placeholder: textData.fields.password.placeholder,
      name: "password",
    },
    {
      type: "password",
      label: textData.fields.confirmPassword.label,
      placeholder: textData.fields.confirmPassword.placeholder,
      name: "password2",
    },
  ],
  initialValues: {
    email: "",
    password: "",
    password2: "",
    full_name: "",
    username: ""
  },
};
