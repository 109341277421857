import React from "react";
// data
import { textData } from "../../../assets/translations/en/textData";

type Props = {
    className?: string
}

export const Separator = ({className = ""}: Props) => {
  return (
    <div className={`flex gap-5 items-center w-full ${className}`}>
      <div className="h-[1px] bg-primary-grey w-full" />
      <span className="font-Inter font-normal text-xxs xl:text-sm text-primary-grey">
        {textData.signUpPage.or}
      </span>
      <div className="h-[1px] bg-primary-grey w-full" />
    </div>
  );
};
