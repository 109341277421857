import React, { useEffect, useRef, useState } from "react";
// hooks
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../stores/hooks/hooks";
// slice
import { setActiveWindow } from "../../../../../stores/slice/treeBotSlice";
// data
import { textData } from "../../../../../assets/translations/en/textData";
// components
import { ZoomableContainer } from "react-zoomable-container";
import { BotTreeControls } from "../../../../../components/molecules/botTreeControls/BotTreeControls";
import { TreeStartButton } from "../../../../../components/organisms/treeStartButton/TreeStartButton";

export const BotTree = () => {
  // hooks
  const dispatch = useAppDispatch();
  // ref
  const treeRef = useRef(null);
  // state
  const [message, setMessage] = useState("");
  // data
  const overrides = {
    scale: 1,
    position: {
      x: 0,
      y: 0,
    },
    lerpTime: 500,
    scaleStep: 0.1,
    minScale: 0.1,
    maxScale: 2,
    disablePan: false,
  };
  // store
  const dialogById = useAppSelector((state) => state.treeBot.dialogById);
  const tree = dialogById?.tree;
  // effect
  useEffect(() => {
    if (dialogById) {
      setMessage(dialogById.start_message);
    }
  }, [dialogById]);

  return (
    <ZoomableContainer
      customControls={<BotTreeControls />}
      controlOverrides={overrides}
    >
      <div ref={treeRef} className="flex flex-col items-center">
        <TreeStartButton
          handleCallback={() => {
            dispatch(setActiveWindow("welcomeMessage"));
          }}
          icon="home"
          start
          childScenario={null}
          text={message || textData.tree.startMessage}
          replies={[]}
        />
        {dialogById?.start_message && (
          <TreeStartButton
            handleCallback={() => {
              if (!tree) {
                dispatch(setActiveWindow("properties"));
              }
            }}
            disabled={!!tree}
            icon="bot"
            childScenario={tree || null}
            text={textData.tree.botResponse}
            replies={tree?.child_replies || []}
          />
        )}
      </div>
    </ZoomableContainer>
  );
};
