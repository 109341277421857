import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import ConfirmEmailWrapper from "./components/ConfirmEmailWrapper";


const ConfirmEmailPage = ()  => {
  return (
    <Layout
      sideBar={false}
      noPadding
      className="bg-black w-full flex items-center h-screen"
    >
      <ConfirmEmailWrapper />
    </Layout>
  );
};

export default ConfirmEmailPage;
