// core
import * as Yup from "yup";
import {
  CreateValidateFieldsType,
  RegexDataType,
  ValidateFieldCommonKeyType,
  ValidateFieldCommonType,
} from "./validationTypes";

const regexFields: RegexDataType = {
  password:
    // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,30}$/,
  //     /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*.,;:'"\]\[{}()])[A-Za-z\d!@#$%^&*.,;:'"\]\[{}()]{8,30}$/
};
const MAX_FILE_SIZE = 512000; //500KB

const validateFieldCommon: ValidateFieldCommonType = {
  email: Yup.string().required("Required").email("Invalid format"),
  password: Yup.string()
    // .matches(
    //   regexFields.password,
    //   "Must contain: capital letter, number and special character. Min. length - 8"
    // )
    .required("Required")
    .min(9, "Too short")
    .max(100, "Too long"),
  old_password: Yup.string()
    .required("Required")
    .min(9, "Too short")
    .max(100, "Too long"),
  password2: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
  name: Yup.string()
    .required("Required")
    .min(3, "Too short")
    .max(20, "Too long"),
  full_name: Yup.string()
    .required("Required")
    .min(1, "Too short")
    .max(64, "Too long"),
  username: Yup.string()
    .required("Required")
    .min(1, "Too short")
    .max(32, "Too long"),
  description: Yup.string()
    .required("Required")
    .min(3, "Too short")
    .max(100, "Too long"),
  image_url: Yup.mixed()
    .required("Required")
    .test("is-valid-size", "Max allowed size is 500KB", (value) =>
      typeof value === "string"
        ? true
        : // @ts-ignore
          value && value?.size && value.size <= MAX_FILE_SIZE
    ),
  bots: Yup.array()
    .of(Yup.string())
    .min(1, "At least one clone is required")
    .required("Required"),
  price: Yup.string()
    .matches(/^\d+(\.\d+)?$/, "Must be a decimal number")
    .required("Required"),
};

export const createValidate = (fields: CreateValidateFieldsType) => {
  const validate = fields.reduce(
    (acc: { [item: string]: any }, key: ValidateFieldCommonKeyType) => {
      acc[key] = validateFieldCommon[key];
      return acc;
    },
    {}
  );
  return Yup.object().shape(validate);
};
