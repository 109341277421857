// data
import { textData } from "../../../../../assets/translations/en/textData";
// type
import { SubscriptionPlanMockDataType } from "../mockDataType";
// icon
import { ReactComponent as GreenCheckMarkIcon } from "../../../../../assets/icons/greenCheckmark.svg";
import {useNavigate} from "react-router-dom";

export type PropsType = {
  handleSelectCard: (value: any) => void;
  isSelected: boolean;
  data: SubscriptionPlanMockDataType;
};

export const SubscriptionPlanCard = ({
  handleSelectCard,
  data,
  isSelected,
}: PropsType) => {

  const navigate = useNavigate();

  return (
    <div
      className={`flex-1 relative hover:opacity-80 cursor-pointer min-w-[190px]`}
      onClick={handleSelectCard}
    >
      {isSelected && (
        <div className="absolute w-2 h-2 xl:w-5 xl:h-5 right-3 top-3">
          <GreenCheckMarkIcon className="w-full h-full object-cover"/>
        </div>
      )}
      <div
        className={`h-full flex flex-col justify-between py-7 px-5 border min-h-[250px] ${
          isSelected ? "border-secondary-green" : "border-dark-450/[.15]"
        } transition duration-300 ease-in-out rounded-xl active:border-dark-100`}
      >
        <div>
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between min-h-[44px] gap-2">
            <p className="flex-1 font-Roboto text-start font-bold text-sm xl:text-xl xxl:text-7xl text-white capitalize">
              {data.planName}
            </p>
            {data.icon && <div className="w-[32px] xl:w-[50px] h-[16px] xl:h-[32px]"><img src={data.icon} alt="" /></div>}
          </div>

          <ul className="mt-5 xxl:mt-12 ml-6 list-disc text-left">
            {data.listPlan.map((item) => (
              <li
                className="font-Roboto font-normal text-xxs xl:text-lg xxl:text-3xl text-dark-470 leading-2 xl:leading-6 "
                key={item.id}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col items-start mt-5 xxl:mt-20">
          <button type="button" className="font-Roboto font-normal text-xxs xl:text-lg xxl:text-xl text-blue-400 leading-1 xl:leading-6 capitalize"
          onClick={() => navigate("/select-membership")}>
            {data.planDetails}
          </button>
          <p className="font-Roboto font-bold text-xxs xl:text-xl xxl:text-5xl xl:leading-9 mt-2">
            {data.planPrice}
          </p>
        </div>
      </div>

      {data.recommended && (
        <p className="absolute top-0 left-1/2 pb-1 translate-y-[-100%] translate-x-[-50%] font-Inter font-normal text-secondary-green text-xs xl:text-4xl capitalize">
          {textData.subscriptionPlansPage.recommended}
        </p>
      )}
    </div>
  );
};
