import React from "react";
// types
import { AlertPropsType } from "../../../../../stores/slice/alertSlice";
// icon
import { icons } from "./mockData";
import {getIconsComponents} from "../../../../../helpers/iconsComponents/iconsComponents";

type Props = {
  alertData: AlertPropsType;
  onClose: () => void;
  className?: string;
};

const styleData = {
  error: {
    textColor: "text-white",
    colorBg: "bg-red-500",
    colorBg2: "bg-red-100",
    border: "border-red-500",
    icon: icons.errorMessage,
  },
  success: {
    textColor: "text-teal-700",
    colorBg: "bg-teal-500",
    colorBg2: "bg-teal-100",
    border: "border-teal-500",
    icon: icons.successMessage,
  },
  base: {
    textColor: "text-brown-100",
    colorBg: "bg-brown-100",
    colorBg2: "bg-red-500",
    border: "border-red-500",
    icon: icons.successMessage,
  },
  info: {
    textColor: "text-brown-100",
    colorBg: "bg-blue-100",
    colorBg2: "bg-red-500",
    border: "border-red-500",
    icon: icons.successMessage,
  },
  warning: {
    textColor: "text-brown-100",
    colorBg: "bg-yellow-100",
    colorBg2: "bg-red-500",
    border: "border-red-500",
    icon: icons.successMessage,
  },
};

 export const  Alert = ({ alertData, onClose, className = "" }: Props) => {
  const actualStyle = styleData[alertData.type];
  return (
    <div
      className={`fixed z-[100] top-10 rounded-md left-1/2 translate-x-[-50%] w-3/4 mb-2 ${className}`}
    >
      <div
        className={`font-bold rounded-t-md px-4 py-2 flex items-center gap-1 ${actualStyle.colorBg}`}
      >
        {/*<img width={20} height={20} src={actualStyle.icon} alt="alert style" />*/}
        <p className={`capitalize font-bold font-Inter text-xl text-white`}>
          {alertData.heading || alertData.type}
        </p>
      </div>
      <div
        className={`flex items-center justify-between gap-4 rounded-b-md px-4 py-3 ${actualStyle.colorBg2}`}
      >
        <p
          className={`font-regular font-Inter text-sm ${actualStyle.textColor}`}
        >
          {alertData?.text && (
            <>
              {alertData.text.split("\n").map((errorText, index) => (
                <span key={index}>
                  {errorText}
                  <br />
                </span>
              ))}
            </>
          )}
        </p>
        <button onClick={onClose}>
          {getIconsComponents({name: 'close', className: "fill-primary-light w-5 h-5"})}
        </button>
      </div>
    </div>
  );
};

