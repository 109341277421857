// data
import { textData } from "../../../../assets/translations/en/textData";
// icons
import wordpress from "../../../../assets/icons/wordpress.svg";
import wix from "../../../../assets/icons/wix.svg";
import shopify from "../../../../assets/icons/shopify.svg";
import squarespace from "../../../../assets/icons/squarespace.svg";
// types
import {  DeployPageDataType } from "./mockDataTypes";

export const deployPageIconsData: DeployPageDataType = [
  {
    icon: wordpress,
    text: textData.deployPage.wordpress,
    width: 86,
    slug: "wordpress",
  },
  {
    icon: wix,
    text: textData.deployPage.wix,
    width: 71,
    slug: "wix",
  },
  {
    icon: shopify,
    text: textData.deployPage.shopify,
    width: 167,
    slug: "shopify",
  },
  {
    icon: squarespace,
    text: textData.deployPage.squarespace,
    width: 79,
    slug: "squarespace",
  },
  {
    text: textData.deployPage.other,
    slug: "other",
  },
];
