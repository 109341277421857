import React, {useEffect, useState} from "react";
import {textData} from "../../../assets/translations/en/textData";
import {ButtonCustom} from "../../../components/atoms/button/ButtonCustom";
import {useAppDispatch, useAppSelector} from "../../../stores/hooks/hooks";
import {getStorageName} from "../../../helpers/storageName/storageName";
import {setToken} from "../../../stores/slice/userAuthSlice";
import {editUserProfile, fetchUserProfile} from "../../../stores/thunks/userProfileThunk";
import {
  setChangePasswordModalOpen, setDeleteAccountModalOpen,
  setRemovePaymentModalOpen
} from "../../../stores/slice/userProfileSlice";
import {Field, Formik} from "formik";
import {ProfileValuesType} from "./mockDataTypes";
import {profileData} from "./mockData";
import {FieldFormik} from "../../../components/atoms/fieldFormik/FieldFormik";
import {Spinner} from "../../../components/atoms/spinner/Spinner";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ChangePasswordModal} from "./changePasswordModal/changePasswordModal";
import CardIcon from "../../../assets/icons/card.svg";
import PaypalIcon from "../../../assets/icons/paypal.svg";
import {RemovePaymentModal} from "./removePaymentModal/removePaymentModal";
import {AddPaymentModal} from "./addPaymentModal/addPaymentModal";
import {confirmPaymentMethod, fetchPaymentMethods} from "../../../stores/thunks/paymentsThunk";
import {paymentMethodMap} from "./mappings";
import {setPaymentMethodById, setDefaultPaymentMethodById, setPaymentMethodType} from "../../../stores/slice/paymentsSlice";
import VisaIcon from "../../../assets/icons/visa.svg";
import MasterCardIcon from "../../../assets/icons/mastercard.svg";
import {displayAlert} from "../../../stores/slice/alertSlice";
import {ChangePaymentModal} from "./changePaymentModal/changePaymentModal";
import AddPaymentWrapper from "./addPaymentWrapper/addPaymentWrapper";
import {DateTime} from "luxon";
import {DeleteAccountModal} from "./deleteAccountModal/deleteAccountModal";

const SettingsPageWrapper = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.userAuth.token);
  const profile = useAppSelector((state) => state.userProfile.profile);
  const paymentMethods = useAppSelector((state) => state.payments.paymentMethods);
  const defaultPaymentMethodById = useAppSelector((state) => state.payments.defaultPaymentMethodById);
  const isLoading = useAppSelector((state) => state.userProfile.isLoading);
  const isFetchLoading = useAppSelector((state) => state.userProfile.isFetchLoading);
  const [editMode, setEditMode] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (localStorage.getItem(getStorageName.accessToken)) {
      dispatch(setToken(localStorage.getItem(getStorageName.accessToken)));
    } else {
      dispatch(setToken(""));
    }
  }, []);
  useEffect( () => {
    if (token) {
      dispatch(fetchUserProfile());
      dispatch(fetchPaymentMethods());
    }
  }, [dispatch, token]);
  useEffect(() => {
    if (searchParams.get('redirect_status') === 'succeeded') {
      if(searchParams.get('setup_intent')) {
        dispatch((confirmPaymentMethod({setup_intent_id: searchParams.get('setup_intent')})))
        setSearchParams("");
      }
    } else if (searchParams.get('redirect_status') === 'failed') {
      dispatch(displayAlert({
        type: "error",
        text: textData.alertsMessage.wrong,
      }))
      setSearchParams("");
    }
  }, [searchParams]);

  const profileInitialValues = {
    email: profile?.email || "",
    full_name: profile?.full_name || "",
    gender: profile?.gender,
    username: profile?.username || "",
    phone_number: profile?.phone_number || "",
    birth_date: profile?.birth_date || "",
    enable_two_step_auth: profile?.enable_two_step_auth,
    security_question: profile?.security_question,
    security_question_answer: profile?.security_question_answer,
    city: profile?.city || "",
    language: profile?.language,
  }

  const proceedPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.slice(0, 1) !== "+" ? `+${phoneNumber}` : phoneNumber;
  }

  return (
    <section className="pt-8">
      {!isFetchLoading && <Formik
        validationSchema={profileData.validate}
        initialValues={profileInitialValues}
        // enableReinitialize
        onSubmit={(values: ProfileValuesType, {resetForm}) => {
          dispatch(
            editUserProfile({
              ...values,
              phone_number: proceedPhoneNumber(values.phone_number),
              default_payment_method: defaultPaymentMethodById,
              resetForm
            })
          )
        }}
        onReset={(values: ProfileValuesType) => {
          setEditMode(false);
          dispatch(fetchUserProfile());
        }}
      >
        {({handleSubmit, handleReset, values, setFieldValue, errors, touched}) => (
          <form onSubmit={handleSubmit}>
            <div className="container m-center h-[85vh] mt-[2vh] max-w-4xl">
              <div className="pr-4 relative h-full">
                <h1
                  className="font-Inter font-extrabold text-5xl lg:text-5xl xl:text-5xl leading-10 text-white capitalize">
                  {textData.settings.title}
                </h1>
                {!editMode && <button className="font-Roboto text-xs absolute top-10 right-20 z-[1]"
                                      onClick={() => setEditMode(true)}>{textData.settings.edit}</button>}
                <div className="mt-10 mb-6 max-h-[65vh] lg:pr-12 overflow-y-auto scrollbar relative">
                  <h2 className="font-Inter font-extrabold text-lg leading-6 text-white capitalize">
                    {textData.settings.accountInformation}
                  </h2>
                  <div className="mt-4 mb-6 rounded-2xl bg-[#0A0A0A] pt-[12px] px-[27px] pb-[2px]">
                    <FieldFormik
                      key="full_name"
                      field={profileData.fields[0]}
                      value={values['full_name']}
                      setFieldValue={(e) =>
                        setFieldValue("full_name", e.target.value)
                      }
                      variant="borderless"
                      showIcon={false}
                      readOnly={!editMode}
                      touched={touched["full_name"]}
                      errors={errors["full_name"]}
                      labelClass="text-xs lg:font-medium leading-4 opacity-50"
                      className="bg-transparent py-1.5 text-white text-sm leading-5 font-bold placeholder:text-sm placeholder:font-bold"
                      classNameWrapper="my-2 py-2 border-b border-primary-grey"
                    />
                    <div className="my-2 py-2 flex flex-col gap-1.5 xl:gap-4 border-b border-primary-grey">
                      <label className="block text-xs font-medium leading-4 opacity-50"
                             htmlFor="gender">{textData.settings.gender}</label>
                      <div className="flex flex-col gap-2 relative">
                        <Field as="select"
                               className="block bg-transparent w-full disabled:opacity-100 ml-[-4px] font-bold text-sm py-1.5"
                               id="gender" name="gender"
                               disabled={!editMode}
                               value={values['gender']} onChange={(e: any) => setFieldValue("gender", e.target.value)}>
                          <option value="0">Male</option>
                          <option value="1">Female</option>
                          <option value="2">They/Them</option>
                          <option value="3">Choose not to identify</option>
                        </Field>
                      </div>
                    </div>
                    <FieldFormik
                      key="birth_date"
                      field={profileData.fields[2]}
                      value={values['birth_date']}
                      setFieldValue={(e) =>
                        setFieldValue("birth_date", e.target.value)
                      }
                      variant="borderless"
                      showIcon={false}
                      readOnly={!editMode}
                      touched={touched["birth_date"]}
                      errors={errors["birth_date"]}
                      labelClass="text-xs lg:font-medium leading-4 normal-case opacity-50"
                      className="bg-transparent pt-2 pb-2 text-white text-sm leading-5 font-bold placeholder:text-sm placeholder:font-bold"
                      classNameWrapper="my-2 py-2"
                    />
                  </div>
                  <h2 className="font-Inter font-extrabold text-lg leading-6 text-white capitalize">
                    {textData.settings.contactDetails}
                  </h2>
                  <div className="mt-4 mb-6 rounded-2xl bg-[#0A0A0A] pt-[12px] pb-[2px] px-[27px]">
                    <FieldFormik
                      key="email"
                      field={profileData.fields[3]}
                      value={values['email']}
                      setFieldValue={(e) =>
                        setFieldValue("email", e.target.value)
                      }
                      variant="borderless"
                      showIcon={false}
                      readOnly={!editMode}
                      touched={touched["email"]}
                      errors={errors["email"]}
                      labelClass="text-xs lg:font-medium leading-4 opacity-50"
                      className="bg-transparent py-1.5 text-white text-sm leading-5 font-bold placeholder:text-sm placeholder:font-bold"
                      classNameWrapper="my-2 py-2 border-b border-primary-grey"
                    />
                    {profile?.email_to_change && (
                      <span className="font-Inter font-normal text-xs xl:text-sm text-yellow-200 translate-y-full">
                        please confirm verification to change: {profile?.email_to_change}
                      </span>
                    )}
                    <FieldFormik
                      key="phone_number"
                      field={profileData.fields[4]}
                      value={values['phone_number']}
                      setFieldValue={(e) =>
                        setFieldValue("phone_number", e.target.value)
                      }
                      variant="borderless"
                      showIcon={false}
                      readOnly={!editMode}
                      touched={touched["phone_number"]}
                      errors={errors["phone_number"]}
                      labelClass="text-xs lg:font-medium normal-case leading-4 opacity-50"
                      className="bg-transparent py-1.5 text-white text-sm leading-5 font-bold placeholder:text-sm placeholder:normal-case placeholder:font-bold"
                      classNameWrapper="my-2 py-2 border-b border-primary-grey"
                    />
                    <FieldFormik
                      key="username"
                      field={profileData.fields[5]}
                      value={values['username']}
                      setFieldValue={(e) =>
                        setFieldValue("username", e.target.value)
                      }
                      variant="borderless"
                      showIcon={false}
                      readOnly={!editMode}
                      touched={touched["username"]}
                      errors={errors["username"]}
                      labelClass="text-xs lg:font-medium leading-4 normal-case opacity-50"
                      className="bg-transparent pt-2 pb-2 text-white text-sm leading-5 font-bold placeholder:text-sm placeholder:font-bold"
                      classNameWrapper="my-2 py-2"
                    />
                  </div>
                  <h2 className="font-Inter font-extrabold text-lg leading-6 text-white capitalize">
                    {textData.settings.loginDetails}
                  </h2>
                  <div className="mt-4 mb-6 rounded-2xl bg-[#0A0A0A] pt-[16px] pb-[2px] px-[27px]">
                    <div className="my-2 pb-2 border-b border-primary-grey">
                      <label className="block font-Inter text-xs opacity-50"
                             htmlFor="password">{textData.settings.currentPassword}</label>
                      <div className="flex">
                        <input className="block bg-transparent w-full font-bold text-sm py-1.5" id="password"
                               value="******" readOnly={true} type="password"/>
                        <ButtonCustom
                          variant="primary"
                          disabled={!editMode}
                          className="whitespace-nowrap xl:py-2 xl:px-4 xl:text-xs w-[130px] h-[36px]"
                          handleClick={() => {
                            dispatch(setChangePasswordModalOpen(true));
                          }}
                          title={textData.settings.changePassword}
                          type="button"
                        />
                      </div>
                    </div>
                    <div className="my-2 py-2 border-b border-primary-grey">
                      <label className="block font-Inter text-xs opacity-50"
                             htmlFor="question">{textData.settings.securityQuestions}</label>
                      <div className="flex gap-2">
                        <Field as="select"
                               className="block bg-transparent w-1/2 disabled:opacity-100 ml-[-4px] font-bold text-sm py-1.5"
                               id="security_question" name="security_question"
                               value={values['security_question']}
                               disabled={!editMode}
                               onChange={(e: any) => setFieldValue("security_question", e.target.value)}>
                          <option value="0">What is your mother's maiden name?</option>
                          <option value="1">In which city were you born?</option>
                          <option value="2">What is the name of your first pet?</option>
                          <option value="3">What is your favorite book/movie/TV show?</option>
                          <option value="4">What is the model of your first car?</option>
                          <option value="5">What is the name of your favorite teacher?</option>
                        </Field>
                        <Field className="block bg-transparent w-1/2 font-bold text-sm py-1.5"
                               id="security_question_answer" name="security_question_answer"
                               value={values['security_question_answer']}
                               readOnly={!editMode}
                               onChange={(e: any) => setFieldValue("security_question_answer", e.target.value)}
                               placeholder="Type answer" type="text"/>
                      </div>
                    </div>
                    <div className="my-2 py-2 flex flex-col gap-1.5 xl:gap-4">
                      <label className="block font-Inter text-xs font-medium leading-4 opacity-50"
                             htmlFor="twoStepVerification">{textData.settings.twoStepVerification}</label>
                      <Field as="select"
                             className="block bg-transparent w-full disabled:opacity-100 ml-[-4px] font-bold text-sm py-1.5"
                             id="twoStepVerification" name="enable_two_step_auth"
                             value={values['enable_two_step_auth']}
                             disabled={!editMode}
                             onChange={(e: any) => setFieldValue("enable_two_step_auth", e.target.value)}>
                        <option value="false">Disabled</option>
                        <option value="true">Enabled</option>
                      </Field>
                    </div>
                  </div>
                  <h2 className="font-Inter font-extrabold text-lg leading-6 text-white capitalize">
                    {textData.settings.countryAndLanguage}
                  </h2>
                  <div className="mt-4 mb-6 rounded-2xl bg-[#0A0A0A] pt-[16px] pb-[2px] px-[27px]">
                    <FieldFormik
                      key="email"
                      field={profileData.fields[7]}
                      value={values['city']}
                      setFieldValue={(e) =>
                        setFieldValue("city", e.target.value)
                      }
                      variant="borderless"
                      showIcon={false}
                      readOnly={!editMode}
                      touched={touched["city"]}
                      errors={errors["city"]}
                      labelClass="text-xs lg:font-medium leading-4 opacity-50"
                      className="bg-transparent py-1.5 text-white text-sm leading-5 font-bold placeholder:text-sm placeholder:font-bold"
                      classNameWrapper="my-2 py-2 border-b border-primary-grey"
                    />
                    <div className="my-2 py-2 flex flex-col gap-1.5 xl:gap-4">
                      <label className="block font-Inter text-xs font-medium leading-4 opacity-50"
                             htmlFor="twoStepVerification">{textData.settings.language}</label>
                      <Field as="select"
                             className="block bg-transparent w-full disabled:opacity-100 ml-[-4px] font-bold text-sm py-1.5"
                             id="language" name="language"
                             value={values['language']}
                             disabled={!editMode}
                             onChange={(e: any) => setFieldValue("language", e.target.value)}>
                        <option value="en">English</option>
                        <option value="fr">French</option>
                        <option value="de">Deutsch</option>
                      </Field>
                    </div>
                  </div>
                  <h2 className="font-Inter font-extrabold text-lg leading-6 text-white capitalize">
                    {textData.settings.subscriptionPlan}
                  </h2>
                  <div className="mt-4 mb-6 rounded-2xl bg-[#0A0A0A] pt-[16px] pb-[2px] px-[27px]">
                    <div className="mt-4 mb-6 pb-6 border-b border-primary-grey">
                      <div className="flex justify-between align-middle">
                        <b className="font-Inter font-bold text-sm leading-9 text-white capitalize">
                          {profile?.membership?.display_name} -
                          {profile?.membership?.price ? " $" + profile?.membership?.price : ' Free'}
                          {profile?.membership?.billing_period === 0 && "/" + textData.selectMembership.monthly}
                          {profile?.membership?.billing_period === 1 && "/" + textData.selectMembership.annual}
                        </b>
                        <span className="font-Inter text-sm leading-9 opacity-50">{profile?.membership_valid_until && " Renews " + DateTime.fromISO(profile?.membership_valid_until).toFormat('MM/dd/yyyy')}</span>
                        <ButtonCustom
                          variant="primary"
                          className="whitespace-nowrap xl:py-2 xl:px-4 xl:text-xs w-[130px] h-[36px]"
                          handleClick={() => navigate("/select-membership")}
                          disabled={!editMode}
                          title={textData.settings.changePlan}
                          type="button"
                        />
                      </div>
                    </div>
                    <div className="my-6 pb-2">
                      <div className="flex justify-between align-middle">
                        <b className="font-Inter font-bold text-sm leading-9 text-white capitalize">
                          {textData.settings.transactionHistory}
                        </b>
                        <ButtonCustom
                          variant="primary"
                          className="whitespace-nowrap xl:py-2 xl:px-4 xl:text-xs w-[130px] h-[36px]"
                          handleClick={() => navigate("/transactions")}
                          title={textData.btns.view}
                          type="button"
                        />
                      </div>
                    </div>
                  </div>
                  <h2 className="font-Inter font-extrabold text-lg leading-6 text-white capitalize">
                    {textData.settings.linkedAccounts}
                  </h2>
                  <div className="mt-4 mb-6 rounded-2xl bg-[#0A0A0A] pt-[16px] pb-[2px] px-[27px]">
                    <AddPaymentWrapper editMode={editMode} type='card' />
                    <AddPaymentWrapper editMode={editMode} type='paypal' />
                    {paymentMethods?.map((paymentMethod, index) => (
                      <div className={`my-2 ${index !== (paymentMethods?.length - 1) ? "border-b border-primary-grey": ""}`} key={index}>
                        <div className="flex my-2 py-2 align-middle">
                          <div
                            className="flex justify-center items-center w-[46px] h-[46px] mr-4 border border-dark-250 rounded-full">
                            {paymentMethod.payment_method_type === 1 ?
                              <img src={PaypalIcon} alt="paypal" className="h-fit"/> :
                              paymentMethod.card_brand === 'visa' ?
                                <img src={VisaIcon} alt="visa" className="h-fit"/> :
                              paymentMethod.card_brand === 'mastercard' ?
                                <img src={MasterCardIcon} alt="mastercard" className="h-fit"/> :
                                <img src={CardIcon} alt="card" className="h-fit"/>
                            }
                          </div>
                          <div className="flex flex-col gap-1.5">
                            <label
                              className="block font-Inter text-xs font-medium leading-4 opacity-50">{paymentMethodMap[paymentMethod.payment_method_type]}</label>
                            {paymentMethod.card_last_numbers &&
                                <p className="w-[200px]">...{paymentMethod.card_last_numbers}</p>}
                            {paymentMethod.paypal_payer_email &&
                                <p className="w-[200px] overflow-hidden overflow-ellipsis">{paymentMethod.paypal_payer_email}</p>}
                          </div>
                          <div className="flex w-[180px] items-center justify-center">
                            {defaultPaymentMethodById === paymentMethod.id ?
                              <p className="text-xs text-white">{textData.settings.primary}</p> :
                              <button
                                className="text-xs text-blue-500"
                                type="button"
                                disabled={!editMode}
                                onClick={() => {
                                  dispatch(setDefaultPaymentMethodById(paymentMethod.id));
                                }}>
                                {textData.settings.makePrimary}
                              </button>
                            }
                          </div>
                          <div className="flex w-[100px] items-center justify-center">
                            <button
                              className="text-xs text-red-500"
                              disabled={!editMode}
                              type="button"
                              onClick={() => {
                                dispatch(setPaymentMethodById(paymentMethod.id));
                                dispatch(setRemovePaymentModalOpen(true));
                              }}>
                              {textData.settings.remove}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <button
                    className="text-xs mx-4 font-bold text-red-500"
                    type="button"
                    onClick={() => {
                      dispatch(setDeleteAccountModalOpen(true));
                    }}>
                    {textData.settings.deleteAccount}
                  </button>
                </div>
                {editMode && <div className="flex mt-[5vh] max-h-[15vh] mr-[50px] justify-end">
                    <ButtonCustom
                        variant="primary"
                        size="l"
                        handleClick={() => {
                          setEditMode(false);
                          handleReset();
                        }}
                        title={textData.btns.btnDiscard}
                        type="button"
                    />
                    <ButtonCustom
                        className="ml-20"
                        variant="secondary"
                        size="l"
                        title={textData.btns.btnSave}
                        type="submit"
                    />
                </div>}
              </div>
            </div>
          </form>
        )}
      </Formik>}
      {(isLoading || isFetchLoading) && <Spinner page/>}
      <AddPaymentModal/>
      <RemovePaymentModal/>
      <ChangePaymentModal/>
      <DeleteAccountModal/>
      <ChangePasswordModal/>
    </section>
  );
};

export default SettingsPageWrapper;
