import React from "react";
// types
import { HandleClickDataType, HeaderCallbackNameType } from "./mockDataTypes";
// data
import { headerData } from "./mockData";
// components
import { ButtonCustom } from "../../atoms/button/ButtonCustom";
import { MenuItemForCreateBot } from "./components/menuItemForCreateBot/MenuItemForCreateBot";

type Props = {
  data: HeaderCallbackNameType[];
  handleClickData: HandleClickDataType;
  className?: string;
  classNameWrapper?: string;
  disabled?: boolean;
  isMenuDisabled?: boolean;
  isBtnDisabled?: boolean;
  disabledButtonStates?: { [key in HeaderCallbackNameType]?: boolean };
  classNameIcon?: string[];
};

export const Header = ({
  data,
  handleClickData,
  classNameWrapper = "",
  className = "",
  disabled = false,
  isMenuDisabled = false,
  disabledButtonStates = {},
  classNameIcon = [],
}: Props) => {
  return (
    <div
      className={`fixed z-[20] pt-8 pb-1 px-5 lg:px-[75px] bg-transparent top-0 left-0 flex justify-end w-full ${classNameWrapper}`}
    >
      <header className={`flex items-center gap-3 ${className}`}>
        {headerData
          .filter((head) => data.includes(head.callbackName))
          .map((item, index) => {
            const isButtonDisabled =
              disabled || disabledButtonStates[item.callbackName];
            if (item.menuBtn) {
              return (
                <MenuItemForCreateBot
                  btnInfo={item}
                  key={item.title + item.callbackName}
                  disabled={isMenuDisabled}
                />
              );
            } else {
              return (
                <ButtonCustom
                  key={item.title + item.callbackName}
                  variant={item.variant}
                  handleClick={handleClickData[item.callbackName]}
                  title={item.title}
                  icon={item.icon}
                  disabled={isButtonDisabled}
                  classNameIcon={classNameIcon[index]}
                />
              );
            }
          })}
      </header>
    </div>
  );
};
