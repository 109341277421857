import { instance } from "../axios/axiosConfig";
// types
import { FetchDataBodyType, SendMessageBodyType } from "../types/chatUserTypes";

const chatUserApi = {
  startChatData(body: FetchDataBodyType) {
    return instance.post(`/chats/`, body);
  },
  fetchChatDataById(id: string) {
    return instance.get(`/chats/${id}`);
  },
  sendChatMessage(body: SendMessageBodyType) {
    return instance.post(`/chats/send_message/`, body);
  },
};

export default chatUserApi;
