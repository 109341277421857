import React from "react";
// data
import { textData } from "../../../../../../../../assets/translations/en/textData";
// types
import { UserReviewDataType } from "../../../../../../../../stores/types/marketplaceTypes";
// components
import { ButtonCustom } from "../../../../../../../../components/atoms/button/ButtonCustom";
// image
import notFoundImage from "../../../../../../../../assets/img/notFoundImage.png";

export const ReviewSectionItem = ({ user, text }: UserReviewDataType) => {
  return (
    <div className="flex mt-5 justify-between">
      <div className="w-full flex items-center gap-4">
        <div className="min-w-fit w-[34px] h-[34px] rounded-full overflow-hidden">
          <img
            src={user?.avatar || notFoundImage}
            alt="comment text"
            className={`block w-full h-full object-center ${
              user?.avatar ? "object-cover" : "object-contain"
            }`}
          />
        </div>
        <div className="flex flex-col gap-1">
          <p className="font-Roboto italic text-xs text-dark-190 leading-4">
            {user.full_name || textData.marketplacePage.defaultUsername}
          </p>
          <p className="font-Inter font-normal text-sm leading-5 break-words">
            {text}
          </p>
        </div>
      </div>
      <ButtonCustom
        variant={"noBg"}
        title={textData.btns.report}
        className="font-Roboto text-dark-190 xl:text-xs"
      />
    </div>
  );
};
