import React, { useState } from "react";
// types
import { SummarySlugType } from "./summaryElement/mockDataTypes";
// components
import { SummaryElement } from "./summaryElement/SummaryElement";
import { CloneTable } from "./cloneTable/CloneTable";
import { DashboardChart } from "./dashboardChart/DashboardChart";

export const DashboardContent = () => {
  const [period, setPeriod] = useState<SummarySlugType>("week");

  return (
    <div className="mt-3 rounded-[16px] w-full border border-opacity-15 border-dark-450 bg-black ">
      <SummaryElement period={period} setPeriod={setPeriod} />
      <DashboardChart />
      <CloneTable />
    </div>
  );
};
