import { instance } from "../axios/axiosConfig";
// types
import {
  DialogTemplateBodyCreateType,
  DialogTemplateList,
  SetSavedTemplateType,
  TemplateImportToDialogDataType,
} from "../types/templatesTypes";

const templatesApi = {
  getTemplatesList() {
    return instance.get(`templates/`);
  },
  createTemplate(data: DialogTemplateBodyCreateType) {
    return instance.post(`templates/`, data);
  },
  getTemplateById(id: string) {
    return instance.get(`templates/${id}/`);
  },
  updateTemplateById(data: DialogTemplateList) {
    return instance.put(`templates/${data.id}/`, data.body);
  },
  updatePartiallyTemplateById(data: DialogTemplateList) {
    return instance.patch(`templates/${data.id}/`, data.body);
  },
  deleteTemplateById(id: string) {
    return instance.delete(`templates/${id}/`);
  },
  templateImportToDialog(data: TemplateImportToDialogDataType) {
    return instance.post(`/templates/${data.id}/import_to_dialog/`, data.body);
  },
  templateSetSaved(data: SetSavedTemplateType) {
    return instance.post(`/templates/${data.id}/set_saved/`, data.body);
  },
};

export default templatesApi;
