import React from "react";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
import { footerData } from "../../../mockData";
// types
import { FooterInitialValuesType } from "../../../mockDataType";
// components
import { Formik } from "formik";
import { FieldFormik } from "../../../../../atoms/fieldFormik/FieldFormik";
import { ButtonCustom } from "../../../../../atoms/button/ButtonCustom";

export const SubscribeEmail = () => {
  return (
    <div className="flex flex-col gap-4 xl:gap-7">
      <p className="capitalize font-Inter font-bold text-md leading-3 text-white">
        {textData.footer.subscribe}
      </p>
      <Formik
        validationSchema={footerData.validate}
        initialValues={footerData.initialValues}
        onSubmit={(values: FooterInitialValuesType) => {
          console.log(values);
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors, touched }) => (
          <form
            onSubmit={handleSubmit}
            className="flex justify-between items-stretch gap-3 lg:gap-10 w-full"
          >
            <FieldFormik
              field={footerData.field}
              value={values[footerData.field.name]}
              setFieldValue={(e) => {
                setFieldValue(footerData.field.name, e.target.value);
              }}
              variant="dark"
              touched={touched[footerData.field.name]}
              errors={errors[footerData.field.name]}
              labelClass="text-xs font-medium leading-4"
              className="bg-transparent pt-3 pb-3 !pl-4 text-white text-sm leading-5 font-bold placeholder:text-sm placeholder:font-bold"
              classNameWrapper="flex-1 min-w-[200px]"
            />
            <ButtonCustom
              variant="secondary"
              title={textData.btns.subscribe}
              type="submit"
            />
          </form>
        )}
      </Formik>
    </div>
  );
};
