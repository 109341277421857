import React from "react";
// ензуы
import { BranchOrientationType } from "./treeButtonTypes";
import { ChildRepliesType } from "../../../../../stores/types/treeBotTypes";

// icon
import { ReactComponent as LeftBranchIcon } from "../../../../../assets/icons/leftBranch.svg";

export const getOrientation = (
  data: ChildRepliesType[]
): BranchOrientationType[] | null => {
  if (data) {
    const length = data.length;
    const medium = length % 2 === 0 ? length / 2 : Math.round(length / 2);
    if (length % 2 === 0) {
      return data.map((item, index) =>
        index + 1 <= medium ? "left" : "right"
      );
    } else {
      return data.map(
        (item, index) =>
          (index + 1 < medium && "left") ||
          (index + 1 > medium && "right") ||
          "default"
      );
    }
  } else {
    return null;
  }
};

export const branchElement = (branchOrientation: BranchOrientationType) => {
  switch (branchOrientation) {
    case "right":
      return (
        <div
          key={Math.random() * 100 + branchOrientation}
          style={{ transform: "translate(-7%, 100%) scale(-1, 1)" }}
          className="absolute left-1/2 bottom-[-8px] mt-2"
        >
          <LeftBranchIcon />
        </div>
      );

      break;
    case "left":
      return (
        <div
          key={Math.random() * 100 + branchOrientation}
          style={{ transform: "translate(-94%, 100%) scale(1, 1)" }}
          className="absolute left-1/2 bottom-[-8px] mt-2"
        >
          <LeftBranchIcon />
        </div>
      );
      break;
    default:
      return (
        <div
          key={Math.random() * 100 + branchOrientation}
          className="absolute left-1/2 translate-y-full translate-x-[-50%] bottom-[-8px]"
        >
          <div className="w-[3px] h-[60px] bg-primary-grey" />
        </div>
      );
  }
};
