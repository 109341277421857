import React, { Fragment } from "react";
// hooks
import { useAppDispatch } from "../../../../../../../stores/hooks/hooks";
// components
import { Menu, Transition } from "@headlessui/react";
// icons
import { ReactComponent as MoreIcon } from "../../../../../../../assets/icons/more.svg";
// store
import {
  setCloneById,
  setCloneModalOpen,
  setDeleteCloneModalOpen,
  setDuplicateCloneModalOpen,
} from "../../../../../../../stores/slice/botsSlice";
// data
import { textData } from "../../../../../../../assets/translations/en/textData";
// types
import { ClonesDataItemTypes } from "../../../../../../../stores/types/botsTypes";

type Props = {
  clone: ClonesDataItemTypes;
  marketplace?: boolean;
};

export const MenuItemForClones = ({ clone, marketplace = false }: Props) => {
  // hooks
  const dispatch = useAppDispatch();
  // helpers
  const onEditClone = () => {
    dispatch(setCloneById(clone));
    dispatch(setCloneModalOpen(true));
  };
  const onDeleteClone = () => {
    dispatch(setCloneById(clone));
    dispatch(setDeleteCloneModalOpen(true));
  };
  const onDuplicateClone = () => {
    dispatch(setCloneById(clone));
    dispatch(setDuplicateCloneModalOpen(true));
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button>
        <MoreIcon className="hover:opacity-80 w-4 h-4 lg:w-8 lg:h-8" />
      </Menu.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Menu.Items className="origin-top-right absolute right-0 w-24 bg-dark-700 py-1 rounded-md shadow-lg">
          {!marketplace && (
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={onEditClone}
                  className={`${
                    active &&
                    "font-extrabold bg-secondary-green text-secondary-dark"
                  } w-full text-left px-4 py-2 text-sm font-bold text-dark-400 capitalize`}
                >
                  {textData.btns.editBtn}
                </button>
              )}
            </Menu.Item>
          )}
          {!marketplace && (
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={onDuplicateClone}
                  className={`${
                    active &&
                    "font-extrabold bg-secondary-green text-secondary-dark"
                  } w-full text-left px-4 py-2 text-sm font-bold text-dark-400 capitalize`}
                >
                  {textData.btns.duplicateBtn}
                </button>
              )}
            </Menu.Item>
          )}
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={onDeleteClone}
                className={`${
                  active &&
                  "font-extrabold bg-secondary-green text-secondary-dark"
                } w-full text-left px-4 py-2 text-sm font-bold text-dark-400 capitalize`}
              >
                {textData.btns.deleteBtn}
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
