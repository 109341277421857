// data
import { textData } from "../../../../assets/translations/en/textData";
// icons
import ProPlanIcon from "../../../../assets/icons/proPlan.svg";
import ProPlusPlanIcon from "../../../../assets/icons/proPlusPlan.svg";
import {
  ListPlanPreferencesMockDataType,
  SubscriptionMockData,
} from "./mockDataType";

// plans list data
export const listBasicPlan: ListPlanPreferencesMockDataType = [
  {
    title: textData.subscriptionPlansPage.listPlanBasic.creds25,
    id: "creds10",
  },
  {
    title: textData.subscriptionPlansPage.listPlanBasic.aimarket,
    id: "aimarket",
  },
  {
    title: textData.subscriptionPlansPage.listPlanBasic.idverif,
    id: "idverif",
  },
];
export const listPersonalPlan: ListPlanPreferencesMockDataType = [
  {
    title: textData.subscriptionPlansPage.listPlanPersonal.creds100,
    id: "creds100",
  },
  {
    title: textData.subscriptionPlansPage.listPlanPersonal.aimarket,
    id: "aimark",
  },
  {
    title: textData.subscriptionPlansPage.listPlanPersonal.idverif,
    id: "idver",
  },
];
export const listBusinessPlan: ListPlanPreferencesMockDataType = [
  {
    title: textData.subscriptionPlansPage.listPlanMedium.creds500,
    id: "creds500",
  },
  {
    title: textData.subscriptionPlansPage.listPlanMedium.aimarket,
    id: "aim",
  },
  {
    title: textData.subscriptionPlansPage.listPlanMedium.idverif,
    id: "idv",
  },
  {
    title: textData.subscriptionPlansPage.listPlanMedium.support,
    id: "support",
  },
];
export const listEnterprisePlan: ListPlanPreferencesMockDataType = [
  {
    title: textData.subscriptionPlansPage.listPlanEnterprise.credsUnlim,
    id: "credsUnlim",
  },
  {
    title: textData.subscriptionPlansPage.listPlanEnterprise.aimarket,
    id: "ai",
  },
  {
    title: textData.subscriptionPlansPage.listPlanEnterprise.idverif,
    id: "idverificat",
  },
  {
    title: textData.subscriptionPlansPage.listPlanEnterprise.support,
    id: "supporting",
  },
];

export const subscriptionPlanMockData: SubscriptionMockData = [
  {
    slug: "basic",
    planName: textData.subscriptionPlansPage.basic,
    recommended: false,
    listPlan: listBasicPlan,
    planDetails: textData.subscriptionPlansPage.planDetails,
    planPrice: "Free",
  },
  {
    slug: "personal",
    planName: textData.subscriptionPlansPage.personal,
    recommended: false,
    listPlan: listPersonalPlan,
    planDetails: textData.subscriptionPlansPage.planDetails,
    planPrice: "$9.99/month",
  },
  {
    slug: "business",
    planName: textData.subscriptionPlansPage.business,
    recommended: true,
    listPlan: listBusinessPlan,
    planDetails: textData.subscriptionPlansPage.planDetails,
    planPrice: "Starting at $99.99/month",
    icon: ProPlanIcon,
  },
  {
    slug: "enterprise",
    planName: textData.subscriptionPlansPage.enterprise,
    recommended: false,
    listPlan: listEnterprisePlan,
    planDetails: textData.subscriptionPlansPage.planDetails,
    planPrice: "Contact Sales",
    icon: ProPlusPlanIcon,
  },
];
