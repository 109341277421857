import React from "react";
import CardIcon from "../../../../assets/icons/card.svg";
import {textData} from "../../../../assets/translations/en/textData";
import {setPaymentMethodType} from "../../../../stores/slice/paymentsSlice";
import {setAddPaymentModalOpen} from "../../../../stores/slice/userProfileSlice";
import AddIcon from "../../../../assets/icons/add.svg";
import {useAppDispatch} from "../../../../stores/hooks/hooks";
import PaypalIcon from "../../../../assets/icons/paypal.svg";

const AddPaymentWrapper = ({editMode, type}: {editMode: boolean, type: 'card' | 'paypal'}) => {

  const dispatch = useAppDispatch();

  return (
    <div className="my-2 border-b border-primary-grey">
      <div className="flex my-2 py-2 align-middle">
        <div
          className="flex justify-center items-center w-[46px] h-[46px] mr-4 border border-dark-250 rounded-full">
          <img src={type === 'card' ? CardIcon : PaypalIcon} alt="card" className="h-fit"/>
        </div>
        <div className="flex flex-col gap-1.5">
          <label
            className="block font-Inter text-xs font-medium leading-4 opacity-50">{type === 'card' ? textData.settings.addCard : textData.settings.addPayPal}</label>
          <button
            className="w-[18px] h-[18px]"
            type="button"
            disabled={!editMode}
            onClick={() => {
              dispatch(setPaymentMethodType(type === 'card' ? 0 : 1));
              dispatch(setAddPaymentModalOpen(true));
            }}>
            <img src={AddIcon} alt="add" className=""/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddPaymentWrapper;