import {createValidate} from "../../../../helpers/validation/validationHelper";
// data
import {textData} from "../../../../assets/translations/en/textData";
// types
import {SignInDataType} from "./mockDataTypes";

export const signInData: SignInDataType = {
    validate: createValidate(["email", "password"]),
    fields: [
        {
            type: "email",
            label: textData.fields.email.label,
            placeholder: textData.fields.email.placeholder,
            name: "email",
        },
        {
            type: "password",
            label: textData.fields.password.label,
            placeholder: textData.fields.password.placeholder,
            name: "password",
        },
    ],
    initialValues: {
        email: "",
        password: "",
    },
};
