import React, { useEffect } from "react";
// hooks
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks/hooks";
// types
import { CallbackDataType } from "../../molecules/modalWrapper/modalWrapperTypes";
// data
import { textData } from "../../../assets/translations/en/textData";
import { sideBarLogOutBtnData } from "./components/sideBar/mockData";
// components
import { SideBar } from "./components/sideBar/SideBar";
import { Spinner } from "../../atoms/spinner/Spinner";
import { ModalWrapper } from "../../molecules/modalWrapper/ModalWrapper";
import { Alert } from "./components/alert/Alert";
import { MarketplaceHeaderFilterSection } from "./components/marketplaceFilter/MarketplaceHeaderFilterSection";
// slice
import { setLogOutShow, setToken } from "../../../stores/slice/userAuthSlice";
import { closeAlert, closeAllAlert } from "../../../stores/slice/alertSlice";
import {
  clearPaginationPage,
  clearPathRequest,
  clearSelectedCategory,
  clearToolsList,
  setSearchActiveValue,
  setSearchValue,
} from "../../../stores/slice/marketplaceSlice";
// helpers
import { getStorageName } from "../../../helpers/storageName/storageName";
import { MobileApp } from "../../molecules/mobileApp/MobileApp";

type Props = {
  children: any;
  sideBar?: boolean;
  className?: string;
  noPadding?: boolean;
  classNameWrapper?: string;
  marketplace?: boolean;
  mobileApp?: boolean;
  resetTour?: () => void;
};

export const Layout = ({
  children,
  sideBar = true,
  classNameWrapper = "",
  className = "",
  noPadding = false,
  marketplace = false,
  mobileApp = false,
  resetTour,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // store
  const isLoadingUserAuth = useAppSelector((state) => state.userAuth.isLoading);
  const logOutShow = useAppSelector((state) => state.userAuth.logOutShow);
  const alertProps = useAppSelector((state) => state.alert.alertProps);
  // useEffect
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(closeAllAlert());
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [alertProps.length]);

  useEffect(() => {
    if (!location.pathname.includes("network")) {
      dispatch(clearPaginationPage());
      dispatch(clearSelectedCategory());
      dispatch(clearToolsList());
      dispatch(clearPathRequest());
      dispatch(setSearchValue(""));
      dispatch(setSearchActiveValue(""));
    }
  }, [location]);

  // helpers
  const callback: CallbackDataType = {
    logOut: () => {
      {
        localStorage.removeItem(getStorageName.refreshToken);
        localStorage.removeItem(getStorageName.accessToken);
        localStorage.removeItem(getStorageName.serviceAuth);

        // remove tooltips items
        localStorage.removeItem("seenSideBarTooltips");
        localStorage.removeItem("isShownSidebar");
        localStorage.removeItem("seenDashboardTooltips");
        localStorage.removeItem("seenActivityPageTooltips");
        localStorage.removeItem("seenActivityCloneItemTooltips");

        dispatch(setToken(""));
        navigate("/signin");
        dispatch(setLogOutShow(false));
      }
    },
    discard: () => dispatch(setLogOutShow(false)),
  };

  if (isLoadingUserAuth) {
    return (
      <section className="h-screen flex justify-center items-center">
        <Spinner />
      </section>
    );
  }

  const standardPadding = "py-8 pr-14 pl-[76px]";
  const paddingStyles = noPadding ? "" : standardPadding;

  return (
    <>
      {mobileApp && <MobileApp />}
      <section
        className={`${paddingStyles} relative ${
          mobileApp ? "hidden md:block" : ""
        } ${classNameWrapper}`}
      >
        {alertProps.length > 0 &&
          alertProps.map((alertProp) => (
            <Alert
              key={alertProp.id}
              alertData={alertProp}
              onClose={() => dispatch(closeAlert(alertProp.id))}
            />
          ))}
        {sideBar && <SideBar />}
        {marketplace && (
          <MarketplaceHeaderFilterSection resetTour={resetTour} />
        )}
        <div className={`max-w-[1920px] m-center ${className}`}>{children}</div>
        <ModalWrapper
          open={logOutShow}
          onClose={() => dispatch(setLogOutShow(false))}
          title={textData.confirm}
          text={textData.sideBar.prompt}
          btnData={sideBarLogOutBtnData}
          callbackData={callback}
        />
      </section>
    </>
  );
};
