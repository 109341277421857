import React from "react";
// helper
import { getIconsComponents } from "../../../helpers/iconsComponents/iconsComponents";
// types
import { IconsComponentsItemTypes } from "../../../helpers/iconsComponents/iconsComponentsTypes";
import {
  ButtonCustomVariantType,
  ButtonTypeItemType,
  VariantStylesType,
} from "./buttonCustomType";
// data
import { textData } from "../../../assets/translations/en/textData";
// components
import { Spinner } from "../spinner/Spinner";

type Props = {
  variant: ButtonCustomVariantType;
  handleClick?: (e: any) => void;
  title?: string;
  icon?: IconsComponentsItemTypes;
  disabled?: boolean;
  type?: ButtonTypeItemType;
  size?: string;
  className?: string;
  classNameIcon?: string;
  spinner?: boolean;
  adaptive?: boolean;
};

export const ButtonCustom = ({
  icon,
  variant,
  title,
  handleClick,
  disabled = false,
  type = "button",
  spinner = false,
  adaptive = true,
  size,
  className = "",
  classNameIcon = "",
}: Props) => {
  const variantStyles: VariantStylesType = {
    primary: `${
      disabled ? "bg-gray-100 text-gray-200" : "bg-primary-grey text-white"
    }`,
    secondary: `${
      disabled
        ? "bg-gray-100 text-gray-200"
        : "bg-secondary-green text-secondary-dark"
    }`,
    red: `${
      disabled
        ? "bg-gray-100 text-gray-200"
        : "bg-red-100 text-white"
    }`,
    yellow: `${
      disabled
        ? "bg-gray-100 text-gray-200"
        : "bg-yellow-350 text-secondary-dark"
    }`,
    tertiary: `${
      disabled ? "bg-gray-100 text-gray-200" : "bg-white text-secondary-dark"
    }`,
    violet: `${
      disabled ? "bg-gray-100 text-gray-200" : "bg-violet-200 text-white"
    }`,
    noBg: `${disabled ? "bg-gray-100 text-gray-200" : "bg-transparent"}`,
    aphid: `${disabled ? "bg-gray-100 text-gray-200" : "bg-white"}`,
    noBgWithBorder: `border border-white ${
      disabled ? "bg-gray-100 text-gray-200" : "bg-transparent"
    }`,
  };

  if (variant === "aphid") {
    return (
      <button
        disabled={disabled}
        className={`flex items-center hover:opacity-80 justify-center px-2 py-4 xl:py-6 xl:px-2.5 relative rounded-[50px] font-Montserrat font-bold text-xs xl:text-lg leading-4 tracking-[0.5px] ${variantStyles[variant]} ${className}`}
        onClick={handleClick}
        type={type}
      >
        {/*<div className="hidden xl:block absolute left-3 top-1/2 translate-y-[-50%]">*/}
        {/*  {iconsComponents.aphidWhite}*/}
        {/*</div>*/}
        <div className="absolute left-3 top-1/2 translate-y-[-50%]">
          {spinner ?
            <Spinner className="absolute left-3 top-1/2 translate-y-[-50%]" btn /> :
            getIconsComponents({ name: "aphidWhiteSmall" })
          }
        </div>
        <p className="text-black">
          {textData.signInPage.title}
          <span className="text-violet-200 pl-2">{textData.signInPage.aphid}</span>
        </p>
      </button>
    );
  }
  if (icon) {
    return (
      <button
        disabled={disabled}
        className={`${
          disabled ? "" : "hover:opacity-80"
        } capitalize flex items-center justify-center text-center py-2 px-4 xl:py-3 xl:px-6 gap-2 rounded font-Inter font-semibold lg:font-bold text-xs xl:text-base leading-4 ${
          variantStyles[variant]
        } ${spinner ? "relative" : ""} ${className}`}
        onClick={handleClick}
        type={type}
      >
        {getIconsComponents({
          name: icon,
          className: disabled ? "fill-gray-200" : classNameIcon,
        })}
        {title}
        {spinner && <Spinner btn />}
      </button>
    );
  }
  return (
    <button
      disabled={disabled}
      className={`${
        disabled ? "" : " hover:opacity-80"
      } capitalize flex justify-center py-2 px-4 ${adaptive && "xl:py-4 xl:px-7 xl:text-base"} gap-2 items-center rounded font-Inter font-bold text-xs leading-4 ${
        variantStyles[variant]
      } ${size === "l" ? "w-[165px] h-[46px]" : ""} ${spinner ? "relative" : ""} ${className}`}
      onClick={handleClick}
      type={type}
    >
      {title}
      {spinner && <Spinner btn />}
    </button>
  );
};
