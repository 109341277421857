import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks/hooks";
import {
  clearSelectedAvatar,
  setUploadFileModalOpen,
} from "../../../stores/slice/botsSlice";
// icon
import { ReactComponent as DragIcon } from "../../../assets/icons/drag.svg";
import { ReactComponent as DefaultFileIcon } from "../../../assets/icons/defaultFile.svg";

// data
import { textData } from "../../../assets/translations/en/textData";
import { uploadFilesBtnData } from "./mockData";
// components
import { ModalWrapper } from "../../molecules/modalWrapper/ModalWrapper";
import { uploadAvatar } from "../../../stores/thunks/botsThunk";
import userCustom from "../../../assets/img/userCustom.png";
import {getIconsComponents} from "../../../helpers/iconsComponents/iconsComponents";

type Props = {
  setFieldValue: (value: any) => void;
};

export const UploadFileModal = ({ setFieldValue }: Props) => {
  const dispatch = useAppDispatch();
  const [percent, setPercent] = useState(0);
  const [bytes, setBytes] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [file, setFile] = useState<null | any>(null);

  const uploadFileModalOpen = useAppSelector(
    (state) => state.bots.uploadFileModalOpen
  );
  const options = {
    onUploadProgress: (progressEvent: any) => {
      setShowProgress(true);
      const { loaded, total, bytes } = progressEvent;
      setBytes(Math.round(bytes / 1000));
      setPercent(Math.round((100 * loaded) / total));
    },
  };

  const handleOnChange = (event: any) => {
    event.preventDefault();
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      setFile(file);
      setFieldValue(file);
      dispatch(uploadAvatar({ avatar: file, options }));
    }
  };

  const handleDiscard = () => {
    dispatch(clearSelectedAvatar());
    setShowProgress(false);
    setFile(null);
    setFieldValue(null);
  };

  return (
    <ModalWrapper
      open={uploadFileModalOpen}
      className="pt-8 pb-7 px-9"
      onClose={() => {
        handleDiscard();
        dispatch(setUploadFileModalOpen(false));
      }}
      title={textData.createNewClone.uploadImage}
      btnData={uploadFilesBtnData}
      callbackData={{
        save: () => {
          dispatch(setUploadFileModalOpen(false));
        },
        discard: () => {
          handleDiscard();
          dispatch(setUploadFileModalOpen(false));
        },
      }}
    >
      <div className="flex items-center gap-[44px] xl:gap-[72px] pl-[28px] xl:pl-[44px]">
        <div className="w-[128px] h-[128px] xl:w-[180px] xl:h-[180px] relative bg-secondary-dark flex flex-col gap-3 items-center justify-center">
          <input
            type="file"
            name="avatar"
            id="avatar"
            accept="image/jpeg, image/png, image/jpg"
            onChange={(e) => handleOnChange(e)}
            className="cursor-grab opacity-0 w-[128px] h-[128px] xl:w-[180px] xl:h-[180px] absolute top-0 left-0 bg-transparent"
          />
          <div className="w-[22px] h-[22px] xl:w-[32px] xl:h-[32px]">
            <DragIcon className="w-[22px] h-[22px] xl:w-[32px] xl:h-[32px]" />
          </div>
          <p className="font-Inter font-normal text-center text-xs xl:text-md leading-4 text-dark-500">
            {textData.createNewClone.dragFiles}
          </p>
        </div>
        {showProgress && (
          <div className="flex flex-col gap-6 w-full flex-1">
            <div className="flex justify-between items-center">
              <p className="font-Inter text-start font-normal text-xs xl:text-md leading-4 text-white capitalize">
                {percent < 100
                  ? textData.createNewClone.statusUploading
                  : textData.createNewClone.done}
              </p>
              <button type="button" onClick={() => handleDiscard()}>
                {getIconsComponents({
                  name: "close",
                  className: "fill-primary-light",
                })}
              </button>
            </div>
            <div className="flex items-end w-full gap-6">
              <div>
                {file ? (
                  <div className="w-[40px] h-[40px] overflow-hidden">
                    <img
                      src={URL.createObjectURL(file)}
                      alt=""
                      className="object-cover w-full h-full"
                    />
                  </div>
                ) : (
                  <DefaultFileIcon />
                )}
              </div>
              <div className="flex flex-col gap-2 w-full">
                <div className="flex items-center gap-6 justify-between">
                  <p className="font-Inter text-start font-normal text-xs xl:text-md leading-4 text-dark-400 max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
                    {file.name}
                  </p>
                  <p className="font-Inter text-start font-normal text-xxs xl:text-sm leading-4 text-dark-500">
                    {bytes}
                    {textData.createNewClone.kb}
                  </p>
                </div>
                <div className="w-full bg-gray-200 rounded-sm h-1 dark:bg-dark-500">
                  <div
                    className="bg-secondary-green h-1 rounded-sm"
                    style={{ width: `${percent}%` }}
                  />
                </div>
                <div className="flex items-center gap-6 justify-between">
                  <p className="font-Inter text-start font-normal text-xs xl:text-md leading-4 text-secondary-green">
                    {percent}% {textData.createNewClone.done}
                  </p>
                  <p className="font-Inter text-start font-normal text-xxs xl:text-sm leading-4 text-dark-500"></p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};
