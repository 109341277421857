import React from "react";
// components
import { ChooseAccountTypeWrapper } from "./components/chooseAccountTypeWrapper/ChooseAccountTypeWrapper";
import { Layout } from "../../components/organisms/layout/Layout";

const ChooseAccountTypePage = () => {
  return (
    <Layout
      sideBar={false}
      noPadding
      className="h-screen flex justify-center pt-[115px]"
    >
      <ChooseAccountTypeWrapper />
    </Layout>
  );
};

export default ChooseAccountTypePage;
