import axios from "axios";
import { auth } from "../../firebase";
import {getStorageName} from "../../helpers/storageName/storageName";
const BASE_URL = process.env.REACT_APP_PUBLIC_BASE_URL;
export const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
export const instanceFile = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "text/plain",
  },
});

instanceFile.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(getStorageName.accessToken);
    const serviceAuth = localStorage.getItem(getStorageName.serviceAuth);
    if (token && serviceAuth) {
      // @ts-ignore
      config.headers["Authorization"] = `${token}`;
    } else if (token && !serviceAuth) {
      // @ts-ignore
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      // @ts-ignore
      delete config.headers["Authorization"];
      return config;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instanceFile.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    let refreshRequest = null;
    const refresh = localStorage.getItem(getStorageName.refreshToken);
    if (error.response.status === 401 && error.config.retry) {
      localStorage.removeItem(getStorageName.serviceAuth);
      localStorage.removeItem(getStorageName.accessToken);
      localStorage.removeItem(getStorageName.refreshToken);
      window.location.href = window.location.origin;
      return Promise.reject(error);
    }
    if (!refresh || error.response.status !== 401 || error.config.retry) {
      return Promise.reject(error);
    }
    const serviceAuth = localStorage.getItem(getStorageName.serviceAuth);

    if (serviceAuth) {
      if (auth.currentUser) {
        const result = auth.currentUser.getIdToken();
        result.then((r) => localStorage.setItem(getStorageName.accessToken, r));
      }
    } else {
      refreshRequest = instance.post("users/auth/token_refresh/", { refresh });
      const { data } = await refreshRequest;
      localStorage.setItem(getStorageName.accessToken, data.access);
      refreshRequest = null;
    }

    const newRequest = {
      ...error.config,
      retry: true,
    };
    return instance(newRequest);
  }
);

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(getStorageName.accessToken);
    const serviceAuth = localStorage.getItem(getStorageName.serviceAuth);
    if (token && serviceAuth) {
      // @ts-ignore
      config.headers["Authorization"] = `${token}`;
    } else if (token && !serviceAuth) {
      // @ts-ignore
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      // @ts-ignore
      delete config.headers["Authorization"];
      return config;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    let refreshRequest = null;
    const refresh = localStorage.getItem(getStorageName.refreshToken);
    if (error.response.status === 401 && error.config.retry) {
      localStorage.removeItem(getStorageName.serviceAuth);
      localStorage.removeItem(getStorageName.accessToken);
      localStorage.removeItem(getStorageName.refreshToken);
      window.location.href = window.location.origin;
      return Promise.reject(error);
    }
    if (!refresh || error.response.status !== 401 || error.config.retry) {
      return Promise.reject(error);
    }
    const serviceAuth = localStorage.getItem(getStorageName.serviceAuth);
    if (serviceAuth) {
      if (auth.currentUser) {
        const result = auth.currentUser.getIdToken();
        result.then((r) => localStorage.setItem(getStorageName.accessToken, r));
      }
    } else {
      refreshRequest = instance.post("users/auth/token_refresh/", { refresh });
      const { data } = await refreshRequest;
      localStorage.setItem(getStorageName.accessToken, data.access);
      refreshRequest = null;
    }

    const newRequest = {
      ...error.config,
      retry: true,
    };
    return instance(newRequest);
  }
);
