import React, { useMemo, useState } from "react";
// data
import { textData } from "../../../../../assets/translations/en/textData";
import { tableContentData } from "./mockData";
// components
import { CloneTableRow } from "./cloneTableRow/CloneTableRow";

export const CloneTable = () => {
  const [activity, setActivity] = useState(true);
  // memo
  const content = useMemo(() => {
    if (activity) {
      return tableContentData.filter((item) => item.active);
    } else {
      return tableContentData;
    }
  }, [activity]);
  return (
    <div className="py-6 px-[30px]">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-[54px]">
          <button
            className="font-Inter font-medium text-xs text-white hover:text-opacity-50"
            onClick={() => setActivity(true)}
          >
            {textData.dashboardPage.table.activityClone}
          </button>
          <button
            className="font-Inter font-medium text-xs text-white hover:text-opacity-50"
            onClick={() => setActivity(false)}
          >
            {textData.dashboardPage.table.allClone}
          </button>
        </div>
        <button className="dashboard-earnings-tooltip font-Inter font-medium text-xs text-white">
          {textData.dashboardPage.table.earnings}
        </button>
      </div>
      <div>
        {content.map((item, index) => (
          <CloneTableRow
            data={item}
            key={`${index}${item.title}`}
            border={!!index}
          />
        ))}
      </div>
    </div>
  );
};
