import React from "react";
// import { Step, TooltipRenderProps } from "react-joyride";
import "./Tooltip.css";

// interface CustomTooltipDieProps extends TooltipRenderProps {
//   continuous: boolean;
//   step: Step & { positionClass?: string };
// }

export const CustomTooltipDie = ({
  continuous,
  step,
  closeProps,
  primaryProps,
  tooltipProps,
  additionalStylesClass = "",
}: any) => {
  return (
    <div
      {...tooltipProps}
      className={`custom-tooltip ${additionalStylesClass} ${step.positionClass}`}
    >
      <div className="custom-tooltip-content">
        {step.title && <h3>{step.title}</h3>}
        <div>{step.content}</div>
      </div>
      <div className="custom-tooltip-footer">
        {continuous ? (
          <button {...primaryProps} className="custom-tooltip-button">
            Got it
          </button>
        ) : (
          <button {...closeProps} className="custom-tooltip-button">
            Got it
          </button>
        )}
      </div>
    </div>
  );
};
