import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import SettingsPageWrapper from "./components/SettingsPageWrapper";

type Props = {
  isAuth: boolean;
};
const SettingsPage = ({ isAuth }: Props)  => {
  return (
    <Layout classNameWrapper="pr-5 lg:pr-14 ml-5">
      <SettingsPageWrapper/>
    </Layout>
  );
};

export default SettingsPage;
