import React, { useMemo } from "react";
// icons
import userCustom from "../../../../../../assets/img/userCustom.png";
import { useAppSelector } from "../../../../../../stores/hooks/hooks";

type Props = {
  full: boolean;
};

export const SideBarAccount = ({ full }: Props) => {
  // store
  const profile = useAppSelector((state) => state.userProfile.profile);

  const initialsName = useMemo(() => {
    if (profile && profile.full_name) {
      return profile.full_name
        .split(" ")
        .map((item) => item.slice(0, 1))
        .join("");
    }
  }, [profile]);
  return (
    <section
      className={`translate-y-[-100%] w-full absolute py-5 flex gap-2.5 items-center justify-center cursor-pointer overflow-hidden rounded-b-[20px] bg-secondary-dark border-t border-primary-grey `}
    >
      <div className="w-[42px] h-[42px] border rounded-full border-secondary-green bg-secondary-green flex justify-center items-center">
        <span className="text-2xl text-white font-bold font-Inter uppercase leading-none">{initialsName}</span>
      </div>
      {full && (
        <div>
          <p className="mb-1 font-Inter font-normal text-sm text-white leading-5">
            {profile?.full_name}
          </p>
          <span className="mb-1 font-Inter font-medium text-xxs text-primary-light leading-3">
            {profile?.email}
          </span>
        </div>
      )}
    </section>
  );
};
