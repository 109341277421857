import { createAsyncThunk } from "@reduxjs/toolkit";
// api
import paymentsApi from "../api/paymentsApi";
import {AddPaymentMethodType, ConfirmPaymentMethodType} from "../types/paymentsType";
import {displayAlert} from "../slice/alertSlice";
import {textData} from "../../assets/translations/en/textData";
import {setAddPaymentModalOpen, setRemovePaymentModalOpen} from "../slice/userProfileSlice";
import {TransactionListDataType} from "../types/transactionType";
import {fetchUserProfile} from "./userProfileThunk";
import {processMsg} from "../../helpers/utilityFunctions/utilityFunctions";
// types

export const fetchMembershipList = createAsyncThunk(
  "payments/fetchMembershipList",
  async (data, thunkAPI) => {
    try {
      const response = await paymentsApi.fetchMembershipList();
      return response.data;
    } catch (e) {
      console.log(e);
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: processMsg(e.response.data) || textData.alertsMessage.wrong,
        })
      );
    }
  }
);

export const fetchTransactionList = createAsyncThunk(
  "payments/fetchTransactionList",
  async (data: TransactionListDataType, thunkAPI) => {
    try {
      const response = await paymentsApi.fetchTransactionList(data);
      return response.data;
    } catch (e) {
      console.log(e);
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: processMsg(e.response.data) || textData.alertsMessage.wrong,
        })
      );
    }
  }
);

export const fetchPaymentMethods = createAsyncThunk(
  "payments/fetchPaymentMethods",
  async (arg, thunkAPI) => {
    try {
      const response = await paymentsApi.fetchPaymentList();
      thunkAPI.dispatch(fetchUserProfile());
      return response.data;
    } catch (e) {
      console.log(e);
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: processMsg(e.response.data) || textData.alertsMessage.wrong,
        })
      );
    }
  }
);

export const getStripePublicKey = createAsyncThunk(
  "payments/getStripePublicKey",
  async (data, thunkAPI) => {
    try {
      const response = await paymentsApi.getStripeConfig();
      return response.data;
    } catch (e) {
      console.log(e);
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: processMsg(e.response.data) || textData.alertsMessage.wrong,
        })
      );
    }
  }
);

export const addPaymentMethod = createAsyncThunk(
  "payments/addPaymentMethod",
  async (data: AddPaymentMethodType, thunkAPI ) => {
    try {
      const response = await paymentsApi.addPaymentMethod(data);
      return response.data;
    } catch (e) {
      console.log(e);
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: processMsg(e.response.data) || textData.alertsMessage.wrong,
        })
      );
    }
  }
);

export const confirmPaymentMethod = createAsyncThunk(
  "payments/confirmPaymentMethod",
  async (data: ConfirmPaymentMethodType, thunkAPI ) => {
    try {
      const response = await paymentsApi.confirmPaymentMethod(data);
      if (response.status === 200) {
        thunkAPI.dispatch(setAddPaymentModalOpen(false));
        thunkAPI.dispatch(
          displayAlert({
            type: "success",
            text: textData.alertsMessage.paymentAdded,
          })
        );
        thunkAPI.dispatch(fetchPaymentMethods());
      }
      return response.data;
    } catch (e) {
      console.log(e);
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: processMsg(e.response.data) || textData.alertsMessage.wrong,
        })
      );
    }
  }
);

export const removePaymentMethod = createAsyncThunk(
  "payments/removePaymentMethod",
  async (id: string, thunkAPI ) => {
    try {
      const response = await paymentsApi.removePaymentMethod(id);
      if (response.status === 204) {
        thunkAPI.dispatch(setRemovePaymentModalOpen(false));
        thunkAPI.dispatch(
          displayAlert({
            type: "success",
            text: textData.alertsMessage.paymentRemoved,
          })
        );
        thunkAPI.dispatch(fetchPaymentMethods());
        thunkAPI.dispatch(fetchUserProfile());
      }
      return response.data;
    } catch (e) {
      console.log(e);
      thunkAPI.dispatch(
        displayAlert({
          type: "success",
          text: textData.alertsMessage.paymentAdded,
        })
      );
    }
  }
);