import React, { useEffect, useRef } from "react";
// components
import { MarketplaceItemDetails } from "./marketplaceItemDetails/MarketplaceItemDetails";

export const MarketPlaceChosenItemPageWrapper = () => {
  // ref
  const ref = useRef(null);
// helpers
  const scrollToTop = () => {
    // @ts-ignore
    ref.current?.scrollIntoView();
  };
// effect
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <section ref={ref}>
      <MarketplaceItemDetails />
    </section>
  );
};
