import React from "react";
// helper
import { getIconsComponents } from "../../../helpers/iconsComponents/iconsComponents";
import "./spinner.css";

type Props = {
  className?: string;
  classNameCircle?: string;
  absolute?: boolean;
  page?: boolean;
  btn?: boolean;
};

export const Spinner = ({
  className = "",
  classNameCircle = "",
  absolute = false,
  page = false,
  btn = false,
}: Props) => {
  if (page) {
    return (
      <div className="fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
        <div className={className}>
          {getIconsComponents({
            name: "drop",
            className: "h-16 w-16 mb-4 pulse",
          })}
        </div>
      </div>
    );
  }

  if (absolute) {
    return (
      <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
        {getIconsComponents({
          name: "drop",
          className: "h-16 w-16 mb-4 pulse",
        })}
      </div>
    );
  }

  if (btn) {
    return (
      <div className={className}>
        <div
          className={` ${
            classNameCircle || " border-2 border-t-2 h-4 w-4"
          } loader ease-linear rounded-[50%]`}
        />
      </div>
    );
  }
  return (
    <div className={className}>
      {getIconsComponents({
        name: "drop",
        className: "h-16 w-16 mb-4 pulse",
      })}
    </div>
  );
};
