import React from "react";
import { useNavigate } from "react-router-dom";
// components
import { ProgressBar } from "../activityCloneItemPage/progressBar/ProgressBar";
// types
import { ActivityItemType } from "./mockDataTypes";

export const ActivityTaskTableItem = ({
  id,
  cloneName,
  cloneIcon,
  category,
  status,
  earnings,
  currency,
  currencyIcon,
}: ActivityItemType) => {
  // hooks
  const navigate = useNavigate();
  const handleRowClick = () => {
    navigate(`/activity/${id}`);
  };
  return (
    <tr
      className="[&:not(:last-child)]:border-b border-dark-450/[0.15] hover:opacity-80 cursor-pointer"
      onClick={handleRowClick}
    >
      <td className="py-4 pl-0 pr-2 flex items-center w-full">
        <img src={cloneIcon} alt={cloneName} className="h-12 w-12 mr-[18px]" />
        <div className="flex flex-col gap-3 justify-between">
          <div className="font-Inter font-bold text-md leading-3">
            {cloneName}
          </div>
          <div className="font-Inter text-sm text-white/[0.5] leading-[21px]">
            {category}
          </div>
        </div>
      </td>
      <td className="py-2 px-4 w-[30%]">
        <div className="flex flex-col items-center gap-y-3">
          <ProgressBar status={status} />
          <span className="font-Roboto text-sm text-dark-170">{status}</span>
        </div>
      </td>
      <td className="py-2 px-4 w-[30%] pr-12">
        <div className="flex flex-col gap-y-2">
          <div className="flex items-center gap-4 text-gray-400">
            {currencyIcon && <img src={currencyIcon} alt="currency icon" />}
            <span className="font-Inter font-bold text-white">{earnings}</span>
          </div>
          <span className="font-Inter text-sm text-white/[0.5]">
            {currency}
          </span>
        </div>
      </td>
    </tr>
  );
};
