import { createSlice } from "@reduxjs/toolkit";
import { getTemplateById, getTemplatesList } from "../thunks/templatesThunk";
import { TemplateDataByIdType, TemplateDataType } from "../types/templateTypes";

interface TemplatesState {
  isLoading: boolean;
  savedDialogId: string | null;
  templatesData: TemplateDataType[];
  templateById: TemplateDataByIdType | null;
  importTemplateStatus: "success" | "warning" | null;
  errorText: string | null;
}

const initialState: TemplatesState = {
  isLoading: false,
  savedDialogId: null,
  templatesData: [],
  templateById: null,
  importTemplateStatus: null,
  errorText: null,
};

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    clearTemplateById: (state) => {
      state.templateById = initialState.templateById;
    },
    displayImportStatus: (state, action) => {
      state.importTemplateStatus = action.payload;
      state.errorText = null;
    },
    clearImportStatus: (state) => {
      state.importTemplateStatus = null;
    },
    setImportError: (state, action) => {
      state.errorText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplatesList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTemplatesList.fulfilled, (state, action) => {
      state.templatesData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getTemplateById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTemplateById.fulfilled, (state, action) => {
      state.templateById = action.payload;
      state.isLoading = false;
    });
  },
});

export const {
  clearTemplateById,
  displayImportStatus,
  clearImportStatus,
  setImportError,
} = templatesSlice.actions;
