// types
import { CurrencyDataType, TableItemType } from "./mockDataTypes";
// icons
import { ReactComponent as UBXPIcon } from "../../../../../assets/icons/ubxpDashboard.svg";
import { ReactComponent as EthereumIcon } from "../../../../../assets/icons/ethereum.svg";
import { ReactComponent as TezosIcon } from "../../../../../assets/icons/tezos.svg";

export const currencyData: CurrencyDataType = {
  dollar: {
    title: "United States Dollar",
    icon: null,
  },
  ubxp: {
    title: "UBXP",
    icon: <UBXPIcon />,
  },
  ethereum: {
    title: "Ethereum",
    icon: <EthereumIcon />,
  },
  tezos: {
    title: "Tezos",
    icon: <TezosIcon />,
  },
};

export const tableContentData: TableItemType[] = [
  {
    title: "Trading Bot",
    subtitle: "3Commas",
    value: "4.95",
    currency: "ubxp",
    active: true,
  },
  {
    title: "Bored Ape Chemistry Club",
    subtitle: "3Commas",
    value: "4.95",
    currency: "dollar",
    active: true,
  },
  {
    title: "RTFKT CloneX Mintvial",
    subtitle: "3Commas",
    value: "4.95",
    currency: "ethereum",
    active: true,
  },
  {
    title: "Chromie Squiggle by Snowfro",
    subtitle: "3Commas",
    value: "4.95",
    currency: "ubxp",
    active: false,
  },
  {
    title: "Auto Writer",
    subtitle: "3Commas",
    value: "4.95",
    currency: "tezos",
    active: false,
  },
];
