import React, {Fragment, useEffect, useState} from "react";
import { DateTime } from "luxon";
// data
import {textData} from "../../../assets/translations/en/textData";
// icon
import {useAppDispatch, useAppSelector} from "../../../stores/hooks/hooks";
import {fetchTransactionList} from "../../../stores/thunks/paymentsThunk";
import {ButtonCustom} from "../../../components/atoms/button/ButtonCustom";
import {useNavigate} from "react-router-dom";
import NoTransactionsIcon from "../../../assets/img/red_x.svg";
import DownArrow from "../../../assets/icons/downArr.svg";
import DateIcon from "../../../assets/icons/date.svg";
import {statusTransactionMap, transactionListData} from "./mockData";
import {Spinner} from "../../../components/atoms/spinner/Spinner";
import UsePagination from "./pagination";
import {
  setTransactionListOrderField,
  setTransactionListOrderDirection,
  setTransactionListPage,
  setTransactionListFromDate
} from "../../../stores/slice/paymentsSlice";
import {Menu, Transition} from "@headlessui/react";
import {transactionListDataPeriodType} from "./mockDataType";

export const TransactionsPageWrapper = () => {
  // state
  const transactionList = useAppSelector((state) => state.payments.transactionList);
  const isLoading = useAppSelector((state) => state.payments.isLoading);
  const page = useAppSelector((state) => state.payments.transactionListPage);
  const page_size = useAppSelector((state) => state.payments.transactionListSize);
  const created_at__gte = useAppSelector((state) => state.payments.transactionListFromDate?.value);
  const period = useAppSelector((state) => state.payments.transactionListFromDate?.name);
  const orderField = useAppSelector((state) => state.payments.transactionListOrderField);
  const orderDirection = useAppSelector((state) => state.payments.transactionListOrderDirection);
  const ordering = orderDirection === 'desc' ? '-' + orderField : orderField;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getDateFromLastDays = (days: number) => {
    const now = DateTime.now();
    return now.minus({days: days}).endOf('day').toISODate();
  }

  const getDaysFromDate = (date: string) => {
    return "Last " + DateTime.fromISO(date).diffNow().negate().toFormat('d') + " days";
  }

  useEffect(() => {
    dispatch(fetchTransactionList({page, page_size, ordering, created_at__gte}));
  }, [dispatch]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setTransactionListPage(page));
    dispatch(fetchTransactionList({page, page_size, ordering, created_at__gte}));
  };

  const handleChangeOrder = (field: string, direction: string) => {
    const page = 1;
    const ordering = direction === 'desc' ? '-' + field : field;
    dispatch(setTransactionListPage(page));
    dispatch(setTransactionListOrderField(field));
    dispatch(setTransactionListOrderDirection(direction));
    dispatch(fetchTransactionList({page, page_size, ordering, created_at__gte}));
  };

  const onPeriodChange = (period: transactionListDataPeriodType) => {
    const page = 1;
    const created_at__gte = period.value ? getDateFromLastDays(period.value) : null;
    dispatch(setTransactionListPage(page));
    dispatch(setTransactionListFromDate({
      name: period.name,
      value: created_at__gte
    }));
    dispatch(fetchTransactionList({page, page_size, ordering, created_at__gte}));
  }

  return (
    <section className="relative pt-8 m-center h-[100vh] w-full max-w-[1024px]">
      <div className="relative w-full">
        <h1
          className="font-Inter font-extrabold text-xl lg:text-3xl xl:text-5xl mt-4 leading-10 text-white capitalize">
          {textData.transactions.title}
        </h1>
        <Menu
          as="div"
          className="absolute top-0 right-0 inline-block text-center self-start rounded-full border border-dark-250 bg-transparent"
        >
          <Menu.Button className="flex py-[13px] px-[30px] items-center justify-center text-center gap-4 hover:opacity-80">
            <img src={DateIcon} className="" />
            <span className="font-Inter  font-bold text-sm leading-4 capitalize">
              {period ? period : textData.transactions.period}
            </span>
          </Menu.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Menu.Items className="z-20 absolute top-[45px] left-0 w-full bg-dark-300 border border-dark-250 py-5 px-4 rounded-md">
              {transactionListData.periods.map((period) => {
                return (
                  <Menu.Item key={period.value}>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          onPeriodChange(period);
                        }}
                        className={`w-full text-left text-sm font-bold text-white hover:text-secondary-green capitalize`}
                      >
                        {period.name}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      {!transactionList?.results.length && !isLoading && (<div className="text-center p-10">
        <img src={NoTransactionsIcon} alt="no_transactions" className="block m-center my-10"/>
        <h3
          className="font-Inter font-extrabold text-5xl text-white capitalize">{textData.transactions.noTransactions}</h3>
        <ButtonCustom
          variant="secondary"
          type="button"
          adaptive={false}
          title={textData.transactions.backToSettings}
          className="w-[230px] lg:text-sm h-12 m-center mt-20"
          handleClick={() => navigate("/settings")}
        />
      </div>)}
      {!!transactionList?.results.length && (<div className="pt-8">
        <table className="min-w-full font-Inter border-separate border-spacing-y-2 pb-10 border-gray-700 text-white">
          <thead>
          <tr>
            {transactionListData.fields.map((field) => (
              <th key={field.value} className="px-4 py-2 text-sm text-left">
              <span className="flex items-center">
                {field.name}
                <button
                  className="block w-[10px] h-[10px] ml-3"
                  onClick={() => {
                    handleChangeOrder(field.value, orderDirection === 'desc' ? 'asc' : 'desc')
                  }}>
                  <img src={DownArrow} className={orderField === field.value && orderDirection === 'asc' ? "rotate-180" : ""}
                       alt="order"/>
                </button>
              </span>
              </th>
            ))}
          </tr>
          </thead>
          <tbody>
          {transactionList?.results.map(({
                                          bot,
                                          transaction_currency,
                                          transaction_value,
                                          fee_value,
                                          fee_currency,
                                          created_at,
                                          payout_value,
                                          payout_currency,
                                          status
                                        }, index) => (
            <tr key={index}>
              <td className="border border-dark-250 border-r-0 rounded-l-[12px] w-[240px] px-4 py-2">
                <div className="flex items-center">
                  <img className="mr-2 h-8 w-8 rounded-full" src={bot.avatar} alt="User Image"/>
                  <span>{bot.name}</span>
                </div>
              </td>
              <td
                className="border-y border-dark-250 px-4 py-4">{transaction_value} {transaction_currency.display_name}</td>
              <td className="border-y border-dark-250 px-4 py-4">{fee_value} {fee_currency.display_name}</td>
              <td className="border-y border-dark-250 px-4 py-4">{DateTime.fromISO(created_at).toFormat('MM/dd/yyyy h.mm a')}</td>
              <td className="border-y border-dark-250 px-4 py-4">{payout_value} {payout_currency.display_name}</td>
              <td className="border border-dark-250 border-l-0 rounded-r-[12px] px-4 py-4">
                <div
                  className="flex items-center rounded-full border border-dark-250 bg-transparent justify-center w-[106px] px-4 py-2">
                  <div className={`h-2.5 w-2.5 rounded-full ${status === 1 ? 'bg-green-200' : 'bg-yellow-300'}`}></div>
                  <p className="ml-2 font-bold text-xs text-white capitalize">{statusTransactionMap[status]}</p>
                </div>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
        <UsePagination count={Math.ceil(transactionList.count/page_size)} page={page} onChange={handleChangePage}/>
      </div>)}
      {isLoading && <Spinner page />}
    </section>
  );
};
