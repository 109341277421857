import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import {TransactionsPageWrapper} from "./components/TransactionsPageWrapper";

type Props = {
  isAuth: boolean;
};
const TransactionsPage = ({ isAuth }: Props)  => {
  return (
    <Layout classNameWrapper="pr-5 lg:pr-14">
      <TransactionsPageWrapper/>
    </Layout>
  );
};

export default TransactionsPage;
