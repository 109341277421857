import React from "react";
// components
import { Layout } from "../../../../components/organisms/layout/Layout";
import { ActivityCloneItemPageWrapper } from "./activityCloneItemPageWrapper/ActivityCloneItemPageWrapper";
// data
import { activityItemData } from "./mockData";

const ActivityCloneItemPage = () => {
  return (
    <Layout
      mobileApp
      sideBar={true}
      noPadding
      className="bg-bgMarketplace pl-[103px] pr-[77px] py-[70px]"
    >
      <ActivityCloneItemPageWrapper itemData={activityItemData} />
    </Layout>
  );
};

export default ActivityCloneItemPage;
