import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import { CloneIFramePageWrapper } from "./components/cloneIframePageWrapper/CloneIFramePageWrapper";

const CloneIFramePage = () => {
  return (
    <Layout mobileApp noPadding sideBar={false} classNameWrapper="bg-dark-200">
      <CloneIFramePageWrapper />
    </Layout>
  );
};
export default CloneIFramePage;
