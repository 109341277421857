import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import { ExportTemplateWrapper } from "./components/exportTemplateWrapper/ExportTemplateWrapper";

const ExportTemplatePage = () => {
  return (
    <Layout
      noPadding
      mobileApp
      classNameWrapper="h-full pt-[128px] pb-8 pr-14 pl-[100px] flex justify-center items-center "
    >
      <ExportTemplateWrapper />
    </Layout>
  );
};

export default ExportTemplatePage;
