import React from "react";
// data
import { textData } from "../../../assets/translations/en/textData";
// types
import { HeaderDataType } from "./mockDataTypes";

export const headerData: HeaderDataType = [
  {
    callbackName: "testChatBot",
    title: textData.header.testChatBot,
    icon: "flask",
    variant: "primary",
    menuBtn: false,

  },
  {
    callbackName: "exportTemplate",
    title: textData.header.exportTemplate,
    icon: "save",
    variant: "primary",
    menuBtn: false,

  },
  {
    callbackName: "deploy",
    title: textData.header.deploy,
    icon: "rocket",
    variant: "secondary",
    menuBtn: false,

  },
  {
    callbackName: "addNewBot",
    title: textData.header.addNewBot,
    icon: "plus",
    variant: "secondary",
    menuBtn: true,
  },
  {
    callbackName: "importBotTemplate",
    title: textData.header.importBotTemplate,
    icon: "download",
    variant: "primary",
    menuBtn: false,

  },
];
