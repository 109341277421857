import React from "react";
import Joyride, { Step } from "react-joyride";
// components
import { CustomTooltipDie } from "./CustomTooltipDie";
// styles
import "./Tooltip.css";

type TooltipProps = {
  runTour: boolean;
  steps: Step[];
  handleJoyrideCallback: (data: any) => void;
  additionalStylesClass?: string;
};

export const TooltipComponent = ({
  runTour,
  steps,
  handleJoyrideCallback,
  additionalStylesClass = "",
}: TooltipProps) => {
  return (
    <Joyride
      steps={steps}
      continuous
      showSkipButton={false}
      hideBackButton={true}
      hideCloseButton={true}
      disableOverlayClose={true}
      disableCloseOnEsc={true}
      disableScrolling={true}
      locale={{
        next: "Got it",
        last: "Got it",
      }}
      run={runTour}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          arrowColor: "transparent",
          backgroundColor: "#fff",
          overlayColor: "rgba(0, 0, 0, 0.5)",
          primaryColor: "transparent",
          textColor: "#000",
          zIndex: 10000,
          width: "100%",
        },
        // overlay: { height: "100vh" },
      }}
      tooltipComponent={(props) => (
        <CustomTooltipDie
          {...props}
          additionalStylesClass={additionalStylesClass}
        />
      )}
    />
  );
};
