interface INumberIndex {
  [key: number]: string
}

export const billingPeriodMap: INumberIndex = {
  0: "monthly",
  1: "annual",
  2: "permanent",
  3: "custom"
}

export const planMap: INumberIndex = {
  0: "basic",
  1: "personal",
  2: "business",
  3: "business_upgraded",
  4: "enterprise"
}


