import React from "react";
import { useNavigate } from "react-router-dom";
// components
import { LinkCustom } from "../../../../../components/atoms/linkCustom/LinkCustom";
import { TooltipComponent } from "../../../../../components/atoms/tooltip/Tooltip";
// types
import { ActivityItemDataType } from "../mockDataTypes";
// data
import { textData } from "../../../../../assets/translations/en/textData";
import { activityCloneItemSteps } from "../mockData";
// styles
import "./ActivityCloneItemPageWrapper.css";
// hooks
import { useTour } from "../../../../../helpers/hooks/useTour/useTour";

type Props = {
  itemData: ActivityItemDataType;
};

export const ActivityCloneItemPageWrapper = ({ itemData }: Props) => {
  // hooks
  const navigate = useNavigate();
  const { runTour, handleJoyrideCallback } = useTour(
    "seenActivityCloneItemTooltips",
    "sidebarTourFinished"
  );
  return (
    <div>
      <TooltipComponent
        runTour={runTour}
        steps={activityCloneItemSteps}
        handleJoyrideCallback={handleJoyrideCallback}
        additionalStylesClass="activity-clone-item-tooltip"
      />
      <section className="max-w-[760px] xl:max-w-[1080px] lg:min-w-[768px] xl:min-w-[1080px] pt-4 pb-[60px] pr-[50px] pl-[50px] bg-dark-200 border border-primary-grey rounded mx-auto">
        <div className="flex items-center gap-x-[51px]">
          <LinkCustom
            link={`..`}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            icon="chevronRight"
            variant="default"
            classNameIcon="w-6 h-6 xl:w-10 xl:h-10 fill-white"
          />
          <div className="flex items-center space-x-1 font-Roboto">
            <span
              className="cursor-pointer hover:opacity-80"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              {textData.activityPage.itemClickedPage.breadCrumb1}
            </span>
            <span>&gt;</span>
            <span>{textData.activityPage.itemClickedPage.breadCrumb2}</span>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="mt-[44px] flex flex-col items-start">
            <div className="flex flex-col space-y-[29px]">
              <img
                src={itemData.imageUrl}
                alt={itemData.title}
                className="max-w-[100px]"
              />
              <h2 className="text-3xl font-Roboto font-bold">
                {itemData.title}
              </h2>
            </div>
            <div className="mt-6">
              <p className="mb-2 text-Roboto text-xl">
                <span className="capitalize mr-2">
                  {textData.activityPage.itemClickedPage.orderData.orderTotal}:
                </span>
                {itemData.orderTotal}
              </p>
              <p className="mb-2 text-Roboto text-xl">
                <span className="capitalize mr-2">
                  {textData.activityPage.itemClickedPage.orderData.payout}:
                </span>
                {itemData.payout}
              </p>
              <p className="mb-2 text-Roboto text-xl flex items-center">
                <span className="capitalize mr-2">
                  {textData.activityPage.itemClickedPage.orderData.host}:
                </span>
                <div className="item-host-tooltip ml-2 inline-flex items-center gap-x-3">
                  {itemData.hostIcon && (
                    <img
                      src={itemData.hostIcon}
                      alt="host icon"
                      className="w-[30px] h-[30px]"
                    />
                  )}
                  {itemData.host}
                </div>
              </p>
              <p className="mb-2 text-Roboto text-xl">
                <span className="normal-case mr-2">
                  {textData.activityPage.itemClickedPage.orderData.duration}:
                </span>
                {itemData.duration}
              </p>
              <p className="mb-2 text-Roboto text-xl">
                <span className="normal-case mr-2">
                  {textData.activityPage.itemClickedPage.orderData.time}:
                </span>
                {itemData.timeOfTask}
              </p>
              <p className="mb-2 text-Roboto text-xl">
                <span className="capitalize mr-2">
                  {textData.activityPage.itemClickedPage.orderData.date}:
                </span>
                {itemData.date}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-4 text-Roboto text-xl text-center">
        <a href="#" className="text-blue-600 hover:opacity-80">
          {textData.activityPage.itemClickedPage.orderData.viewExplorer}
        </a>
      </div>
    </div>
  );
};
