import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export interface AlertActionType {
  type: "base" | "info" | "error" | "warning" | "success";
  text: string | null;
  heading?: string | null;
}

export interface AlertPropsType extends AlertActionType {
  show: boolean;
  id: string;
}

type AlertPropsStateType = {
  alertProps: AlertPropsType[];
};

const initialState: AlertPropsStateType = {
  alertProps: []
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    displayAlert: (state, action: PayloadAction<AlertActionType>) => {
      function generateUniqueId() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
      }

      const id = generateUniqueId();
      state.alertProps.push({ ...action.payload, show: true, id });
    },
    closeAlert: (state, action) => {
      state.alertProps = state.alertProps.filter(
        (props) => props.id !== action.payload
      );
    },
    closeTimeoutAlert: (state, action) => {
      state.alertProps = state.alertProps.filter(
        (props) => props.id !== action.payload
      );
    },
    closeAllAlert: (state) => {
      state.alertProps = [];
    }
  }
});

export const { displayAlert, closeAlert, closeAllAlert } = alertSlice.actions;
