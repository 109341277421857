import React, { useState } from "react";
// hooks
import { useAppDispatch } from "../../../../../../stores/hooks/hooks";
// store
import { setActiveWindow } from "../../../../../../stores/slice/treeBotSlice";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
import { propertiesCardsData } from "./mockData";
// types
import { PropertiesCardItemIdType } from "./mockDataTypes";
// components
import { BlackWrapper } from "../../../../../../components/atoms/blackWrapper/BlackWrapper";
import { CardCustom } from "./cardCustom/CardCustom";
import { ButtonCustom } from "../../../../../../components/atoms/button/ButtonCustom";

export const PropertiesWrapper = () => {
  // hooks
  const dispatch = useAppDispatch();
  // state
  const [active, setActive] = useState<PropertiesCardItemIdType | null>(null);
  return (
    <BlackWrapper title={textData.properties.title} className="flex flex-col">
      <div className="mb-4">
        <p className="font-Inter font-semibold text-md lg:text-lg leading-6 text-white capitalize">
          {textData.properties.blockStyle}
        </p>
      </div>
      <div className="flex-grow overflow-y-auto scrollbar">
        <div className="flex flex-col gap-3 max-h-[50vh] lg:max-h-full">
          {propertiesCardsData.map((item) => (
            <CardCustom
              key={item.id + item.title}
              data={item}
              active={active}
              setActive={() => setActive(item.id)}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center gap-3 mt-6">
        <ButtonCustom
          variant="primary"
          handleClick={() => dispatch(setActiveWindow(null))}
          title={textData.btns.btnDiscard}
        />
        <ButtonCustom
          disabled={!active}
          variant="secondary"
          handleClick={() => dispatch(setActiveWindow(active))}
          title={textData.btns.btnSelect}
        />
      </div>
    </BlackWrapper>
  );
};
