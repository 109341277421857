// data
import { textData } from "../../../../../../assets/translations/en/textData";
// types
import { FieldCustomTypes } from "../../../../../../components/atoms/field/fieldCustomTypes";

export const welcomeMessageFieldData: FieldCustomTypes = {
  name: "welcomeMessage",
  label: "",
  placeholder: textData.welcomeMessage.placeholder,
  type: "text",
};
