// data
import { textData } from "../../../../../../assets/translations/en/textData";
// types
import { FieldCustomTypes } from "../../../../../../components/atoms/field/fieldCustomTypes";

export const freeTextDataFieldData: FieldCustomTypes = {
  name: "freeText",
  label: textData.freeTextWrapper.botMessage,
  placeholder: textData.freeTextWrapper.placeholder,
  type: "text",
};
