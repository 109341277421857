import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../../../stores/hooks/hooks";
import { Menu, Transition } from "@headlessui/react";
// icons
import { ReactComponent as MoreActionsIcon } from "../../../../../../../../assets/icons/details.svg";
// data
import { textData } from "../../../../../../../../assets/translations/en/textData";
// thunk
import { setUnInstallModal } from "../../../../../../../../stores/slice/marketplaceSlice";

type MarketplaceMoreActionsMenuType = {
  itemId: number;
};

export const MarketplaceMoreActionsMenu = ({
  itemId,
}: MarketplaceMoreActionsMenuType) => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // store
  const installedTools = useAppSelector(
    (state) => state.marketplace.installedTools
  );
  // helpers
  const onUninstallClone = (e: any) => {
    e.stopPropagation();
    dispatch(setUnInstallModal(itemId));
  };

  const onReportToReport = (e: any) => {
    e.stopPropagation();
    navigate(`/marketplace/${itemId}`);
  };

  const onMenuButtonClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <Menu as="div" className="relative">
      <Menu.Button onClick={onMenuButtonClick}>
        <MoreActionsIcon />
      </Menu.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Menu.Items className="absolute top-[45px] -right-5 w-[300px] z-10 bg-dark-700 py-2 px-3 rounded-md shadow-lg">
          {installedTools.includes(itemId) && (
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={onUninstallClone}
                  className={`${
                    // (active || selectedCategory === category) &&
                    active && "font-bold text-secondary-green"
                  } w-full text-left text-sm font-bold text-dark-400 capitalize`}
                >
                  {textData.marketplacePage.uninstall}
                </button>
              )}
            </Menu.Item>
          )}
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={onReportToReport}
                className={`${
                  // (active || selectedCategory === category) &&
                  active && "font-bold text-secondary-green"
                } w-full text-left text-sm font-bold text-dark-400 capitalize`}
              >
                {textData.marketplacePage.report}
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
