// data
import { textData } from "../../../../../../../assets/translations/en/textData";
// types
import { FieldCustomTypes } from "../../../../../../../components/atoms/field/fieldCustomTypes";

export const addReviewFieldData: FieldCustomTypes = {
  name: "review",
  label: "",
  placeholder: textData.marketplacePage.addReview,
  type: "text",
};
