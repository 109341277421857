// image
import forgotPswBgImage from "../../../assets/img/forgotPswBg.png";
// components
import { LinkCustom } from "../../atoms/linkCustom/LinkCustom";
// data
import { textData } from "../../../assets/translations/en/textData";

export const ForgotPasswordContent = () => {
  const bgImage = {
    backgroundImage: `url(${forgotPswBgImage})`,
  };
  return (
    <div
      className="h-[25vh] md:h-full grow bg-no-repeat bg-center bg-cover order-2 md:order-1"
      style={bgImage}
    >
      <div className="bg-black/80 w-full h-full">
        <div className="flex flex-col justify-between pt-[68px] xl:pt-[90px] pb-[33px] xl:pb-[80px] px-[36px] xl:px-[60px] h-full">
          <LinkCustom
            link="/"
            icon="aphidLogoLarge"
            variant="default"
            className="hidden md:block"
          />
          <div className="w-full flex justify-center mx-5">
            <div className="w-full xl:min-w-[500px] hidden md:flex items-center justify-between py-2 pl-7 pr-3 bg-black/20 rounded-5xl shadow-m">
              <span className="block shadow-sm text-md font-bold">
                {textData.forgotPasswordPage.needHelp}
              </span>
              <LinkCustom
                variant="secondary"
                title={textData.btns.contactSupportBtn}
                link="/"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
