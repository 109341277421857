// components
import { ForgotPasswordContent } from "../../../../../components/organisms/forgotPasswordContent/ForgotPasswordContent";
import { ForgotPasswordForm } from "./forgotPasswordFormWrapper/ForgotPasswordForm";
import { Footer } from "../../../../../components/molecules/footer/Footer";

export const ForgotPasswordContainer = () => {
  return (
    <div className="min-h-screen">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <ForgotPasswordContent />
        <ForgotPasswordForm />
      </div>
      <Footer />
    </div>
  );
};
