import React from "react";
// data
import { dashboardItemsData } from "./mockData";
// components
import { DashboardItem } from "./dashboardItem/DashboardItem";

export const DashboardItems = () => {
  return (
    <div className="w-full grid grid-cols-3 gap-x-6">
      {dashboardItemsData.map((item, index) => (
        <DashboardItem data={item} key={`${index}${item.title}`} />
      ))}
    </div>
  );
};
