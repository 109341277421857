// types
import { SignUpObjectIconTypes } from "./mockDataTypes";
// icons
import { ReactComponent as FullNameIcon } from "../../../assets/icons/fullName.svg";
import { ReactComponent as FullNameBoldIcon } from "../../../assets/icons/fullNameBold.svg";
import { ReactComponent as EMailIcon } from "../../../assets/icons/email.svg";
import { ReactComponent as EMailBoldIcon } from "../../../assets/icons/emailBold.svg";
import { ReactComponent as UserNameIcon } from "../../../assets/icons/info.svg";
import { ReactComponent as UserNameBoldIcon } from "../../../assets/icons/infoBold.svg";
import { ReactComponent as OpenedEyeIcon } from "../../../assets/icons/openedEye.svg";
import { ReactComponent as OpenedEyeBoldIcon } from "../../../assets/icons/openedEyeBold.svg";
import { ReactComponent as ClosedEyeIcon } from "../../../assets/icons/closedEye.svg";
import { ReactComponent as ClosedEyeBoldIcon } from "../../../assets/icons/closedEyeBold.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import { ReactComponent as LockBoldIcon } from "../../../assets/icons/lockBold.svg";

export const signUpIconsFieldsData: SignUpObjectIconTypes = {
  full_name: {
    icon: {
      active: <FullNameBoldIcon />,
      inactive: <FullNameIcon />,
    },
  },
  email: {
    icon: {
      active: <EMailBoldIcon />,
      inactive: <EMailIcon />,
    },
  },
  username: {
    icon: {
      active: <UserNameBoldIcon />,
      inactive: <UserNameIcon />,
    },
  },
  password: {
    icon: {
      active: <ClosedEyeBoldIcon />,
      inactive: <ClosedEyeIcon />,
    },
    switchIcon: {
      active: <OpenedEyeBoldIcon />,
      inactive: <OpenedEyeIcon />,
    },
  },
  old_password: {
    icon: {
      active: <ClosedEyeBoldIcon />,
      inactive: <ClosedEyeIcon />,
    },
    switchIcon: {
      active: <OpenedEyeBoldIcon />,
      inactive: <OpenedEyeIcon />,
    },
  },
  password2: {
    icon: {
      active: <LockBoldIcon />,
      inactive: <LockIcon />,
    },
  },
};
