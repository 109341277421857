import React from "react";
// data
import { footerData } from "../../mockData";
import { textData } from "../../../../../assets/translations/en/textData";
// components
import { LinkCustom } from "../../../../atoms/linkCustom/LinkCustom";
import { SubscribeEmail } from "./subscribeEmail/SubscribeEmail";
import { getIconsComponents } from "../../../../../helpers/iconsComponents/iconsComponents";
import { AppLinks } from "../../../../atoms/appLinks/AppLinks";

export const FooterContentWrapper = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-9 pt-[40px] xl:pt-[66px] pb-[40px] xl:pb-[66px] px-[30px] xl:px-[58px] gap-x-[74px] gap-y-[56px]">
      <div className="col-span-1 md:col-start-1 md:col-end-5 flex flex-col gap-7">
        <SubscribeEmail />
        <div>
          <p className="capitalize font-Inter font-bold text-md leading-3 text-white mb-5 xl:mb-6">
            {textData.footer.followUs}
          </p>
          <div className="flex flex-wrap items-center gap-4">
            {footerData.followLinks.map((item) => (
              <LinkCustom
                key={item.path + Math.random() * 100}
                variant="default"
                link={item.path}
                icon={item.icon || undefined}
                local={item.local}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="col-span-1 md:col-start-5 md:col-end-10 grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {footerData.data.map(({ title, links }) => (
            <div key={title + Math.random() * 100} className="col-span-1">
              <p className="capitalize font-Inter font-bold text-md leading-3 text-white mb-4 xl:mb-6">
                {title}
              </p>
              <ul className="list-none">
                {links.map((item) => (
                  <li
                    key={item.title + Math.random() * 100}
                    className="h-7 xl:h-auto"
                  >
                    <LinkCustom
                      variant="default"
                      link={item.path}
                      title={item.title}
                      local={item.local}
                      className="text-opacity-50"
                    />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="col-span-1 self-start lg:self-center w-full">
          <p className="capitalize font-Inter font-bold text-md leading-3 text-white text-left lg:text-center mb-4 xl:mb-6">
            {textData.footer.linksData.downloadMobileApps.title}
          </p>
          <AppLinks />
        </div>
      </div>
    </div>
  );
};
