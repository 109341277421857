import React, { useEffect, useState } from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../../../stores/hooks/hooks";
import { useNavigate } from "react-router-dom";
// store
import {
  setActiveClone,
  setDialogsModalOpen,
} from "../../../../../../stores/slice/botsSlice";
// types
import { ClonesDataItemTypes } from "../../../../../../stores/types/botsTypes";
import { StatusItemType } from "./statusBadge/mockDataTypes";
// data
import { statusData } from "./statusBadge/mockData";
import { textData } from "../../../../../../assets/translations/en/textData";
import { infoCloneData } from "./mockData";
// components
import { ButtonCustom } from "../../../../../../components/atoms/button/ButtonCustom";
import { StatusBadge } from "./statusBadge/StatusBadge";
import { MenuItemForClones } from "./menuItemForClones/MenuItemForClones";
// image
import notFoundImage from "../../../../../../assets/img/notFoundImage.png";
// helpers
import { getIconsComponents } from "../../../../../../helpers/iconsComponents/iconsComponents";

type Props = {
  data: ClonesDataItemTypes;
  customRef: any;
};
export const CloneCard = ({ data, customRef }: Props) => {
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState<StatusItemType>("active");
  const [showUseBtn, setShowUseBtn] = useState(true);
  // store
  const toolIdForScroll = useAppSelector(
    (state) => state.marketplace.toolIdForScroll
  );
// effect
  useEffect(() => {
    data.is_active ? setStatus("active") : setStatus("inactive");
  }, [data]);

  // helper
  const handleLinkClick = () => {
    setShowUseBtn(false);

    setTimeout(() => {
      navigate(`/clones/${data.tool_id}`);
    }, 1000);
  };

  return (
    <div
      ref={
        toolIdForScroll && toolIdForScroll === data.tool_id
          ? customRef
          : undefined
      }
      className={`rounded-2xl relative bg-dark-200 p-4 lg:p-5 border ${statusData[status].cardStyle}`}
    >
      <div className="mb-6 lg:mb-8 flex justify-between items-start ">
        <div className="flex items-center gap-3 w-full">
          <div className="flex-2 lg:w-[60px] lg:h-[60px] w-[36px] h-[36px] rounded-full overflow-hidden">
            <img
              src={data.avatar || notFoundImage}
              alt=""
              className={`block w-full h-full object-center ${
                data.avatar ? "object-cover" : "object-contain"
              }`}
            />
          </div>
          <div className="flex flex-col gap-0.5 overflow-hidden">
            <div className="flex items-start gap-1.5">
              <h4 className="text-ellipsis max-w-[65%] font-Inter font-extrabold text-md xl:text-3xl leading-7 text-white">
                {data.name}
              </h4>
              <div className="pt-1.5">
                <StatusBadge status={status} />
              </div>
            </div>
            <p className="w-full ellipsis-3-text-card font-Inter font-normal text-xxs lg:text-xs xl:text-md leading-5 text-primary-light">
              {data.description}
            </p>
          </div>
        </div>
        <div className="absolute right-2 top-2">
          <MenuItemForClones clone={data} marketplace={data.from_marketplace} />
        </div>
      </div>
      {/*<div className="mb-6 lg:mb-9 flex items-center gap-3">*/}
      {/*  {infoCloneData.map((info) => (*/}
      {/*    <div*/}
      {/*      className={`border-l-4 px-2 lg:px-3 flex flex-col flex-1 gap-1 lg:gap-3 whitespace-nowrap ${info.style}`}*/}
      {/*      key={info.id}*/}
      {/*    >*/}
      {/*      <h5 className="font-Inter font-extrabold text-md lg:text-lg xl:text-7xl leading-normal lg:leading-7 text-white">*/}
      {/*        {info.value}*/}
      {/*      </h5>*/}
      {/*      <p className="font-Inter font-normal text-[8px] lg:text-xs xl:text-md leading-normal lg:leading-5 text-primary-light">*/}
      {/*        {info.title}*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}
      {data.from_marketplace ? (
        showUseBtn ? (
          <ButtonCustom
            variant="noBgWithBorder"
            title={textData.btns.use}
            handleClick={handleLinkClick}
          />
        ) : (
          <div className="w-[117px] rounded-[50px] py-2 pl-4 pr-10 xl:py-4 xl:pl-7 xl:pr-10 flex items-center gap-4 border border-dark-700 animate-fadeIn">
            {getIconsComponents({ name: "droplet" })}
            <span className="font-Inter text-md font-bold leading-[75%] text-white">
              -1
            </span>
          </div>
        )
      ) : (
        <ButtonCustom
          variant="tertiary"
          disabled={data.is_tree_dialogs_limited}
          title={textData.btns.addDialogTree}
          handleClick={() => {
            dispatch(setActiveClone(data.id));
            dispatch(setDialogsModalOpen(true));
          }}
        />
      )}
    </div>
  );
};
