import React, { useEffect, useMemo } from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../stores/hooks/hooks";
import { useNavigate } from "react-router-dom";
// store
import {
  setDataImportTemplate,
  setDialogById,
  setDialogImportTemplate,
  setDialogsModalOpen,
} from "../../../stores/slice/botsSlice";
import { createDialog, editDialog } from "../../../stores/thunks/botsThunk";
import { templateImportToDialog } from "../../../stores/thunks/templatesThunk";
// data
import { textData } from "../../../assets/translations/en/textData";
import { createDialogFieldsData } from "./mockData";
// types
import { CreateDialogValuesType } from "./mockDataTypes";
import { ClonesDataItemTypes } from "../../../stores/types/botsTypes";
// components
import { Formik } from "formik";
import { ModalWrapper } from "../../molecules/modalWrapper/ModalWrapper";
import { ButtonCustom } from "../../atoms/button/ButtonCustom";
import { FieldFormik } from "../../atoms/fieldFormik/FieldFormik";

export const CreateDialogModal = () => {
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // store
  const dialogModalOpen = useAppSelector(
    (state) => state.bots.editDialogsModalOpen
  );
  const importTemplateData = useAppSelector(
    (state) => state.bots.importTemplateData
  );
  const clones = useAppSelector((state) => state.bots.clones);
  const dialogById = useAppSelector((state) => state.bots.dialogById);
  const activeClone = useAppSelector((state) => state.bots.activeClone);
  // memo
  const manuallyCreatedChatBots = useMemo(() => {
    return clones.filter((bot) => !bot.from_marketplace);
  }, [clones]);
  // effect
  useEffect(() => {
    if (!dialogModalOpen) {
      dispatch(setDialogById(null));
    }
  }, [dialogModalOpen]);

  return (
    <ModalWrapper
      open={dialogModalOpen}
      onClose={() => {
        dispatch(setDialogsModalOpen(false));
      }}
      title={
        dialogById
          ? textData.createNewDialog.titleEdit
          : textData.createNewDialog.title
      }
      className="pt-[55px] pb-[44px] pl-[101px] pr-[143px]"
    >
      <Formik
        validationSchema={createDialogFieldsData.validate}
        initialValues={
          dialogById
            ? {
                name: dialogById.name,
                description: dialogById.description,
                bots: dialogById.bots,
              }
            : {
                ...createDialogFieldsData.initialValues,
                bots: activeClone
                  ? [activeClone]
                  : createDialogFieldsData.initialValues.bots,
              }
        }
        onSubmit={async (values: CreateDialogValuesType, { resetForm }) => {
          if (importTemplateData && !dialogById) {
            const response = await dispatch(createDialog(values));
            const createdDialogId = response.payload.id;
            if (createdDialogId) {
              dispatch(setDialogImportTemplate(createdDialogId));
              await dispatch(
                templateImportToDialog({
                  id: importTemplateData,
                  body: { dialog: createdDialogId },
                  navigate,
                })
              );
            }
            dispatch(setDataImportTemplate(null));
            dispatch(setDialogsModalOpen(false));
          } else {
            if (dialogById) {
              dispatch(
                editDialog({
                  id: dialogById.id,
                  body: {
                    name: values.name,
                    description: values.description,
                    bots: values.bots,
                  },
                })
              );
            } else {
              dispatch(createDialog(values));
            }
            resetForm();
            dispatch(setDialogById(null));
            dispatch(setDialogsModalOpen(false));
          }
        }}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          errors,
          touched,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-6 mb-6">
              {createDialogFieldsData.fields.map((field) => (
                <FieldFormik
                  key={field.name + field.label}
                  field={field}
                  value={values[field.name] || ""}
                  setFieldValue={(e) =>
                    setFieldValue(field.name, e.target.value)
                  }
                  variant="dark"
                  touched={touched[field.name]}
                  errors={errors[field.name]}
                  classNameWrapper={`${field.name === "name" && "w-3/4"}`}
                  as={field.name === "description" ? "textarea" : "input"}
                />
              ))}
            </div>

            <div className="flex gap-1 relative flex-wrap">
              {manuallyCreatedChatBots.map((clone: ClonesDataItemTypes) => {
                const isBotSelected = values.bots.includes(clone.id);
                return (
                  <div key={clone.id} className="flex gap-4">
                    <button
                      type="button"
                      onClick={() => {
                        const updatedBot: string[] = isBotSelected
                          ? values.bots.filter((id: string) => id !== clone.id)
                          : [...values.bots, clone.id];
                        setFieldValue("bots", updatedBot);
                      }}
                      className={`rounded border-2 ${
                        isBotSelected
                          ? "border-dark-100"
                          : "border-transparent hover:border-primary-light"
                      }
                      `}
                      title={clone.name}
                    >
                      <img
                        src={clone.avatar}
                        alt={clone.name}
                        className="rounded w-[58px] h-[58px] object-cover"
                      />
                    </button>
                    {touched.bots && errors.bots && (
                      <span className="font-Inter font-normal text-sm text-scarlet absolute left-0 bottom-0 translate-y-full">
                        {errors.bots}
                      </span>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="flex gap-8 justify-center mt-10">
              <ButtonCustom
                variant="primary"
                title={textData.btns.btnDiscard}
                handleClick={() => {
                  resetForm();
                  dispatch(setDialogById(null));
                  dispatch(setDialogsModalOpen(false));
                }}
                type="button"
              />
              <ButtonCustom
                variant="secondary"
                title={
                  dialogById
                    ? textData.btns.btnSave
                    : textData.btns.createDialog
                }
                type="submit"
              />
            </div>
          </form>
        )}
      </Formik>
    </ModalWrapper>
  );
};
