import React from "react";
// hooks
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../stores/hooks/hooks";
import { useNavigate } from "react-router-dom";
// data
import { textData } from "../../../../../assets/translations/en/textData";
// icons
import SuccessStatusIcon from "../../../../../assets/icons/importSuccess.svg";
import WarningStatusIcon from "../../../../../assets/icons/importWarning.svg";
// types
import { TemplateDataType } from "../../../../../stores/types/templateTypes";
// components
import { ButtonCustom } from "../../../../../components/atoms/button/ButtonCustom";
// slice
import { displayImportStatus } from "../../../../../stores/slice/templatesSlice";

type ImportTemplateProps = {
  templateData: TemplateDataType;
};

export const ImportTemplateStatusWrapper = ({
  templateData,
}: ImportTemplateProps) => {
  const { name, image } = templateData;
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // store
  const status = useAppSelector(
    (state) => state.templates.importTemplateStatus
  );
  const importDialogTemplate = useAppSelector(
    (state) => state.bots.importDialogTemplate
  );
  const errorText = useAppSelector((state) => state.templates.errorText);

  if (status === "success") {
    return (
      <section className="max-w-[1080px] lg:min-w-[768px] xl:min-w-[1080px] flex flex-col justify-between items-center py-[140px] px-[80px] xl:px-[160px] bg-dark-200 border border-primary-grey rounded">
        <div className="flex flex-col justify-between items-center">
          <div className="flex gap-2 items-center">
            <h3 className="font-Inter font-extrabold text-5xl leading-9 capitalize">{`${textData.templatesPage.success}!`}</h3>
            <img src={SuccessStatusIcon} alt="" />
          </div>
          <p className="font-Inter text-xl leading-6 mt-[60px]">
            {`${name} ${textData.templatesPage.successImport}`}
          </p>
          <div className="flex items-center gap-6 mt-[60px]">
            <img
              src={image}
              alt=""
              className="w-[80px] h-[80px] object-cover"
            />
            <h6 className="font-Inter font-extrabold text-lg leading-6">
              {name}
            </h6>
          </div>
          <div className="flex gap-10 mt-[100px]">
            <ButtonCustom
              variant="primary"
              title={textData.btns.backToClonesBtn}
              handleClick={() => {
                dispatch(displayImportStatus(null));
                navigate(`/clones`);
              }}
            />
            <ButtonCustom
              variant="secondary"
              title={textData.btns.openBtn}
              handleClick={() => {
                dispatch(displayImportStatus(null));
                navigate(`/dialog/${importDialogTemplate}`);
              }}
              className="text-m font-semibold leading-4"
            />
          </div>
        </div>
      </section>
    );
  }

  if (status === "warning") {
    return (
      <section className="max-w-[1080px] lg:min-w-[768px] xl:min-w-[1080px] flex flex-col justify-between items-center py-[140px] px-[80px] xl:px-[160px] bg-dark-200 border border-primary-grey rounded">
        <div className="flex items-center gap-3">
          <h3 className="font-Inter font-extrabold text-5xl leading-9 capitalize">
            {textData.templatesPage.warning}
          </h3>
          <img src={WarningStatusIcon} alt="" />
        </div>
        <p className="font-Inter text-xl leading-6 mt-[60px]">{errorText}</p>
        <div className="flex items-center gap-6 mt-[60px]">
          <img src={image} alt="" className="w-[80px] h-[80px] object-cover" />
          <h6 className="font-Inter font-extrabold text-lg leading-6">
            {name}
          </h6>
        </div>
        <div className="flex mt-[100px]">
          <ButtonCustom
            variant="secondary"
            title={textData.btns.acknowledgeBtn}
            handleClick={() => {
              dispatch(displayImportStatus(null));
            }}
            className="text-m font-semibold leading-4"
          />
        </div>
      </section>
    );
  }

  return null;
};
