import React from "react";
// types
import { BadgeButtonVariantType, VariantStylesType } from "./badgeFieldType";
import { IconsComponentsItemTypes } from "../../../../../../../../helpers/iconsComponents/iconsComponentsTypes";
// data
import { textData } from "../../../../../../../../assets/translations/en/textData";
import { getIconsComponents } from "../../../../../../../../helpers/iconsComponents/iconsComponents";

type Props = {
  handleChange: (value: string) => void;
  handleBlur?: () => void;
  handleFocus?: () => void;
  value: string;
  variant?: BadgeButtonVariantType;
  disabled?: boolean;
  icon?: IconsComponentsItemTypes;
  iconCallback?: () => void;
};

export const BadgeField = ({
  value,
  handleChange,
  handleBlur,
  disabled,
  handleFocus,
  variant = "dark",
  icon,
  iconCallback,
}: Props) => {
  const variantStyles: VariantStylesType = {
    light:
      "text-primary-light placeholder:text-primary-light placeholder:opacity-70",
    dark: "text-dark-400 placeholder:text-dark-400 placeholder:opacity-70",
  };
  return (
    <div className="relative">
      <input
        type="text"
        value={value}
        style={{
          width: value.length > 0 ? `${Number(value.length) * 18}px` : "120px",
        }}
        placeholder={`${textData.btns.example} 1`}
        disabled={disabled}
        maxLength={12}
        className={`max-w-[160px] min-w-[80px] capitalize py-2 px-4 xl:py-4 xl:px-5 rounded bg-secondary-dark
       border border-primary-grey font-Inter font-medium text-xs placeholder:font-medium xl:font-bold xl:text-md leading-5 placeholder:font-Inter xl:placeholder:font-bold 
       placeholder:text-xs xl:placeholder:text-md placeholder:leading-5 disabled:opacity-60
      ${variantStyles[variant]}`}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {icon && (
        <button className="absolute top-3 right-3" onClick={iconCallback}>
          {getIconsComponents({ name: icon })}
        </button>
      )}
    </div>
  );
};
