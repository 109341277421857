import {textData} from "../../../assets/translations/en/textData";
import {INumberIndex, transactionListDataType} from "./mockDataType";

export const statusTransactionMap: INumberIndex = {
  0: "pending",
  1: "paid",
}

export const transactionListData: transactionListDataType = {
  fields: [
    {
      name: textData.transactions.name,
      value: "bot",
    },
    {
      name: textData.transactions.transaction,
      value: "transaction_value",
    },
    {
      name: textData.transactions.fee,
      value: "fee_value",
    },
    {
      name: textData.transactions.date,
      value: "created_at",
    },
    {
      name: textData.transactions.payout,
      value: "payout_value",
    },
    {
      name: textData.transactions.status,
      value: "status",
    }
  ],
  periods: [
    {
      name: "Last 7 days",
      value: 7
    },
    {
      name: "Last 30 days",
      value: 30
    },
    {
      name: "Last 90 days",
      value: 90
    },
  ]
}
