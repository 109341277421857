import { createAsyncThunk } from "@reduxjs/toolkit";
// api
import userAuthApi from "../api/userAuthApi";
// types
import {
  UserSignInType,
  UserSignUpType,
  ForgotPasswordBodyType,
  ResetPasswordType,
} from "../types/userAuthTypes";
// alert
import { displayAlert } from "../slice/alertSlice";
// data
import { textData } from "../../assets/translations/en/textData";
// thunk
import {
  saveAccountType,
  setCredentials,
  setEmailToVerify,
  setPhoneNumber,
  setToken,
  setTwoStepVerificationModalOpen
} from "../slice/userAuthSlice";
// helpers
import {getStorageName} from "../../helpers/storageName/storageName";

export const signUpUser = createAsyncThunk(
  "userAuth/signUpUser",
  async (data: UserSignUpType, thunkAPI) => {
    try {
      const response = await userAuthApi.userSignUp({
        email: data.email,
        password: data.password,
        full_name: data.full_name,
        username: data.username,
      });
      if (response.status === 201) {
        data.navigate("/confirm-email");
        data.resetForm();
        thunkAPI.dispatch(setEmailToVerify(data.email));
        thunkAPI.dispatch(
          displayAlert({
            type: "success",
            text: textData.alertsMessage.emailConfirmation,
          })
        );
      }
      return response.data;
    } catch (e: any) {
      if (e.response && e.response.data) {
        const { email, username, password } = e.response.data;
        const errors = [];

        if (email && email.length > 0) {
          errors.push(...email);
        }
        if (username && username.length > 0) {
          errors.push(...username);
        }
        if (password && password.length > 0) {
          errors.push(...password);
        }

        if (errors.length > 0) {
          const errorText = errors.join("\n");
          thunkAPI.dispatch(
            displayAlert({
              type: "error",
              text: errorText || textData.alertsMessage.wrong,
            })
          );
        }
      }
    }
  }
);

export const signInUser = createAsyncThunk(
  "userAuth/signInUser",
  async (data: UserSignInType, thunkAPI) => {
    try {
      const response = await userAuthApi.userSignIn(data);
      if (response.status === 200) {
        if (response.data.tfa_required) {
          const {resetForm, navigate, ...credentials} = data;
          thunkAPI.dispatch(setTwoStepVerificationModalOpen(true));
          thunkAPI.dispatch(setCredentials(credentials));
          thunkAPI.dispatch(setPhoneNumber(response.data.phone_number));
        } else {
          data.navigate("/");
          data.resetForm && data.resetForm();

          localStorage.setItem(getStorageName.accessToken, response.data.access);
          localStorage.setItem(getStorageName.refreshToken, response.data.refresh);
          thunkAPI.dispatch(setToken(response.data.access));
          thunkAPI.dispatch(setCredentials(null));
          thunkAPI.dispatch(setTwoStepVerificationModalOpen(false));

          const accountType = response.data.user.account_type;
          thunkAPI.dispatch(saveAccountType(accountType));
        }
      }

      return response.data.access;
    } catch (e: any) {
      if (e.response && e.response.data) {
        thunkAPI.dispatch(
          displayAlert({
            type: "error",
            text: e.response.data.detail || textData.alertsMessage.wrong,
          })
        );
      }
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "userAuth/forgotPassword",
  async (data: ForgotPasswordBodyType, thunkAPI) => {
    try {
      const response = await userAuthApi.forgotPassword(data);
      if (response.status === 200) {
        thunkAPI.dispatch(
          displayAlert({
            type: "success",
            text: textData.alertsMessage.emailConfirm,
          })
        );
      }
      return response.data;
    } catch (e: any) {
      console.log(e);
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: e.response.data.detail || textData.alertsMessage.wrong,
        })
      );
    }
  }
);

export const resetPassword = createAsyncThunk(
  "userAuth/resetPassword",
  async (data: ResetPasswordType, thunkAPI) => {
    try {
      const response = await userAuthApi.resetPassword({
        password: data.password,
        token: data.token,
        key: data.key,
      });
      if (response.status === 200) {
        thunkAPI.dispatch(
          displayAlert({
            type: "success",
            text: textData.alertsMessage.passwordUpdated,
          })
        );
        data.navigate("/signin");
        data.resetForm();
      }
      return response.data;
    } catch (e: any) {
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: e.response.data.detail || textData.alertsMessage.wrong,
        })
      );
    }
  }
);
