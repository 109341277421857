import { textData } from "../../assets/translations/en/textData";

export const extractErrorMessage = (errorResponse: any) => {
  if (!errorResponse) return textData.alertsMessage.wrong;

  if (errorResponse.data) {
    const { data } = errorResponse;

    if (data.owner && data.owner.length > 0) {
      return data.owner[0];
    }

    if (data.detail) {
      return data.detail;
    }

    if (typeof data === "string") {
      return data;
    }

    if (data.message) {
      return data.message;
    }
  }

  return textData.alertsMessage.wrong;
};
