// components
import { Footer } from "../../../../../components/molecules/footer/Footer";
import { ForgotPasswordContent } from "../../../../../components/organisms/forgotPasswordContent/ForgotPasswordContent";
import { ForgotPasswordOptions } from "./forgotPasswordOptions/ForgotPasswordOptions";

export const ForgotPasswordMethodsPage = () => {
  return (
    <div className="h-screen">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <ForgotPasswordContent />
        <ForgotPasswordOptions />
      </div>
      <Footer />
    </div>
  );
};
