import React, { Fragment } from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
// store
// data
import { textData } from "../../../../assets/translations/en/textData";
// components
import { ModalWrapper } from "../../../../components/molecules/modalWrapper/ModalWrapper";
import { ButtonCustom } from "../../../../components/atoms/button/ButtonCustom";
import { Formik } from "formik";
import {setChangePasswordModalOpen} from "../../../../stores/slice/userProfileSlice";
import {UserChangePasswordDataType} from "../../../../stores/types/userProfileTypes";
import {changePasswordUserProfile} from "../../../../stores/thunks/userProfileThunk";
import {FieldFormik} from "../../../../components/atoms/fieldFormik/FieldFormik";
import {changePasswordData} from "./mockData";

export const ChangePasswordModal = () => {
  // hooks
  const dispatch = useAppDispatch();
  // store
  const changePasswordModalOpen = useAppSelector((state) => state.userProfile.changePasswordModalOpen);

  return (
    <Fragment>
      <ModalWrapper
        open={changePasswordModalOpen}
        onClose={() => dispatch(setChangePasswordModalOpen(false))}
        title={textData.settings.changePassword}
      >
        <div>
          <Formik
            validationSchema={changePasswordData.validate}
            initialValues={changePasswordData.initialValues}
            onSubmit={(values: UserChangePasswordDataType, { resetForm }) => {
              dispatch(changePasswordUserProfile({
                old_password: values.old_password,
                password: values.password
              }))
              dispatch(setChangePasswordModalOpen(false));
            }}
          >
            {({
                handleSubmit,
                values,
                setFieldValue,
                resetForm,
                errors,
                touched,
              }) => (
              <form onSubmit={handleSubmit}>

                {changePasswordData.fields.map((field) => (
                  <FieldFormik
                    key={field.name + field.label}
                    field={field}
                    value={values[field.name]}
                    setFieldValue={(e) =>
                      setFieldValue(field.name, e.target.value)
                    }
                    variant="dark"
                    touched={touched[field.name]}
                    errors={errors[field.name]}
                    className="bg-transparent pt-3 pb-3 pr-5 pl-5 text-sm text-white font-bold leading-5 placeholder:text-sm placeholder:font-bold"
                    classNameWrapper="gap-[0px] py-4"
                  />
                ))}
                
                <div className="flex gap-7 justify-center mt-10">
                  <ButtonCustom
                    variant="primary"
                    title={textData.btns.btnDiscard}
                    handleClick={() => {
                      resetForm();
                      dispatch(setChangePasswordModalOpen(false));
                    }}
                    type="button"
                  />
                  <ButtonCustom
                    variant="secondary"
                    title={textData.btns.btnSave}
                    type="submit"
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </ModalWrapper>
    </Fragment>
  );
};
