import React from "react";
// data
import { textData } from "../../../../assets/translations/en/textData";
import { activityTableMockData } from "../activityTaskTableItem/mockData";
// helper
import { getIconsComponents } from "../../../../helpers/iconsComponents/iconsComponents";
// components
import { ActivityTaskTableItem } from "../activityTaskTableItem/ActivityTaskTableItem";

export const TasksTabContent = () => {
  return (
    <div className="mt-4">
      <div className="overflow-x-auto">
        <table className="min-w-full text-white font-Roboto text-left">
          <thead>
            <tr className="border-b border-primary-grey">
              <th className="font-Roboto font-normal text-md w-[30%] relative">
                <div className="flex flex-col">
                  <p className="heading-tooltip text-dark-170 font-Roboto italic text-sm leading-[150%]">
                    {textData.activityPage.tasksTab.heading}
                  </p>
                  <p className="clone-tooltip pl-0 pr-4 pt-[30px] pb-[17px] capitalize">
                    {textData.activityPage.tasksTab.thead.first}
                  </p>
                </div>
              </th>
              <th className="px-4 font-Roboto font-normal text-md text-center w-[30%] relative">
                <p className="status-tooltip pt-[30px] capitalize">
                  {textData.activityPage.tasksTab.thead.second}
                </p>
              </th>
              <th className="px-4 font-Roboto font-normal text-md w-[32%]">
                <div className="flex flex-col">
                  <button onClick={() => {}}>
                    {getIconsComponents({
                      name: "activityFilter",
                      className: "w-4 h-[14px]",
                    })}
                  </button>
                  <p className="earnings-tooltip pt-[30px] pb-[17px] capitalize">
                    {textData.activityPage.tasksTab.thead.third}
                  </p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {activityTableMockData.map((item) => (
              <ActivityTaskTableItem key={item.id} {...item} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
