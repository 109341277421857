import React, { useState, useMemo } from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../../../../stores/hooks/hooks";
// data
import { textData } from "../../../../../../../assets/translations/en/textData";
// thunk
import { setRatingModal } from "../../../../../../../stores/slice/marketplaceSlice";

type Props = {
  readOnly?: boolean;
};

export const StarRating = ({ readOnly = false }: Props) => {
  // hooks
  const dispatch = useAppDispatch();
  // state
  const [hover, setHover] = useState(0);
  // store
  const profile = useAppSelector((state) => state.userProfile.profile);
  const toolById = useAppSelector((state) => state.marketplace.toolById);
  // memo
  const currenRatingByUser = useMemo(() => {
    if (toolById && profile) {
      return (
        toolById.ratings.find((item) => item.user.id === profile.id)?.rating ||
        0
      );
    } else {
      return 0;
    }
  }, [toolById, profile]);
  // helpers
  const renderStars = () => {
    if (toolById) {
      return [...Array(5)].map((_, index) => {
        const active = readOnly
          ? index + 1 <= toolById?.average_rating
          : index + 1 <= (hover || currenRatingByUser);
        return (
          <span
            key={index.toString() + "star"}
            className={active ? "text-yellow-200" : "text-dark-650"}
            onMouseOver={() =>
              !(readOnly || currenRatingByUser) && setHover(index + 1)
            }
            onClick={() =>
              !(readOnly || currenRatingByUser) &&
              dispatch(setRatingModal(index + 1))
            }
          >
            &#9733;
          </span>
        );
      });
    }
  };

  return (
    <div
      className={`text-8.5xl flex items-baseline gap-1 ${
        !(readOnly || currenRatingByUser) ? "cursor-pointer" : ""
      }`}
      onMouseLeave={() => setHover(0)}
    >
      {renderStars()}
      {readOnly && (
        <div className="flex gap-5 font-Robobto font-normal text-xl leading-6 ml-10">
          <p>{toolById?.average_rating || 0}/5</p>
          <p>
            ({toolById?.ratings.length} {textData.marketplacePage.reviews})
          </p>
        </div>
      )}
    </div>
  );
};
