import { useNavigate } from "react-router-dom";
// data
import { textData } from "../../../../../assets/translations/en/textData";
import { resetPasswordData } from "./mockData";
// types
import { ResetPasswordValuesType } from "./mockDataTypes";
// icon
import IdLogo from "../../../../../assets/icons/alphaIdColored.svg";
// hooks
import { useAppDispatch } from "../../../../../stores/hooks/hooks";
// components
import { ButtonCustom } from "../../../../../components/atoms/button/ButtonCustom";
import { Formik } from "formik";
import { FieldFormik } from "../../../../../components/atoms/fieldFormik/FieldFormik";
// thunk
import { resetPassword } from "../../../../../stores/thunks/userAuthThunk";
// helpers
import { sliceUrlCode } from "../../../../../helpers/utilityFunctions/utilityFunctions";

export const ResetPasswordForm = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <section className="grow justify-center pt-[116px] pr-[76px] pb-[276px] pl-[80px] bg-black">
      <Formik
        validationSchema={resetPasswordData.validate}
        initialValues={resetPasswordData.initialValues}
        onSubmit={(values: ResetPasswordValuesType, { resetForm }) =>
          dispatch(
            resetPassword({
              password: values.password,
              token: sliceUrlCode(document.location.search, "token") as string,
              key: sliceUrlCode(document.location.search, "key") as string,
              navigate,
              resetForm,
            })
          )
        }
      >
        {({ handleSubmit, values, setFieldValue, errors, touched }) => (
          <form onSubmit={handleSubmit} className="max-w-[490px]">
            <div className="">
              <div className="flex items-center gap-5 mb-20">
                <h1 className="font-Inter font-extrabold text-8xl leading-7 text-white capitalize">
                  {textData.resetPasswordPage.title}
                </h1>
                <img src={IdLogo} alt="colored logo" />
              </div>
              <div className="flex flex-col gap-4 mb-[108px]">
                {resetPasswordData.fields.map((field) => (
                  <FieldFormik
                    key={field.name + field.label}
                    field={field}
                    value={values[field.name]}
                    setFieldValue={(e) =>
                      setFieldValue(field.name, e.target.value)
                    }
                    variant="dark"
                    touched={touched[field.name]}
                    errors={errors[field.name]}
                    labelClass="text-xs font-medium leading-4"
                    className="bg-transparent pt-3 pb-3 pr-5 pl-5 text-sm text-white font-bold leading-5 placeholder:text-sm placeholder:font-bold"
                    classNameWrapper="gap-[0px]"
                  />
                ))}
              </div>
              <ButtonCustom
                variant="secondary"
                type="submit"
                title={textData.btns.btnSave}
                className="w-full"
              />
            </div>
          </form>
        )}
      </Formik>
    </section>
  );
};
