import React from "react";
// types
import { BadgeButtonVariantType, VariantStylesType } from "./badgeButtonType";

type Props = {
  handleClick: () => void;
  title?: string;
  disabled?: boolean;
  variant?: BadgeButtonVariantType;
  children?: any;
};

export const BadgeButton = ({
  title,
  handleClick,
  disabled,
  variant = "dark",
  children,
}: Props) => {
  const variantStyles: VariantStylesType = {
    light: "text-primary-light border-primary-grey ",
    dark: "text-dark-400 border-primary-grey ",
    red: "text-dark-400 border-red-100",
  };
  return (
    <button
      type="button"
      disabled={disabled}
      className={`max-w-[150px] capitalize overflow-hidden text-ellipsis  py-2 px-3 xl:py-4 xl:px-5 rounded bg-secondary-dark border font-Inter font-medium text-xs xl:font-bold xl:text-md leading-5 
    ${
        disabled ? "opacity-60" : "opacity-100 hover:opacity-80"
      } ${variantStyles[variant]}`}
      onClick={handleClick}
    >
      {children || title}
    </button>
  );
};
