import React from "react";
// store
import { setUploadFileModalOpen } from "../../../stores/slice/botsSlice";
// hooks
import { useAppDispatch } from "../../../stores/hooks/hooks";
// data
import { textData } from "../../../assets/translations/en/textData";


type Props = {
  touched?: boolean;
  errors?: string;
  avatar: string;
};
export const AddAvatarWrapper = ({ touched, errors, avatar }: Props) => {
  // hooks
  const dispatch = useAppDispatch();

  return (
    <div className="mt-1 mb-10 relative">
      <h5 className="font-Inter text-start font-extrabold text-xs xl:text-lg leading-6 text-white mb-3">
        {textData.createNewClone.addLogo}
      </h5>
      <div className="flex items-center gap-9">
        <div className="xl:w-[100px] xl:h-[100px] w-[71px] h-[71px] rounded-full overflow-hidden">
          <img src={avatar} alt="" className="object-cover w-full h-full" />
        </div>
        <div className="flex flex-col items-start gap-3 flex-1">
          <p className="text-start font-Inter font-normal text-xs xl:text-md leading-4 text-dark-500">
            {textData.createNewClone.recommendText}
          </p>
          <button
            className={`font-Inter font-normal text-xs xl:text-md leading-4 hover:opacity-80 capitalize ${
              touched && errors ? " text-scarlet" : " text-blue-100"
            }`}
            type="button"
            onClick={() => dispatch(setUploadFileModalOpen(true))}
          >
            {textData.createNewClone.uploadImage}
          </button>
        </div>
      </div>
      {touched && errors && (
        <span className="font-Inter font-normal text-xs xl:text-sm text-scarlet absolute left-0 bottom-0 translate-y-full">
          {errors}
        </span>
      )}
    </div>
  );
};
