import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import { ActivityPageWrapper } from "./components/ActivityPageWrapper";

const ActivityPage = () => {
  return (
    <Layout
      noPadding
      mobileApp
      classNameWrapper="h-full pt-[70px] pb-8 pr-10 pl-[100px] flex justify-center items-center"
    >
      <ActivityPageWrapper />
    </Layout>
  );
};

export default ActivityPage;
