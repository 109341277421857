// icons
import { ReactComponent as FinanceIcon } from "../../../../../../../assets/icons/finance.svg";
import { ReactComponent as PriceIcon } from "../../../../../../../assets/icons/dollarSign.svg";
import { ReactComponent as PopularIcon } from "../../../../../../../assets/icons/popular.svg";
// types
import { InnerBadgeVariantType } from "./badgeFilterType";

export const innerBadgeIcon: InnerBadgeVariantType = {
  installed: <FinanceIcon />,
  free: <PriceIcon />,
  popular: <PopularIcon />,
  premium: <FinanceIcon />,
  new: <FinanceIcon />,
  topRated: <FinanceIcon />,
};
