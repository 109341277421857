import React from "react";
// icon
import toolIcon from "../../../../assets/icons/activity-transaction-item.png";
import hostIcon from "../../../../assets/icons/host.svg";
// types
import { ActivityItemDataType } from "./mockDataTypes";
import { CustomStep } from "../activityTaskTableItem/mockDataTypes";

export const activityItemData: ActivityItemDataType = {
  imageUrl: toolIcon,
  title: "DCA-34",
  orderTotal: "$2,370.88",
  payout: "$121.33",
  host: "3Commas",
  hostIcon: hostIcon,
  duration: "00:03:42",
  timeOfTask: "3:43pm EST",
  date: "10/22/2023",
};

export const activityCloneItemSteps: CustomStep[] = [
  {
    target: ".item-host-tooltip",
    content: (
      <p>
        <span className="italic font-semibold">Host</span> displays the developer
        of the bot
      </p>
    ),
    placement: "bottom",
    disableBeacon: true,
    positionClass: "position-top-center",
  },
];
