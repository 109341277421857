import React from "react";
// type
import { StatusItemType } from "./mockDataTypes";
// data
import { statusData } from "./mockData";

type Props = {
  status: StatusItemType;
};
export const StatusBadge = ({ status }: Props) => {
  return (
    <div
      className={`px-1.5 lg:px-3 py-[2px] rounded-2xl font-Inter font-bold text-[7px] capitalize lg:text-xs leading-4 ${statusData[status].style}`}
    >
      <p className="leading-none">{statusData[status].title}</p>
    </div>
  );
};
