import React, { ReactComponentElement, useEffect, useState } from "react";
// hooks
import { useAppSelector } from "../../../stores/hooks/hooks";
// icon
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as ReplyIcon } from "../../../assets/icons/reply.svg";
import { ReactComponent as HomeIcon } from "../../../assets/icons/home.svg";
import { ReactComponent as BotIcon } from "../../../assets/icons/bot.svg";
import { ReactComponent as ButtonsIcon } from "../../../assets/icons/buttonsSmall.svg";
// types
import { IconVariantDataType, IconVariantNameType } from "./treeButtonTypes";
import {
  ChildRepliesType,
  ChildScenarioType,
} from "../../../stores/types/treeBotTypes";
// helpers
import { branchElement, getOrientation } from "./helper";
// components
import { TreeLevelView } from "./components/treeLevelView/TreeLevelView";

type Props = {
  handleCallback: () => void;
  icon: IconVariantNameType;
  start?: boolean;
  childScenario: ChildScenarioType | null;
  text: string;
  replies: ChildRepliesType[];
  disabled?: boolean;
  preview?: boolean;
};

export const TreeStartButton = ({
  handleCallback,
  icon,
  start = false,
  childScenario,
  text,
  replies,
  disabled = false,
  preview = false,
}: Props) => {
  // state
  const [branches, setBranches] = useState<ReactComponentElement<any>[] | null>(
    null
  );
  // store
  const dialogById = useAppSelector((state) => state.treeBot.dialogById);
  // data
  const iconVariant: IconVariantDataType = {
    freeText: <ReplyIcon />,
    home: <HomeIcon />,
    bot: <BotIcon />,
    buttons: <ButtonsIcon />,
  };
  //effect
  useEffect(() => {
    if (replies && replies.length > 0) {
      const branchOrientations = getOrientation(replies);
      setBranches(
        branchOrientations?.map((item) => branchElement(item)) || null
      );
    } else if ((start && dialogById?.start_message) || disabled || preview ) {
      setBranches([branchElement("default")]);
    } else {
      setBranches([]);
    }
  }, [childScenario, start, dialogById]);

  return (
    <div className={`relative w-fit flex flex-col`}>
      <button
        type="button"
        disabled={preview || disabled}
        onClick={start ? handleCallback : handleCallback}
        className={`flex justify-center items-center gap-3 flex-col relative  ${
          disabled || preview ? "" : "hover:opacity-80 "
        } ${
          (replies && replies.length > 0) ||
          (start && dialogById?.start_message) ||
          disabled || preview
            ? "mb-[96px]"
            : ""
        }`}
      >
        <div
          className={`py-2 px-4 xl:py-3 xl:px-6 bg-primary-grey rounded flex gap-2.5 items-center justify-center min-w-[160px] max-w-full ${
            start ? "border border-secondary-green" : ""
          }`}
        >
          {iconVariant[icon]}
          <span className="font-Inter font-semibold xl:font-bold xl:text-md text-sm leading-5 text-white overflow-hidden text-ellipsis whitespace-nowrap">
            {text}
          </span>
        </div>
        {branches}
        {!start && !disabled
          ? replies.length === 0 && (
              <div
                onClick={handleCallback}
                className="w-6 h-6 rounded-full bg-dark-500 flex justify-center items-center"
              >
                <PlusIcon />
              </div>
            )
          : null}
      </button>

      {childScenario &&
        (childScenario.reply_type === 0 || childScenario.reply_type === 1) && (
          <TreeLevelView data={childScenario} preview={preview}/>
        )}
    </div>
  );
};
