import React, { useEffect } from "react";
// hooks
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
// thunks
import { fetchDialogById } from "../../../../stores/thunks/botsThunk";
// reducer
import {
  clearButtons,
  setActiveWindow,
  setParentReply,
  setScenarioActive,
} from "../../../../stores/slice/treeBotSlice";
// components
import { BotTree } from "./botTree/BotTree";
import { Header } from "../../../../components/molecules/header/Header";
import { Spinner } from "../../../../components/atoms/spinner/Spinner";
import { BotTreeTablet } from "./botTreeTablet/BotTreeTablet";
// data
import { activeWindowData } from "./mockData";
// helpers
import { getStorageName } from "../../../../helpers/storageName/storageName";

export const BotSettingsPreview = () => {
  // hooks
  const dispatch = useAppDispatch();
  // location
  const params = useParams();
  const dialogId: string = params.id as string;
  // navigation
  const navigate = useNavigate();
  // store
  const activeWindow = useAppSelector((state) => state.treeBot.activeWindow);
  const isLoadingTreeBot = useAppSelector((state) => state.treeBot.isLoading);
  const hasTree = useAppSelector((state) => state.treeBot.dialogById?.tree);
  // effect
  useEffect(() => {
    if (dialogId) {
      dispatch(
        fetchDialogById({
          id: dialogId,
          callbackError: () => navigate("/clones"),
        })
      );
    }
  }, [dispatch, dialogId]);

  useEffect(() => {
    return () => {
      dispatch(setActiveWindow(null));
      dispatch(setScenarioActive(null));
      dispatch(clearButtons());
      dispatch(setParentReply(null));
    };
  }, [dispatch, dialogId]);

  useEffect(() => {
    localStorage.removeItem(getStorageName.chatBotId);
    return () => {
      localStorage.removeItem(getStorageName.chatBotId);
    };
  }, []);

  if (isLoadingTreeBot) {
    return (
      <section className="h-screen flex justify-center items-center">
        <Spinner />
      </section>
    );
  }
  if (dialogId) {
    return (
      <div className="w-full flex flex-col gap-[60px] pt-8 items-end">
        <Header
          handleClickData={{
            testChatBot: () => dispatch(setActiveWindow("chatBot")),
            exportTemplate: () => {
              navigate(`/create-template/${dialogId}`);
            },
            deploy: () => {
              navigate(`/deploy/${dialogId}`);
            },
          }}
          data={["testChatBot", "exportTemplate", "deploy"]}
          classNameWrapper="px-8"
          classNameIcon={["fill-white", "fill-white", "fill-secondary-dark"]}
          disabledButtonStates={{ exportTemplate: hasTree === null }}
        />
        <div className="w-full grid grid-cols-2">
          <div className="hidden lg:block">
            <BotTree />
          </div>
          <div className="lg:hidden">
            <BotTreeTablet />
          </div>
          <div className="flex justify-end">
            {activeWindow && activeWindowData[activeWindow]}
          </div>
        </div>
      </div>
    );
  }
  return null;
};
