import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import {SelectBusinessPlanWrapper} from "./components/SelectBusinessPlanWrapper";

const SelectBusinessPlan = () => {
  return (
    <Layout
      sideBar={false}
      noPadding
      className="bg-black w-full"
    >
      <SelectBusinessPlanWrapper />
    </Layout>
  );
};

export default SelectBusinessPlan;
