import React, { useEffect, useRef, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ChartData,
  ChartArea,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { textData } from "../../../../../assets/translations/en/textData";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
export const DashboardChart = () => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState<ChartData<"line">>({
    datasets: [],
  });
  const options = {
    responsive: true,
    scales: {
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const colorsGreen = [
    "rgb(0, 248, 190, 0.3)",
    "rgb(0, 248, 190, 0)",
    "rgb(0, 248, 190,0)",
  ];
  const colorsPurple = [
    "rgb(122, 82, 244, 0.3)",
    "rgb(122, 82, 244, 0)",
    "rgb(122, 82, 244,0)",
  ];
  function createGradient(
    ctx: CanvasRenderingContext2D,
    area: ChartArea,
    colors: string[]
  ) {
    const colorStart = colors[0];
    const colorMid = colors[1];
    const colorEnd = colors[2];

    const gradient = ctx.createLinearGradient(0, area.top, 0, area.bottom);

    gradient.addColorStop(0, colorStart);
    gradient.addColorStop(0.5, colorMid);
    gradient.addColorStop(1, colorEnd);

    return gradient;
  }

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }
    const data = {
      labels,
      datasets: [
        {
          fill: true,
          label: "",
          tension: 0.5,
          data: [600, 700, 600, 400, 350, 600, 450, 400, 200, 750, 500, 600],
          borderColor: "#00F8BE",
          borderWidth: 2,
          backgroundColor: createGradient(
            // @ts-ignore
            chart.ctx,
            // @ts-ignore
            chart.chartArea,
            colorsGreen
          ),
        },
        {
          fill: true,
          label: "",
          tension: 0.5,
          data: [700, 800, 500, 300, 550, 800, 250, 300, 300, 450, 600, 700],
          borderColor: "#7A52F4",
          borderWidth: 1,
          backgroundColor: createGradient(
            // @ts-ignore
            chart.ctx,
            // @ts-ignore
            chart.chartArea,
            colorsPurple
          ),
        },
      ],
    };
    setChartData(data);
  }, []);
  return (
    <div className="py-7 px-2.5 border-b border-opacity-15 border-dark-450">
      <div className="flex gap-x-5 items-center mb-8">
        <div className="flex gap-x-2 items-center">
          <div className="w-3 h-3 rounded-full bg-violet-100 " />
          <span className="font-Inter text-sm text-white">
            {textData.dashboardPage.timeSaved}
          </span>
        </div>
        <div className="flex gap-x-2 items-center">
          <div className="w-3 h-3 rounded-full  bg-secondary-green" />
          <span className="font-Inter text-sm text-white">
            {textData.dashboardPage.earnings}
          </span>
        </div>
      </div>
      <Line ref={chartRef} options={options} data={chartData} />
    </div>
  );
};
