import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import { TreeTemplateWrapper } from "./components/treeTemplateWrapper/TreeTemplateWrapper";

const TreeTemplatesPage = () => {
  return (
    <Layout
      noPadding
      mobileApp
      classNameWrapper="h-full pt-[108px] pb-8 pr-10 pl-[100px] flex justify-center items-center "
    >
      <TreeTemplateWrapper />
    </Layout>
  );
};

export default TreeTemplatesPage;
