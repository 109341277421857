import React, { useEffect, useState } from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../../../stores/hooks/hooks";
// store
import { setActiveWindow } from "../../../../../../stores/slice/treeBotSlice";
import { editeUserReplies } from "../../../../../../stores/thunks/botsThunk";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
import { endMessageFieldData } from "./mockData";
// components
import { BlackWrapper } from "../../../../../../components/atoms/blackWrapper/BlackWrapper";
import { ButtonCustom } from "../../../../../../components/atoms/button/ButtonCustom";
import { FieldCustom } from "../../../../../../components/atoms/field/FieldCustom";

export const EndMessageWrapper = () => {
  // hooks
  const dispatch = useAppDispatch();
  // store
  const dialogById = useAppSelector((state) => state.treeBot.dialogById);
  const replyById = useAppSelector((state) => state.treeBot.replyById);
  // state
  const [endMessage, setEndMessageLocal] = useState("");

  useEffect(() => {
    setEndMessageLocal(replyById?.end_message || "");
  }, [replyById]);

  const handleCallbackSave = () => {
    if (replyById && dialogById && endMessage) {
      dispatch(
        editeUserReplies({
          id: replyById.id,
          dialogId: dialogById.id,
          callback: () => {
            dispatch(setActiveWindow(null));
          },
          body: {
            end_message: endMessage,
          },
        })
      );
    }
  };
  return (
    <BlackWrapper title={textData.endMessage.title} className="border-red-100">
      <div>
        <div className="hidden lg:block">
          <FieldCustom
            field={endMessageFieldData}
            value={endMessage}
            setFieldValue={(value: string) => setEndMessageLocal(value)}
            variant="dark"
            rows={4}
          />
        </div>
        <div className="lg:hidden">
          <FieldCustom
            field={endMessageFieldData}
            value={endMessage}
            setFieldValue={(value: string) => setEndMessageLocal(value)}
            variant="dark"
            rows={2}
          />
        </div>
        <div className="flex items-center gap-3 mt-4 lg:mt-14">
          <ButtonCustom
            variant="primary"
            handleClick={() => dispatch(setActiveWindow(null))}
            title={textData.btns.btnDiscard}
          />
          <ButtonCustom
            disabled={!endMessage}
            variant="secondary"
            handleClick={handleCallbackSave}
            title={textData.btns.btnSave}
          />
        </div>
      </div>
    </BlackWrapper>
  );
};
