// types
import { PropertiesCardsDataType } from "./mockDataTypes";
// icons
import { ReactComponent as ABC } from "../../../../../../assets/icons/abc.svg";
import { ReactComponent as Buttons } from "../../../../../../assets/icons/buttons.svg";
import { ReactComponent as Tasks } from "../../../../../../assets/icons/tasks.svg";
import { ReactComponent as Megaphone } from "../../../../../../assets/icons/megaphone.svg";
// data
import { textData } from "../../../../../../assets/translations/en/textData";

export const propertiesCardsData: PropertiesCardsDataType = [
  {
    icon: <ABC />,
    title: textData.properties.freeText,
    describe: textData.properties.freeTextDescribe,
    access: true,
    id: "freeText",
  },
  {
    icon: <Buttons />,
    title: textData.properties.buttons,
    describe: textData.properties.buttonsDescribe,
    access: true,
    id: "buttons",
  },
  {
    icon: <Tasks />,
    title: textData.properties.microSurveys,
    describe: textData.properties.microSurveysDescribe,
    access: false,
    id: "microSurveys",
  },
  {
    icon: <Megaphone />,
    title: textData.properties.upsell,
    describe: textData.properties.upsellDescribe,
    access: false,
    id: "upsell",
  },
];
