import React, { useEffect, useState } from "react";
// hooks
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
// data
import { textData } from "../../../../assets/translations/en/textData";
import { createTemplateFieldsData } from "./mockData";
// icon
import userCustom from "../../../../assets/img/userCustom.png";
// store
import { createTemplate } from "../../../../stores/thunks/templatesThunk";
// types
import { CreateTemplateValuesType } from "./mockDataTypes";
//components
import { AddAvatarWrapper } from "../../../../components/molecules/addAvatarWrapper/AddAvatarWrapper";
import { ButtonCustom } from "../../../../components/atoms/button/ButtonCustom";
import { UploadFileModal } from "../../../../components/organisms/uploadFileModal/UploadFileModal";
import { ModalWrapper } from "../../../../components/molecules/modalWrapper/ModalWrapper";
import { Formik } from "formik";
import { FieldFormik } from "../../../../components/atoms/fieldFormik/FieldFormik";

export const ExportTemplateWrapper = () => {
  // hooks
  const dispatch = useAppDispatch();
  // navigate
  const navigate = useNavigate();
  const { id } = useParams();
  // store
  const avatarUrl = useAppSelector((state) => state.bots.avatarUrl);
  // state
  const [imageUrl, setImageUrl] = useState("");
  const [avatar, setAvatar] = useState<any | null>(null);
  // effect
  useEffect(() => {
    if (avatar && avatarUrl) {
      setImageUrl(URL.createObjectURL(avatar));
    } else {
      setImageUrl(userCustom);
    }
  }, [avatar, avatarUrl]);

  return (
    <ModalWrapper
      open={true}
      onClose={() => navigate(-1)}
      title={textData.exportTemplatePage.title}
      className="capitalize"
    >
      <div>
        <Formik
          validationSchema={createTemplateFieldsData.validate}
          initialValues={createTemplateFieldsData.initialValues}
          onSubmit={(values: CreateTemplateValuesType, { resetForm }) => {
            dispatch(
              createTemplate({
                dialog: id || "",
                name: values.name,
                price: values.price,
                image_url: avatarUrl,
                navigate,
              })
            );
            setAvatar(null);
            resetForm();
          }}
        >
          {({
            handleSubmit,
            values,
            setFieldValue,
            resetForm,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-6 mb-10">
                {createTemplateFieldsData.fields.map((field) => (
                  <FieldFormik
                    key={field.name + field.label}
                    field={field}
                    value={values[field.name] || ""}
                    setFieldValue={(e) =>
                      setFieldValue(field.name, e.target.value)
                    }
                    variant="dark"
                    touched={!!touched[field.name]}
                    errors={errors[field.name]?.toString()}
                    className={field.name === "price" ? "w-1/4" : "w-2/3"}
                  />
                ))}
              </div>
              <AddAvatarWrapper
                avatar={imageUrl}
                touched={!!touched.image_url}
                errors={errors.image_url?.toString()}
              />
              <div className="flex gap-7 justify-center mt-20">
                <ButtonCustom
                  variant="primary"
                  title={textData.btns.btnDiscard}
                  handleClick={() => {
                    setAvatar(null);
                    resetForm();
                    navigate(-1);
                  }}
                  type="button"
                />
                <ButtonCustom
                  variant="secondary"
                  title={textData.btns.exportTemplate}
                  type="submit"
                />
              </div>
              <UploadFileModal
                setFieldValue={(value) => {
                  setAvatar(value);
                  if (value) {
                    setFieldValue("image_url", value);
                  }
                }}
              />
            </form>
          )}
        </Formik>
      </div>
    </ModalWrapper>
  );
};
