import React from "react";

// data
import { textData } from "../../../../../assets/translations/en/textData";
// icons default
import { ReactComponent as DashboardIcon } from "../../../../../assets/icons/dashboard.svg";
import { ReactComponent as ClonesIcon } from "../../../../../assets/icons/clones.svg";
import { ReactComponent as AnalyticsIcon } from "../../../../../assets/icons/analytics.svg";
import { ReactComponent as AntennaIcon } from "../../../../../assets/icons/antenna.svg";
import { ReactComponent as HostsNetworkIcon } from "../../../../../assets/icons/network.svg";
import { ReactComponent as DeveloperPortalIcon } from "../../../../../assets/icons/developerPortal.svg";
import { ReactComponent as OperatorsIcon } from "../../../../../assets/icons/operators.svg";
import { ReactComponent as SettingsIcon } from "../../../../../assets/icons/settings.svg";
import { ReactComponent as LogOutIcon } from "../../../../../assets/icons/logOut.svg";
import { ReactComponent as ActivityIcon } from "../../../../../assets/icons/leaf.svg";

// types
import { SideBarDataType, SideBarIconsTypeData } from "./mockDataTypes";
import { ModalWrapperBtnType } from "../../../../molecules/modalWrapper/modalWrapperTypes";
import { CustomStep } from "../../../../../pages/activityPage/components/activityTaskTableItem/mockDataTypes";

export const sideBarData: SideBarDataType = {
  up: [
    { link: "dashboard", title: textData.sideBar.dashboard, icon: "dashboard" },
    { link: "activity", title: textData.sideBar.activity, icon: "activity" },
    { link: "clones", title: textData.sideBar.clones, icon: "clones" },
    { link: "", title: textData.sideBar.analytics, icon: "analytics" },
    {
      link: "network",
      title: textData.sideBar.network,
      icon: "network",
    },
    {
      link: "",
      title: textData.sideBar.hostsNetwork,
      icon: "hosts",
    },
    {
      link: "",
      title: textData.sideBar.developerPortal,
      icon: "developerPortal",
    },
    {
      link: "",
      title: textData.sideBar.operators,
      icon: "operators",
    },
  ],
  down: [
    { link: "settings", title: textData.sideBar.settings, icon: "settings" },
    { link: "", title: textData.sideBar.logOut, icon: "logOut" },
  ],
};

export const sideBarIconsData: SideBarIconsTypeData = {
  dashboard: {
    active: <DashboardIcon className="w-4 h-4 fill-secondary-green" />,
    default: <DashboardIcon className="w-4 h-4 fill-white" />,
    hover: <DashboardIcon className="w-4 h-4 fill-secondary-green" />,
    disabled: <DashboardIcon className="fill-gray-200" />,
  },
  clones: {
    active: <ClonesIcon className="w-4 h-4 fill-secondary-green" />,
    default: <ClonesIcon className="w-4 h-4 fill-white" />,
    hover: <ClonesIcon className="w-4 h-4 fill-secondary-green" />,
    disabled: <ClonesIcon className="fill-gray-200" />,
  },
  analytics: {
    active: <AnalyticsIcon className="w-4 h-4 fill-secondary-green" />,
    default: <AnalyticsIcon className="w-4 h-4 fill-white" />,
    hover: <AnalyticsIcon className="w-4 h-4 fill-secondary-green" />,
    disabled: <AnalyticsIcon className="fill-gray-200" />,
  },
  network: {
    active: <HostsNetworkIcon className="w-4 h-4 fill-secondary-green" />,
    default: <HostsNetworkIcon className="w-4 h-4 fill-white" />,
    hover: <HostsNetworkIcon className="w-4 h-4 fill-secondary-green" />,
    disabled: <HostsNetworkIcon className="fill-gray-200" />,
  },
  hosts: {
    active: <AntennaIcon className="w-4 h-4 fill-secondary-green" />,
    default: <AntennaIcon className="w-4 h-4 fill-white" />,
    hover: <AntennaIcon className="w-4 h-4 fill-secondary-green" />,
    disabled: <AntennaIcon className="fill-gray-200" />,
  },
  developerPortal: {
    active: <DeveloperPortalIcon className="w-4 h-4 fill-secondary-green" />,
    default: <DeveloperPortalIcon className="w-4 h-4 fill-white" />,
    hover: <DeveloperPortalIcon className="w-4 h-4 fill-secondary-green" />,
    disabled: <DeveloperPortalIcon className="fill-gray-200" />,
  },
  operators: {
    active: <OperatorsIcon className="w-4 h-4 fill-secondary-green" />,
    default: <OperatorsIcon className="w-4 h-4 fill-white" />,
    hover: <OperatorsIcon className="w-4 h-4 fill-secondary-green" />,
    disabled: <OperatorsIcon className="fill-gray-200" />,
  },
  settings: {
    active: <SettingsIcon className="w-4 h-4 fill-secondary-green" />,
    default: <SettingsIcon className="w-4 h-4 fill-white" />,
    hover: <SettingsIcon className="w-4 h-4 fill-secondary-green" />,
    disabled: <SettingsIcon className="fill-gray-200" />,
  },
  logOut: {
    active: <LogOutIcon className="w-4 h-4 fill-secondary-green" />,
    default: <LogOutIcon className="w-4 h-4 fill-white" />,
    hover: <LogOutIcon className="w-4 h-4 fill-secondary-green" />,
    disabled: <LogOutIcon className="fill-gray-200" />,
  },
  activity: {
    active: <ActivityIcon className="w-4 h-4 fill-secondary-green" />,
    default: <ActivityIcon className="w-4 h-4 fill-white" />,
    hover: <ActivityIcon className="w-4 h-4 fill-secondary-green" />,
    disabled: <ActivityIcon className="fill-gray-200" />,
  },
};
export const sideBarLogOutBtnData: ModalWrapperBtnType = [
  {
    variant: "primary",
    title: textData.btns.btnDiscard,
    type: "button",
    callbackName: "discard",
  },
  {
    variant: "secondary",
    title: textData.btns.logOut,
    type: "button",
    callbackName: "logOut",
  },
];

export const sideBarTooltipsData: CustomStep[] = [
  {
    target: ".sidebar-dashboard-tooltip",
    content: (
      <p>
        <span className="italic font-semibold">Dashboard</span> displays your
        earnings, the amount of time saved from bot tasks, and remaining credits
        left to power your bots.
      </p>
    ),
    placement: "right",
    disableBeacon: true,
    positionClass: "position-left-center",
  },
  {
    target: ".sidebar-activity-tooltip",
    content: (
      <p>
        <span className="italic font-semibold">Activity</span> displays the
        status of your bot tasks in the network.
      </p>
    ),
    placement: "right",
    positionClass: "position-left-center",
  },
  {
    target: ".sidebar-analytics-tooltip",
    content: (
      <p>
        <span className="italic font-semibold">Analytics</span> displays the
        metrics of your chatbots.
      </p>
    ),
    placement: "right",
    positionClass: "position-left-center",
  },
  {
    target: ".sidebar-network-tooltip",
    content: (
      <p>
        <span className="italic font-semibold">Network</span> allows you to
        search for pre-built bots to use for online tasks.
      </p>
    ),
    placement: "right",
    positionClass: "position-left-center",
  },
  {
    target: ".sidebar-operators-tooltip",
    content: (
      <p>
        <span className="italic font-semibold">Operators</span> is a first of
        its kind support network that allows you to request human support and AI
        supervision.
      </p>
    ),
    placement: "right",
    positionClass: "position-left-center",
  },
];
