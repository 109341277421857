import { createSlice } from "@reduxjs/toolkit";
// types
import { UserProfileDataType } from "../types/userProfileTypes";
// thunks
import {
  changePasswordUserProfile, confirmEmail,
  editUserProfile,
  fetchUserProfile, resendEmailConfirmation,
  setMembershipProfile,
} from "../thunks/userProfileThunk";

interface userProfileState {
  isLoading: boolean;
  isFetchLoading: boolean;
  profileEditMode: boolean;
  changePasswordModalOpen: boolean;
  addPaymentModalOpen: boolean;
  removePaymentModalOpen: boolean;
  changePaymentModalOpen: boolean;
  noPaymentModalOpen: boolean;
  downgradeNoticeModalOpen: boolean;
  selectMembershipModalOpen: boolean;
  deleteAccountModalOpen: boolean;
  membershipBillingPeriod: "monthly" | "annual";
  membershipSelectedPlan: string | null;
  profile: UserProfileDataType | null;
}

const initialState: userProfileState = {
  isLoading: false,
  isFetchLoading: false,
  profileEditMode: false,
  changePasswordModalOpen: false,
  addPaymentModalOpen: false,
  removePaymentModalOpen: false,
  changePaymentModalOpen: false,
  noPaymentModalOpen: false,
  downgradeNoticeModalOpen: false,
  selectMembershipModalOpen: false,
  deleteAccountModalOpen: false,
  membershipBillingPeriod: "monthly",
  membershipSelectedPlan: null,
  profile: null,
};
export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    addCustomSubscribePlan: (state) => {
      if (state.profile) {
        state.profile = {
          ...state.profile,
          membership: {
            name: "Basic",
            display_name: "Basic",
            billing_period: 0,
            plan: 0,
            price: "free",
            initial_price: null,
            id: "0"
          },
        };
      }
    },
    setChangePasswordModalOpen: (state, action) => {
      state.changePasswordModalOpen = action.payload;
    },
    setAddPaymentModalOpen: (state, action) => {
      state.addPaymentModalOpen = action.payload;
    },
    setRemovePaymentModalOpen: (state, action) => {
      state.removePaymentModalOpen = action.payload;
    },
    setChangePaymentModalOpen: (state, action) => {
      state.changePaymentModalOpen = action.payload;
    },
    setNoPaymentModalOpen: (state, action) => {
      state.noPaymentModalOpen = action.payload;
    },
    setDeleteAccountModalOpen: (state, action) => {
      state.deleteAccountModalOpen = action.payload;
    },
    setDowngradeNoticeModalOpen: (state, action) => {
      state.downgradeNoticeModalOpen = action.payload;
    },
    setSelectMembershipModalOpen: (state, action) => {
      state.selectMembershipModalOpen = action.payload;
    },
    setMembershipSelectedPlan: (state, action) => {
      state.membershipSelectedPlan = action.payload;
    },
    setMembershipBillingPeriod: (state, action) => {
      state.membershipBillingPeriod = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfile.pending, (state) => {
      state.isFetchLoading = true;
    });
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.isFetchLoading = false;
      state.profile = action.payload;
    });
    builder.addCase(changePasswordUserProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changePasswordUserProfile.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(confirmEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(confirmEmail.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(resendEmailConfirmation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resendEmailConfirmation.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(editUserProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editUserProfile.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(editUserProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.profile = action.payload;
    });
    builder.addCase(setMembershipProfile.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(setMembershipProfile.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { addCustomSubscribePlan, setChangePasswordModalOpen, setAddPaymentModalOpen, setRemovePaymentModalOpen, setChangePaymentModalOpen, setNoPaymentModalOpen, setDeleteAccountModalOpen, setDowngradeNoticeModalOpen, setSelectMembershipModalOpen, setMembershipBillingPeriod, setMembershipSelectedPlan } = userProfileSlice.actions;
