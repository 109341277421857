import React, { useEffect } from "react";
// hooks
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../stores/hooks/hooks";
// components
import { StarRating } from "./starRaiting/StarRating";
import { ReviewSection } from "./reviewSection/ReviewSection";
import { AddReviewForm } from "./addReviewForm/AddReviewForm";
import { ConfirmRatingModal } from "./confirmRatingModal/ConfirmRatingModal";
import { ButtonCustom } from "../../../../../../components/atoms/button/ButtonCustom";
import { Spinner } from "../../../../../../components/atoms/spinner/Spinner";
// store
import {
  addToolToClone,
  fetchFavoriteTools,
  fetchInstalledTools,
  fetchMarketplaceByIdData,
  setMarketplaceLike,
  setMarketplaceUnLike,
} from "../../../../../../stores/thunks/marketplaceThunk";
import {
  setIsLoadingTool,
  setToolIdForScroll,
} from "../../../../../../stores/slice/marketplaceSlice";
// helpers
import { getIconsComponents } from "../../../../../../helpers/iconsComponents/iconsComponents";
// data
import { textData } from "../../../../../../assets/translations/en/textData";

// helpers
export const MarketplaceItemDetails = () => {
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  // store
  const isLoading = useAppSelector((state) => state.marketplace.isLoading);
  const token = useAppSelector((state) => state.userAuth.token);
  const toolById = useAppSelector((state) => state.marketplace.toolById);
  const favoriteTools = useAppSelector(
    (state) => state.marketplace.favoriteTools
  );
  const installedTools = useAppSelector(
    (state) => state.marketplace.installedTools
  );
  const installedToolNow = useAppSelector(
    (state) => state.marketplace.installedToolNow
  );
  const isLoadingAddToll = useAppSelector(
    (state) => state.marketplace.isLoadingAddToll
  );

  // useEffect
  useEffect(() => {
    dispatch(fetchFavoriteTools());
    dispatch(fetchInstalledTools());
    if (id) {
      dispatch(fetchMarketplaceByIdData({ id: Number(id) }));
    }
  }, [dispatch, id]);

  // helpers
  const setLikeMarketplaceCard = () => {
    if (toolById) {
      if (favoriteTools.includes(toolById.id)) {
        dispatch(
          setMarketplaceUnLike({
            body: { id: toolById.id },
            like: favoriteTools.includes(toolById.id),
          })
        );
      } else {
        dispatch(
          setMarketplaceLike({
            body: { id: toolById.id },
            like: favoriteTools.includes(toolById.id),
          })
        );
      }
    }
  };

  const handleButtonClick = (e: any) => {
    e.stopPropagation();
    if (toolById && installedTools.includes(toolById.id)) {
      dispatch(setToolIdForScroll(toolById.id));
      navigate("/clones");
    } else if (toolById) {
      dispatch(setIsLoadingTool(toolById.id));
      dispatch(addToolToClone({ tool_id: toolById.id }));
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
        <Spinner />
      </div>
    );
  }
  if (toolById) {
    return (
      <div className="pl-[103px] pr-[77px] py-12">
        <Link
          to={".."}
          className="font-Inter font-bold text-xl leading-5"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          &#60;
        </Link>
        <div className="flex flex-col mt-12">
          <div className="w-1/2">
            <div className="grid grid-cols-[3fr_1fr_1fr] items-center justify-between">
              <p className="font-Inter font-extrabold text-3xl leading-6">
                {toolById.title}
              </p>

              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2">
                  <button
                    type="button"
                    className={`text-lg flex items-center leading-none hover:text-secondary-green`}
                    onClick={setLikeMarketplaceCard}
                  >
                    {favoriteTools.includes(toolById.id) ? "❤" : "♡"}
                  </button>
                  <span className="font-Inter font-bold text-sm leading-5">
                    {toolById.likes}
                  </span>
                </div>
              </div>
              <div className="flex">
                {toolById.users_installed &&
                  toolById.users_installed.length > 0 &&
                  toolById.users_installed.slice(-3).map((user, index) => (
                    <div
                      className={`flex justify-center items-center border-2 rounded-full w-[34px] h-[34px] overflow-hidden bg-secondary-green ${
                        index > 0 ? "ml-[-10px]" : ""
                      }`}
                      key={`${user.id}user_installed`}
                    >
                      <span className="text-lg text-white font-bold font-Inter uppercase leading-none">
                        {user.full_name
                          .split(" ")
                          .map((item) => item.slice(0, 1))
                          .join("") || "AH"}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex items-center gap-20 mt-10">
              <img
                src={toolById.image_tool}
                alt={toolById.title}
                className="block w-[254px] h-[254px] object-contain object-center"
              />
              {installedToolNow === toolById.id ? (
                <div className="w-[117px] rounded-[50px] py-2 pl-4 pr-10 xl:py-4 xl:pl-7 xl:pr-10 flex items-center gap-4 border border-dark-700">
                  {getIconsComponents({ name: "droplet" })}
                  <span className="font-Inter text-md font-bold leading-[75%] text-white">
                    -1
                  </span>
                </div>
              ) : (
                <ButtonCustom
                  spinner={isLoadingAddToll === toolById.id}
                  type="button"
                  className={`${
                    installedTools.includes(toolById.id)
                      ? ""
                      : "px-9 xl:px-[44px]"
                  }`}
                  variant={
                    installedTools.includes(toolById.id)
                      ? "tertiary"
                      : "secondary"
                  }
                  title={
                    installedTools.includes(toolById.id)
                      ? textData.btns.openInClonesMarketplace
                      : textData.btns.addMarketplace
                  }
                  handleClick={handleButtonClick}
                />
              )}
            </div>
          </div>

          <div className="flex gap-5 mt-12">
            <div className="w-1/2 font-Inter font-normal text-sm leading-5">
              <p className="capitalize">
                {textData.marketplacePage.description}:
              </p>
              <p className="max-w-[70%] break-words">{toolById.description}</p>
            </div>
            {token ? (
              <div className="w-1/2">
                <p className="font-Roboto text-md font-normal leading-5 tracking-[1px] capitalize">
                  {textData.marketplacePage.rateThis}
                </p>
                <StarRating />
              </div>
            ) : (
              <ButtonCustom
                variant={"noBg"}
                title={textData.marketplacePage.loginToRate}
                className="font-Roboto text-md font-normal leading-5 tracking-[1px] capitalize"
                handleClick={() => navigate("/signin")}
              />
            )}
          </div>
          <div className="mt-12 flex-1 flex gap-3 items-center">
            {getIconsComponents({ name: "ubxp" })}
            {toolById.pricing ? (
              <div className="font-Inter text-sm font-bold leading-[150%]">
                <span>{toolById.pricing}</span>
                <span className="uppercase">
                  {textData.marketplacePage.ubxp}
                </span>
              </div>
            ) : (
              <span className="capitalize font-Inter text-sm font-bold leading-[150%]">
                {textData.marketplacePage.free}
              </span>
            )}
          </div>
          {toolById.tags && toolById.tags.length > 0 && (
            <div className="flex-1 w-fit mt-16">
              <div className="">
                <div className="grid justify-items-stretch grid-cols-2 gap-2">
                  {toolById.tags.map((tag) => (
                    <div
                      key={tag}
                      className="py-2 px-3 font-Inter text-xxxs border border-dark-170/[.60] rounded-sm flex items-center justify-center"
                    >
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/*<div className="flex flex-row gap-3 mt-14">*/}
          {/*  <div className="w-1/2">*/}
          {/*    <p className="font-Inter font-normal text-sm leading-5 capitalize">*/}
          {/*      {textData.marketplacePage.sourceMap}:*/}
          {/*    </p>*/}
          {/*    <div className="flex gap-2 font-Inter font-bold text-xs leading-4 mt-2">*/}
          {/*      <div className="p-6 border border-dark-150 rounded-2xl">*/}
          {/*        {textData.marketplacePage.gpt}*/}
          {/*      </div>*/}
          {/*      <div className="p-6 border border-dark-150 rounded-2xl">*/}
          {/*        {textData.marketplacePage.ingUrl}*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="w-1/2 flex flex-col gap-5">*/}
          {/*    <p className="font-Inter font-normal text-sm leading-5 capitalize">*/}
          {/*      {textData.marketplacePage.operatorsUsing}*/}
          {/*    </p>*/}
          {/*    <div className="flex gap-0 w-[34px] h-[34px]">*/}
          {/*      /!*{product?.buyers &&*!/*/}
          {/*      /!*  product?.buyers.length > 0 &&*!/*/}
          {/*      /!*  product?.buyers.map((buyer, index) => (*!/*/}
          {/*      /!*    <img*!/*/}
          {/*      /!*      key={buyer.id}*!/*/}
          {/*      /!*      src={buyer.image}*!/*/}
          {/*      /!*      alt="bueyer image"*!/*/}
          {/*      /!*      className={`rounded-full object-cover w-full h-full border-2 border-black ${*!/*/}
          {/*      /!*        index > 0 ? "ml-[-10px]" : ""*!/*/}
          {/*      /!*      }`}*!/*/}
          {/*      /!*    />*!/*/}
          {/*      /!*  ))}*!/*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="flex-1 mt-14">
            <p className="font-Inter font-normal text-sm leading-5 capitalize">
              {textData.marketplacePage.rating}
            </p>
            <StarRating readOnly />
          </div>

          <div className="mt-14">
            <div className="">
              <p className="font-Inter font-normal text-sm leading-5 capitalize mb-10">
                {textData.marketplacePage.reviews}
              </p>
              <div className="w-1/2">
                <ReviewSection commentsData={toolById.reviews} />
              </div>
              <div className="w-1/2 mt-20">
                <AddReviewForm />
              </div>
            </div>
          </div>
        </div>
        <ConfirmRatingModal />
      </div>
    );
  }
  return null;
};
