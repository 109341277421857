import React, { Fragment, useState } from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
// store
// data
import { textData } from "../../../../assets/translations/en/textData";
// components
import { ModalWrapper } from "../../../../components/molecules/modalWrapper/ModalWrapper";
import { setTwoStepVerificationModalOpen } from "../../../../stores/slice/userAuthSlice";
import PhoneImage from "../../../../assets/img/mobile-phone.svg";
import { ButtonCustom } from "../../../../components/atoms/button/ButtonCustom";
import { signInUser } from "../../../../stores/thunks/userAuthThunk";
import { useNavigate } from "react-router-dom";
import { getIconsComponents } from "../../../../helpers/iconsComponents/iconsComponents";

export const TwoStepVerificationModal = () => {
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // store
  const twoStepVerificationModalOpen = useAppSelector(
    (state) => state.userAuth.twoStepVerificationModalOpen
  );
  const phoneNumber = useAppSelector((state) => state.userAuth.phoneNumber);
  const credentials = useAppSelector((state) => state.userAuth.credentials);
  const isLoading = useAppSelector((state) => state.userAuth.isLoadingSignIn);
  const [code, setCode] = useState<string>("");

  if (!credentials) {
    setTwoStepVerificationModalOpen(false);
  }

  return (
    <Fragment>
      <ModalWrapper
        open={twoStepVerificationModalOpen}
        onClose={() => dispatch(setTwoStepVerificationModalOpen(false))}
        title=""
      >
        <div className="flex flex-col items-center gap-2">
          <button
            className="p-[14px] absolute top-[20px] right-[30px] border-primary-grey border-[1px] z-[1] rounded-full"
            onClick={() => dispatch(setTwoStepVerificationModalOpen(false))}
          >
            {getIconsComponents({
              name: "close",
              className: "fill-primary-light",
            })}
          </button>
          <img src={PhoneImage} alt="" />
          <h3 className="font-Roboto text-center font-bold text-2xl my-4 leading-6 text-secondary-green">
            {textData.signInPage.verificationTitle}
          </h3>
          <p className="font-Roboto text-sm">
            {textData.signInPage.verificationSubtitle}
          </p>
          <p className="font-Roboto text-sm font-bold">{phoneNumber}</p>
          <button
            className="font-Roboto text-sm text-blue-100"
            onClick={() => {
              credentials && dispatch(signInUser({ ...credentials }));
            }}
          >
            {textData.signInPage.notReceiveCode}
          </button>
          <input
            className="p-6 my-8 w-[340px] h-[74px] bg-transparent text-center rounded-[10px] border-dark-170 border text-5xl text-white"
            name="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            type="number"
          />
          <ButtonCustom
            spinner={isLoading}
            variant="secondary"
            title={textData.btns.continueBtn}
            handleClick={() => {
              credentials &&
                dispatch(
                  signInUser({ ...credentials, navigate, sms_code: code })
                );
              setCode("");
            }}
            type="button"
            className="w-[170px] my-8"
          />
        </div>
      </ModalWrapper>
    </Fragment>
  );
};
