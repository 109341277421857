// hooks
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
// data
import { textData } from "../../../../assets/translations/en/textData";
import { deployPageIconsData } from "./mockData";
// components
import { ButtonCustom } from "../../../../components/atoms/button/ButtonCustom";
// store
import { fetchDialogById } from "../../../../stores/thunks/botsThunk";
import { createScriptData } from "../../../../helpers/createScriptData/createScriptData";
// types
import { DeploySlugItemType } from "./mockDataTypes";
import { getIconsComponents } from "../../../../helpers/iconsComponents/iconsComponents";

export const DeployPageWrapper = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // navigate
  const params = useParams();
  const id = params.id;
  // state
  const [copied, setCopied] = useState<null | string>(null);
  // store
  const dialogById = useAppSelector((state) => state.treeBot.dialogById);

  // effect
  useEffect(() => {
    if (id) {
      dispatch(
        fetchDialogById({ id, callbackError: () => navigate("/clones") })
      );
    }
  }, [dispatch, id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCopied(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [copied]);
  // data
  const copyText = (slug: DeploySlugItemType) => {
    const script = createScriptData({
      APH_ID_DIALOG_UNIQ_ID: id,
      APH_ID_BOT_UNIQ_ID: dialogById?.bots[0],
      APH_ID_BASE_URI: process.env.REACT_APP_PUBLIC_BASE_URL_SCRIPT,
      REACT_APP_PUBLIC_BASE_URL_STATIC:
        process.env.REACT_APP_PUBLIC_BASE_URL_STATIC,
    });
    if (navigator.clipboard && window.isSecureContext) {
      switch (slug) {
        case "wordpress":
          navigator.clipboard
            .writeText(
              `function wpb_hook_aph_id_bot () {
          ?>
          ${script}
        <?php
        }
      add_action('wp_footer', 'wpb_hook_aph_id_bot');`
            )
            .then((r) => setCopied(slug));
          break;
        case "wix":
          navigator.clipboard
            .writeText(
              `
            <div id='aph_uniq_id_chat-bot'></div>
          ${script}`
            )
            .then((r) => setCopied(slug));
          break;
        default:
          navigator.clipboard.writeText(script).then((r) => setCopied(slug));
          break;
      }
    } else {
      const textArea = document.createElement("textarea");
      switch (slug) {
        case "wordpress":
          textArea.value = `function wpb_hook_aph_id_bot () {
          ?>
          ${script}
        <?php
        }
      add_action('wp_footer', 'wpb_hook_aph_id_bot');`;

          textArea.style.position = "absolute";
          textArea.style.left = "-999999px";
          document.body.prepend(textArea);
          textArea.select();

          try {
            document.execCommand("copy");
            setCopied(slug);
          } catch (error) {
            console.error(error);
          } finally {
            textArea.remove();
          }
          break;
        case "wix":
          textArea.value = `
            <div id='aph_uniq_id_chat-bot'></div>
          ${script}`;
          textArea.style.position = "absolute";
          textArea.style.left = "-999999px";
          document.body.prepend(textArea);
          textArea.select();

          try {
            document.execCommand("copy");
            setCopied(slug);
          } catch (error) {
            console.error(error);
          } finally {
            textArea.remove();
          }
          break;
        default:
          textArea.value = script;

          textArea.style.position = "absolute";
          textArea.style.left = "-999999px";
          document.body.prepend(textArea);
          textArea.select();

          try {
            document.execCommand("copy");
            setCopied(slug);
          } catch (error) {
            console.error(error);
          } finally {
            textArea.remove();
          }
          break;
      }
    }
  };
  if (dialogById) {
    return (
      <section className="flex flex-col items-center justify-center relative">
        <button
          className="p-[14px] absolute top-0 right-0 bg-dark-300 border-primary-grey border-[1px] rounded-full"
          onClick={() => navigate(-1)}
        >
          {getIconsComponents({
            name: "close",
            className: "fill-primary-light",
          })}
        </button>
        <div className="flex">
          <h2 className="font-Inter font-bold text-3xl leading-8 mb-1 capitalize">
            {textData.deployPage.heading}
          </h2>
          {/*<span className="capitalize">{textData.deployPage.hireAgent}</span>*/}
        </div>
        <p className="font-Poppins text-lg leading-8 mb-9">
          {textData.deployPage.description}
        </p>
        <div className="flex flex-col gap-5">
          {deployPageIconsData.map((host) => {
            return (
              <div
                className="w-[890px] h-[96px] py-5 px-10 bg-dark-200 flex items-center content-center justify-between border-[1px] border-solid border-primary-grey rounded-3xl shadow-sm capitalize"
                key={host.text}
              >
                <div className="grow flex items-center gap-3">
                  {host.icon && (
                    <img src={host.icon} alt={host.text} width={host.width} />
                  )}
                  {host.icon ? (
                    <span className="font-Inter text-sm leading-4 capitalize">
                      {host.text}
                    </span>
                  ) : (
                    <span className="font-Arial font-bold text-5xl leading-8 uppercase">
                      {host.text}
                    </span>
                  )}
                </div>
                <div className="h-full flex items-center relative">
                  <ButtonCustom
                    variant={"secondary"}
                    title={textData.btns.copyCodeBtn}
                    handleClick={() => {
                      if (
                        id &&
                        dialogById?.bots[0] &&
                        process.env.REACT_APP_PUBLIC_BASE_URL_SCRIPT
                      ) {
                        copyText(host.slug);
                      }
                    }}
                  />
                  {copied === host.slug && (
                    <span className="font-Inter font-normal text-xxs absolute bottom-[-16px] left-1/2 translate-x-[-50%] text-green-100">
                      {textData.deployPage.copied}!
                    </span>
                  )}
                </div>

                <ButtonCustom
                  variant={"primary"}
                  title={textData.btns.setupGuideBtn}
                  handleClick={() => {
                    navigate(`/deploy/${id}/setup-guide/${host.slug}`);
                  }}
                  className="text-m font-semibold leading-4 bg-transparent ml-6"
                />
              </div>
            );
          })}
        </div>
      </section>
    );
  }
  return null;
};
