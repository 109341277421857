// icons
import { ReactComponent as BalanceIcon } from "../../../../assets/icons/wallet.svg";
import { ReactComponent as CreditIcon } from "../../../../assets/icons/creadits.svg";
import { ReactComponent as TimesheetIcon } from "../../../../assets/icons/timesheet.svg";
// data
import { textData } from "../../../../assets/translations/en/textData";
// types
import { DashboardDataItemType } from "./mockDataTypes";
import { CustomStep } from "../../../activityPage/components/activityTaskTableItem/mockDataTypes";

export const dashboardItemsData: DashboardDataItemType[] = [
  {
    icon: <BalanceIcon />,
    title: textData.dashboardPage.items.balance,
    percent: 10,
    value: "$711.44",
    tooltipClass: "dashboard-balance-tooltip",
  },
  {
    icon: <CreditIcon />,
    title: textData.dashboardPage.items.credit,
    percent: null,
    value: "86",
    tooltipClass: "dashboard-credit-tooltip",
  },
  {
    icon: <TimesheetIcon />,
    title: textData.dashboardPage.items.timesheet,
    percent: -2,
    value: "00:09:03",
    tooltipClass: "dashboard-timesheet-tooltip",
  },
];

export const dashboardTooltipsData: CustomStep[] = [
  {
    target: ".dashboard-balance-tooltip",
    content: (
      <p>
        <span className="italic font-bold">Balance</span> displays the total
        amount of earnings from bot tasks in the network
      </p>
    ),
    placement: "bottom",
    disableBeacon: true,
    positionClass: "position-top-center",
  },
  {
    target: ".dashboard-credit-tooltip",
    content: (
      <p>
        <span className="italic font-bold">Credits</span> are used to power
        tasks in the ecosystem
      </p>
    ),
    placement: "bottom",
    positionClass: "position-top-center",
  },
  {
    target: ".dashboard-timesheet-tooltip",
    content: (
      <p>
        <span className="italic font-bold">Timesheet</span> tracks the total
        amount of time your Clones have saved you from manual labor
      </p>
    ),
    placement: "bottom",
    positionClass: "position-top-center",
  },
  {
    target: ".dashboard-earnings-tooltip",
    content: <p>Displays how much the bot has earned you for the task</p>,
    placement: "bottom-end",
    positionClass: "position-top-center",
  },
];
