// types
import { CreateDialogDataType } from "./mockDataTypes";
// data
import { textData } from "../../../assets/translations/en/textData";
// helper
import { createValidate } from "../../../helpers/validation/validationHelper";

export const createDialogFieldsData: CreateDialogDataType = {
  validate: createValidate(["name", "description", "bots"]),
  fields: [
    {
      type: "text",
      label: "",
      placeholder: textData.fields.nameDialog.placeholder,
      name: "name",
    },
    {
      type: "text",
      label: "",
      placeholder: textData.fields.descriptionDialog.placeholder,
      name: "description",
    },
  ],
  initialValues: {
    name: "",
    description: "",
    bots: [],
  },
};
