import React, { useEffect, useRef, useState } from "react";
// store
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../stores/hooks/hooks";
import {
  clearMessagesList,
  clearScenario,
  saveMessages,
  setIsLoadingMessage,
} from "../../../../../../stores/slice/chatUserSlice";
import { setActiveWindow } from "../../../../../../stores/slice/treeBotSlice";
import {
  fetchChatData,
  fetchChatDataById,
  sendChatMessage,
} from "../../../../../../stores/thunks/chatUserThunk";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
// images
import AphIdLogo from "../../../../../../assets/icons/aphIDNotColored.svg";
// components
import { ButtonCustom } from "../../../../../../components/atoms/button/ButtonCustom";
import { LinkCustom } from "../../../../../../components/atoms/linkCustom/LinkCustom";
import { BotLoader } from "./botLoader/BotLoader";
import { BotMessageItem } from "./botMessageItem/BotMessageItem";
// helpers
import { getStorageName } from "../../../../../../helpers/storageName/storageName";
import { getIconsComponents } from "../../../../../../helpers/iconsComponents/iconsComponents";

export const TestChatBot = () => {
  // hooks
  const dispatch = useAppDispatch();
  // state
  const [popupEndChat, setPopupEndChat] = useState(false);
  const [popupStartMsg, setPopupStartMsg] = useState(false);
  const [finishConversation, setFinishConversation] = useState(false);
  const [senderMessage, setSenderMessage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  // ref
  const aphIdEndRef = useRef(null);
  // store
  const currentScenario = useAppSelector(
    (state) => state.chatUser.currentScenario
  );
  const messagesList = useAppSelector((state) => state.chatUser.messagesList);
  const dialogById = useAppSelector((state) => state.treeBot.dialogById);
  const isLoading = useAppSelector(
    (state) => state.chatUser.isLoadingSendMessage
  );
  const botInfo = useAppSelector((state) => state.chatUser.bot);
  const chatId = useAppSelector((state) => state.chatUser.chatId);
  const activeWindow = useAppSelector((state) => state.treeBot.activeWindow);
  // effect
  useEffect(() => {
    if (messagesList.length === 2) {
      if (currentIndex < messagesList.length) {
        dispatch(setIsLoadingMessage(true));
        const interval = setInterval(() => {
          setCurrentIndex((prev) => prev + 1);
          dispatch(setIsLoadingMessage(false));
        }, 2000);

        return () => clearInterval(interval);
      }
    }
  }, [currentIndex, messagesList]);

  useEffect(() => {
    const chatId = localStorage.getItem(getStorageName.chatBotId);
    if (chatId) {
      dispatch(fetchChatDataById(chatId));
    } else if (dialogById) {
      dispatch(
        fetchChatData({
          dialog: dialogById.id || "",
          bot: dialogById.bots[0] || "",
        })
      );
    }
  }, [dispatch, dialogById]);

  const scrollToBottom = () => {
    // @ts-ignore
    aphIdEndRef.current?.scrollIntoView();
  };
  useEffect(() => {
    scrollToBottom();
  }, [activeWindow, messagesList]);
  // helpers
  const handleWithoutSubmit = async () => {
    if (
      currentScenario?.reply_type === 1 &&
      currentScenario &&
      currentScenario.child_replies &&
      currentScenario.child_replies.length > 0
    ) {
      const replyId = currentScenario.child_replies[0].id;
      dispatch(clearScenario());
      await dispatch(
        saveMessages({
          author: 1,
          text: senderMessage,
          buttons: null,
        })
      );
      if (chatId && replyId) {
        dispatch(setIsLoadingMessage(true));
        setTimeout(
          () =>
            dispatch(
              sendChatMessage({
                chat: chatId,
                text: senderMessage,
                reply: replyId,
              })
            ),
          2000
        );
      }
    } else {
      dispatch(clearScenario());
      await dispatch(
        saveMessages({
          author: 1,
          text: senderMessage,
          buttons: null,
        })
      );
      if (chatId) {
        dispatch(setIsLoadingMessage(true));
        setTimeout(
          () =>
            dispatch(sendChatMessage({ chat: chatId, text: senderMessage })),
          2000
        );
      }
    }
    setSenderMessage("");
  };

  const isSendMessageDisabled = finishConversation || !senderMessage.trim();

  const onChoiceBtnClick = (replyId: string) => {
    const text = currentScenario?.child_replies?.find(
      (reply) => reply.id === replyId
    )?.button_text;
    dispatch(clearScenario());
    dispatch(
      saveMessages({
        author: 1,
        text,
        buttons: null,
      })
    );
    if (chatId) {
      dispatch(setIsLoadingMessage(true));
      setTimeout(
        () => dispatch(sendChatMessage({ chat: chatId, reply: replyId })),
        2000
      );
    }
  };

  const onStartNewChatClick = () => {
    setPopupStartMsg(true);
    setSenderMessage("");
  };

  if (botInfo) {
    return (
      <div className="fixed w-screen h-screen top-0 left-0 z-[100]">
        <div className="absolute h-screen right-0 top-0 lg:right-14 md:max-h-[70vh] md:top-[140px] xl:max-h-[80vh] flex flex-col shadow-sm border border-primary-grey md:rounded bg-dark-200 w-full md:w-[400px] xl:w-[480px]">
          <div className="w-full bg-black md:rounded-t flex items-center justify-between p-5 border-b border-primary-grey">
            <div className="flex flex-row-reverse items-center gap-4">
              <h4 className="font-Roboto font-bold text-xl text-white">
                {botInfo?.name}
              </h4>
              <div className="w-[34px] h-[34px] rounded-full overflow-hidden">
                <img
                  src={botInfo?.avatar}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <img src={AphIdLogo} alt="" className="w-[31px]" />
            <button onClick={() => dispatch(setActiveWindow(null))}>
              {getIconsComponents({ name: "close", className: "fill-white" })}
            </button>
          </div>
          {finishConversation && <div className="flex-1" />}
          {!finishConversation && (
            <div className="flex flex-col flex-1 p-5 overflow-y-scroll invisible-scrollbar">
              <div className="flex-1">
                {messagesList
                  ?.slice(
                    0,
                    messagesList.length === 2
                      ? currentIndex
                      : messagesList.length
                  )
                  .map((message) => (
                    <BotMessageItem
                      key={message + Math.random().toString()}
                      data={message}
                      avatar={botInfo?.avatar}
                    />
                  ))}
                {isLoading && (
                  <div className="pl-[36px]">
                    <BotLoader />
                  </div>
                )}
                {!isLoading &&
                  currentScenario &&
                  currentScenario.child_replies &&
                  currentScenario.child_replies.length > 0 &&
                  currentScenario.reply_type === 0 && (
                    <div className="w-full grid gap-x-2 md:gap-x-10 gap-y-[40px] grid-cols-2 pl-[40px] mt-10 py-6 inline-block-element">
                      {currentScenario.child_replies.map((reply) => (
                        <ButtonCustom
                          variant="secondary"
                          title={reply.title.slice(0, 12)}
                          key={reply.id}
                          handleClick={() => onChoiceBtnClick(reply.id)}
                          className="font-bold text-xs overflow-hidden whitespace-nowrap"
                        />
                      ))}
                    </div>
                  )}
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col gap-10 mt-4 py-8">
                  <button
                    className="font-Roboto text-red-500 text-md font-bold capitalize"
                    onClick={() => setPopupEndChat(true)}
                  >
                    {textData.testChatBotPage.chatEnded}
                  </button>
                  {/*<button className="text-blue-200/[0.77] text-md font-Roboto font-normal capitalize" >*/}
                  {/*  {textData.testChatBotPage.saveTranscript}*/}
                  {/*</button>*/}
                  <button
                    className="font-Roboto text-secondary-green text-md font-bold capitalize"
                    onClick={onStartNewChatClick}
                  >
                    {textData.testChatBotPage.startNewChat}
                  </button>
                </div>
                <div
                  ref={aphIdEndRef}
                  className={`flex items-end justify-center mt-4 overflow-hidden ${
                    popupEndChat || popupStartMsg ? "opacity-0" : "opacity-100"
                  }`}
                >
                  <p className="font-Roboto font-normal text-primary-light text-sm leading-3 capitalize">
                    {textData.testChatBotPage.controlledOn}
                  </p>
                  <LinkCustom
                    link="https://www.aphid.com/support-agents"
                    variant="default"
                    local={false}
                    icon="alphaLogo"
                    className="flex items-center w-[101px] h-[37px] relative top-[6px]"
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className={`w-full relative flex items-center justify-between bg-white px-5 py-4 ${
              popupEndChat ? "opacity-0.40 cursor-no-drop" : "opacity-1"
            } md:rounded-b`}
          >
            {currentScenario?.reply_type === 0 && (
              <div className="w-full absolute top-0 left-0 h-full md:rounded-b bg-dark-200/[0.77]" />
            )}
            <input
              type="text"
              required={false}
              placeholder={textData.testChatBotPage.typeMessageBtn}
              value={senderMessage}
              onChange={(e) => setSenderMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !isSendMessageDisabled) {
                  handleWithoutSubmit();
                }
              }}
              disabled={finishConversation || currentScenario?.reply_type === 0}
              className={`flex-1 text-dark-200 outline-none border-none disabled:bg-transparent`}
            />
            <ButtonCustom
              variant="noBg"
              type="button"
              title={textData.btns.sendBtn}
              handleClick={handleWithoutSubmit}
              className="px-[0px] py-[0px] font-semibold font-Roboto text-dark-200 text-sm border-none rounded-none bg-transparent"
              disabled={isSendMessageDisabled}
            />
          </div>

          {popupEndChat && (
            <div className="w-full h-[calc(100%-74px)] bottom-0 left-0 bg-black/[.86] absolute z-40 flex flex-col items-center justify-center md:rounded-b">
              {finishConversation ? (
                <div className="p-5 flex flex-col flex-1">
                  <div className="flex flex-col flex-1 items-center justify-center gap-8 text-md font-Roboto font-bold leading-3">
                    {textData.testChatBotPage.deletedSuccessfully}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center px-[90px]">
                  <p className="font-Roboto font-bold text-xl text-center">
                    {textData.testChatBotPage.endChat}
                  </p>
                  <div className="w-full flex justify-center gap-7 mt-20">
                    <ButtonCustom
                      variant="primary"
                      title={textData.btns.cancelBtn}
                      className=""
                      handleClick={() => setPopupEndChat(false)}
                    />
                    <ButtonCustom
                      variant="secondary"
                      title={textData.btns.yesBtn}
                      className=""
                      handleClick={() => {
                        localStorage.removeItem(getStorageName.chatBotId);
                        dispatch(clearMessagesList());
                        setCurrentIndex(0);
                        setFinishConversation(true);
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="flex items-end justify-center svg-custom absolute bottom-[65px] left-1/2 translate-x-[-50%]">
                <p className="font-Roboto font-normal text-primary-light text-sm leading-3 capitalize">
                  {textData.testChatBotPage.controlledOn}
                </p>
                <LinkCustom
                  link="https://www.aphid.com/support-agents"
                  variant="default"
                  local={false}
                  icon="alphaLogo"
                  className="flex items-center w-[101px] h-[37px] relative top-[6px] "
                />
              </div>
            </div>
          )}

          {popupStartMsg && (
            <div className="w-full h-[calc(100%-74px)] bottom-0 left-0 bg-black/[.86] absolute z-40 flex flex-col items-center justify-center md:rounded-b">
              <div>
                <p className="max-w-[231px] font-Roboto font-bold text-xl text-center">
                  {textData.testChatBotPage.sureToStartNewChat}
                </p>
                <div className="w-full flex justify-center gap-7 mt-20">
                  <ButtonCustom
                    variant="primary"
                    title={textData.btns.cancelBtn}
                    className=""
                    handleClick={() => setPopupStartMsg(false)}
                  />
                  <ButtonCustom
                    variant="secondary"
                    title={textData.btns.yesBtn}
                    handleClick={() => {
                      if (dialogById) {
                        dispatch(
                          fetchChatData({
                            dialog: dialogById.id || "",
                            bot: dialogById.bots[0] || "",
                          })
                        );
                      }
                      dispatch(clearMessagesList());
                      setCurrentIndex(0);
                      setPopupStartMsg(false);
                      dispatch(clearScenario());
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
};
