import React from "react";
// types
import {
  IconsComponentsPropsTypes,
  IconsComponentsTypes,
} from "./iconsComponentsTypes";
// icons
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";
import { ReactComponent as CockroachIcon } from "../../assets/icons/cockroach.svg";
import { ReactComponent as SaveIcon } from "../../assets/icons/save.svg";
import { ReactComponent as FlaskIcon } from "../../assets/icons/flask.svg";
import { ReactComponent as RocketIcon } from "../../assets/icons/rocket.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import { ReactComponent as FacebookBlueIcon } from "../../assets/icons/facebook-blue.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/icons/linkedIn.svg";
import { ReactComponent as DiscordIcon } from "../../assets/icons/discord.svg";
import { ReactComponent as TelegramIcon } from "../../assets/icons/telegram.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as YouTubeIcon } from "../../assets/icons/youTube.svg";
import { ReactComponent as TikTokIcon } from "../../assets/icons/tiktok.svg";
import { ReactComponent as AlphaLogo } from "../../assets/icons/aphidLogo.svg";
import { ReactComponent as GoogleLogo } from "../../assets/icons/googleIcon.svg";
import { ReactComponent as AppleLogo } from "../../assets/icons/appleLogo.svg";
import { ReactComponent as AphidWhite } from "../../assets/icons/aphIDWhite.svg";
import { ReactComponent as AphidWhiteSmall } from "../../assets/icons/aphIDWhiteSmall.svg";
import { ReactComponent as AphidLogoLarge } from "../../assets/icons/aphidLogoLarge.svg";
import { ReactComponent as UBXPIcon } from "../../assets/icons/ubxp.svg";
import { ReactComponent as DevelopedByAphidIcon } from "../../assets/icons/aphidApproved.svg";
import { ReactComponent as AphidLogoIcon } from "../../assets/icons/aphidCo.svg";
import { ReactComponent as GreenCheckmarkIcon } from "../../assets/icons/greenCheckmark.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/close.svg";
import dropletIcon from "../../assets/icons/droplet.png";
import { ReactComponent as CategoryIcon } from "../../assets/icons/category.svg";
import { ReactComponent as MarketplaceExitIcon } from "../../assets/icons/marketplaceExit.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/icons/chevronRight.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/checkmark.svg";
import { ReactComponent as GooglePlayIcon } from "../../assets/icons/google-play.svg";
import { ReactComponent as AppStoreIcon } from "../../assets/icons/apple-store.svg";
import { ReactComponent as ActivityFilterIcon } from "../../assets/icons/activity-filter.svg";
import { ReactComponent as QuestionIcon } from "../../assets/icons/question.svg";
import { ReactComponent as DropIcon } from "../../assets/icons/drop.svg";

export const getIconsComponents = ({
  name,
  className = "",
}: IconsComponentsPropsTypes) => {
  const iconsComponents: IconsComponentsTypes = {
    save: <SaveIcon className={className} />,
    flask: <FlaskIcon className={className} />,
    rocket: <RocketIcon className={className} />,
    plus: <PlusIcon className={className} />,
    download: <DownloadIcon className={className} />,
    twitter: <TwitterIcon className={className} />,
    facebook: <FacebookIcon className={className} />,
    facebookBlue: <FacebookBlueIcon className={className} />,
    linkedIn: <LinkedInIcon className={className} />,
    discord: <DiscordIcon className={className} />,
    telegram: <TelegramIcon className={className} />,
    instagram: <InstagramIcon className={className} />,
    youtube: <YouTubeIcon className={className} />,
    tiktok: <TikTokIcon className={className} />,
    alphaLogo: <AlphaLogo className={className} />,
    google: <GoogleLogo className={className} />,
    apple: <AppleLogo className={className} />,
    aphidWhite: <AphidWhite className={className} />,
    ubxp: <UBXPIcon className={className} />,
    aphidApproved: <DevelopedByAphidIcon className={className} />,
    aphidCo: <AphidLogoIcon className={className} />,
    greenCheckmark: <GreenCheckmarkIcon className={className} />,
    delete: <DeleteIcon className={className} />,
    droplet: <img src={dropletIcon} className={className} />,
    drop: <DropIcon className={className} />,
    category: <CategoryIcon className={className} />,
    marketplaceExit: <MarketplaceExitIcon className={className} />,
    chevronRight: <ChevronRightIcon className={className} />,
    checkmark: <CheckmarkIcon className={className} />,
    aphidWhiteSmall: <AphidWhiteSmall className={className} />,
    aphidLogoLarge: <AphidLogoLarge className={className} />,
    googlePlay: <GooglePlayIcon className={className} />,
    appStore: <AppStoreIcon className={className} />,
    close: <CloseIcon className={className} />,
    cockroach: <CockroachIcon className={className} />,
    activityFilter: <ActivityFilterIcon className={className} />,
    arrow: <ArrowIcon className={className} />,
    question: <QuestionIcon className={className} />,
  };
  return iconsComponents[name];
};
