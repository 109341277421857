import { createValidate } from "../../../../helpers/validation/validationHelper";
import { textData } from "../../../../assets/translations/en/textData";
import { ChangePasswordDataType } from "./mockDataTypes";

export const changePasswordData: ChangePasswordDataType = {
  validate: createValidate(["password", "old_password"]),
  fields: [
    {
      type: "password",
      label: "",
      placeholder: textData.fields.oldPassword.placeholder,
      name: "old_password",
    },
    {
      type: "password",
      label: "",
      placeholder: textData.fields.newPassword.placeholder,
      name: "password",
    },
  ],
  initialValues: {
    old_password: "",
    password: "",
  },
};
