import React from "react";
// icons
import { ReactComponent as PlusIcon } from "../../../assets/icons/whitePlus.svg";
import { ReactComponent as MinusIcon } from "../../../assets/icons/whiteMinus.svg";
import { ReactComponent as LeftArrowIcon } from "../../../assets/icons/leftArrow.svg";
import { ReactComponent as RightArrowIcon } from "../../../assets/icons/rightArrow.svg";

// components
import { useZoomableContext } from "react-zoomable-container";

export const BotTreeControls = () => {
  // hooks
  const { handleReset, zoomIn, zoomOut, info } = useZoomableContext();

  return (
    <div className="fixed z-10 flex bottom-8 left-[124px] gap-3">
      <div className="bg-primary-grey py-3 px-[44px] relative rounded flex items-center text-white">
        <button
          type="button"
          className="w-[44px] absolute top-0 left-0 h-full flex justify-center items-center rounded-l hover:opacity-80"
          onClick={zoomOut}
        >
          <MinusIcon />
        </button>
        <button
          type="button"
          onClick={handleReset}
          className="font-Inter font-bold text-sm leading-3 hover:opacity-80"
        >
          {Number(info.scale.toFixed(1)) * 10 * 10}%
        </button>
        <button
          type="button"
          className="h-full w-[44px] absolute top-0 right-0 flex justify-center items-center rounded-r hover:opacity-80"
          onClick={zoomIn}
        >
          <PlusIcon />
        </button>
      </div>
      <div className="py-3 px-5 bg-primary-grey rounded flex items-center text-white gap-3">
        <LeftArrowIcon />
        <RightArrowIcon />
      </div>
    </div>
  );
};
