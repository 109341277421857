import React from "react";
import { useAppDispatch } from "../../../../../stores/hooks/hooks";
import { useNavigate } from "react-router-dom";
// formik
import { Formik } from "formik";
// data
import { signUpData } from "./mockData";
import { textData } from "../../../../../assets/translations/en/textData";
// types
import { SignUpValuesType } from "./mockDataTypes";
// store
import { signUpUser } from "../../../../../stores/thunks/userAuthThunk";
// components
import { FieldFormik } from "../../../../../components/atoms/fieldFormik/FieldFormik";
import { ButtonCustom } from "../../../../../components/atoms/button/ButtonCustom";
import { LinkCustom } from "../../../../../components/atoms/linkCustom/LinkCustom";
import { Separator } from "../../../../../components/atoms/separator/Separator";
// icon
import IdLogo from "../../../../../assets/icons/alphaIdColored.svg";
// firebase
import {
  signInWithApple,
  signInWithFacebook,
  signInWithGoogle,
} from "../../../../../firebase";

export const SignUpForm = () => {
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <section className="grow justify-center pt-[25px] px-[30px] lg:pt-[40px] xl:pt-[70px] lg:pr-[80px] xl:pr-[114px] md:pb-[70px] xl:pb-[147px] lg:pl-[44px] xl:pl-[63px] bg-black col-start-1 col-end-21 md:col-start-12 md:col-end-21 order-1 md:order-2">
      <LinkCustom
        link="/"
        icon="aphidLogoLarge"
        variant="default"
        className="flex items-center justify-center md:hidden mb-[50px]"
      />
      <Formik
        validationSchema={signUpData.validate}
        initialValues={signUpData.initialValues}
        onSubmit={(values: SignUpValuesType, { resetForm }) =>
          dispatch(
            signUpUser({
              email: values.email,
              password: values.password,
              full_name: values.full_name,
              username: values.username,
              navigate,
              resetForm,
            })
          )
        }
      >
        {({ handleSubmit, values, setFieldValue, errors, touched }) => (
          <form onSubmit={handleSubmit} className="xl:max-w-[470px]">
            <div className="">
              <div className="flex items-center gap-2 xl:gap-5 mb-1 xl:mb-2">
                <h1 className="font-Inter font-extrabold text-6xl xl:text-8xl leading-none xl:leading-7 text-white capitalize">
                  {textData.signUpPage.title}
                </h1>
                <img src={IdLogo} alt="colored logo" />
              </div>
              <p className="text-primary-light text-xxs xl:text-sm  mb-6 xl:mb-8">
                {textData.signUpPage.description}
              </p>
              <div className="flex flex-col gap-4 mb-6 xl:mb-8">
                {signUpData.fields.map((field) => (
                  <FieldFormik
                    key={field.name + field.label}
                    field={field}
                    value={values[field.name]}
                    setFieldValue={(e) =>
                      setFieldValue(field.name, e.target.value)
                    }
                    variant="dark"
                    touched={touched[field.name]}
                    errors={errors[field.name]}
                    labelClass="text-xs font-medium leading-4"
                    className="bg-transparent pt-2 pb-2 xl:pt-3 xl:pb-3 pr-5 pl-5 text-white text-sm leading-5 font-bold placeholder:text-sm placeholder:font-bold"
                    classNameWrapper="xl:gap-[10px] text-xs xl:text-md"
                  />
                ))}
              </div>
              <ButtonCustom
                variant="secondary"
                type="submit"
                title={textData.btns.submitAndContinue}
                className="w-full"
              />
            </div>
            <Separator className="my-4 xl:my-6" />
            <ButtonCustom
              variant="noBg"
              type="button"
              handleClick={() => signInWithGoogle(navigate, dispatch)}
              icon="google"
              title={textData.btns.signInGoogle}
              className="w-full border border-primary-grey"
            />
            <ButtonCustom
              variant="noBg"
              type="button"
              handleClick={() => signInWithApple(navigate, dispatch)}
              icon="apple"
              title={textData.btns.signInApple}
              className="w-full border border-primary-grey mt-3"
            />
            <ButtonCustom
              variant="noBg"
              type="button"
              handleClick={() => signInWithFacebook(navigate, dispatch)}
              icon="facebookBlue"
              title={textData.btns.signInFacebook}
              className="normal-case w-full border border-primary-grey mt-3"
            />
          </form>
        )}
      </Formik>
      <div className="flex md:hidden w-full justify-center my-[78px]">
        <div className="min-w-[285px] flex items-center justify-between bg-dark-200 rounded-5xl shadow-m">
          <span className="block shadow-sm text-md font-bold">
            {textData.signUpPage.haveAccount}
          </span>
          <LinkCustom
            variant="secondary"
            title={textData.btns.signIn}
            link="/signin"
          />
        </div>
      </div>
    </section>
  );
};
