import React, { useEffect, useMemo } from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
import { useNavigate } from "react-router-dom";
// store
import { setCloneModalOpen } from "../../../../stores/slice/botsSlice";
import {
  fetchClones,
  fetchClonesLimits,
  fetchDialogs,
} from "../../../../stores/thunks/botsThunk";
// components
import { Header } from "../../../../components/molecules/header/Header";
import { DialogsWrapper } from "./dialogsWrapper/DialogsWrapper";
import { ClonesWrapper } from "./ClonesWrapper";
import { CreateCloneModal } from "./createCloneModal/CreateCloneModal";
import { CreateDialogModal } from "../../../../components/organisms/createDialogModal/CreateDialogModal";
import { Spinner } from "../../../../components/atoms/spinner/Spinner";
import { DeleteCloneModal } from "./deleteCloneModal/DeleteCloneModal";
import { DeleteDialogModal } from "./deleteDialogModal/DeleteDialogModal";
import { DuplicateCloneModal } from "./duplicateCloneModal/DuplicateCloneModal";
import { DuplicateDialogModal } from "./duplicateDialogModal/DuplicateDialogModal";
// data
import { textData } from "../../../../assets/translations/en/textData";
import { getStorageName } from "../../../../helpers/storageName/storageName";

export const ClonesPageWrapper = () => {
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // store
  const dialogs = useAppSelector((state) => state.bots.dialogs);
  const clones = useAppSelector((state) => state.bots.clones);
  const isLoading = useAppSelector((state) => state.bots.isLoading);
  const token = useAppSelector((state) => state.userAuth.token);
  const profile = useAppSelector((state) => state.userProfile.profile);
  const cloneLimit = useAppSelector((state) => state.bots.clonesLimits);
  // effect
  useEffect(() => {
    if (
      profile &&
      profile.membership === null &&
      !localStorage.getItem(getStorageName.subscriptionPlans)
    ) {
      navigate("/subscription-plans");
    }
  }, [profile]);

  useEffect(() => {
    dispatch(fetchClones());
    dispatch(fetchClonesLimits());
    dispatch(fetchDialogs());
  }, [dispatch]);

  useEffect(() => {
    if (!(token || localStorage.getItem(getStorageName.accessToken))) {
      navigate("/signin");
    }
  }, [token, navigate]);

  const isImportBotTemplateDisabled = useMemo(
    () => dialogs?.length === 0,
    [dialogs]
  );

  if (
    isLoading &&
    clones &&
    clones.length === 0 &&
    dialogs &&
    dialogs.length === 0
  ) {
    return <Spinner page />;
  }

  return (
    <section className={`pl-[44px] lg:pl-[60px] pr-[75px] pt-8`}>
      <Header
        handleClickData={{
          addNewBot: () =>
            clones && clones.length > 0
              ? dispatch(setCloneModalOpen(true))
              : navigate("/clones/welcome"),
          importBotTemplate: () => {
            navigate("/template");
          },
        }}
        data={["addNewBot", "importBotTemplate"]}
        className="justify-between"
        isMenuDisabled={cloneLimit.limit}
        disabledButtonStates={{
          importBotTemplate: isImportBotTemplateDisabled,
        }}
      />
      <div className="grid grid-cols-2 h-[85vh] mt-8">
        {clones && clones.length > 0 ? (
          <ClonesWrapper />
        ) : (
          <div className="py-2 pr-4 lg:pr-8 border-r-[2px] border-primary-grey h-full">
            <h3 className="font-Inter font-extrabold text-xl lg:text-3xl xl:text-5xl leading-7 text-white capitalize">
              {textData.clones.title}
            </h3>
            <div className="h-full flex justify-center mt-[124px]">
              <p className="font-Inter font-extrabold text-lg leading-5 text-dark-600">
                {textData.clones.helper}
              </p>
            </div>
          </div>
        )}
        {dialogs && dialogs.length > 0 ? (
          <DialogsWrapper />
        ) : (
          <div className="py-2 pl-4 lg:pl-8 h-full">
            <h3 className="font-Inter font-extrabold text-xl lg:text-3xl xl:text-5xl leading-7 text-white capitalize">
              {textData.dialogs.title}
            </h3>{" "}
            <div className="h-full flex justify-center mt-[124px]">
              <p className="font-Inter font-extrabold text-lg leading-5 text-dark-600">
                {textData.dialogs.helper}
              </p>
            </div>
          </div>
        )}
      </div>
      <CreateCloneModal />
      <CreateDialogModal />
      <DeleteCloneModal />
      <DeleteDialogModal />
      <DuplicateCloneModal />
      <DuplicateDialogModal />
    </section>
  );
};
