import React, { Fragment } from "react";
// hooks
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../stores/hooks/hooks";
import { useNavigate } from "react-router-dom";
// components
import { Menu, Transition } from "@headlessui/react";
// store
import { setCloneModalOpen } from "../../../../../stores/slice/botsSlice";
// data
import { textData } from "../../../../../assets/translations/en/textData";
// types
import { HeaderDataItemType } from "../../mockDataTypes";
// helpers
import { getIconsComponents } from "../../../../../helpers/iconsComponents/iconsComponents";

type Props = {
  btnInfo: HeaderDataItemType;
  disabled?: boolean;
};

export const MenuItemForCreateBot = ({ btnInfo, disabled }: Props) => {
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // store
  const profile = useAppSelector((state) => state.userProfile.profile);
  const clones = useAppSelector((state) => state.bots.clones);
  // callback
  const createChatBotCloneCallback = () => {
    clones && clones.length > 0
      ? dispatch(setCloneModalOpen(true))
      : navigate("/clones/welcome");
  };
  return (
    <Menu as="div" className="relative inline-block text-left ">
      <Menu.Button
        className={`capitalize flex outline-none focus-visible:outline-none items-center justify-center text-center py-3 px-6 gap-2 rounded font-Inter font-bold text-xs xl:text-base leading-4
        bg-secondary-green text-secondary-dark opacity-100 hover:opacity-80 disabled:cursor-default disabled:bg-gray-100 disabled:opacity-100 disabled:hover:opacity-100"`}
      >
        {getIconsComponents({ name: btnInfo.icon })}
        {btnInfo.title}
      </Menu.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Menu.Items className="outline-none focus-visible:outline-none origin-top-left absolute z-10 left-0 top-10 bg-dark-700 py-2 rounded-md shadow-lg">
          <Menu.Item>
            {({ active }) =>
              profile?.membership ? (
                <button
                  onClick={createChatBotCloneCallback}
                  disabled={disabled}
                  className={`${
                    active && "font-extrabold text-white"
                  } w-full text-left px-6 py-4 text-xs font-bold text-dark-400 capitalize whitespace-nowrap disabled:text-dark-500`}
                >
                  {textData.btns.createChatBotClone}
                </button>
              ) : (
                <div className="flex gap-3 items-center">
                  <button
                    onClick={createChatBotCloneCallback}
                    disabled={disabled}
                    className={`${
                      active && "font-extrabold text-white"
                    } text-white/[0.12] w-full text-left px-6 py-4 text-xs font-bold text-dark-400 capitalize whitespace-nowrap disabled:text-dark-500`}
                    // disabled={true}
                  >
                    {textData.btns.createChatBotClone}
                  </button>
                  <button
                    onClick={() => navigate("/subscription-plans")}
                    className="px-4 py-1.5 bg-white rounded-md mr-4 hover:opacity-80"
                  >
                    <p className="text-xs font-medium text-gradient capitalize whitespace-nowrap">
                      {textData.btns.upgradeToPro}
                    </p>
                  </button>
                </div>
              )
            }
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => navigate("/network")}
                className={`${
                  active && "font-bold text-white"
                } w-full text-left px-6 py-4 text-xs font-bold text-dark-400 capitalize whitespace-nowrap`}
              >
                {textData.btns.selectFromMarketplace}
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
