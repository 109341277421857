import React from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../../../../stores/hooks/hooks";
// store
import {
  setUnInstallModal,
} from "../../../../../../../stores/slice/marketplaceSlice";
import {
  removeToolFromClone,
} from "../../../../../../../stores/thunks/marketplaceThunk";
// data
import { textData } from "../../../../../../../assets/translations/en/textData";
// components
import { ModalWrapper } from "../../../../../../../components/molecules/modalWrapper/ModalWrapper";
import { ButtonCustom } from "../../../../../../../components/atoms/button/ButtonCustom";

export const UninstallToolModal = () => {
  // hooks
  const dispatch = useAppDispatch();
  // store
  const uninstallModal = useAppSelector(
    (state) => state.marketplace.unInstallModal
  );
// helpers
  const handleClick = () => {
    if (uninstallModal) {
      dispatch(removeToolFromClone({ tool_id: uninstallModal }));
      dispatch(setUnInstallModal(0));
    }
  };

  return (
    <ModalWrapper
      open={!!uninstallModal}
      onClose={() => {
        dispatch(setUnInstallModal(0));
      }}
      title={textData.marketplacePage.confirmTitle}
      className="pt-[55px] pb-[44px] pl-[101px] pr-[120px]"
    >
      <h3 className="font-Inter text-start font-normal text-lg leading-6 text-white my-10">
        {textData.marketplacePage.unInstallTool}
      </h3>
      <div className="flex gap-8 justify-start mt-10">
        <ButtonCustom
          variant="primary"
          title={textData.btns.btnDiscard}
          handleClick={() => {
            dispatch(setUnInstallModal(0));
          }}
        />
        <ButtonCustom
          variant="secondary"
          title={textData.btns.yesBtn}
          handleClick={handleClick}
        />
      </div>
    </ModalWrapper>
  );
};
