import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// data
import { sideBarData, sideBarTooltipsData } from "./mockData";
// components
import { SideBarItem } from "./sideBarItem/SideBarItem";
import { SideBarAccount } from "./sideBarAccount/SideBarAccount";
import { TooltipComponent } from "../../../../atoms/tooltip/Tooltip";
// icons
import { ReactComponent as Aphid } from "../../../../../assets/icons/aphid.svg";
// helper
import { getIconsComponents } from "../../../../../helpers/iconsComponents/iconsComponents";
// styles
import "./Sidebar.css";

export const SideBar = () => {
  // state
  const [full, setFull] = useState(true);
  const [runTour, setRunTour] = useState(false);
  // hooks
  const location = useLocation();
  // effect
  useEffect(() => {
    const seenTooltips = localStorage.getItem("seenSideBarTooltips");
    if (seenTooltips !== "true") {
      setRunTour(true);
    }
  }, []);
  useLayoutEffect(() => {
    // close sidebar when route change
    setFull(false);
  }, [location]);

  const handleJoyrideCallback = (data: any) => {
    const { status } = data;
    const finishedStatuses = ["finished"];

    if (finishedStatuses.includes(status)) {
      localStorage.setItem("seenSideBarTooltips", "true");
      setRunTour(false);
      // trigger the other pages tooltips after the sidebar tooltips was shown
      localStorage.setItem("isShownSidebar", "true");
      window.dispatchEvent(new Event("sidebarTourFinished"));
    }
  };

  return (
    <nav
      onMouseEnter={() => setFull(true)}
      onMouseLeave={() => setFull(false)}
      onClick={() => setFull(!full)}
      className={`box-content dynamic-height flex flex-col justify-between rounded-[20px] border border-primary-grey fixed top-1/2 translate-y-[-50%] left-5 z-30 ${
        full ? "w-[250px] bg-dark-gradient" : "w-[56px] bg-dark-200"
      }`}
    >
      <div>
        <TooltipComponent
          runTour={runTour}
          steps={sideBarTooltipsData}
          handleJoyrideCallback={handleJoyrideCallback}
          additionalStylesClass="sidebar-tooltip"
        />
        <div
          className={`w-full py-5 flex gap-4 items-end cursor-pointer border-b border-primary-grey ${
            full ? "justify-start px-7" : "justify-center"
          }`}
        >
          {getIconsComponents({
            name: "cockroach",
            className: "w-6 h-6 fill-secondary-green",
          })}
          {full && <Aphid />}
        </div>
        <section className="pt-2 xl:py-5">
          {sideBarData.up.map((item) => (
            <SideBarItem
              key={item.link + item.title}
              data={item}
              full={full}
              tooltipClass={`sidebar-${item.link}-tooltip`}
            />
          ))}
        </section>
      </div>
      <div>
        <section className="xl:pt-5 pb-20 xl:pb-[100px]">
          {sideBarData.down.map((item) => (
            <SideBarItem key={item.link + item.title} data={item} full={full} />
          ))}
        </section>
        <SideBarAccount full={full} />
      </div>
    </nav>
  );
};
