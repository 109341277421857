import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import { BotSettingsPreview } from "./components/botSettingsPreview/BotSettingsPreview";

const CreateBotTreePage = () => {
  return (
    <Layout mobileApp>
      <BotSettingsPreview />
    </Layout>
  );
};

export default CreateBotTreePage;
