import { instance } from "../axios/axiosConfig";
import {AddPaymentMethodType, ConfirmPaymentMethodType} from "../types/paymentsType";
import {TransactionListDataType} from "../types/transactionType";
// types

const paymentsApi = {
  fetchMembershipList() {
    return instance.get(`/payments/membership/`);
  },
  fetchTransactionList({page , page_size, ordering, created_at__gte} : TransactionListDataType) {
    return instance.get(`/payments/transactions/?page=${page}&page_size=${page_size}${ordering ? ("&ordering=" + ordering) : ""}${created_at__gte ? ("&created_at__gte=" + created_at__gte) : ""}`);
  },
  fetchPaymentList() {
    return instance.get(`/payments/payment_method/`);
  },
  getStripeConfig() {
    return instance.get(`/payments/stripe/config/`);
  },
  addPaymentMethod(body: AddPaymentMethodType) {
    return instance.post(`/payments/stripe/payment_method/add/`, body);
  },
  confirmPaymentMethod(body: ConfirmPaymentMethodType) {
    return instance.post(`/payments/stripe/payment_method/confirm/`, body);
  },
  removePaymentMethod(id: string) {
    return instance.delete(`/payments/payment_method/${id}`);
  },
};

export default paymentsApi;
