import React from "react";
// data
import { getIconsComponents } from "../../../helpers/iconsComponents/iconsComponents";
import { textData } from "../../../assets/translations/en/textData";

export const AppLinks = () => {
  return (
    <div className="grid grid-cols-2 gap-4 w-full">
      <a
        target="_blank"
        href="https://apps.apple.com/us/app/aphid-clone-automate-earn/id1631408192"
        className="border border-white rounded-md flex items-center justify-center gap-1 py-1.5 pl-1 pr-3"
        rel="noreferrer"
      >
        {getIconsComponents({ name: "appStore", className: "h-[30px]" })}
        <div className="flex flex-col justify-between">
          <p className="font-Inter text-xxs whitespace-nowrap">
            {textData.footer.linksData.downloadMobileApps.links.appStore1}
          </p>
          <p className="font-Inter text-xs font-bold capitalize whitespace-nowrap">
            {textData.footer.linksData.downloadMobileApps.links.appStore2}
          </p>
        </div>
      </a>
      <a
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.aphid.botmobile&hl=en_US&gl=US"
        className="border border-white rounded-md flex items-center justify-center gap-1 py-1.5 pl-2 pr-3"
        rel="noreferrer"
      >
        {getIconsComponents({
          name: "googlePlay",
          className: "w-[30px] h-[30px]",
        })}
        <div>
          <p className="font-Inter text-xxs uppercase whitespace-nowrap">
            {textData.footer.linksData.downloadMobileApps.links.googlePlay1}
          </p>
          <p className="font-Inter text-xs font-bold capitalize whitespace-nowrap">
            {textData.footer.linksData.downloadMobileApps.links.googlePlay2}
          </p>
        </div>
      </a>
    </div>
  );
};
