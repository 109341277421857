import { marketplaceApi } from "../api/marketplaceApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
// types
import {
  AddRatingBodyType,
  AddReviewBodyType,
  AddToolToCloneBodyType,
  MarketplaceByIdBodyType,
  MarketplaceLikeDataType,
  MarketPlaceToolsDataType,
} from "../types/marketplaceTypes";
// slice
import { setCountLikes, setInstalledToolNow } from "../slice/marketplaceSlice";
// alert
import { displayAlert } from "../slice/alertSlice";

export const fetchMarketplaceData = createAsyncThunk(
  "marketplace/fetchMarketplaceData",
  async (data: MarketPlaceToolsDataType) => {
    try {
      const response = await marketplaceApi.fetchMarketplace(data);
      return {
        count: response.data.count,
        results: response.data.results,
      };
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const fetchMarketplaceByIdData = createAsyncThunk(
  "marketplace/fetchMarketplaceByIdData",
  async (body: MarketplaceByIdBodyType) => {
    try {
      const response = await marketplaceApi.fetchMarketplaceByIdData(body);
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const fetchFavoriteTools = createAsyncThunk(
  "marketplace/fetchFavoriteTools",
  async () => {
    try {
      const response = await marketplaceApi.fetchFavoriteTools();
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const fetchInstalledTools = createAsyncThunk(
  "marketplace/fetchInstalledTools",
  async () => {
    try {
      const response = await marketplaceApi.fetchInstalledTools();
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const setMarketplaceLike = createAsyncThunk(
  "marketplace/setMarketplaceLike",
  async (data: MarketplaceLikeDataType, thunkAPI) => {
    try {
      const response = await marketplaceApi.setMarketplaceLike(data.body);
      if (response.status === 200) {
        thunkAPI.dispatch(fetchFavoriteTools());
        thunkAPI.dispatch(setCountLikes(data));
      }
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const setMarketplaceUnLike = createAsyncThunk(
  "marketplace/setMarketplaceUnLike",
  async (data: MarketplaceLikeDataType, thunkAPI) => {
    try {
      const response = await marketplaceApi.setMarketplaceUnLike(data.body);
      if (response.status === 200) {
        thunkAPI.dispatch(fetchFavoriteTools());
        thunkAPI.dispatch(setCountLikes(data));
      }
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const addToolToClone = createAsyncThunk(
  "marketplace/addToolToClone",
  async (body: AddToolToCloneBodyType, thunkAPI) => {
    try {
      const response = await marketplaceApi.addToolToClone(body);
      if (response.status === 201) {
        thunkAPI.dispatch(fetchInstalledTools());
        thunkAPI.dispatch(setInstalledToolNow(body.tool_id));
        setTimeout(() => thunkAPI.dispatch(setInstalledToolNow(null)), 3000);
      }
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const removeToolFromClone = createAsyncThunk(
  "marketplace/removeToolFromClone",
  async (body: AddToolToCloneBodyType, thunkAPI) => {
    try {
      const response = await marketplaceApi.removeToolFromClone(body);
      if (response.status === 200) {
        thunkAPI.dispatch(fetchInstalledTools());
      }
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const fetchCategoriesTags = createAsyncThunk(
  "marketplace/fetchCategoriesTags",
  async () => {
    try {
      const response = await marketplaceApi.fetchCategoriesTags();
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const addReview = createAsyncThunk(
  "marketplace/addReview",
  async (body: AddReviewBodyType, thunkAPI) => {
    try {
      const response = await marketplaceApi.addReview(body);
      if (response.status === 200) {
        thunkAPI.dispatch(fetchMarketplaceByIdData({ id: body.tool_id }));
      }
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const addRating = createAsyncThunk(
  "marketplace/addRating",
  async (body: AddRatingBodyType, thunkAPI) => {
    try {
      const response = await marketplaceApi.addRating(body);
      if (response.status === 200) {
        thunkAPI.dispatch(fetchMarketplaceByIdData({ id: body.tool_id }));
      }
      return response.data;
    } catch (e: any) {
      console.log(e);
      thunkAPI.dispatch(
        displayAlert({
          type: "warning",
          text: e.response.data.detail,
        })
      );
    }
  }
);
