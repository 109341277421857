import { configureStore } from "@reduxjs/toolkit";
// slice
import { chatUserSlice } from "./slice/chatUserSlice";
import { treeBotSlice } from "./slice/treeBotSlice";
import { userAuthSlice } from "./slice/userAuthSlice";
import { userProfileSlice } from "./slice/userProfileSlice";
import { botsSlice } from "./slice/botsSlice";
import { alertSlice } from "./slice/alertSlice";
import { templatesSlice } from "./slice/templatesSlice";
import { marketplaceSlice } from "./slice/marketplaceSlice";
import { paymentsSlice } from "./slice/paymentsSlice";

export const store = configureStore({
  reducer: {
    chatUser: chatUserSlice.reducer,
    treeBot: treeBotSlice.reducer,
    userAuth: userAuthSlice.reducer,
    userProfile: userProfileSlice.reducer,
    payments: paymentsSlice.reducer,
    bots: botsSlice.reducer,
    alert: alertSlice.reducer,
    templates: templatesSlice.reducer,
    marketplace: marketplaceSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
