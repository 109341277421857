import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import { SignInContainer } from "./components/signInContainer/SignInContainer";

const SignInPage = () => {
  return (
    <Layout sideBar={false} noPadding classNameWrapper="bg-black">
      <SignInContainer />
    </Layout>
  );
};

export default SignInPage;
