import React, { useEffect } from "react";
// router
import { BrowserRouter } from "react-router-dom";
import { useRoute } from "../routes/useRoute";
// redux
import { useAppDispatch, useAppSelector } from "../stores/hooks/hooks";
import { fetchUserProfile } from "../stores/thunks/userProfileThunk";
// components
import { setToken } from "../stores/slice/userAuthSlice";
import { fetchClones, fetchDialogs } from "../stores/thunks/botsThunk";
import { getStorageName } from "../helpers/storageName/storageName";

export const ReduxPage = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.userAuth.token);
  useEffect(() => {
    if (localStorage.getItem(getStorageName.accessToken)) {
      dispatch(setToken(localStorage.getItem(getStorageName.accessToken)));
    } else {
      dispatch(setToken(""));
    }
  }, []);
  useEffect(() => {
    if (token) {
      dispatch(fetchUserProfile());
      dispatch(fetchClones());
    }
  }, [dispatch, token]);
  return (
      <BrowserRouter>{useRoute({ token: token })}</BrowserRouter>
  );
};
