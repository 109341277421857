// icons
import errorMessage from "../../../../../assets/icons/errorMessage.svg";
import successMessage from "../../../../../assets/icons/successMessage.svg";
// types
import { IconsType } from "./mockDataTypes";

export const icons: IconsType = {
  errorMessage: errorMessage,
  successMessage: successMessage
};
