import React from "react";
// components
import { FooterPrivacyWrapper } from "./components/footerPrivacyWrapper/FooterPrivacyWrapper";
import { FooterContentWrapper } from "./components/footerContentWrapper/FooterContentWrapper";

type Props = {
  noPrivacy?: boolean;
};
export const Footer = ({ noPrivacy = false }: Props) => {
  return (
    <footer className="bg-black">
      <FooterContentWrapper />
      {!noPrivacy && <FooterPrivacyWrapper />}
    </footer>
  );
};
