import React, {Fragment, useEffect} from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
// data
import { textData } from "../../../../assets/translations/en/textData";
// components
import { ModalWrapper } from "../../../../components/molecules/modalWrapper/ModalWrapper";
import {setAddPaymentModalOpen} from "../../../../stores/slice/userProfileSlice";
import {addPaymentMethod, getStripePublicKey} from "../../../../stores/thunks/paymentsThunk";
import {Elements, PaymentElement} from '@stripe/react-stripe-js';
import {Appearance, loadStripe} from '@stripe/stripe-js';
import {StripeElements} from "./stripeElements";

export const AddPaymentModal = () => {
  // hooks
  const dispatch = useAppDispatch();
  // store
  const addPaymentModalOpen = useAppSelector((state) => state.userProfile.addPaymentModalOpen);
  const stripePublicKey = useAppSelector((state) => state.payments.stripePublicKey);
  const clientSecret = useAppSelector((state) => state.payments.stripeClientSecret);
  const paymentMethodType = useAppSelector((state) => state.payments.paymentMethodType);
  // state

  useEffect(() => {
    if(addPaymentModalOpen) {
      if (!stripePublicKey) {
        dispatch(getStripePublicKey());
      }
      dispatch(addPaymentMethod({"payment_method_type": paymentMethodType}));
    }
  }, [addPaymentModalOpen]);

  const customAppearance: Appearance = {
    theme: 'night'
  }

  return (
    <Fragment>
      <ModalWrapper
        open={addPaymentModalOpen}
        onClose={() => dispatch(setAddPaymentModalOpen(false))}
        title=""
      >
        <div className="flex flex-col">
          <h3 className="font-Inter text-center font-extrabold text-xl leading-6 text-white mb-10">
            {textData.settings.addAccount}
          </h3>
          {stripePublicKey && clientSecret &&
            <Elements stripe={loadStripe(stripePublicKey)} options={{clientSecret: clientSecret, appearance: customAppearance}}>
              <StripeElements clientSecret={clientSecret} />
            </Elements>
          }
        </div>
      </ModalWrapper>
    </Fragment>
  );
};
