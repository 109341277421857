// components
import { Layout } from "../../components/organisms/layout/Layout";
import { ResetPasswordContainer } from "./components/resetPasswordContainer/ResetPasswordContainer";

const ResetPasswordPage = () => {
  return (
    <Layout sideBar={false} noPadding classNameWrapper="bg-black">
      <ResetPasswordContainer />
    </Layout>
  );
};

export default ResetPasswordPage;
