// components
import { Layout } from "../../components/organisms/layout/Layout";
import { SignUpContainer } from "./components/signUpContainer/SignUpContainer";

const SignUpPage = () => {
  return (
    <Layout sideBar={false} noPadding classNameWrapper="bg-black">
      <SignUpContainer />
    </Layout>
  );
};

export default SignUpPage;
