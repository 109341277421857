import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import { SubscriptionPlansWrapper } from "./components/subsciptionPlansWrapper/SubscriptionPlansWrapper";

const SubscriptionPlansPage = () => {
  return (
    <Layout
      sideBar={false}
      noPadding
      className="h-screen bg-black flex justify-center pt-[90px] px-4 lg:px-[100px] w-full"
    >
      <SubscriptionPlansWrapper />
    </Layout>
  );
};

export default SubscriptionPlansPage;
