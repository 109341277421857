// types
import { ModalWrapperBtnType } from "../../../../../components/molecules/modalWrapper/modalWrapperTypes";
import { CreateCloneDataType } from "./mockDataTypes";
// data
import { textData } from "../../../../../assets/translations/en/textData";
// helper
import { createValidate } from "../../../../../helpers/validation/validationHelper";

export const createCloneFieldsData: CreateCloneDataType = {
  validate: createValidate(["name", "description", "image_url"]),
  fields: [
    {
      type: "text",
      label: "",
      placeholder: textData.fields.nameClone.placeholder,
      name: "name",
    },
    {
      type: "text",
      label: "",
      placeholder: textData.fields.descriptionClone.placeholder,
      name: "description",
    },
  ],
  initialValues: {
    name: "",
    description: "",
    image_url: null,
    brand_color: "7A52F4",
  },
};
