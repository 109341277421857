import { instance } from "../axios/axiosConfig";
// types
import {
  AddRatingBodyType,
  AddReviewBodyType,
  AddToolToCloneBodyType,
  MarketplaceByIdBodyType,
  MarketplaceLikeBodyType,
  MarketPlaceToolsDataType,
} from "../types/marketplaceTypes";
import {removeToolFromClone} from "../thunks/marketplaceThunk";
export const marketplaceApi = {
  fetchMarketplace({ params, path }: MarketPlaceToolsDataType) {
    return instance.get(`/marketplace/${path}/`, { params });
  },
  setMarketplaceLike(body: MarketplaceLikeBodyType) {
    return instance.post(`/marketplace/tools/${body.id}/like_tool/`);
  },
  setMarketplaceUnLike(body: MarketplaceLikeBodyType) {
    return instance.post(`/marketplace/tools/${body.id}/unlike_tool/`);
  },
  fetchMarketplaceByIdData(body: MarketplaceByIdBodyType) {
    return instance.get(`/marketplace/tools/${body.id}/`);
  },
  fetchFavoriteTools() {
    return instance.get(`/marketplace/favorite-tools/`);
  },
  fetchInstalledTools() {
    return instance.get(`/marketplace/tools/installed-ids/`);
  },
  addToolToClone(body: AddToolToCloneBodyType) {
    return instance.post(`/marketplace/tools/add_tool/`, body);
  },
  removeToolFromClone(body: AddToolToCloneBodyType) {
    return instance.post(`/marketplace/tools/uninstall_tool/`, body);
  },
  fetchCategoriesTags() {
    return instance.get(`marketplace/tool-filter-categories/`);
  },
  addReview(body: AddReviewBodyType) {
    return instance.post(`/marketplace/tools/add_review/`, body);
  },
  addRating(body: AddRatingBodyType) {
    return instance.post(`/marketplace/tools/add_rating/`, body);
  },
};
