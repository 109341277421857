import React, { Fragment, useEffect } from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
// store
// data
import { textData } from "../../../../assets/translations/en/textData";
// components
import { ModalWrapper } from "../../../../components/molecules/modalWrapper/ModalWrapper";
import { setDeleteAccountModalOpen } from "../../../../stores/slice/userProfileSlice";
import { useNavigate } from "react-router-dom";
import { fetchMembershipList } from "../../../../stores/thunks/paymentsThunk";
import { ButtonCustom } from "../../../../components/atoms/button/ButtonCustom";
import AphIdLogo from "../../../../assets/icons/aphidLogo.svg";
import {
  deleteUserProfile,
  setMembershipProfile,
} from "../../../../stores/thunks/userProfileThunk";
import { getIconsComponents } from "../../../../helpers/iconsComponents/iconsComponents";

export const DeleteAccountModal = () => {
  // store
  const deleteAccountModalOpen = useAppSelector(
    (state) => state.userProfile.deleteAccountModalOpen
  );
  const membershipList = useAppSelector(
    (state) => state.payments.membershipList
  );
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchMembershipList());
  }, []);

  const downgradeToBasic = () => {
    dispatch(
      setMembershipProfile({
        body: {
          membership:
            membershipList && membershipList["basic"]["permanent"]?.id,
        },
        navigate: navigate,
      })
    );
    dispatch(setDeleteAccountModalOpen(false));
  };

  const deleteAccount = () => {
    dispatch(deleteUserProfile({ navigate }));
  };

  return (
    <Fragment>
      <ModalWrapper
        open={deleteAccountModalOpen}
        onClose={() => dispatch(setDeleteAccountModalOpen(false))}
        className="xl:min-w-[1024px] lg:min-w-[768px] flex flex-col"
        title=""
      >
        <div className="flex flex-col m-center max-w-[400px] h-[calc(100vh-10rem)] max-h-[550px]">
          <button
            className="p-[14px] absolute top-[20px] right-[30px] border-primary-grey border-[1px] z-[1] rounded-full"
            onClick={() => dispatch(setDeleteAccountModalOpen(false))}
          >
            {getIconsComponents({
              name: "close",
              className: "fill-primary-light",
            })}
          </button>
          <div className="flex-grow overflow-y-auto scrollbar flex flex-col items-center justify-center">
            <img
              src={AphIdLogo}
              alt="aphid logo"
              className="w-[110px] h-[69px] mb-10"
            />
            <h3 className="font-Inter text-center font-extrabold text-xl leading-6 text-white mb-4">
              {textData.settings.deleteAccountTitle}
            </h3>
            <p className="font-Inter text-center mb-4">
              {textData.settings.deleteAccountSubtitle}
            </p>
            <p className="font-Roboto text-xs text-red-100 mb-4 lg:mb-10">
              {textData.settings.deleteAccountMsg}
            </p>
          </div>
          <div className="flex-shrink-0 flex flex-col gap-4 lg:gap-16 items-center my-5 lg:my-10">
            <ButtonCustom
              variant="yellow"
              title={textData.settings.downgradeToBasic}
              handleClick={() => {
                downgradeToBasic();
              }}
            />
            <ButtonCustom
              variant="red"
              title={textData.settings.deleteAccount}
              handleClick={() => {
                deleteAccount();
              }}
            />
          </div>
        </div>
      </ModalWrapper>
    </Fragment>
  );
};
