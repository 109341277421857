// data
import { textData } from "../../../../../../assets/translations/en/textData";
// types
import { MarketplaceFilterDataType } from "./mockDataTypes";
import { CustomStep } from "../../../../../activityPage/components/activityTaskTableItem/mockDataTypes";

export const marketplaceFilterData: MarketplaceFilterDataType = [
  {
    name: textData.marketplacePage.filters.installed,
    icon: "installed",
    path: "installation-tools",
  },
  {
    name: textData.marketplacePage.filters.free,
    icon: "free",
    path: "free-tools",
  },
  {
    name: textData.marketplacePage.filters.premium,
    icon: "premium",
    path: "premium-tools",
  },
  {
    name: textData.marketplacePage.filters.new,
    icon: "new",
    path: "new-tools",
  },
  {
    name: textData.marketplacePage.filters.popular,
    icon: "popular",
    path: "popular-tools",
  },
  {
    name: textData.marketplacePage.filters.topRated,
    icon: "topRated",
    path: "top-rated-tools",
  },
];

export const marketplaceTooltipsData: CustomStep[] = [
  {
    target: ".marketplace-search-tooltip",
    content: <p>Search for AI tools by name or keywords</p>,
    placement: "bottom",
    disableBeacon: true,
    positionClass: "position-top-center",
  },
  {
    target: ".marketplace-add-btn-tooltip",
    content: (
      <p>
        Add a bot to <span className="italic font-medium">Clones</span> on your
        sidebar menu. Think of this like downloading an app from the app store.
        Each action uses a credit. Click “Open in Clones” to use the tool.
      </p>
    ),
    placement: "bottom-start",
    positionClass: "position-top-left-15",
  },
  {
    target: ".marketplace-category-tooltip",
    content: (
      <p>
        Search for the tool of your choice by
        <span className="italic font-medium"> Category</span>
      </p>
    ),
    placement: "bottom",
    positionClass: "position-top-center",
  },
  {
    target: ".marketplace-item-tooltip",
    content: <p>Click to view the full bot description</p>,
    placement: "auto",
    positionClass: "position-top-center",
  },
  {
    target: ".marketplace-submit-btn-tooltip",
    content: (
      <p>
        <span className="italic font-medium">Submit a Tool </span> is a form to
        submit an AI tool to the Network (For Developers Only)
      </p>
    ),
    placement: "bottom",
    positionClass: "position-top-right-65",
  },
  {
    target: ".marketplace-wallet-btn-tooltip",
    content: <p>Connect a web3 wallet. Pro users only</p>,
    placement: "bottom",
    positionClass: "position-top-right-85",
  },
];
