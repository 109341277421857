import React, { useCallback, useEffect, useState } from "react";
// data
import { textData } from "../../../assets/translations/en/textData";
// icon
import ProPlanIcon from "../../../assets/icons/proPlan.svg";
import AphIdLogo from "../../../assets/icons/aphidLogo.svg";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks/hooks";
import {
  confirmPaymentMethod,
  fetchMembershipList,
  fetchPaymentMethods,
} from "../../../stores/thunks/paymentsThunk";
import { ButtonCustom } from "../../../components/atoms/button/ButtonCustom";
import { setMembershipProfile } from "../../../stores/thunks/userProfileThunk";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import BackIcon from "../../../assets/icons/leftArr.svg";
import PaypalIcon from "../../../assets/icons/paypal.svg";
import VisaIcon from "../../../assets/icons/visa.svg";
import CardIcon from "../../../assets/icons/card.svg";
import {
  setAddPaymentModalOpen,
  setChangePaymentModalOpen,
  setDowngradeNoticeModalOpen,
  setNoPaymentModalOpen,
} from "../../../stores/slice/userProfileSlice";
import AddIcon from "../../../assets/icons/add.svg";
import { AddPaymentModal } from "../../settingsPage/components/addPaymentModal/addPaymentModal";
import { ChangePaymentModal } from "../../settingsPage/components/changePaymentModal/changePaymentModal";
import MasterCardIcon from "../../../assets/icons/mastercard.svg";
import { DowngradeNoticeModal } from "../../selectMembership/downgradeNoticeModal/downgradeNoticeModal";
import { NoPaymentModal } from "../../settingsPage/components/noPaymentModal/noPaymentModal";
import { displayAlert } from "../../../stores/slice/alertSlice";
import { SelectMembershipModal } from "../../selectMembership/selectMembershipModal/selectMembershipModal";
import { getIconsComponents } from "../../../helpers/iconsComponents/iconsComponents";

export const SelectBusinessPlanWrapper = () => {
  // state
  const [selectedPlan, setSelectedPlan] = useState<string>("business_upgraded");
  const membershipList = useAppSelector(
    (state) => state.payments.membershipList
  );
  const defaultPaymentMethodById = useAppSelector(
    (state) => state.payments.defaultPaymentMethodById
  );
  const paymentMethods = useAppSelector(
    (state) => state.payments.paymentMethods
  );
  const paymentMethod = paymentMethods?.find(
    (p) => p.id === defaultPaymentMethodById
  );
  const membership = useAppSelector(
    (state) => state.userProfile.profile?.membership
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const billingPeriod = useAppSelector(
    (state) => state.userProfile.membershipBillingPeriod
  );
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(fetchMembershipList());
    dispatch(fetchPaymentMethods());
  }, [dispatch]);
  useEffect(() => {
    if (searchParams.get("redirect_status") === "succeeded") {
      if (searchParams.get("setup_intent")) {
        dispatch(
          confirmPaymentMethod({
            setup_intent_id: searchParams.get("setup_intent"),
          })
        );
        setSearchParams("");
      }
    } else if (searchParams.get("redirect_status") === "failed") {
      dispatch(
        displayAlert({
          type: "error",
          text: textData.alertsMessage.wrong,
        })
      );
      setSearchParams("");
    }
  }, [searchParams]);

  const setMembership = (plan: string, billingPeriod: string) => {
    console.log(billingPeriod);
    if (!defaultPaymentMethodById) {
      dispatch(setNoPaymentModalOpen(true));
    } else if (
      membershipList &&
      membership &&
      membershipList[plan][billingPeriod]!.plan < membership.plan
    ) {
      dispatch(setDowngradeNoticeModalOpen(true));
    } else if (defaultPaymentMethodById) {
      dispatch(
        setMembershipProfile({
          body: {
            membership:
              membershipList && membershipList[plan][billingPeriod]?.id,
            payment_method: defaultPaymentMethodById,
          },
          navigate: navigate,
        })
      );
    }
  };

  const isActiveMembership = (plan: string) => {
    if (membershipList && membership) {
      return membership.id === membershipList[plan][billingPeriod]?.id;
    }
    return false;
  };

  const getPrice = (plan: string) => {
    const initial_price =
      membershipList && membershipList[plan][billingPeriod]?.initial_price;
    return (
      <span>
        {initial_price && (
          <span className="line-through text-dark-170">${initial_price}</span>
        )}{" "}
        ${membershipList && membershipList[plan][billingPeriod]?.price}
      </span>
    );
  };

  return (
    <section className="relative m-center min-h-[100vh] w-full max-w-[1024px]">
      <img
        src={AphIdLogo}
        alt="aphid logo"
        className="absolute top-[30px] left-[46px] w-[100px] h-[38px]"
      />
      <button
        className="p-[14px] absolute top-[68px] right-[167px] bg-dark-300 border-primary-grey border-[1px] z-[1] rounded-full"
        onClick={() => navigate("/settings")}
      >
        {getIconsComponents({
          name: "close",
          className: "fill-primary-light rounded-full",
        })}
      </button>
      <div className="pt-[200px] relative text-center">
        <button
          className="w-6 h-6 absolute flex items-center top-[200px] left-[46px]"
          onClick={() => navigate(-1)}
        >
          <img src={BackIcon} className="centred" alt="back" />
        </button>
        <h2 className="font-Inter font-bold text-2lg text-secondary-green">
          {textData.selectMembership.medium}
          <img
            src={ProPlanIcon}
            className="inline-block w-[32px] h-[16px] ml-[10px]"
            alt=""
          />
        </h2>
        <p className="text-sm my-12">{textData.selectMembership.select}</p>
        <div
          className={`w-[532px] m-center my-12 py-2 px-14 border ${
            isActiveMembership("business")
              ? "opacity-50 pointer-events-none"
              : ""
          } ${
            selectedPlan === "business"
              ? "border-secondary-green"
              : "border-dark-500"
          } rounded-[10px] bg-dark-200 cursor-pointer`}
          onClick={() => setSelectedPlan("business")}
        >
          <p className="text-sm my-6">{textData.selectMembership.standard}</p>
          <p className="text-sm my-6">
            {getPrice("business")}/
            {billingPeriod === "monthly"
              ? textData.selectMembership.month
              : textData.selectMembership.year}
          </p>
        </div>
        <div
          className={`w-[532px] m-center my-12 py-2 px-14 border ${
            isActiveMembership("business_upgraded")
              ? "opacity-50 pointer-events-none"
              : ""
          } ${
            selectedPlan === "business_upgraded"
              ? "border-secondary-green"
              : "border-dark-500"
          } rounded-[10px] bg-dark-200 cursor-pointer`}
          onClick={() => setSelectedPlan("business_upgraded")}
        >
          <p className="text-sm my-6">{textData.selectMembership.upgraded}</p>
          <ul className="list-disc list-inside text-2xxs text-left">
            <li>{textData.selectMembership.cloneBots}</li>
            <li>
              {textData.selectMembership.connectMachine}{" "}
              {textData.selectMembership.dlModels}
            </li>
          </ul>
          <p className="text-sm my-6">
            {getPrice("business_upgraded")}/
            {billingPeriod === "monthly"
              ? textData.selectMembership.month
              : textData.selectMembership.year}
          </p>
        </div>
      </div>
      <ButtonCustom
        variant="secondary"
        type="button"
        adaptive={false}
        disabled={isActiveMembership(selectedPlan)}
        title={textData.btns.purchase}
        className="w-[180px] h-12 font-Roboto font-medium tracking-wider lg:text-lg m-center"
        handleClick={() => {
          setMembership(selectedPlan, billingPeriod);
        }}
      />
      {paymentMethod ? (
        <div className="flex flex-col mt-8 pb-8 text-center justify-center">
          <div className="flex justify-center items-center w-[53px] h-[53px] m-center mb-4 border border-dark-250 rounded-full">
            {paymentMethod?.payment_method_type === 1 ? (
              <img src={PaypalIcon} alt="paypal" className="h-fit" />
            ) : paymentMethod?.card_brand === "visa" ? (
              <img src={VisaIcon} alt="visa" className="h-fit" />
            ) : paymentMethod.card_brand === "mastercard" ? (
              <img src={MasterCardIcon} alt="mastercard" className="h-fit" />
            ) : (
              <img src={CardIcon} alt="card" className="h-fit" />
            )}
          </div>
          <div className="flex flex-col justify-center">
            {paymentMethod?.payment_method_type === 1 ? (
              <p className="text-xs">{paymentMethod?.paypal_payer_email}</p>
            ) : (
              <p className="text-xs">
                {paymentMethod?.card_brand}: ...
                {paymentMethod?.card_last_numbers}
              </p>
            )}
            <button
              className="text-xs mt-2 text-blue-500"
              type="button"
              onClick={() => {
                dispatch(setChangePaymentModalOpen(true));
              }}
            >
              {textData.settings.change}
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col mt-8 pb-8 text-center justify-center">
          <div className="flex justify-center items-center w-[53px] h-[53px] m-auto border border-dark-250 rounded-full">
            <img src={CardIcon} alt="card" className="h-fit" />
          </div>
          <div className="flex text-center justify-center">
            <button
              className="flex text-xs mt-4 text-white"
              type="button"
              onClick={() => {
                dispatch(setChangePaymentModalOpen(true));
              }}
            >
              <img src={AddIcon} alt="add" className="mr-2" />{" "}
              {textData.settings.addAccount}
            </button>
          </div>
        </div>
      )}
      <DowngradeNoticeModal />
      <SelectMembershipModal />
      <ChangePaymentModal />
      <NoPaymentModal />
      <AddPaymentModal />
    </section>
  );
};
