import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// thunk
import {
  addReview,
  fetchCategoriesTags,
  fetchFavoriteTools,
  fetchInstalledTools,
  fetchMarketplaceByIdData,
  fetchMarketplaceData,
} from "../thunks/marketplaceThunk";
// types
import {
  MarketplaceCategoryType,
  MarketplaceLikeBodyType,
  MarketplaceLikeDataType,
  MarketplaceProductItemIdType,
  MarketPlaceToolByIdType,
  MarketPlaceToolsItemType,
  MarketPlaceToolsType,
  PaginationDataType,
} from "../types/marketplaceTypes";
import { MarketplacePathItemType } from "../../pages/marketplace/marketplacePage/components/marketplacePageWrapper/marketplaceTypesFilter/mockDataTypes";

interface marketplaceState {
  isLoading: boolean;
  toolsList: MarketPlaceToolsType;
  count: number | null;
  toolById: MarketPlaceToolByIdType | null;
  toolIdForScroll: MarketplaceProductItemIdType | null;
  favoriteTools: MarketplaceProductItemIdType[];
  installedTools: MarketplaceProductItemIdType[];
  pagination: PaginationDataType;
  isLoadingAddToll: number | null;
  installedToolNow: MarketplaceProductItemIdType | null;
  selectedCategory: string[];
  searchValue: string;
  searchActiveValue: string;
  categories: MarketplaceCategoryType[] | null;
  pathForRequest: MarketplacePathItemType | "tools";
  ratingModal: number;
  unInstallModal: number;
}

const initialState: marketplaceState = {
  pagination: {
    offset: undefined,
    limit: undefined,
    page: 1,
  },
  unInstallModal: 0,
  searchValue: "",
  searchActiveValue: "",
  pathForRequest: "tools",
  toolIdForScroll: null,
  isLoading: false,
  toolById: null,
  toolsList: [],
  count: null,
  favoriteTools: [],
  installedTools: [],
  isLoadingAddToll: null,
  installedToolNow: null,
  selectedCategory: [],
  categories: [],
  ratingModal: 0,
};
export const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    setSelectedCategory: (state, action) => {
      state.pagination = initialState.pagination;
      state.toolsList = initialState.toolsList;
      if (state.selectedCategory.includes(action.payload)) {
        state.selectedCategory = state.selectedCategory.filter(
          (item) => item !== action.payload
        );
      } else {
        state.selectedCategory = [...state.selectedCategory, action.payload];
      }
    },
    setCountLikes: (state, action: { payload: MarketplaceLikeDataType }) => {
      if (
        state.toolsList &&
        state.toolsList.find((item) => item.id === action.payload.body.id)
      ) {
        state.toolsList = state.toolsList.map((item) =>
          item.id === action.payload.body.id
            ? {
                ...item,
                likes: action.payload.like ? item.likes - 1 : item.likes + 1,
              }
            : item
        );
      }
    },
    increasePaginationPage: (state) => {
      if (state.pagination.page === 1) {
        state.pagination = { offset: 12, limit: 12, page: 2 };
      } else {
        state.pagination = {
          offset: state.pagination.offset ? state.pagination.offset + 8 : 8,
          limit: 8,
          page: state.pagination.page + 1,
        };
      }
    },
    clearPaginationPage: (state) => {
      state.pagination = initialState.pagination;
    },
    clearToolsList: (state) => {
      state.toolsList = initialState.toolsList;
    },
    clearPathRequest: (state) => {
      state.pathForRequest = initialState.pathForRequest;
    },
    setIsLoadingTool: (state, action) => {
      state.isLoadingAddToll = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSearchActiveValue: (state, action) => {
      state.searchActiveValue = action.payload;
    },
    setRatingModal: (state, action) => {
      state.ratingModal = action.payload;
    },
    setUnInstallModal: (state, action) => {
      state.unInstallModal = action.payload;
    },
    setPathRequest: (state, action) => {
      state.pathForRequest = action.payload;
    },
    clearSelectedCategory: (state) => {
      state.selectedCategory = initialState.selectedCategory;
    },
    setInstalledToolNow: (state, action) => {
      state.installedToolNow = action.payload;
    },
    setToolIdForScroll: (state, action) => {
      state.toolIdForScroll = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMarketplaceData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMarketplaceData.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload) {
        state.count = action.payload.count;
        if (state.toolsList && state.toolsList.length === 0) {
          state.toolsList = action.payload.results;
        } else if (
          state.toolsList &&
          state.toolsList.length > 0 &&
          state.toolsList.length - 12 === (state.pagination.page - 1) * 8
        ) {
          if (state.searchActiveValue) {
            state.toolsList = action.payload.results;
          }
          //  if pagination wasn't change we leave list
        } else {
          const newTools = action.payload.results.filter(
            (newTool: MarketPlaceToolsItemType) =>
              !state.toolsList.some(
                (existingTool) => existingTool.id === newTool.id
              )
          );
          state.toolsList = [...state.toolsList, ...newTools];
        }
      }
    });
    builder.addCase(fetchMarketplaceByIdData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMarketplaceByIdData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.toolById = action.payload;
    });
    builder.addCase(
      fetchFavoriteTools.fulfilled,
      (state, action: PayloadAction<MarketplaceLikeBodyType[]>) => {
        state.favoriteTools = action.payload.map((item) => item.id);
      }
    );
    builder.addCase(
      fetchInstalledTools.fulfilled,
      (state, action: PayloadAction<MarketplaceLikeBodyType[]>) => {
        state.isLoadingAddToll = null;
        state.installedTools = action.payload.map((item) => item.id);
      }
    );
    builder.addCase(
      fetchCategoriesTags.fulfilled,
      (state, action: PayloadAction<MarketplaceCategoryType[]>) => {
        state.categories = action.payload;
      }
    );
    builder
      .addCase(addReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addReview.fulfilled, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const {
  clearPaginationPage,
  increasePaginationPage,
  setInstalledToolNow,
  setSelectedCategory,
  clearSelectedCategory,
  setCountLikes,
  setIsLoadingTool,
  clearToolsList,
  setToolIdForScroll,
  setPathRequest,
  clearPathRequest,
  setRatingModal,
  setSearchValue,
  setSearchActiveValue,
  setUnInstallModal,
} = marketplaceSlice.actions;
