import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import { ClonesPageWrapper } from "./components/clonesPageWrapper/ClonesPageWrapper";

const ClonesPage = () => {
  return (
    <Layout mobileApp classNameWrapper="pr-5 lg:pr-14">
      <ClonesPageWrapper />
    </Layout>
  );
};

export default ClonesPage;
