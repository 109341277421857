import React from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../../stores/hooks/hooks";
// store
import {
  setActiveWindow,
  setParentReply,
  setReplyById,
  setScenarioActive,
} from "../../../../../stores/slice/treeBotSlice";
// types
import {
  ChildRepliesType,
  ChildScenarioType,
} from "../../../../../stores/types/treeBotTypes";
// components
import { TreeButton } from "../treeButton/TreeButton";

type Props = {
  data: ChildScenarioType;
  preview?: boolean;
};
export const TreeLevelView = ({ data, preview }: Props) => {
  // hooks
  const dispatch = useAppDispatch();
  // store
  const dialogById = useAppSelector((state) => state.treeBot.dialogById);
  // callback
  const handleCallback = (element: ChildRepliesType) => {
    dispatch(setParentReply(element.id));
    dispatch(setScenarioActive(null));
    dispatch(setActiveWindow("properties"));
  };
  const handleEditCallback = (element: ChildRepliesType) => {
    dispatch(setScenarioActive(data));
    dispatch(setParentReply(element.id));
    dispatch(
      setActiveWindow(data.reply_type === 0 ? "editButtons" : "editFreeText")
    );
  };


  if (data && data.child_replies && data.child_replies.length > 0) {
    return (
      <div className={`flex gap-1 justify-center`}>
        {data.child_replies.map((item) => (
          <TreeButton
            key={item.id}
            handleCallback={() => handleCallback(item)}
            handleEditCallback={() => handleEditCallback(item)}
            handleEndNodeCallback={() => {
              dispatch(setReplyById(item));
              dispatch(setActiveWindow("endMessage"));
            }}
            icon={data.reply_type === 0 ? "buttons" : "freeText"}
            childReplies={item}
            freeText={data.text}
            preview={preview}
          />
        ))}
      </div>
    );
  }
  return null;
};
