import React, { useEffect, useState } from "react";
// hooks
import { useAppSelector } from "../../../../../../stores/hooks/hooks";
// types
import {
  ClonesDataItemTypes,
  DialogsDataItemTypes,
} from "../../../../../../stores/types/botsTypes";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
// components
import { LinkCustom } from "../../../../../../components/atoms/linkCustom/LinkCustom";
import { MenuItemForDialogs } from "./menuItemForDialogs/MenuItemForDialogs";

type Props = {
  data: DialogsDataItemTypes;
};

export const DialogCard = ({ data }: Props) => {
  // store
  const clones = useAppSelector((state) => state.bots.clones);
  // state
  const [actualClone, setActualClone] = useState<ClonesDataItemTypes[]>([]);
  // effect
  useEffect(() => {
    const foundClone = clones?.filter((clone) => data.bots.includes(clone.id));
    setActualClone(foundClone);
  }, [clones, data]);

  return (
    <div className="px-5 pb-5 pt-2 bg-dark-200 border border-primary-grey shadow-sm rounded-2xl">
      <div className="mb-6 xl:mb-12 flex justify-between items-start">
        <div className="flex items-center">
          <div className="flex flex-col mt-3">
            <h3 className="mb-[10px] font-Inter font-extrabold text-md lg:text-xl xl:text-5xl leading-8 text-white">
              {data.name}
            </h3>
            <p className="font-Inter font-normal text-[11px] lg:text-xs xl:text-md leading-5 text-dark-600">
              {data.description}
            </p>
          </div>
        </div>
        <MenuItemForDialogs dialog={data} />
      </div>
      <div className="flex justify-between items-center">
        <div className="flex gap-3 w-[32px] h-[32px] lg:w-[42px] lg:h-[42px] xl:w-[60px] xl:h-[60px]">
          {actualClone.length > 0 &&
            actualClone.map((clone) => (
              <img
                key={clone.id}
                src={clone.avatar}
                alt={clone.name}
                className="rounded-full object-cover w-full h-full"
              />
            ))}
        </div>
        <LinkCustom
          variant="primary"
          title={textData.btns.editDialogTree}
          link={`/dialog/${data.id}`}
        />
      </div>
    </div>
  );
};
