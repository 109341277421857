import { createAsyncThunk } from "@reduxjs/toolkit";
import templatesApi from "../api/templatesApi";
// types
import {
  DialogTemplateType,
  SetSavedTemplateType,
  TemplateImportToDialogType,
} from "../types/templatesTypes";
// data
import { textData } from "../../assets/translations/en/textData";
// alert
import { displayAlert } from "../slice/alertSlice";
// thunks
import { fetchDialogs } from "./botsThunk";
import {
  clearTemplateById,
  displayImportStatus,
  setImportError,
} from "../slice/templatesSlice";

export const getTemplatesList = createAsyncThunk(
  "templates/getTemplatesList",
  async () => {
    try {
      const response = await templatesApi.getTemplatesList();
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);
export const createTemplate = createAsyncThunk(
  "templates/createTemplate",
  async (data: DialogTemplateType, thunkAPI) => {
    try {
      const response = await templatesApi.createTemplate(data);
      if (response.status === 200) {
        data.navigate("/clones");
        thunkAPI.dispatch(
          displayAlert({
            type: "success",
            text: textData.alertsMessage.success,
          })
        );
      }
      return response.data;
    } catch (e: any) {
      console.log("error", e);
      if (e.response && e.response.data) {
        thunkAPI.dispatch(
          displayAlert({
            type: "error",
            text: e.response.data.error || textData.alertsMessage.wrong,
          })
        );
      }
    }
  }
);
export const getTemplateById = createAsyncThunk(
  "templates/getTemplateById",
  async (id: string) => {
    try {
      const response = await templatesApi.getTemplateById(id);
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);
export const templateImportToDialog = createAsyncThunk(
  "templates/templateImportToDialog",
  async (data: TemplateImportToDialogType, thunkAPI) => {
    try {
      const response = await templatesApi.templateImportToDialog(data);
      if (response.status === 200) {
        thunkAPI.dispatch(clearTemplateById());
        thunkAPI.dispatch(displayImportStatus("success"));
        thunkAPI.dispatch(fetchDialogs());
      }
      return response.data;
    } catch (e: any) {
      console.log("error", e);
      if (e.response && e.response.data) {
        thunkAPI.dispatch(displayImportStatus("warning"));
        thunkAPI.dispatch(setImportError(e.response.data.detail));
      }
    }
  }
);

export const templateSetSaved = createAsyncThunk(
  "templates/templateSetSaved",
  async (data: SetSavedTemplateType, thunkAPI) => {
    try {
      const response = await templatesApi.templateSetSaved(data);
      if (response.status === 200) {
        thunkAPI.dispatch(getTemplatesList());
        thunkAPI.dispatch(
          displayAlert({
            type: "success",
            text: textData.alertsMessage.bookmark,
          })
        );
      }
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);
