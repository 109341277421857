import { createValidate } from "../../../../../helpers/validation/validationHelper";
import { textData } from "../../../../../assets/translations/en/textData";
import { ResetPasswordDataType } from "./mockDataTypes";

export const resetPasswordData: ResetPasswordDataType = {
  validate: createValidate(["password", "password2"]),
  fields: [
    {
      type: "password",
      label: textData.fields.newPassword.label,
      placeholder: textData.fields.newPassword.placeholder,
      name: "password",
    },
    {
      type: "password",
      label: textData.fields.confirmNewPassword.label,
      placeholder: textData.fields.confirmNewPassword.placeholder,
      name: "password2",
    },
  ],
  initialValues: {
    password: "",
    password2: "",
    token: "",
    key: ""
  },
};
