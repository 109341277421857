import React from "react";
// icons
import art1 from "../../../../assets/icons/art1.png";
import art2 from "../../../../assets/icons/art2.png";
import art3 from "../../../../assets/icons/art3.png";
import art4 from "../../../../assets/icons/art4.png";
import art5 from "../../../../assets/icons/art5.png";
import ethereum from "../../../../assets/icons/ethereum.svg";
import xrp from "../../../../assets/icons/xrp.svg";
// types
import { ActivityMockData, CustomStep } from "./mockDataTypes";

export const activityTableMockData: ActivityMockData = [
  {
    id: "1111",
    cloneName: "aClone 2",
    cloneIcon: art1,
    category: "3Commas",
    status: "Completed",
    earnings: "$99.13",
    currency: "United States Dollar",
  },
  {
    id: "2222",
    cloneName: "Bored Ape Chemistry Club",
    cloneIcon: art2,
    category: "Art",
    status: "Failed",
    earnings: "$99.13",
    currency: "United States Dollar",
  },
  {
    id: "3333",
    cloneName: "RTFKT CloneX Mintvial",
    cloneIcon: art3,
    category: "Clone",
    status: "Failed",
    earnings: "4,310",
    currency: "Ethereum",
    currencyIcon: ethereum,
  },
  {
    id: "4444",
    cloneName: "Chromie Squiggle by Snowfro",
    cloneIcon: art4,
    category: "Productivity",
    status: "Completed",
    earnings: "$99.13",
    currency: "USD",
  },
  {
    id: "5555",
    cloneName: "Auto Writer",
    cloneIcon: art5,
    category: "Literature",
    status: "Running",
    earnings: "3,570",
    currency: "XRP",
    currencyIcon: xrp,
  },
  {
    id: "6666",
    cloneName: "aClone 2",
    cloneIcon: art1,
    category: "3Commas",
    status: "Completed",
    earnings: "--",
    currency: "",
  },
  {
    id: "7777",
    cloneName: "Bored Ape Chemistry Club",
    cloneIcon: art2,
    category: "Art",
    status: "Completed",
    earnings: "$99.13",
    currency: "United States Dollar",
  },
  {
    id: "8888",
    cloneName: "RTFKT CloneX Mintvial",
    cloneIcon: art3,
    category: "Clone",
    status: "Running",
    earnings: "4,310",
    currency: "Ethereum",
    currencyIcon: ethereum,
  },
  {
    id: "9999",
    cloneName: "Chromie Squiggle by Snowfro",
    cloneIcon: art1,
    category: "Productivity",
    status: "Running",
    earnings: "--",
    currency: "",
  },
];

export const activityStepsTooltipsData: CustomStep[] = [
  {
    target: ".clone-tooltip",
    content: (
      <p>
        <span className="italic font-semibold">Clone</span> displays which bot
        completed the transaction
      </p>
    ),
    placement: "bottom",
    disableBeacon: true,
    positionClass: "position-top-center",
  },
  {
    target: ".earnings-tooltip",
    content: (
      <p>
        <span className="italic font-semibold">Earnings</span> displays the
        amount of money earned from each transaction
      </p>
    ),
    placement: "bottom",
    positionClass: "position-top-center",
  },
  {
    target: ".status-tooltip",
    content: (
      <p>
        <span className="italic font-semibold">Status</span> displays the status
        of your clone tasks
      </p>
    ),
    placement: "bottom",
    positionClass: "position-top-center",
  },
];
