// data
import { textData } from "../../../../assets/translations/en/textData";
// types
import {
  SelectedTypeMockData,
  TextListCommonMockDataType,
} from "./mockDataTypes";
// icons
import PersonalAccTypeIcon from "../../../../assets/icons/personalAccType.svg";
import BusinessAccTypeIcon from "../../../../assets/icons/businessAccType.svg";

export const personalTextListMockData: TextListCommonMockDataType = [
  {
    title: textData.chooseAccountTypePage.benefitListForPersonal.shop,
    id: "shop",
  },
  {
    title: textData.chooseAccountTypePage.benefitListForPersonal.money,
    id: "money",
  },
  {
    title: textData.chooseAccountTypePage.benefitListForPersonal.save,
    id: "save",
  },
  {
    title: textData.chooseAccountTypePage.benefitListForPersonal.payouts,
    id: "payouts",
  },
  {
    title: textData.chooseAccountTypePage.benefitListForPersonal.delegate,
    id: "delegate",
  },
  {
    title: textData.chooseAccountTypePage.benefitListForPersonal.skills,
    id: "skills",
  },
  {
    title: textData.chooseAccountTypePage.benefitListForPersonal.tips,
    id: "tips",
  },
  {
    title: textData.chooseAccountTypePage.benefitListForPersonal.plan,
    id: "plan",
  },
  {
    title: textData.chooseAccountTypePage.benefitListForPersonal.plugins,
    id: "plugins",
  },
];

export const businessTextListMockData: TextListCommonMockDataType = [
  {
    title: textData.chooseAccountTypePage.benefitListForBusiness.assist,
    id: "assist",
  },
  {
    title: textData.chooseAccountTypePage.benefitListForBusiness.certified,
    id: "certified",
  },
  {
    title: textData.chooseAccountTypePage.benefitListForBusiness.plugins,
    id: "plugins",
  },
  {
    title: textData.chooseAccountTypePage.benefitListForBusiness.microsurveys,
    id: "microsurveys",
  },
];

export const selectedTypeMockData: SelectedTypeMockData = [
  {
    icon: {
      typeIcon: BusinessAccTypeIcon,
      typeText: "Business",
    },
    text: businessTextListMockData,
    note: textData.chooseAccountTypePage.note.business,
    value: 1,
  },
  {
    icon: {
      typeIcon: PersonalAccTypeIcon,
      typeText: "Personal",
    },
    text: personalTextListMockData,
    note: textData.chooseAccountTypePage.note.personal,
    value: 0,
  },
];
