import React, { Fragment, useEffect, useState } from "react";
// hooks
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../stores/hooks/hooks";
// store
import {
  clearSelectedAvatar,
  setCloneById,
  setCloneModalOpen,
} from "../../../../../stores/slice/botsSlice";
import { createClone, editClone } from "../../../../../stores/thunks/botsThunk";
import { UploadFileModal } from "../../../../../components/organisms/uploadFileModal/UploadFileModal";
// data
import { textData } from "../../../../../assets/translations/en/textData";
import { createCloneFieldsData } from "./mockData";
// img
import userCustom from "../../../../../assets/img/userCustom.png";
// types
import { CreateCloneValuesType } from "./mockDataTypes";
// components
import { ModalWrapper } from "../../../../../components/molecules/modalWrapper/ModalWrapper";
import { ButtonCustom } from "../../../../../components/atoms/button/ButtonCustom";
import { Formik } from "formik";
import { FieldFormik } from "../../../../../components/atoms/fieldFormik/FieldFormik";
import { AddAvatarWrapper } from "../../../../../components/molecules/addAvatarWrapper/AddAvatarWrapper";
import { BrandColorWrapper } from "./brandColorWrapper/BrandColorWrapper";

export const CreateCloneModal = () => {
  // hooks
  const dispatch = useAppDispatch();
  // store
  const cloneModalOpen = useAppSelector(
    (state) => state.bots.editCloneModalOpen
  );
  const avatarUrl = useAppSelector((state) => state.bots.avatarUrl);
  const cloneById = useAppSelector((state) => state.bots.cloneById);
  // state
  const [imageUrl, setImageUrl] = useState("");
  const [avatar, setAvatar] = useState<any | null>(null);
  // effects
  useEffect(() => {
    if (avatar && avatarUrl) {
      setImageUrl(URL.createObjectURL(avatar));
    } else if (cloneById) {
      setImageUrl(cloneById.avatar);
    } else {
      setImageUrl(userCustom);
    }
  }, [cloneById, avatar, avatarUrl]);

  useEffect(() => {
    if (!cloneModalOpen) {
      dispatch(setCloneById(null));
    }
  }, [cloneModalOpen]);

  return (
    <Fragment>
      <ModalWrapper
        open={cloneModalOpen}
        onClose={() => dispatch(setCloneModalOpen(false))}
        title={
          cloneById
            ? textData.createNewClone.titleEdit
            : textData.createNewClone.title
        }
        classNameWrapper="overflow-y-hidden"
      >
        <div className="flex flex-col h-[calc(100vh-20rem)] max-h-[640px]">
          <Formik
            validationSchema={createCloneFieldsData.validate}
            initialValues={
              cloneById
                ? {
                    name: cloneById.name,
                    description: cloneById.description,
                    image_url: cloneById.avatar,
                    brand_color: cloneById.brand_color,
                  }
                : createCloneFieldsData.initialValues
            }
            onSubmit={(values: CreateCloneValuesType, { resetForm }) => {
              cloneById
                ? dispatch(
                    editClone({
                      id: cloneById.id,
                      body: {
                        name: values.name,
                        description: values.description,
                        brand_color: values.brand_color,
                        image_url: avatarUrl || undefined,
                      },
                    })
                  )
                : dispatch(
                    createClone({
                      ...values,
                      image_url: avatarUrl,
                    })
                  );
              resetForm();
              dispatch(setCloneById(null));
              dispatch(clearSelectedAvatar());
              setAvatar(null);
              dispatch(setCloneModalOpen(false));
            }}
          >
            {({
              handleSubmit,
              values,
              setFieldValue,
              resetForm,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit} className="flex flex-col h-full">
                <div className="flex-grow overflow-y-auto scrollbar pr-2">
                  <div className="flex flex-col gap-6 mb-10">
                    {createCloneFieldsData.fields.map((field) => (
                      <FieldFormik
                        key={field.name + field.label}
                        field={field}
                        value={values[field.name] || ""}
                        setFieldValue={(e) =>
                          setFieldValue(field.name, e.target.value)
                        }
                        variant="dark"
                        touched={!!touched[field.name]}
                        errors={errors[field.name]?.toString()}
                        as={field.name === "description" ? "textarea" : "input"}
                        className="max-w-[230px]"
                      />
                    ))}
                  </div>
                  <AddAvatarWrapper
                    avatar={imageUrl}
                    touched={!!touched.image_url}
                    errors={errors.image_url?.toString()}
                  />
                  <BrandColorWrapper
                    avatar={imageUrl}
                    name={values.name || textData.createNewClone.defaultName}
                    setFieldValue={(value: string) =>
                      setFieldValue("brand_color", value)
                    }
                    brandColor={values.brand_color}
                  />
                </div>
                <div className="flex-shrink-0 flex gap-7 justify-center mt-10">
                  <ButtonCustom
                    variant="primary"
                    title={textData.btns.btnDiscard}
                    handleClick={() => {
                      resetForm();
                      dispatch(setCloneById(null));
                      dispatch(clearSelectedAvatar());
                      setAvatar(null);
                      dispatch(setCloneModalOpen(false));
                    }}
                    type="button"
                  />
                  <ButtonCustom
                    variant="secondary"
                    title={
                      cloneById
                        ? textData.btns.btnSave
                        : textData.btns.createNewClone
                    }
                    type="submit"
                  />
                </div>
                <UploadFileModal
                  setFieldValue={(value) => {
                    setAvatar(value);
                    if (value) {
                      setFieldValue("image_url", value);
                    } else {
                      setFieldValue("image_url", cloneById?.avatar || null);
                    }
                  }}
                />
              </form>
            )}
          </Formik>
        </div>
      </ModalWrapper>
    </Fragment>
  );
};
