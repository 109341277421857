import { MouseEventHandler } from "react";
// types
import { TemplateDataType } from "../../../../../stores/types/templateTypes";
// icons
import ActiveBookmarkIcon from "../../../../../assets/icons/bookmarkActive.svg";
import InactiveBookmarkIcon from "../../../../../assets/icons/bookmark.svg";

type TreeTemplateCardPropsType = {
  card: TemplateDataType;
  onCardClick: (id: string) => void;
  selectedCardId: string | null;
  onBookmarkClick: () => void;
};

export const TreeTemplateCard = ({
  card,
  onCardClick,
  selectedCardId,
  onBookmarkClick,
}: TreeTemplateCardPropsType) => {
  // helpers
  const handleBookmarkClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onBookmarkClick();
  };
  const handleCardClick = () => {
    onCardClick(card.id);
  };
  return (
    <div
      className={`flex flex-col justify-between w-[142px] min-h-[142px] max-h-[200px] xl:w-[200px] xl:min-h-[200px] xl:max-h-[300px] cursor-pointer pt-5 xl:pt-7 pr-4 pb-3 xl:pb-5 pl-4 border ${
        selectedCardId === card.id
          ? "border-dark-100 shadow-green"
          : " border-primary-grey"
      } transition duration-300 ease-in-out rounded overflow-hidden`}
      onClick={handleCardClick}
    >
      <div>
        <div className="flex justify-between relative mb-3">
          <div className="w-[56px] h-[56px] xl:w-[80px] xl:h-[80px]">
            <img src={card.image} alt="company logo" className="rounded-sm" />
          </div>
          <button
            className="cursor-grabbing self-baseline absolute top-[-10px] right-0"
            onClick={handleBookmarkClick}
          >
            <img
              src={card.is_saved ? ActiveBookmarkIcon : InactiveBookmarkIcon}
              alt="bookmark logo"
            />
          </button>
        </div>
        <h6
          className="font-Inter font-extrabold text-xs xl:text-lg text-left leading-2 xl:leading-6 mb-3 overflow-hidden text-ellipsis whitespace-nowrap"
          title={card.name}
        >
          {card.name}
        </h6>
      </div>
      <div className="flex gap-2 mt-auto">
        {/*<img src={card.authorImage} alt="description logo" />*/}
        <p className="font-Inter font-bold text-primary-light text-xxs xl:text-xs leading-5 ">
          {card.author.full_name}
        </p>
      </div>
    </div>
  );
};
