import React, {useEffect, useState} from "react";
// data
import { textData} from "../../../assets/translations/en/textData";
// icon
import ProPlanIcon from "../../../assets/icons/proPlan.svg";
import ProPlusPlanIcon from "../../../assets/icons/proPlusPlan.svg";
import AphIdLogo from "../../../assets/icons/aphidLogo.svg";
import {useAppDispatch, useAppSelector} from "../../../stores/hooks/hooks";
import {fetchMembershipList} from "../../../stores/thunks/paymentsThunk";
import {ButtonCustom} from "../../../components/atoms/button/ButtonCustom";
import {getIconsComponents} from "../../../helpers/iconsComponents/iconsComponents";
import infoAccTypeIcon from "../../../assets/icons/infoAccType.svg";
import {setMembershipProfile} from "../../../stores/thunks/userProfileThunk";
import {useNavigate} from "react-router-dom";
import BackIcon from "../../../assets/icons/leftArr.svg";
import {
  setDowngradeNoticeModalOpen,
  setMembershipBillingPeriod, setMembershipSelectedPlan,
  setNoPaymentModalOpen, setSelectMembershipModalOpen
} from "../../../stores/slice/userProfileSlice";
import {NoPaymentModal} from "../../settingsPage/components/noPaymentModal/noPaymentModal";
import {DowngradeNoticeModal} from "../downgradeNoticeModal/downgradeNoticeModal";
import {billingPeriodMap} from "./mockData";
import {SelectMembershipModal} from "../selectMembershipModal/selectMembershipModal";

export const SelectMembershipWrapper = () => {
  // state
  const membershipList = useAppSelector((state) => state.payments.membershipList);
  const defaultPaymentMethodById = useAppSelector((state) => state.payments.defaultPaymentMethodById);
  const membership = useAppSelector((state) => state.userProfile.profile?.membership);
  const [billingPeriod, setBillingPeriod] = useState(billingPeriodMap[membership?.billing_period === 1 ? 1 : 0]);
  const dispatch = useAppDispatch();
  const checkmark = getIconsComponents({
    name: "checkmark",
    className: "m-auto",
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchMembershipList());
  }, [dispatch]);

  const setSelectedMembership = ((plan: string, billingPeriod: string) => {
    console.log(plan, billingPeriod);
    dispatch(setMembershipSelectedPlan(plan));
    plan !== 'basic' && dispatch(setMembershipBillingPeriod(billingPeriod));
    if (membershipList && membership && membershipList[plan][billingPeriod]!.plan < membership.plan) {
      dispatch(setDowngradeNoticeModalOpen(true));
    } else
    if(!defaultPaymentMethodById && plan !== 'basic') {
      dispatch(setNoPaymentModalOpen(true));
    } else if (plan === 'basic') {
      dispatch(setMembershipProfile({
        body: {
          membership: membershipList && membershipList[plan][billingPeriod]!.id,
        },
        navigate: navigate,
      }));
    } else {
      dispatch(setSelectMembershipModalOpen(true));
    }
  });

  const getPrice = (plan: string) => {
    const initial_price = membershipList && membershipList[plan][billingPeriod]?.initial_price;
    return <span>{initial_price && <span className="line-through text-dark-170">${initial_price}</span>} ${membershipList && membershipList[plan][billingPeriod]?.price}</span>;
  }

  const isActiveMembership = (plan: string, billingPeriod: string) => {
    if(membershipList && plan && membership) {
      return membership.id === membershipList[plan][billingPeriod]?.id;
    }
    return false;
  }

  return (
    <section className="w-full pt-[130px] md:px-[35px] px-[95px]">
      <div className="flex flex-wrap">
        <img
          src={AphIdLogo}
          alt="aphid logo"
          className="absolute top-[30px] left-[37px] w-[150px] xl:w-[176px] xl:h-[67px] h-[52px]"
        />
        <button className="w-6 h-6 absolute flex items-center top-[138px] left-[46px]" onClick={() => navigate(-1)}>
          <img src={BackIcon} className="centred" alt="back"/>
        </button>
        <div className="basis-full text-center">
          <h1 className="font-Inter font-bold text-3xl capitalize">
            {textData.selectMembership.title}
          </h1>
          <p className="font-Inter text-xs text-primary-light">{textData.selectMembership.subtitle}</p>
        </div>
        <div className="ml-auto text-center">
          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" checked={billingPeriod === 'monthly'}
                   onChange={() => {
                     const value = billingPeriod === 'annual' ? 'monthly' : 'annual';
                     setBillingPeriod(value);
                     dispatch(setMembershipBillingPeriod(value));
                   }}
                   className="sr-only peer"/>
            <div className="relative
            w-20 h-6 bg-secondary-green rounded-full
            peer peer-checked:after:-translate-x-14 rtl:peer-checked:after:translate-x-14 peer-checked:after:border-white
            after:content-[''] after:absolute after:top-[2px] after:end-[2px] after:bg-white after:border-white
            after:shadow-toggle after:border after:rounded-full
            after:h-5 after:w-5 after:transition-all peer-checked:bg-secondary-green"></div>
          </label>
          <div className="flex text-m mt-2">
            <button
              className={`font-Roboto text-m ${billingPeriod === 'monthly' && "font-bold text-secondary-green"} capitalize mr-2 tracking-[1px]`}>
              {textData.selectMembership.monthly}
            </button>
            <span> | </span>
            <button
              className={`font-Roboto text-m ${billingPeriod === 'annual' && "font-bold text-secondary-green"} capitalize ml-2 tracking-[1px]`}>
              {textData.selectMembership.annual}
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full m-auto md:mt-20 max-w-[830px]">
        <div className="flex items-end h-52 border-b border-primary-light">
          <div className="w-[170px] pb-4">
          <span className="font-Inter font-medium text-m text-secondary-green">{textData.selectMembership.price}</span>
          </div>
          <div className="flex flex-col text-center w-[140px] pb-4 gap-4">
            <h2 className="font-Inter font-bold text-2lg text-secondary-green">{textData.selectMembership.basic}</h2>
            {isActiveMembership('basic', 'permanent') ?
              <p className="flex w-[100px] h-[36px] items-center justify-center m-auto border border-secondary-green rounded-full font-bold text-2xxs text-secondary-green">Current</p> :
              <ButtonCustom
                variant="secondary"
                type="button"
                adaptive={false}
                title={textData.btns.getStarted}
                className="w-[102px] py-[10px] text-2xxs m-auto"
                handleClick={() => {
                  setSelectedMembership('basic', 'permanent');
                }}
              />}
            <span className="font-Roboto font-medium text-xs">{textData.selectMembership.free}</span>
          </div>
          <div className="flex flex-col text-center w-[140px] pb-4 gap-4">
            <h2 className="font-Inter font-bold text-2lg text-secondary-green">{textData.selectMembership.personal}</h2>
            {isActiveMembership('personal', billingPeriod) ?
              <p className="flex w-[100px] h-[36px] items-center justify-center m-auto border border-secondary-green rounded-full font-bold text-2xxs text-secondary-green">Current</p> :
              <ButtonCustom
              variant="secondary"
              type="button"
              adaptive={false}
              title={textData.btns.getStarted}
              className="w-[102px] py-[10px] text-2xxs m-auto"
              handleClick={() => {
                setSelectedMembership('personal', billingPeriod);
              }}
            />}
            <span className="font-Roboto font-medium text-xs">
              {getPrice('personal')}/{billingPeriod === 'monthly' ? textData.selectMembership.month : textData.selectMembership.year}
            </span>
          </div>
          <div className="flex flex-col text-center w-[210px] pb-4 bg-[#161616] gap-4">
            <p className="font-Inter text-sm text-violet-100 py-4">{textData.selectMembership.popular}</p>
            <h2 className="font-Inter font-bold text-2lg text-secondary-green">
              {textData.selectMembership.medium}
              <img src={ProPlanIcon} className="inline-block w-[32px] h-[16px] ml-[10px]" alt=""/>
            </h2>
            <ButtonCustom
              variant="secondary"
              type="button"
              adaptive={false}
              title={isActiveMembership('business', billingPeriod) || isActiveMembership('business_upgraded', billingPeriod) ? textData.btns.current : textData.btns.getStarted}
              className={`w-[102px] py-[10px] text-2xxs m-auto ${(isActiveMembership('business', billingPeriod) || isActiveMembership('business_upgraded', billingPeriod)) && "border border-secondary-green text-secondary-green bg-transparent"}`}
              handleClick={() => {
                navigate("/select-business-plan");
              }}
            />
            <span className="font-Roboto font-medium text-xs">
              {getPrice('business')}/{billingPeriod === 'monthly' ? textData.selectMembership.month : textData.selectMembership.year}
            </span>
          </div>
          <div className="flex flex-col text-center w-[170px] pb-4 gap-4">
            <h2 className="font-Inter font-bold text-2lg text-secondary-green">
              {textData.selectMembership.enterprise}
              <img src={ProPlusPlanIcon} className="inline-block w-[32px] h-[16px] ml-[10px]" alt=""/>
            </h2>
            {isActiveMembership('enterprise', billingPeriod) ?
              <p className="flex w-[100px] h-[36px] items-center justify-center m-auto border border-secondary-green rounded-full font-bold text-2xxs text-secondary-green">Current</p> :
              <ButtonCustom
                variant="secondary"
                type="button"
                adaptive={false}
                title={textData.btns.getStarted}
                className="w-[102px] py-[10px] text-2xxs m-auto"
                handleClick={() => {
                  setSelectedMembership('enterprise', billingPeriod);
                }}
              />}
            <span className="font-Roboto font-medium text-xs">
              {getPrice('enterprise')}/{billingPeriod === 'monthly' ? textData.selectMembership.month : textData.selectMembership.year}
            </span>
          </div>
        </div>
        <div className="flex border-b border-primary-light">
          <div className="w-[170px] py-4">
            <span className="font-Inter font-medium text-xs text-dark-400">{textData.selectMembership.credits}</span>
          </div>
          <div className="text-center w-[140px] py-4">
            <span className="font-Inter font-medium text-xs">25</span>
          </div>
          <div className="text-center w-[140px] py-4">
            <span className="font-Inter font-medium text-xs">100</span>
          </div>
          <div className="text-center w-[210px] py-4 bg-[#161616]">
            <span className="font-Inter font-medium text-xs">1000</span>
          </div>
          <div className="text-center w-[170px] py-4">
            <span className="font-Inter font-medium text-xs">{textData.selectMembership.unlimited}</span>
          </div>
        </div>
        <div className="flex border-b border-primary-light">
          <div className="w-[170px] py-4">
            <span className="font-Inter font-medium text-xs text-dark-400">
              {textData.selectMembership.aiMarketplace}
              <img src={infoAccTypeIcon} className="inline-block transform scale-50" alt=""/>
            </span>
          </div>
          <div className="text-center w-[140px] py-4">
            {checkmark}
          </div>
          <div className="text-center w-[140px] py-4">
            {checkmark}
          </div>
          <div className="text-center w-[210px] py-4 bg-[#161616]">
            {checkmark}
          </div>
          <div className="text-center w-[170px] py-4">
            {checkmark}
          </div>
        </div>
        <div className="flex border-b border-primary-light">
          <div className="w-[170px] py-4">
            <span className="font-Inter font-medium text-xs text-dark-400">
              {textData.selectMembership.aphidId}
              <img src={infoAccTypeIcon} className="inline-block transform scale-50" alt=""/>
            </span>
          </div>
          <div className="text-center w-[140px] py-4">
            {checkmark}
          </div>
          <div className="text-center w-[140px] py-4">
            {checkmark}
          </div>
          <div className="text-center w-[210px] py-4 bg-[#161616]">
            {checkmark}
          </div>
          <div className="text-center w-[170px] py-4">
            {checkmark}
          </div>
        </div>
        <div className="flex border-b border-primary-light">
          <div className="w-[170px] py-4">
            <span className="font-Inter font-medium text-xs text-dark-400">
              {textData.selectMembership.web3Components}
              <img src={infoAccTypeIcon} className="inline-block transform scale-50" alt=""/>
            </span>
          </div>
          <div className="text-center w-[140px] py-4">

          </div>
          <div className="text-center w-[140px] py-4">
            {checkmark}
          </div>
          <div className="text-center w-[210px] py-4 bg-[#161616]">
            {checkmark}
          </div>
          <div className="text-center w-[170px] py-4">
            {checkmark}
          </div>
        </div>
        <div className="flex border-b border-primary-light">
          <div className="w-[450px] py-4">
            <span className="font-Inter font-medium text-xs text-dark-400">
              {textData.selectMembership.automatedCustomer}
              <img src={infoAccTypeIcon} className="inline-block transform scale-50" alt=""/>
            </span>
          </div>
          <div className="text-center w-[210px] py-4 bg-[#161616]">
          <span className="font-Inter font-medium text-xs">10 {textData.selectMembership.aiChatbotAgents}</span>
          </div>
          <div className="text-center w-[170px] py-4">
            <span className="font-Inter font-medium text-xs">20 {textData.selectMembership.aiChatbotAgents}</span>
          </div>
        </div>
        <div className="flex border-b border-primary-light">
          <div className="w-[450px] py-4">
            <span className="font-Inter font-medium text-xs text-dark-400">{textData.selectMembership.analytics}</span>
          </div>
          <div className="text-center w-[210px] py-4 bg-[#161616]">
            {checkmark}
          </div>
          <div className="text-center w-[170px] py-4">
            {checkmark}
          </div>
        </div>
        <div className="flex border-b border-primary-light">
          <div className="w-[450px] py-4">
            <span className="font-Inter font-medium capitalize text-xs text-dark-400">{textData.selectMembership.cloneBots}*</span>
          </div>
          <div className="text-center w-[210px] py-4 bg-[#161616]">
            {checkmark}
          </div>
          <div className="text-center w-[170px] py-4">
            {checkmark}
          </div>
        </div>
        <div className="flex border-b border-primary-light">
          <div className="w-[450px] py-4">
            <span className="font-Inter font-medium text-xs capitalize text-dark-400">{textData.selectMembership.connectMachine}*</span>
          </div>
          <div className="text-center w-[210px] py-4 bg-[#161616]">
            {checkmark}
          </div>
          <div className="text-center w-[170px] py-4">
            {checkmark}
          </div>
        </div>
        <div className="flex border-b border-primary-light">
          <div className="w-[450px] py-4">
            <span className="font-Inter font-medium text-xs text-dark-400">{textData.selectMembership.whiteGlove}</span>
          </div>
          <div className="text-center w-[210px] py-4 bg-[#161616]">

          </div>
          <div className="text-center w-[170px] py-4">
            {checkmark}
          </div>
        </div>
        <div className="flex">
          <div className="w-[450px] py-4">
            <span className="font-Inter font-medium text-xs text-dark-400">{textData.selectMembership.humanOperators}</span>
          </div>
          <div className="text-center w-[210px] py-4 bg-[#161616]">

          </div>
          <div className="text-center w-[170px] py-4">
            {checkmark}
          </div>
        </div>
      </div>
      <div className="text-center py-8 m-auto w-[570px]">
        <p className="font-Roboto text-xs text-dark-400 my-4">{textData.selectMembership.planRenews}</p>
        <p className="font-Roboto text-xs text-dark-400 my-4">{textData.selectMembership.requires}</p>
        <p className="font-Roboto text-xs text-dark-400 my-4">{textData.selectMembership.disclaimer}</p>
      </div>
      <DowngradeNoticeModal />
      <SelectMembershipModal />
      <NoPaymentModal />
    </section>
  );
};
