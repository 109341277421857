import React, { Fragment } from "react";
// hooks
import { useAppDispatch } from "../../../../../../../stores/hooks/hooks";
// components
import { Menu, Transition } from "@headlessui/react";
// icons
import { ReactComponent as MoreIcon } from "../../../../../../../assets/icons/more.svg";
// store
import {
  setDeleteDialogModalOpen,
  setDialogById,
  setDialogsModalOpen,
  setDuplicateDialogModalOpen,
} from "../../../../../../../stores/slice/botsSlice";
// data
import { textData } from "../../../../../../../assets/translations/en/textData";
// types
import { DialogsDataItemTypes } from "../../../../../../../stores/types/botsTypes";

type Props = {
  dialog: DialogsDataItemTypes;
};

export const MenuItemForDialogs = ({ dialog }: Props) => {
  const dispatch = useAppDispatch();

  const onEditDialog = () => {
    dispatch(setDialogById(dialog));
    dispatch(dispatch(setDialogsModalOpen(true)));
  };

  const onDeleteDialog = () => {
    dispatch(setDialogById(dialog));
    dispatch(setDeleteDialogModalOpen(true));
  };

  const onDuplicateDialog = () => {
    dispatch(setDialogById(dialog));
    dispatch(setDuplicateDialogModalOpen(true));
  };

  return (
    <Menu as="div" className="inline-block text-left relative">
      <Menu.Button>
        <MoreIcon className="hover:opacity-80 w-4 h-4 lg:w-8 lg:h-8" />
      </Menu.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Menu.Items className="origin-top-right absolute right-0 w-24 bg-dark-700 py-1 rounded-md shadow-lg">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={onEditDialog}
                className={`${
                  active &&
                  "font-extrabold bg-secondary-green text-secondary-dark"
                } w-full text-left px-4 py-2 text-sm font-bold text-dark-400 capitalize`}
              >
                {textData.btns.editBtn}
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={onDuplicateDialog}
                className={`${
                  active &&
                  "font-extrabold bg-secondary-green text-secondary-dark"
                } w-full text-left px-4 py-2 text-sm font-bold text-dark-400 capitalize`}
              >
                {textData.btns.duplicateBtn}
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={onDeleteDialog}
                className={`${
                  active &&
                  "font-extrabold bg-secondary-green text-secondary-dark"
                } w-full text-left px-4 py-2 text-sm font-bold text-dark-400 capitalize`}
              >
                {textData.btns.deleteBtn}
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
