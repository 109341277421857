import React, { useEffect, useState } from "react";
// location
import { useParams } from "react-router-dom";
// store
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../stores/hooks/hooks";
import {
  clearButtons,
  clearScenario,
  setActiveWindow,
  setExistButtons,
  setParentReply,
} from "../../../../../../stores/slice/treeBotSlice";
import {
  createScenario,
  createUserReplies,
  deleteScenario,
  deleteUserReplies,
  editeUserReplies,
  editScenario,
  fetchDialogById,
} from "../../../../../../stores/thunks/botsThunk";
// icon
import { ReactComponent as PenIcon } from "../../../../../../assets/icons/pen.svg";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
import { buttonsMessageFieldData, discardScenarioBtnData } from "./mockData";
// components
import { BlackWrapper } from "../../../../../../components/atoms/blackWrapper/BlackWrapper";
import { ButtonCustom } from "../../../../../../components/atoms/button/ButtonCustom";
import { FieldCustom } from "../../../../../../components/atoms/field/FieldCustom";
import { ButtonsRepliesWrapper } from "./buttonsRepliesWrapper/ButtonsRepliesWrapper";
import { ModalWrapper } from "../../../../../../components/molecules/modalWrapper/ModalWrapper";
// types
import { CallbackDataType } from "../../../../../../components/molecules/modalWrapper/modalWrapperTypes";

export const ButtonsScenarioWrapper = () => {
  const dispatch = useAppDispatch();
  // location
  const params = useParams();
  const dialogId: string = params.id as string;
  // selectors
  const activeWindow = useAppSelector((state) => state.treeBot.activeWindow);
  const scenario = useAppSelector((state) => state.treeBot.scenario);
  const editButtons = useAppSelector((state) => state.treeBot.editButtons);
  const newButtons = useAppSelector((state) => state.treeBot.newButtons);
  const existButtons = useAppSelector((state) => state.treeBot.existButtons);
  const deleteButtons = useAppSelector((state) => state.treeBot.deleteButtons);
  const parentReply = useAppSelector((state) => state.treeBot.parentReply);
  // state
  const [botMessage, setBotMessage] = useState("");
  const [discardShow, setDiscardShow] = useState(false);
  const [btnSaveAgree, setBtnSaveAgree] = useState(false);
  // effect
  useEffect(() => {
    if (
      scenario &&
      scenario.child_replies &&
      scenario.child_replies.length > 0
    ) {
      dispatch(setExistButtons(scenario.child_replies));
    }
  }, [scenario]);

  useEffect(() => {
    if (scenario) {
      setBotMessage(scenario.text);
    }
  }, [scenario]);
  // helpers
  const handleSave = () => {
    const callback = () => {
      dispatch(clearButtons());
      dispatch(clearScenario());
      dispatch(setParentReply(null));
      dispatch(setActiveWindow(null));
    };
    editButtons.map((item, index) =>
      index === editButtons.length - 1
        ? dispatch(
            editeUserReplies({
              ...item,
              callback:
                deleteButtons.length > 0 || newButtons.length > 0
                  ? undefined
                  : callback,
              dialogId:
                deleteButtons.length > 0 || newButtons.length > 0
                  ? undefined
                  : dialogId,
            })
          )
        : dispatch(
            editeUserReplies({
              ...item,
            })
          )
    );
    deleteButtons.map((item, index) =>
      index === deleteButtons.length - 1
        ? dispatch(
            deleteUserReplies({
              dialogId: newButtons.length > 0 ? undefined : dialogId,
              body: { id: item },
              callback: newButtons.length > 0 ? undefined : callback,
            })
          )
        : dispatch(deleteUserReplies({ dialogId, body: { id: item } }))
    );
    newButtons.map((item, index) =>
      index === newButtons.length - 1
        ? dispatch(
            createUserReplies({
              body: item.body,
              dialogId,
              callback: callback,
            })
          )
        : dispatch(createUserReplies({ body: item.body }))
    );
    if (btnSaveAgree) {
      dispatch(fetchDialogById({ id: dialogId }));
      setBtnSaveAgree(false);
      dispatch(setActiveWindow(null));
    }
  };

  const handleDiscard = () => {
    // delete if scenario was created but btn wasn't add
    if (
      scenario &&
      activeWindow === "buttons" &&
      existButtons &&
      existButtons.length === 0
    ) {
      dispatch(
        deleteScenario({
          id: scenario.id,
          callback: () => dispatch(fetchDialogById({id:dialogId})),
        })
      );
    }
    dispatch(clearButtons());
    dispatch(clearScenario());
    dispatch(setParentReply(null));
    activeWindow === "editButtons"
      ? dispatch(setActiveWindow(null))
      : dispatch(setActiveWindow("properties"));
    setDiscardShow(false);
    setBtnSaveAgree(false);
  };

  const callback: CallbackDataType = {
    notSave: () => handleDiscard(),
    discard: () => setDiscardShow(false),
  };

  return (
    <BlackWrapper
      title={textData.buttonsWrapper.title}
      icon={<PenIcon className="w-[22px] h-[22px] xl:w-[32px] xl:h-[32px]" />}
      deleteCallback={() => dispatch(setActiveWindow("deleteScenario"))}
      className="flex flex-col"
    >
      <div className="flex-grow overflow-y-auto scrollbar">
        <div className="mb-4">
          <FieldCustom
            field={buttonsMessageFieldData}
            value={botMessage}
            setFieldValue={(value: string) => setBotMessage(value)}
            handleBlur={() => {
              if (
                (activeWindow === "editButtons" && scenario) ||
                (activeWindow === "buttons" && scenario)
              ) {
                setBtnSaveAgree(true);
                dispatch(
                  editScenario({
                    id: scenario.id,
                    body: { text: botMessage, reply_type: scenario.reply_type },
                  })
                );
              } else if (!scenario && parentReply) {
                dispatch(
                  createScenario({
                    dialog: dialogId,
                    text: botMessage,
                    reply_type: 0,
                    parent_reply: parentReply,
                  })
                );
              } else {
                dispatch(
                  createScenario({
                    dialog: dialogId,
                    text: botMessage,
                    reply_type: 0,
                  })
                );
              }
            }}
            variant="dark"
            rows={2}
            className="mb-8"
          />
          <ButtonsRepliesWrapper />
        </div>
      </div>
      <div className="flex justify-center items-center gap-3 mt-6">
        <ButtonCustom
          variant="primary"
          handleClick={() => {
            if (
              editButtons.length > 0 ||
              newButtons.length > 0 ||
              deleteButtons.length > 0
            ) {
              setDiscardShow(true);
            } else {
              handleDiscard();
            }
          }}
          title={textData.btns.btnDiscard}
        />
        <ButtonCustom
          variant="secondary"
          handleClick={handleSave}
          disabled={
            !(
              editButtons.length > 0 ||
              newButtons.length > 0 ||
              deleteButtons.length > 0 ||
              btnSaveAgree
            )
          }
          title={textData.btns.btnSave}
        />
      </div>
      <ModalWrapper
        open={discardShow}
        onClose={() => setDiscardShow(false)}
        title={textData.confirm}
        text={textData.buttonsWrapper.prompt}
        btnData={discardScenarioBtnData}
        callbackData={callback}
      />
    </BlackWrapper>
  );
};
