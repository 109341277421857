import React from "react";
// image
import bg from "../../../../../../assets/img/getCertified.png";
// types
import { TableItemType } from "../mockDataTypes";
// data
import { currencyData } from "../mockData";

type Props = {
  data: TableItemType;
  border: boolean
};

export const CloneTableRow = ({ data, border }: Props) => {
  return (
    <div className={`flex items-center justify-between py-3 ${border ? 'border-t border-opacity-15 border-dark-450': ''}`}>
      <div className="flex gap-[18px] items-center">
        <div className="w-[48px] h-[48px] rounded-2lg overflow-hidden">
          <img src={bg} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col gap-y-[14px]">
          <span className="text-md font-Inter font-bold text-white">
            {data.title}
          </span>
          <span className="text-sm font-Inter text-white/50">
            {data.subtitle}
          </span>
        </div>
      </div>
      <div className="flex flex-col items-end gap-y-[14px]">
        <div className="flex gap-x-2.5">
          {currencyData[data.currency].icon}
          <span className="text-md font-Inter font-bold text-white">
            {data.currency === "dollar" ? `$${data.value}` : data.value}
          </span>
        </div>
        <span className="text-sm font-Inter text-white/50">
          {currencyData[data.currency].title}
        </span>
      </div>
    </div>
  );
};
