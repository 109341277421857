// components
import { Layout } from "../../components/organisms/layout/Layout";
import { DeployPageWrapper } from "./components/deployPageWrapper/DeployPageWrapper";

const DeployPage = () => {
  return (
    <Layout
      noPadding
      mobileApp
      classNameWrapper="h-full pt-[70px] pb-8 pr-14 pl-[100px] flex justify-center items-center "
    >
      <DeployPageWrapper />
    </Layout>
  );
};

export default DeployPage;
