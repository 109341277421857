import React from "react";
// data
import { textData } from "../../../../../assets/translations/en/textData";
import { summaryDataBtns } from "./mockData";
// types
import { SummarySlugType } from "./mockDataTypes";

type Props = {
  period: SummarySlugType;
  setPeriod: (value: SummarySlugType) => void;
};

export const SummaryElement = ({ period, setPeriod }: Props) => {
  return (
    <div className="py-3 px-6 border-b border-opacity-15 border-dark-450 flex justify-between items-center">
      <span className="font-Inter font-semibold text-lg text-white">
        {textData.dashboardPage.summary.title}
      </span>
      <div className="grid grid-cols-3">
        {summaryDataBtns.map((btn, index) => (
          <button
            key={`${index}${btn.title}`}
            onClick={() => setPeriod(btn.slug)}
            className={`font-Inter font-bold text-sm hover:text-white py-2.5 px-4 ${
              period === btn.slug ? "text-white" : "text-white/50"
            }`}
          >
            {btn.title}
          </button>
        ))}
      </div>
    </div>
  );
};
