import React from "react";
// components
import { ForgotPasswordContent } from "../../../../components/organisms/forgotPasswordContent/ForgotPasswordContent";
import { ResetPasswordForm } from "./resetPasswordForm/ResetPasswordForm";
import { Footer } from "../../../../components/molecules/footer/Footer";

export const ResetPasswordContainer = () => {
  return (
    <div className="min-h-screen">
      <div className="grid grid-cols-2">
        <ForgotPasswordContent />
        <ResetPasswordForm />
      </div>
      <Footer />
    </div>
  );
};
