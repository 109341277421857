// components
import { Layout } from "../../../components/organisms/layout/Layout";
import { ForgotPasswordMethodsPage } from "./components/forgotPasswordMethodsPage/ForgotPasswordMethodsPage";

const ForgotPasswordCommonPage = () => {
  return (
    <Layout sideBar={false} noPadding classNameWrapper="bg-black">
      <ForgotPasswordMethodsPage />
    </Layout>
  );
};
export default ForgotPasswordCommonPage;
