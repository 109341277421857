import React, { useState } from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../../../../stores/hooks/hooks";
// data
import { textData } from "../../../../../../../assets/translations/en/textData";
import { addReviewFieldData } from "./mockData";
// components
import { FieldCustom } from "../../../../../../../components/atoms/field/FieldCustom";
import { ButtonCustom } from "../../../../../../../components/atoms/button/ButtonCustom";
// thunk
import { addReview } from "../../../../../../../stores/thunks/marketplaceThunk";

export const AddReviewForm = () => {
  // hooks
  const dispatch = useAppDispatch();
  // state
  const [review, setReview] = useState("");
  // store
  const toolById = useAppSelector((state) => state.marketplace.toolById);
// helpers
  const onAddReviewBtn = () => {
    if (toolById) {
      dispatch(
        addReview({
          tool_id: toolById.id,
          text: review,
        })
      );
      setReview("");
    }
  };
  return (
    <div className="flex flex-col gap-7">
      <FieldCustom
        field={addReviewFieldData}
        value={review}
        setFieldValue={(value: string) => setReview(value)}
        variant="darker"
        rows={8}
        max={500}
        className="normal-case bg-red placeholder:font-Inter placeholder:text-gray-100 placeholder:text-md placeholder:font-bold placeholder:leading-5"
      />
      <ButtonCustom
        variant={"secondary"}
        title={textData.btns.submit}
        className="w-fit self-end"
        handleClick={onAddReviewBtn}
      />
    </div>
  );
};
