import React from "react";
// types
import { UserReviewDataType } from "../../../../../../../stores/types/marketplaceTypes";
// components
import { ReviewSectionItem } from "./reviewSectionItem/ReviewSectionItem";

export const ReviewSection = ({
  commentsData,
}: {
  commentsData: UserReviewDataType[];
}) => {
  return (
    <>
      {commentsData.map((item) => (
        <ReviewSectionItem key={item.id} {...item} />
      ))}
    </>
  );
};
