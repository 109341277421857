import React, { Fragment, useEffect } from "react";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
// components
import { Menu, Transition } from "@headlessui/react";
// hooks
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../stores/hooks/hooks";
// helper
import { getIconsComponents } from "../../../../../../helpers/iconsComponents/iconsComponents";
// slice
import { setSelectedCategory } from "../../../../../../stores/slice/marketplaceSlice";
// thunk
import { fetchCategoriesTags } from "../../../../../../stores/thunks/marketplaceThunk";

export const MarketplaceCategoriesFilter = () => {
  //hooks
  const dispatch = useAppDispatch();
  // store
  const allCategories = useAppSelector((state) => state.marketplace.categories);
  const selectedCategory = useAppSelector(
    (state) => state.marketplace.selectedCategory
  );
  // effect
  useEffect(() => {
    dispatch(fetchCategoriesTags());
  }, [dispatch]);

  return (
    <Menu
      as="div"
      className="relative inline-block text-left rounded-4xl border border-primary-grey bg-transparent marketplace-category-tooltip"
    >
      <Menu.Button className="flex items-center py-2.5 px-6 justify-center text-center gap-2 hover:opacity-80 font-Inter text-xs font-bold leading-[150%] capitalize">
        {getIconsComponents({ name: "category" })}
        {textData.marketplacePage.category}
      </Menu.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Menu.Items
          className="absolute z-20 top-[50px] -left-1/2 w-[280px] bg-dark-200 border border-primary-grey py-5 px-4 rounded-md shadow-lg
          max-h-80 overflow-y-auto scrollbar"
        >
          {allCategories &&
            allCategories.map((category) => {
              return (
                <Menu.Item key={category.id}>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        dispatch(setSelectedCategory(category.title));
                      }}
                      className={`${
                        (active || selectedCategory.includes(category.title)) &&
                        "font-bold text-secondary-green"
                      } w-full text-left text-sm font-bold text-dark-400 capitalize`}
                    >
                      {category.title}
                    </button>
                  )}
                </Menu.Item>
              );
            })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
