import React from "react";
// data
import { textData } from "../../../../../assets/translations/en/textData";
// components
import { MarketplaceCategoriesFilter } from "./marketplaceCategoriesFilter/MarketplaceCategoriesFilter";
import { MarketplaceSearchbar } from "./marketplaceSearchbar/MarketplaceSearchbar";
import { LinkCustom } from "../../../../atoms/linkCustom/LinkCustom";
// helpers
import { getIconsComponents } from "../../../../../helpers/iconsComponents/iconsComponents";

type Props = {
  resetTour?: () => void;
};

export const MarketplaceHeaderFilterSection = ({ resetTour }: Props) => {
  return (
    <header className="w-full bg-black ">
      <div className="max-w-[1920px] m-center pt-4 pb-5 pl-20 pr-8 xxl:px-[100px]">
        <div className="grid grid-cols-2">
          <div className="flex gap-4 items-center">
            <MarketplaceSearchbar />
            <MarketplaceCategoriesFilter />
          </div>
          <div className="flex items-center justify-end">
            <button className="mr-[54px] hover:opacity-80" onClick={resetTour}>
              {getIconsComponents({
                name: "question",
                className: "h-[25px] w-[25px]",
              })}
            </button>
            <LinkCustom
              variant="secondary"
              title={textData.btns.submitToolBtn}
              link="https://www.aphid.com/submit-tool"
              local={false}
              className="mr-[45px] marketplace-submit-btn-tooltip"
            />
            <button
              className="w-[40px] h-[40px] py-3 pl-3 pr-[7px] rounded-full border border-primary-grey bg-transparent
              hover:opacity-80 marketplace-wallet-btn-tooltip"
              onClick={() => console.log("clicked on exit")}
            >
              {getIconsComponents({ name: "marketplaceExit" })}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};
