import React, {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {textData} from "../../../assets/translations/en/textData";
import {ButtonCustom} from "../../../components/atoms/button/ButtonCustom";
import {confirmEmail, resendEmailConfirmation} from "../../../stores/thunks/userProfileThunk";
import {useAppDispatch, useAppSelector} from "../../../stores/hooks/hooks";
import {Spinner} from "../../../components/atoms/spinner/Spinner";


const ConfirmEmailWrapper = ()  => {

  const [searchParams, setSearchParams] = useSearchParams();
  const isLoading = useAppSelector((state) => state.userProfile.isLoading);
  const profile = useAppSelector((state) => state.userProfile.profile);
  const emailToVerify = useAppSelector((state) => state.userAuth.emailToVerify);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const verified = !(emailToVerify || profile?.email_to_change);

    useEffect(() => {
    if (searchParams.get('key')) {
      dispatch((confirmEmail({email_confirm_code: searchParams.get('key')})))
    } else {
      setSearchParams("")
    }
  }, []);

  if (isLoading) {
    return <Spinner page />;
  }

  return (
    <div className='flex flex-col w-[983px] h-[468px] m-center py-32 bg-dark-200 rounded border border-primary-grey'>
      <h3 className="font-Roboto text-center font-bold text-xl leading-6 text-white mb-10">
        { verified ? textData.confirmEmail.titleVerified : textData.confirmEmail.titleVerify }
      </h3>
      {verified ?
        <p className="font-Roboto text-xs text-center">
          {textData.confirmEmail.subtitleVerified}
        </p> :
        <>
          <p className="font-Roboto text-xs text-center">
            {textData.confirmEmail.subtitleVerify + (emailToVerify || profile?.email_to_change || '...@gmail.com')}.
          </p>
          <p className="font-Roboto text-xs text-center">
            {!verified && textData.confirmEmail.subtitleVerifyCheck}
          </p>
        </>
      }
      <div className="flex justify-center mt-10">
        {verified ?
          <ButtonCustom
            variant="secondary"
            title={textData.btns.goToDashboard}
            handleClick={() => {
              navigate(profile?.membership ? "/dashboard" : "/subscription-plans");
            }}
          /> :
          <ButtonCustom
            variant="yellow"
            title={textData.btns.resendLink}
            handleClick={() => {
              dispatch(resendEmailConfirmation({email: emailToVerify || profile?.email_to_change || ""}))
            }}
          />
        }
      </div>
    </div>
  )
}

export default ConfirmEmailWrapper;
