import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
// components
import { ButtonCustom } from "../../atoms/button/ButtonCustom";
// types
import { CallbackDataType, ModalWrapperBtnType } from "./modalWrapperTypes";

type Props = {
  children?: any;
  open: boolean;
  onClose: () => void;
  title: string;
  text?: string;
  className?: string;
  classNameWrapper?: string;
  btnData?: ModalWrapperBtnType;
  callbackData?: CallbackDataType;
};

export const ModalWrapper = ({
  children,
  open,
  onClose,
  title,
  text = "",
  btnData,
  callbackData,
  className = "",
  classNameWrapper = "",
}: Props) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 blur" />
        </Transition.Child>

        <div
          className={`fixed inset-0 overflow-y-auto bg-dark-800 bg-opacity-90 ${classNameWrapper}`}
        >
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-[512px] xl:w-[720px] transform overflow-hidden rounded border border-primary-grey bg-dark-200 pt-[50px] pb-[30px] px-[62px] xl:pt-[72px] xl:pb-10 xl:pl-[88px] pr-[96px] ${className}`}
              >
                {title &&
                  <Dialog.Title as="h3" className="font-Inter font-extrabold text-start text-3xl xl:text-7xl xl:leading-7 text-white mb-6 capitalize">
                    {title}
                  </Dialog.Title>
                }
                {text &&
                    <p className="font-Inter font-medium text-start text-xl leading-7 text-white mb-4 ">{text}</p>
                }
                <div>
                  {children}
                  {btnData && callbackData && btnData.length > 0 && (
                    <div className="flex gap-7 justify-center mt-10">
                      {btnData.map((btn, index) => (
                        <ButtonCustom
                          key={btn.variant + btn.title + index}
                          variant={btn.variant}
                          title={btn.title}
                          handleClick={
                            btn.callbackName && callbackData[btn.callbackName]
                          }
                          type={btn.type}
                          icon={btn.icon}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
