import React, { useEffect, useRef, useState } from "react";
//hooks
import {  useAppSelector } from "../../../../../stores/hooks/hooks";
import { useNavigate } from "react-router-dom";
// data
import { textData } from "../../../../../assets/translations/en/textData";
// components
import { ZoomableContainer } from "react-zoomable-container";
import { BotTreeControls } from "../../../../../components/molecules/botTreeControls/BotTreeControls";
import { TreeStartButton } from "../../../../../components/organisms/treeStartButton/TreeStartButton";
// icon
import { ReactComponent as ReturnIcon } from "../../../../../assets/icons/leftArrow.svg";

type Props = {
  returnCallback: () => void;
};

export const PreviewTreeTemplateWrapper = ({ returnCallback }: Props) => {
  // ref
  const treeRef = useRef(null);
  // hooks
  const navigate = useNavigate();
  // state
  const [message, setMessage] = useState("");
  // data
  const overrides = {
    scale: 1,
    position: {
      x: 0,
      y: 0,
    },
    lerpTime: 500,
    scaleStep: 0.1,
    minScale: 0.1,
    maxScale: 2,
    disablePan: false,
  };
  // store
  const templateById = useAppSelector((state) => state.templates.templateById);
  const tree = templateById?.tree;
// effect
  useEffect(() => {
    if (templateById) {
      setMessage(templateById.start_message);
    } else {
      navigate("/clones");
    }
  }, [templateById]);

  if (templateById) {
    return (
      <div>
        <button
          onClick={returnCallback}
          className="w-[50px] h-[50px] fixed top-8 left-[200px]"
        >
          <ReturnIcon />
        </button>

        <ZoomableContainer
          customControls={<BotTreeControls />}
          controlOverrides={overrides}
        >
          <div ref={treeRef} className="flex flex-col items-center">
            <TreeStartButton
              handleCallback={() => {}}
              icon="home"
              start
              childScenario={null}
              text={message || textData.tree.startMessage}
              replies={[]}
              preview
            />
            {templateById?.start_message && (
              <TreeStartButton
                handleCallback={() => {}}
                disabled={!!tree}
                icon="bot"
                childScenario={tree || null}
                text={textData.tree.botResponse}
                replies={tree?.child_replies || []}
                preview
              />
            )}
          </div>
        </ZoomableContainer>
      </div>
    );
  }
  return null;
};
