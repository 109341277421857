import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import { TemplateAlertWrapper } from "./components/templateAlertWrapper/TemplateAlertWrapper";

const TemplateAlertPage = () => {
  return (
    <Layout
      noPadding
      mobileApp
      classNameWrapper="h-full pt-[128px] pb-8 pr-14 pl-[100px] flex justify-center items-center "
    >
      <TemplateAlertWrapper />
    </Layout>
  );
};

export default TemplateAlertPage;
