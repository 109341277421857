import React from "react";
// types
import { ActivityStatusType } from "../../activityTaskTableItem/mockDataTypes";

type ProgressBarType = {
  status: ActivityStatusType;
};

export const ProgressBar = ({ status }: ProgressBarType) => {
  const getStatusColor = () => {
    switch (status) {
      case "Completed":
        return "bg-secondary-green";
      case "Running":
        return "bg-yellow-400";
      case "Failed":
        return "bg-red-200";
      default:
        return "bg-gray-500";
    }
  };
  const statusWidths: { [key in ActivityStatusType]: string } = {
    Completed: "100%",
    Running: "50%",
    Failed: "100%",
  };

  return (
    <div className="w-[36px] h-[3px] bg-dark-150 rounded-full">
      <div
        className={`h-full rounded-full ${getStatusColor()}`}
        style={{ width: statusWidths[status] }}
      ></div>
    </div>
  );
};
