// components
import { Layout } from "../../components/organisms/layout/Layout";
import { SetupGuidePageWrapper } from "./components/setupGuidePageWrapper/SetupGuidePageWrapper";

const SetupGuidePage = () => {
  return (
    <Layout
      noPadding
      mobileApp
      classNameWrapper="h-full pt-[95px] pb-8 pr-14 pl-[100px] flex justify-center items-center"
    >
      <SetupGuidePageWrapper />
    </Layout>
  );
};

export default SetupGuidePage;
