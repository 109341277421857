import { instance } from "../axios/axiosConfig";
// types
import {
  SetUserAccountTypeDataType,
  UserChangePasswordDataType,
  UserChangeProfileDataType, UserConfirmEmailDataType, UserResendEmailConfirmationDataType, UserSetMembershipType,
} from "../types/userProfileTypes";

const userProfileApi = {
  fetchUserProfile() {
    return instance.get(`/users/profile/`);
  },
  editUserProfile(body: UserChangeProfileDataType) {
    return instance.patch(`/users/profile/`, body);
  },
  deleteUserProfile() {
    return instance.delete(`/users/profile/delete`);
  },
  setMembershipProfile(body: UserSetMembershipType) {
    return instance.post(`/users/profile/set_membership/`, body);
  },
  changePasswordUserProfile(body: UserChangePasswordDataType) {
    return instance.post(`/users/profile/change_password/`, body);
  },
  confirmEmail(body: UserConfirmEmailDataType) {
    return instance.post(`/users/profile/confirm_email/`, body);
  },
  resendEmailConfirmation(body: UserResendEmailConfirmationDataType) {
    return instance.post(`/users/profile/resend_email_confirmation/`, body);
  },
  setAccountTypeUserProfile(body: SetUserAccountTypeDataType) {
    return instance.post(`/users/profile/set_account_type/`, body);
  },
};

export default userProfileApi;
