import React from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../stores/hooks/hooks";
import { useNavigate } from "react-router-dom";
// data
import { textData } from "../../assets/translations/en/textData";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import { LinkCustom } from "../../components/atoms/linkCustom/LinkCustom";
import { ButtonCustom } from "../../components/atoms/button/ButtonCustom";
// slice
import { setCloneModalOpen } from "../../stores/slice/botsSlice";

type Props = {
  isAuth: boolean;
};
const ClonesWelcomePage = ({ isAuth }: Props) => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // store
  const token = useAppSelector((state) => state.userAuth.token);
  const clones = useAppSelector((state) => state.bots.clones);
  const profile = useAppSelector((state) => state.userProfile.profile);

  return (
    <Layout
      sideBar={false}
      classNameWrapper="h-screen flex justify-center items-center pl-[56px] bg-black"
    >
      <div className="flex flex-col items-center justify-center max-w-[363px] xl:max-w-full">
        <h2 className="font-Inter font-extrabold text-lg xl:text-5xl leading-7 text-white mb-[93px] xl:mb-[168px]">
          {textData.welcomePage.title}
        </h2>
        <p className="font-Inter font-normal text-xs xl:text-2xl leading-5 text-white mb-[60px] xl:mb-[180px] max-w-[652px] tracking-[1.1px]">
          {textData.welcomePage.description1}
          {profile?.full_name || "User"}
          {textData.welcomePage.description2}
        </p>
        {isAuth && token ? (
          clones && clones.length > 0 ? (
            <LinkCustom
              variant="secondary"
              link="/clones"
              title={textData.btns.getStarted}
            />
          ) : (
            <ButtonCustom
              variant="secondary"
              title={textData.btns.getStarted}
              handleClick={() => {
                dispatch(setCloneModalOpen(true));
                navigate("/clones");
              }}
            />
          )
        ) : (
          <div className="flex gap-4">
            <LinkCustom
              variant="primary"
              link="/signin"
              title={textData.btns.signIn}
            />
            <LinkCustom
              variant="secondary"
              link="/signup"
              title={textData.btns.signUp}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};
export default ClonesWelcomePage;
