import React from "react";
// types
import {
  PropertiesCardItemDataType,
  PropertiesCardItemIdType,
} from "../mockDataTypes";

type Props = {
  data: PropertiesCardItemDataType;
  active: PropertiesCardItemIdType | null;
  setActive: () => void;
};

export const CardCustom = ({ data, active, setActive }: Props) => {
  return (
    <button
      onClick={() => setActive()}
      disabled={!data.access}
      className={`pt-2 xl:pt-4 border pb-3 xl:pb-6 px-9 bg-primary-grey flex gap-10 items-center rounded-3xl ${
        data.access ? "opacity-100 hover:opacity-80" : "opacity-10"
      } ${
        active === data.id ? "border-secondary-green" : "border-primary-grey"
      }`}
    >
      {data.icon}
      <div className="flex flex-col items-start">
        <h5 className="capitalize mb-1 font-Iner font-semibold text-md xl:text-xl leading-7 text-white">
          {data.title}
        </h5>
        <p className="text-primary-light font-Iner font-normal leading-5 text-xs xl:text-md text-start">
          {data.describe}
        </p>
      </div>
    </button>
  );
};
