import { createAsyncThunk } from "@reduxjs/toolkit";
import botsApi from "../api/botsApi";
// types
import {
  CreateCloneBodyType,
  CreateDialogBodyType,
  DeleteDialogDataType,
  DeleteScenarioType,
  DuplicateCloneDialogType,
  EditCloneBodyType,
  EditDialogBodyType,
  FetchDialogByIdData,
  UploadAvatarData,
} from "../types/botsTypes";
import {
  ScenarioBodyType,
  ScenarioEditBodyReloadType,
  ScenarioEditBodyType,
  UserRepliesDeleteBodyType,
  UserRepliesEditBodyType,
  UserRepliesType,
} from "../types/treeBotTypes";
// slice
import { displayAlert } from "../slice/alertSlice";
// data
import { textData } from "../../assets/translations/en/textData";
// helpers
import { getStorageName } from "../../helpers/storageName/storageName";
import { extractErrorMessage } from "../../helpers/extractErrorMessage/extractErrorMessage";

export const createDialog = createAsyncThunk(
  "bots/createDialog",
  async (data: CreateDialogBodyType, thunkAPI) => {
    try {
      const response = await botsApi.createDialog(data);
      thunkAPI.dispatch(fetchDialogs());
      thunkAPI.dispatch(checkDialogLimit(data.bots[0]));
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const fetchDialogs = createAsyncThunk("bots/fetchDialogs", async () => {
  try {
    const response = await botsApi.getDialogs();
    return response.data;
  } catch (e) {
    console.log("error", e);
  }
});
export const fetchDialogById = createAsyncThunk(
  "bots/fetchDialogById",
  async (data: FetchDialogByIdData) => {
    try {
      const response = await botsApi.getDialogById(data.id);
      return response.data;
    } catch (e) {
      data.callbackError && data.callbackError();
      console.log("error", e);
    }
  }
);
export const editDialog = createAsyncThunk(
  "bots/editDialog",
  async (data: EditDialogBodyType, thunkAPI) => {
    try {
      const response = await botsApi.editDialog(data);
      if (response.status === 200) {
        localStorage.removeItem(getStorageName.chatBotId);
        thunkAPI.dispatch(fetchDialogs());
        thunkAPI.dispatch(fetchDialogById({ id: data.id }));
      }
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);
export const deleteDialog = createAsyncThunk(
  "bots/deleteDialog",
  async (data: DeleteDialogDataType, thunkAPI) => {
    try {
      const response = await botsApi.deleteDialog(data.dialog);
      if (response.status === 204) {
        thunkAPI.dispatch(fetchDialogs());
        thunkAPI.dispatch(checkDialogLimit(data.clone));
      }
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);
export const duplicateDialog = createAsyncThunk(
  "bots/duplicateDialog",
  async (body: DuplicateCloneDialogType, thunkAPI) => {
    try {
      const response = await botsApi.duplicateDialog(body);
      if (response.status === 201) {
        thunkAPI.dispatch(fetchDialogs());
      }
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);
export const createClone = createAsyncThunk(
  "bots/createClone",
  async (data: CreateCloneBodyType, thunkAPI) => {
    try {
      const response = await botsApi.createClone(data);
      thunkAPI.dispatch(fetchClones());
      thunkAPI.dispatch(fetchClonesLimits());
      return response.data;
    } catch (e) {
      console.log("error", e);
      const errorMessage = extractErrorMessage(e.response);
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: errorMessage,
        })
      );
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);
export const editClone = createAsyncThunk(
  "bots/editClone",
  async (data: EditCloneBodyType, thunkAPI) => {
    try {
      const response = await botsApi.editClone(data);
      thunkAPI.dispatch(fetchClones());
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);
export const deleteClone = createAsyncThunk(
  "bots/deleteClone",
  async (id: string, thunkAPI) => {
    try {
      const response = await botsApi.deleteClone(id);
      thunkAPI.dispatch(fetchClones());
      thunkAPI.dispatch(fetchClonesLimits());
      thunkAPI.dispatch(fetchDialogs());
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const duplicateClone = createAsyncThunk(
  "bots/duplicateClone",
  async (body: DuplicateCloneDialogType, thunkAPI) => {
    try {
      const response = await botsApi.duplicateClone(body);
      if (response.status === 201) {
        thunkAPI.dispatch(fetchClones());
      }
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);
export const uploadAvatar = createAsyncThunk(
  "bots/uploadAvatar",
  async (data: UploadAvatarData) => {
    try {
      let formData: any = new FormData();
      formData.append("image", data.avatar);
      const response = await botsApi.uploadAvatar({
        body: formData,
        options: data.options,
      });
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const fetchClones = createAsyncThunk("bots/fetchClones", async () => {
  try {
    const response = await botsApi.getClones();
    return response.data;
  } catch (e) {
    console.log("error", e);
  }
});

export const fetchClonesLimits = createAsyncThunk(
  "bots/fetchClonesLimits",
  async () => {
    try {
      const response = await botsApi.getClonesLimits();
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);
export const checkDialogLimit = createAsyncThunk(
  "bots/checkDialogLimit",
  async (id: string) => {
    try {
      const response = await botsApi.checkDialogLimit(id);
      return { data: response.data.limit, id };
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const createUserReplies = createAsyncThunk(
  "bots/createUserReplies",
  async (data: UserRepliesType, thunkAPI) => {
    try {
      const response = await botsApi.createUserReplies(data.body);
      data.dialogId &&
        thunkAPI.dispatch(fetchDialogById({ id: data.dialogId }));
      data.callback && data.callback();
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const editeUserReplies = createAsyncThunk(
  "bots/editeUserReplies",
  async (data: UserRepliesEditBodyType, thunkAPI) => {
    try {
      const response = await botsApi.editeUserReplies(data);
      localStorage.removeItem(getStorageName.chatBotId);
      data.dialogId &&
        thunkAPI.dispatch(fetchDialogById({ id: data.dialogId }));
      data.callback && data.callback();
      return { data: response.data, id: data.id };
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const deleteUserReplies = createAsyncThunk(
  "bots/deleteUserReplies",
  async (data: UserRepliesDeleteBodyType, thunkAPI) => {
    try {
      const response = await botsApi.deleteUserReplies(data.body);
      data.dialogId &&
        thunkAPI.dispatch(fetchDialogById({ id: data.dialogId }));
      data.callback && data.callback();
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);
export const createScenario = createAsyncThunk(
  "bots/createScenario",
  async (data: ScenarioBodyType, thunkAPI) => {
    try {
      const response = await botsApi.createScenario(data);
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);
export const createScenarioReload = createAsyncThunk(
  "bots/createScenarioReload",
  async (data: ScenarioBodyType, thunkAPI) => {
    try {
      const response = await botsApi.createScenario(data);
      if (response.status === 201) {
        thunkAPI.dispatch(fetchDialogById({ id: data.dialog }));
      }
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);
export const editScenario = createAsyncThunk(
  "bots/editScenario",
  async (data: ScenarioEditBodyType, thunkAPI) => {
    try {
      const response = await botsApi.editScenario(data);
      if (response.status === 200) {
        localStorage.removeItem(getStorageName.chatBotId);
      }
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);
export const editScenarioReload = createAsyncThunk(
  "bots/editScenarioReload",
  async (data: ScenarioEditBodyReloadType, thunkAPI) => {
    try {
      const response = await botsApi.editScenario(data);
      if (response.status === 200) {
        localStorage.removeItem(getStorageName.chatBotId);
        thunkAPI.dispatch(fetchDialogById({ id: data.dialogId }));
      }
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const deleteScenario = createAsyncThunk(
  "bots/deleteScenario",
  async (data: DeleteScenarioType, thunkAPI) => {
    try {
      const response = await botsApi.deleteScenario(data.id);
      if (response.status === 204) {
        data.callback();
      }
    } catch (e: any) {
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: e.response.data.detail || textData.alertsMessage.wrong,
        })
      );
    }
  }
);
