import React, { Fragment, useMemo, useState } from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../../../stores/hooks/hooks";
// icons
import { ReactComponent as FilterIcon } from "../../../../../../assets/icons/filter.svg";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
import { marketplaceFilterData } from "./mockData";
// components
import { Menu, Transition } from "@headlessui/react";
import { BadgeFilter } from "./badgeFilter/BadgeFilter";
// slice
import {
  clearPaginationPage,
  clearPathRequest,
  clearToolsList,
  setPathRequest,
  setSelectedCategory,
} from "../../../../../../stores/slice/marketplaceSlice";

export const MarketplaceTypesFilter = () => {
  // hooks
  const dispatch = useAppDispatch();
  // store
  const selectedCategoriesFromStore = useAppSelector(
    (state) => state.marketplace.selectedCategory
  );
  const pathForRequest = useAppSelector(
    (state) => state.marketplace.pathForRequest
  );
  // state
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    selectedCategoriesFromStore
  );
  // helpers
  const toggleCategory = (element: string) => {
    if (selectedCategories.find((item) => item === element)) {
      setSelectedCategories(
        selectedCategories.filter((item) => item !== element)
      );
    } else {
      setSelectedCategories([...selectedCategories, element]);
    }
  };

  const activeFilter = useMemo(() => {
    if (pathForRequest) {
      return marketplaceFilterData.find((item) => item.path === pathForRequest);
    }
  }, [pathForRequest, marketplaceFilterData]);

  return (
    <div className="flex items-center justify-between mt-10 w-full xxl:px-[100px] pl-[126px] pr-[77px]">
      <div className="flex flex-wrap gap-8">
        {selectedCategoriesFromStore.map((item) => (
          <BadgeFilter
            key={item + Math.random().toString()}
            title={item}
            handleClick={() => {
              toggleCategory(item);
              dispatch(setSelectedCategory(item));
            }}
          />
        ))}
        {activeFilter && (
          <BadgeFilter
            key={activeFilter.path + Math.random().toString()}
            icon={activeFilter.icon}
            title={activeFilter.name}
            handleClick={() => {
              dispatch(clearPaginationPage());
              dispatch(clearToolsList());
              dispatch(clearPathRequest());
            }}
          />
        )}
      </div>
      <Menu
        as="div"
        className="relative inline-block text-left self-start rounded-4xl border border-primary-grey bg-transparent"
      >
        <Menu.Button className="flex py-[9px] pl-[14px] pr-[53px] items-center justify-center text-center gap-2 hover:opacity-80">
          <FilterIcon className="w-4 h-4" />
          <span className="font-Inter text-xs font-bold leading-4 capitalize">
            {textData.marketplacePage.filter}
          </span>
        </Menu.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Menu.Items className="z-20 absolute top-[45px] left-0 w-full bg-dark-200 border border-primary-grey py-5 px-4 rounded-md shadow-lg">
            {marketplaceFilterData.map((filter) => {
              return (
                <Menu.Item key={filter.path}>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        dispatch(clearPaginationPage());
                        dispatch(clearToolsList());
                        dispatch(setPathRequest(filter.path));
                      }}
                      className={`${
                        (active || pathForRequest === filter.path) &&
                        "font-bold text-secondary-green"
                      } w-full text-left text-sm font-bold text-dark-400 capitalize`}
                    >
                      {filter.name}
                    </button>
                  )}
                </Menu.Item>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
