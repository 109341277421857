import React, { useMemo } from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
import { useNavigate, useParams } from "react-router-dom";
// data
import { textData } from "../../../../assets/translations/en/textData";
// components
import { ButtonCustom } from "../../../../components/atoms/button/ButtonCustom";
import { ImportTemplateStatusWrapper } from "./importTemplateStatusWrapper/ImportTemplateStatusWrapper";
import { CreateDialogModal } from "../../../../components/organisms/createDialogModal/CreateDialogModal";
// store
import {
  setDataImportTemplate,
  setDialogsModalOpen,
} from "../../../../stores/slice/botsSlice";

export const TemplateAlertWrapper = () => {
  // hooks
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  const navigate = useNavigate();
  // store
  const templatesData = useAppSelector(
    (state) => state.templates.templatesData
  );
  const importTemplateStatus = useAppSelector(
    (state) => state.templates.importTemplateStatus
  );
  // helpers
  const proceedToBuy = async () => {
    if (slug) {
      dispatch(setDataImportTemplate(slug));
      dispatch(setDialogsModalOpen(true));
    }
  };
  // memo
  const selectedTemplateData = useMemo(() => {
    return templatesData.find((item) => String(item.id) === slug);
  }, [templatesData, slug]);

  if (importTemplateStatus && selectedTemplateData) {
    return <ImportTemplateStatusWrapper templateData={selectedTemplateData} />;
  }

  return (
    <section className="max-w-[760px] xl:max-w-[1080px] lg:min-w-[768px] xl:min-w-[1080px] pt-[90px] pb-[60px] px-[70px] xl:pt-[172px] xl:pb-[92px] xl:pr-[202px] xl:pl-[152px] bg-dark-200 border border-primary-grey rounded">
      <h3 className="font-Inter font-extrabold text-center text-5xl leading-8 mb-10 capitalize">
        {textData.templatesPage.alert}
      </h3>
      <div className="font-Inter font-normal text-xl leading-6 mb-[74px]">
        <span>{textData.templatesPage.alertMessage1} </span>
        <span className="capitalize">{selectedTemplateData?.name}</span>{" "}
        <span>{textData.templatesPage.alertMessage2}</span>?
      </div>
      <div className="flex items-center gap-7 mb-8">
        <img src={selectedTemplateData?.image} alt="company logo" width="80" />
        <h6 className="font-Inter font-extrabold text-lg text-left leading-6">
          {selectedTemplateData?.name}
        </h6>
      </div>
      <div className="flex gap-7">
        <p className="w-[80px] font-Inter font-normal text-xl leading-6 capitalize">
          {textData.templatesPage.price}:{" "}
        </p>
        <span className="font-Inter font-extrabold text-lg leading-6">
          {selectedTemplateData?.price}
        </span>
      </div>
      <div className="flex justify-center gap-12 mt-12">
        <ButtonCustom
          variant={"primary"}
          title={textData.btns.cancelBtn}
          handleClick={() => navigate(-1)}
        />
        <ButtonCustom
          variant={"secondary"}
          title={textData.btns.proceedBtn}
          handleClick={proceedToBuy}
          className="text-m font-semibold leading-4"
        />
      </div>
      <CreateDialogModal />
    </section>
  );
};
