import React, { ReactComponentElement, useEffect, useState } from "react";
// icon
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";
import { ReactComponent as ReplyIcon } from "../../../../../assets/icons/reply.svg";
import { ReactComponent as HomeIcon } from "../../../../../assets/icons/home.svg";
import { ReactComponent as BotIcon } from "../../../../../assets/icons/bot.svg";
import { ReactComponent as ButtonsIcon } from "../../../../../assets/icons/buttonsSmall.svg";
// types
import { IconVariantDataType, IconVariantNameType } from "./treeButtonTypes";
import { ChildRepliesType } from "../../../../../stores/types/treeBotTypes";
// helpers
import { branchElement, getOrientation } from "./helper";
// components
import { TreeLevelView } from "../treeLevelView/TreeLevelView";
import { BadgeButton } from "../../../../atoms/badgeButton/BadgeButton";
// data
import { textData } from "../../../../../assets/translations/en/textData";

type Props = {
  handleCallback?: () => void;
  handleEditCallback: () => void;
  handleEndNodeCallback: () => void;
  icon: IconVariantNameType;
  childReplies: ChildRepliesType | null;
  freeText: string;
  preview?: boolean;
};

export const TreeButton = ({
  handleCallback,
  icon,
  childReplies,
  handleEditCallback,
  handleEndNodeCallback,
  freeText,
  preview,
}: Props) => {
  // state
  const [branches, setBranches] = useState<ReactComponentElement<any>[] | null>(
    null
  );
  // data
  const iconVariant: IconVariantDataType = {
    freeText: <ReplyIcon />,
    home: <HomeIcon />,
    bot: <BotIcon />,
    buttons: <ButtonsIcon />,
  };
  // effect
  useEffect(() => {
    if (
      childReplies &&
      childReplies.child_scenario &&
      childReplies.child_scenario.child_replies &&
      childReplies.child_scenario.child_replies.length > 0
    ) {
      const branchOrientations = getOrientation(
        childReplies.child_scenario.child_replies
      );
      setBranches(
        branchOrientations?.map((item) => branchElement(item)) || null
      );
    } else if (
      childReplies &&
      childReplies.child_scenario?.text &&
      childReplies.child_scenario?.reply_type !== 0
    ) {
      setBranches([branchElement("default")]);
    }
  }, [childReplies]);

  if (childReplies) {
    return (
      <div className={`relative w-fit flex flex-col`}>
        <div
          className={`flex justify-center items-center gap-3 flex-col relative ${
            childReplies.child_scenario &&
            ((childReplies.child_scenario.child_replies &&
              childReplies.child_scenario.child_replies.length > 0) ||
              childReplies.child_scenario?.text)
              ? "mb-[96px]"
              : ""
          }`}
        >
          <button
            type="button"
            disabled={preview}
            onClick={handleEditCallback}
            className={`${preview ? "" : "hover:opacity-80"}`}
          >
            <div
              className={`py-2 px-4 xl:py-3 xl:px-6 bg-primary-grey rounded flex gap-2.5 items-center justify-center min-w-[160px] max-w-full`}
            >
              {iconVariant[icon]}
              <span className="font-Inter font-semibold xl:font-bold xl:text-md text-sm leading-5 text-white overflow-hidden text-ellipsis whitespace-nowrap">
                {icon === "buttons" ? childReplies.title : freeText}
              </span>
            </div>
          </button>
          {!preview && (
            <div className="flex justify-center items-center flex-col gap-5">
              <button
                type="button"
                onClick={handleCallback}
                className="w-6 h-6 rounded-full bg-dark-500 flex justify-center items-center hover:opacity-80"
              >
                <PlusIcon />
              </button>
              {!childReplies?.child_scenario && (
                <BadgeButton
                  handleClick={handleEndNodeCallback}
                  title={
                    childReplies.end_message
                      ? textData.tree.nodeEnded
                      : textData.tree.endNode
                  }
                  variant="red"
                />
              )}
            </div>
          )}
          {branches}
        </div>
        {childReplies.child_scenario &&
          (childReplies.child_scenario.reply_type === 0 ||
            childReplies.child_scenario.reply_type === 1) && (
            <TreeLevelView data={childReplies.child_scenario} />
          )}
      </div>
    );
  } else if (freeText) {
    return (
      <div
        className={`flex justify-center items-center gap-3 flex-col relative`}
      >
        <button
          type="button"
          disabled={preview}
          onClick={handleEditCallback}
          className={`${preview ? "" : "hover:opacity-80"}`}
        >
          <div
            className={`py-3 px-6 bg-primary-grey rounded flex gap-2.5 items-center justify-center min-w-[160px] max-w-full`}
          >
            {iconVariant[icon]}
            <span className="font-Inter font-bold text-md leading-5 text-white overflow-hidden text-ellipsis whitespace-nowrap">
              {freeText}
            </span>
          </div>
        </button>
        {branches}
      </div>
    );
  }
  return null;
};
