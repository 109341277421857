import { createAsyncThunk } from "@reduxjs/toolkit";
import chatUserApi from "../api/chatUserApi";
import {
  ChatUserDataType,
  ChatUserReplyType,
  FetchDataBodyType,
  SendMessageBodyType,
} from "../types/chatUserTypes";
import { AxiosResponse } from "axios";
import { WritableDraft } from "immer/src/types/types-external";
// actions
import { clearScenario, saveMessages } from "../slice/chatUserSlice";
// data
import { textData } from "../../assets/translations/en/textData";

export const fetchChatData = createAsyncThunk(
  "chatUser/fetchChatData",
  async (body: FetchDataBodyType) => {
    try {
      const response: AxiosResponse<ChatUserDataType> =
        await chatUserApi.startChatData(body);
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const fetchChatDataById = createAsyncThunk(
  "chatUser/fetchChatDataById",
  async (id: string) => {
    try {
      const response: AxiosResponse<ChatUserDataType> =
        await chatUserApi.fetchChatDataById(id);
      return response.data;
    } catch (e) {
      console.log("error", e);
    }
  }
);

export const sendChatMessage = createAsyncThunk(
  "chatUser/sendChatMessage",
  async (data: SendMessageBodyType, thunkAPI) => {
    try {
      const response: AxiosResponse<WritableDraft<ChatUserReplyType>> =
        await chatUserApi.sendChatMessage(data);
      return response.data;
    } catch (e) {
      thunkAPI.dispatch(
        saveMessages({
          author: 0,
          text: textData.testChatBotPage.startErrorMessage,
          buttons: null,
          error: true,
        })
      );
    }
  }
);
