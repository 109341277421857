// icons
import infoAccTypeIcon from "../../../../../assets/icons/infoAccType.svg";
// types
import { SelectedItemTypeMockData } from "../mockDataTypes";
import { getIconsComponents } from "../../../../../helpers/iconsComponents/iconsComponents";

export type PropsType = {
  setTooltip: (tooltip: 0 | 1 | null) => void;
  handleSelectCard: (value: 0 | 1 | null) => void;
  tooltip: 0 | 1 | null;
  data: SelectedItemTypeMockData;
  isSelected: boolean;
};

export const AccountTypeCard = ({
  setTooltip,
  handleSelectCard,
  tooltip,
  data,
  isSelected,
}: PropsType) => {
  return (
    <div className="flex gap-20">
      <div className="flex flex-col items-center gap-5">
        <button onClick={() => setTooltip(data.value)}>
          <img src={infoAccTypeIcon} alt="check account type" />
        </button>
        <button
          className={`py-[51px] px-14 border ${
            isSelected ? "border-dark-100" : "border-dark-450/[.15]"
          } transition duration-300 ease-in-out rounded-xl active:border-dark-100`}
          onClick={() => handleSelectCard(data.value)}
        >
          <div className="flex items-center justify-center h-[190px] w-[190px] p-[37px] bg-dark-550/[.1] rounded-xl">
            <img src={data.icon.typeIcon} alt="account type icon" />
          </div>
          <h6 className="font-Inter text-lg text-center font-extrabold leading-6 mt-6">
            {data.icon.typeText}
          </h6>
        </button>
      </div>

      {tooltip === data.value && (
        <div className="flex relative">
          <div className="max-w-[503px] mt-8 flex flex-col gap-30">
            <button
              className="p-[14px] absolute top-[-10px] right-[-120px] bg-dark-300 border-primary-grey border-[1px] rounded-full"
              onClick={() => setTooltip(null)}
            >
              {getIconsComponents({
                name: "close",
                className: "fill-primary-light rounded-full",
              })}
            </button>
            <ul className="mt-12 list-disc text-sm leading-5 font-Inter">
              {data.text.map((item) => (
                <li className="ml-4 text-sm leading-5" key={item.id}>
                  {item.title}
                </li>
              ))}
            </ul>
            <h3 className="text-sm text-secondary-green leading-5 mt-24">
              {data.note}
            </h3>
          </div>
        </div>
      )}
    </div>
  );
};
