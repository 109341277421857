import React from "react";
// types
import { ActiveWindowDataType } from "./mockDataTypes";
// components
import { PropertiesWrapper } from "./windowComponents/propertiesWrapper/PropertiesWrapper";
import { WelcomeMessageWrapper } from "./windowComponents/welcomeMessageWrapper/WelcomeMessageWrapper";
import { FreeTextWrapper } from "./windowComponents/freeTextWrapper/FreeTextWrapper";
import { ButtonsScenarioWrapper } from "./windowComponents/buttonsScenarioWrapper/ButtonsScenarioWrapper";
import { DeleteScenarioWrapper } from "./windowComponents/deleteScenarioWrapper/DeleteScenarioWrapper";
import { EndMessageWrapper } from "./windowComponents/endMessageWrapper/EndMessageWrapper";
import { TestChatBot } from "./windowComponents/testChatBot/TestChatBot";

export const activeWindowData: ActiveWindowDataType = {
  properties: <PropertiesWrapper />,
  welcomeMessage: <WelcomeMessageWrapper />,
  endMessage: <EndMessageWrapper />,
  buttons: <ButtonsScenarioWrapper />,
  freeText: <FreeTextWrapper />,
  editFreeText: <FreeTextWrapper />,
  editButtons: <ButtonsScenarioWrapper />,
  deleteScenario: <DeleteScenarioWrapper />,
  chatBot: <TestChatBot />,
};
