import React from "react";
// components
import { MarketPlaceChosenItemPageWrapper } from "./components/marketplaceItemPageWrapper/MarketPlaceChosenItemPageWrapper";
import { Layout } from "../../../components/organisms/layout/Layout";

type Props = {
  isAuth: boolean;
};

const MarketplaceItemPage = ({ isAuth }: Props) => {
  return (
    <Layout mobileApp sideBar={true} noPadding className="bg-bgMarketplace">
      <MarketPlaceChosenItemPageWrapper />
    </Layout>
  );
};

export default MarketplaceItemPage;
