import React, { useEffect, useMemo, useRef } from "react";
// data
import { textData } from "../../../../assets/translations/en/textData";
// hooks
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../stores/hooks/hooks";
// components
import { CloneCard } from "./clonesWrapper/cloneCard/CloneCard";
// slice
import { setToolIdForScroll } from "../../../../stores/slice/marketplaceSlice";

export const ClonesWrapper = () => {
  // hooks
  const dispatch = useAppDispatch();
  // store
  const clones = useAppSelector((state) => state.bots.clones);
  // ref
  const ref = useRef(null);
  const wrapperRef = useRef(null);
  // memo
  const sortedClonesWithChatbotsFirst = useMemo(() => {
    return [...clones].sort((a, b) => {
      return Number(a.from_marketplace) - Number(b.from_marketplace);
    });
  }, [clones]);

  // helpers
  const scrollToMyRef = () => {
    if (ref && ref.current) {
      // @ts-ignore
      ref.current.scrollIntoView(false);
    }
  };
  // effect
  useEffect(() => {
    const timerId = setTimeout(() => scrollToMyRef(), 300);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setToolIdForScroll(null));
    };
  }, [dispatch]);

  if (clones) {
    return (
      <div className="py-2 pr-4 lg:pr-8 border-r-[2px] border-primary-grey h-full">
        <h3 className="font-Inter font-extrabold text-xl lg:text-3xl xl:text-5xl leading-7 text-white capitalize">
          {textData.clones.title}
        </h3>
        <div
          ref={wrapperRef}
          className="mt-10 xl:mt-20 flex flex-col gap-4 lg:gap-5"
        >
          {sortedClonesWithChatbotsFirst.map((clone) => (
            <CloneCard key={clone.id} data={clone} customRef={ref} />
          ))}
        </div>
      </div>
    );
  }
  return null;
};
