// types
import { StatusDataType } from "./mockDataTypes";
// data
import { textData } from "../../../../../../../assets/translations/en/textData";

export const statusData: StatusDataType = {
  active: {
    style: "bg-secondary-green text-dark-700",
    cardStyle: "border-secondary-green shadow-xs",
    title: textData.statuses.model,
  },
  inactive: {
    style: "bg-primary-grey text-white",
    cardStyle: "border-primary-grey",
    title: textData.statuses.model,
  },
};
