import React from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../../../../stores/hooks/hooks";
// store
import { setRatingModal } from "../../../../../../../stores/slice/marketplaceSlice";
import { addRating } from "../../../../../../../stores/thunks/marketplaceThunk";
// data
import { textData } from "../../../../../../../assets/translations/en/textData";
// components
import { ModalWrapper } from "../../../../../../../components/molecules/modalWrapper/ModalWrapper";
import { ButtonCustom } from "../../../../../../../components/atoms/button/ButtonCustom";

export const ConfirmRatingModal = () => {
  // hooks
  const dispatch = useAppDispatch();
  // store
  const ratingModal = useAppSelector((state) => state.marketplace.ratingModal);
  const toolById = useAppSelector((state) => state.marketplace.toolById);
// helpers
  const handleClick = () => {
    if (toolById) {
      dispatch(addRating({ tool_id: toolById.id, rating: ratingModal }));
      dispatch(setRatingModal(0));
    }
  };
  return (
    <ModalWrapper
      open={!!ratingModal}
      onClose={() => dispatch(setRatingModal(0))}
      title={textData.marketplacePage.confirmTitle}
      className="pt-[55px] pb-[44px] pl-[101px] pr-[120px]"
    >
      <h3 className="font-Inter text-start font-normal text-lg leading-6 text-white my-10">
        {textData.marketplacePage.confirmRating}
        {ratingModal}?
      </h3>
      <div className="flex gap-8 justify-start mt-10">
        <ButtonCustom
          variant="primary"
          title={textData.btns.btnDiscard}
          handleClick={() => {
            dispatch(setRatingModal(0));
          }}
        />
        <ButtonCustom
          variant="secondary"
          title={textData.btns.yesBtn}
          handleClick={handleClick}
        />
      </div>
    </ModalWrapper>
  );
};
