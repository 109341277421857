import React from "react";
// types
import { DashboardDataItemType } from "../mockDataTypes";
// helper
import { getIconsComponents } from "../../../../../helpers/iconsComponents/iconsComponents";

type Props = {
  data: DashboardDataItemType;
};
export const DashboardItem = ({ data }: Props) => {
  return (
    <div className="rounded-3xl border border-opacity-15 border-dark-450 py-[18px] px-5 bg-black flex justify-between items-end">
      <div className="flex gap-x-4">
        <div className="w-[46px] h-[46px] rounded-full bg-dark-550/10 flex justify-center items-center">
          {data.icon}
        </div>
        <div className="flex flex-col gap-1.5">
          <span className="font-Inter font-bold text-md text-white">
            {data.value}
          </span>
          <span
            className={`${data.tooltipClass} font-Inter text-sm text-white text-opacity-50 `}
          >
            {data.title}
          </span>
        </div>
      </div>
      <div className="flex gap-x-1 items-center">
        <span className="text-white text-xs font-Inter font-bold">
          {data.percent
            ? data.percent > 0
              ? `+${data.percent}`
              : `${data.percent}`
            : "--"}
        </span>
        {getIconsComponents({
          name: "arrow",
          className:
            data.percent && data.percent > 0
              ? "fill-green-300"
              : "fill-red-300 rotate-180",
        })}
      </div>
    </div>
  );
};
