// data
import { textData } from "../../../../../../assets/translations/en/textData";
// types
import { FieldCustomTypes } from "../../../../../../components/atoms/field/fieldCustomTypes";
import { ModalWrapperBtnType } from "../../../../../../components/molecules/modalWrapper/modalWrapperTypes";

export const buttonsMessageFieldData: FieldCustomTypes = {
  name: "buttonsMessage",
  label: textData.buttonsWrapper.botMessage,
  placeholder: textData.buttonsWrapper.placeholder,
  type: "text",
};

export const discardScenarioBtnData: ModalWrapperBtnType = [
  {
    variant: "primary",
    title: textData.btns.return,
    type: "button",
    callbackName: "discard",
  },
  {
    variant: "secondary",
    title: textData.btns.btnNoSave,
    type: "button",
    callbackName: "notSave",
  },
];
