import React from "react";
// components
import { MarketplacePageWrapper } from "./components/marketplacePageWrapper/MarketplacePageWrapper";
import { Layout } from "../../../components/organisms/layout/Layout";
import { TooltipComponent } from "../../../components/atoms/tooltip/Tooltip";
// data
import { marketplaceTooltipsData } from "./components/marketplacePageWrapper/marketplaceTypesFilter/mockData";
// hooks
import { useTour } from "../../../helpers/hooks/useTour/useTour";

type Props = {
  isAuth: boolean;
};
const MarketplacePage = ({ isAuth }: Props) => {
  const { runTour, handleJoyrideCallback, resetTour } = useTour(
    "seenMarketplaceTooltips",
    "sidebarTourFinished"
  );
  return (
    <>
      <TooltipComponent
        runTour={runTour}
        steps={marketplaceTooltipsData}
        handleJoyrideCallback={handleJoyrideCallback}
        additionalStylesClass="marketplace-tooltip"
      />
      <Layout
        mobileApp
        sideBar
        marketplace
        noPadding
        classNameWrapper="bg-bgMarketplace"
        resetTour={resetTour}
      >
        <MarketplacePageWrapper />
      </Layout>
    </>
  );
};
export default MarketplacePage;
