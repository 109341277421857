import React from "react";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
// component
import { LinkCustom } from "../../../../../../components/atoms/linkCustom/LinkCustom";

export const ForgotPasswordOptions = () => {
  return (
    <section className="grow justify-center pt-[25px] md:pt-[85px] xl:pt-[121px] pr-[30px] md:pr-[73px] xl:pr-[104px] pb-[86px] md:pb-[160px] xl:pb-[241px] pl-[30px] md:pl-[90px] xl:pl-[127px] bg-black order-1 md:order-2">
      <LinkCustom
        link="/"
        icon="aphidLogoLarge"
        variant="default"
        className="flex items-center justify-center md:hidden mb-[66px]"
      />
      <div className="flex flex-col items-center">
        <p className="font-Inter font-medium text-xs xl:text-md leading-5 mb-[35px] md:mb-[50px] xl:mb-[70px]">
          {textData.forgotPasswordPage.option}
        </p>
        <LinkCustom
          variant="secondary"
          title={textData.btns.forgotPasswordBtn}
          link="/forgot-password"
          className="mb-[38px] md:mb-6 xl:mb-[34px]"
        />
        <p className="mb-[148px] md:mb-[66px] xl:mb-[95px] text-center md:text-left text-xxs xl:text-sm">
          {textData.forgotPasswordPage.aphIdDescription}
        </p>
        {/*<LinkCustom*/}
        {/*  variant="secondary"*/}
        {/*  title={textData.btns.forgotAphIdBtn}*/}
        {/*  link="/"*/}
        {/*  className="hidden md:block font-Inter text-md leading-5"*/}
        {/*/>*/}
        <div className="w-full flex justify-center mx-5">
          <div className="w-full xl:min-w-[500px] flex md:hidden items-center justify-between py-2 pl-7 pr-3 bg-black/20 rounded-5xl shadow-m">
            <span className="block shadow-sm text-md font-bold">
              {textData.forgotPasswordPage.needHelp}
            </span>
            <LinkCustom
              variant="secondary"
              title={textData.btns.contactSupportBtn}
              link="/"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
