import React from "react";
// components
import { LinkCustom } from "../../components/atoms/linkCustom/LinkCustom";
// hooks
import { useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen relative flex items-center justify-center">
      <LinkCustom
        link={`..`}
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
        icon="chevronRight"
        variant="default"
        className="absolute top-5 left-5"
        classNameIcon="w-6 h-6 xl:w-10 xl:h-10 fill-secondary-green"
      />
      <span className="text-white text-11xl font-Roboto font-bold">404</span>
    </div>
  );
};
