import { instance } from "../axios/axiosConfig";
// types
import {
  ForgotPasswordUserType,
  RefreshTokenType,
  ResetPasswordBodyType,
  UserSignInType,
  UserSignUpBodyType,
} from "../types/userAuthTypes";

const userAuthApi = {
  userSignUp(body: UserSignUpBodyType) {
    return instance.post(`/users/auth/register/`, body);
  },
  userSignIn(body: UserSignInType) {
    return instance.post(`/users/auth/token/`, body);
  },
  refreshToken(body: RefreshTokenType) {
    return instance.post(`/users/auth/register/`, body);
  },
  forgotPassword(body: ForgotPasswordUserType) {
    return instance.post(`/users/auth/forgot_password/`, body);
  },
  resetPassword(body: ResetPasswordBodyType){
    return instance.post(`/users/auth/reset_password/`, body);
  }
};

export default userAuthApi;
