// helper
import { createValidate } from "../../../../../../helpers/validation/validationHelper";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
// types
import { ForgotPasswordDataType } from "./mockDataTypes";

export const forgotPasswordData: ForgotPasswordDataType = {
  validate: createValidate(["email"]),
  fields: [
    {
      type: "email",
      label: textData.fields.emailForForgotPsw.label,
      placeholder: textData.fields.emailForForgotPsw.placeholder,
      name: "email",
    },
  ],
  initialValues: {
    email: "",
  },
};
