import React from "react";
// components
import { Link } from "react-router-dom";
// data
import { getIconsComponents } from "../../../helpers/iconsComponents/iconsComponents";
// types
import { IconsComponentsItemTypes } from "../../../helpers/iconsComponents/iconsComponentsTypes";
import { LinkCustomVariantType, VariantStylesType } from "./linkCustomType";

type Props = {
  variant: LinkCustomVariantType;
  link: string;
  title?: string;
  icon?: IconsComponentsItemTypes;
  local?: boolean;
  className?: string;
  classNameIcon?: string;
  onClick?: (e:any)=> void
};

export const LinkCustom = ({
  icon,
  variant,
  title,
  link,
  local = true,
  className = "",
                             onClick,
  classNameIcon = "",
}: Props) => {
  const variantStyles: VariantStylesType = {
    primary:
      "capitalize flex items-center justify-center text-center py-2 px-3 xl:py-3 xl:px-6 flex gap-2 items-center rounded font-Inter font-bold text-xs xl:text-base leading-4 hover:opacity-80 bg-primary-grey text-white",
    secondary:
      "capitalize flex items-center justify-center text-center py-2 px-3 xl:py-3 xl:px-6 flex gap-2 items-center rounded font-Inter font-bold text-xs xl:text-base leading-4 hover:opacity-80 bg-secondary-green text-secondary-dark",
    default:
      "capitalize font-Inter font-bold text-xs xl:text-sm leading-8 text-white hover:opacity-80",
    custom: '',
    black: 'py-2 px-4 xl:py-3 xl:px-6 rounded-[20px] border border-white bg-black font-Inter text-xs font-semibold text-white capitalize'
  };

  if (local) {
    return (
      <Link onClick={onClick} to={link} className={` ${variantStyles[variant]} ${className}`}>
        {icon && getIconsComponents({ name: icon, className: classNameIcon })}
        {title}
      </Link>
    );
  }
  return (
    <a
      target="_blank"
      href={link}
      className={`${variantStyles[variant]} ${className}`}
    >
      {icon && getIconsComponents({ name: icon, className: classNameIcon })}
      {title}
    </a>
  );
};
