import React, { Fragment, useEffect } from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../stores/hooks/hooks";
// store
// data
import { textData } from "../../../assets/translations/en/textData";
// components
import { ModalWrapper } from "../../../components/molecules/modalWrapper/ModalWrapper";
import { ButtonCustom } from "../../../components/atoms/button/ButtonCustom";
import {
  setChangePaymentModalOpen,
  setDowngradeNoticeModalOpen,
  setNoPaymentModalOpen,
} from "../../../stores/slice/userProfileSlice";
import { setMembershipProfile } from "../../../stores/thunks/userProfileThunk";
import { useNavigate } from "react-router-dom";
import { getIconsComponents } from "../../../helpers/iconsComponents/iconsComponents";

export const DowngradeNoticeModal = () => {
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // store
  const downgradeNoticeModalOpen = useAppSelector(
    (state) => state.userProfile.downgradeNoticeModalOpen
  );
  const membershipList = useAppSelector(
    (state) => state.payments.membershipList
  );
  const defaultPaymentMethodById = useAppSelector(
    (state) => state.payments.defaultPaymentMethodById
  );
  const billingPeriod = useAppSelector(
    (state) => state.userProfile.membershipBillingPeriod
  );
  const plan = useAppSelector(
    (state) => state.userProfile.membershipSelectedPlan
  );

  useEffect(() => {
    if (!downgradeNoticeModalOpen) {
    }
  }, [downgradeNoticeModalOpen]);

  const setMembership = (plan: string, billingPeriod: string) => {
    if (!defaultPaymentMethodById && plan !== "basic") {
      dispatch(setNoPaymentModalOpen(true));
    } else if (plan === "basic") {
      billingPeriod = "permanent";
      dispatch(
        setMembershipProfile({
          body: {
            membership:
              membershipList && membershipList[plan][billingPeriod]!.id,
          },
          navigate: navigate,
        })
      );
    } else if (defaultPaymentMethodById) {
      dispatch(
        setMembershipProfile({
          body: {
            membership:
              membershipList && membershipList[plan][billingPeriod]!.id,
            payment_method: defaultPaymentMethodById,
          },
          navigate: navigate,
        })
      );
    }
  };

  return (
    <Fragment>
      <ModalWrapper
        open={downgradeNoticeModalOpen}
        onClose={() => dispatch(setDowngradeNoticeModalOpen(false))}
        className="w-[983px] h-[468px] flex flex-col"
        title=""
      >
        <div className="flex flex-col">
          <h3 className="font-Inter text-center font-extrabold text-xl leading-6 text-white mb-10">
            {textData.selectMembership.downgradeNotice}
          </h3>
          <button
            className="p-[14px] absolute top-[20px] right-[30px] border-primary-grey border-[1px] z-[1] rounded-full"
            onClick={() => dispatch(setDowngradeNoticeModalOpen(false))}
          >
            {getIconsComponents({
              name: "close",
              className: "fill-primary-light rounded-full",
            })}
          </button>
          <p className="font-Roboto text-xs m-center pb-8 w-[362px]">
            {textData.selectMembership.downgradeNoticeMsg}
          </p>
          <div className="flex gap-8 justify-center mt-8">
            <ButtonCustom
              variant="primary"
              title={textData.btns.btnDiscard}
              handleClick={() => {
                dispatch(setDowngradeNoticeModalOpen(false));
              }}
            />
            <ButtonCustom
              variant="yellow"
              disabled={!plan}
              title={textData.btns.downgrade}
              handleClick={() => {
                plan && setMembership(plan, billingPeriod);
              }}
            />
          </div>
        </div>
      </ModalWrapper>
    </Fragment>
  );
};
