import React from "react";
// data
import { getIconsComponents } from "../../../helpers/iconsComponents/iconsComponents";
import { textData } from "../../../assets/translations/en/textData";
// components
import { AppLinks } from "../../atoms/appLinks/AppLinks";

export const MobileApp = () => {
  return (
    <div className="md:hidden h-screen flex flex-col justify-center items-center px-5">
      <div className="flex flex-col items-center w-full">
        {getIconsComponents({
          name: "cockroach",
          className: "w-[74px] h-[87px] fill-white",
        })}
        <span className="mt-[58px] mb-[82px] text-xxs text-white font-Inter text-center">
          {textData.mobileAppComponents.title}
        </span>
        <AppLinks />
      </div>
    </div>
  );
};
