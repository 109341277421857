import React, { useEffect } from "react";
// hooks
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../stores/hooks/hooks";
import { useDebounce } from "@uidotdev/usehooks";
//slice
import {
  clearPaginationPage,
  clearToolsList,
  setSearchActiveValue,
  setSearchValue,
} from "../../../../../../stores/slice/marketplaceSlice";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
// icons
import { ReactComponent as SearchIcon } from "../../../../../../assets/icons/search.svg";
// helpers
import { getIconsComponents } from "../../../../../../helpers/iconsComponents/iconsComponents";

export const MarketplaceSearchbar = () => {
  // hooks
  const dispatch = useAppDispatch();
  // store
  const search = useAppSelector((state) => state.marketplace.searchActiveValue);
  const debouncedSearchTerm = useDebounce(search, 300);

  // useEffect
  useEffect(() => {
    dispatch(setSearchValue(search));
    dispatch(clearToolsList());
  }, [debouncedSearchTerm]);

  return (
    <label className="w-full relative block marketplace-search-tooltip">
      <span className="absolute inset-y-0 left-4 flex items-center">
        <SearchIcon />
      </span>
      <input
        className="w-full py-2.5 px-12 bg-dark-550/10 rounded-4xl placeholder:font-Inter placeholder:text-sm placeholder:text-white/50 placeholder:leading-5 placeholder:capitalize"
        placeholder={textData.templatesPage.search}
        type="text"
        name="search"
        autoComplete="marketplace-search-field"
        value={search}
        onChange={(e) => {
          dispatch(setSearchActiveValue(e.target.value));
          dispatch(clearPaginationPage());
          if (!e.target.value) {
            dispatch(setSearchValue(""));
            dispatch(clearToolsList());
          }
        }}
      />
      {debouncedSearchTerm && (
        <button
          className="absolute inset-y-0 right-4 flex items-center"
          type="button"
          onClick={() => {
            dispatch(setSearchActiveValue(""));
            dispatch(setSearchValue(""));
            dispatch(clearToolsList());
            dispatch(clearPaginationPage());
          }}
        >
          {getIconsComponents({
            name: "close",
            className: "fill-primary-light",
          })}
        </button>
      )}
    </label>
  );
};
