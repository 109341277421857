// data
import { textData } from "../../../../../assets/translations/en/textData";
// types
import { listOfBenefitsDataType } from "./mockDataTypes";

export const listOfBenefitsData: listOfBenefitsDataType = [
  {
    title: textData.signUpPage.listOfBenefits.connectAi,
    id: "connectAi",
  },
  {
    title: textData.signUpPage.listOfBenefits.saveTime,
    id: "saveTime",
  },
  {
    title: textData.signUpPage.listOfBenefits.earn,
    id: "earn",
  },
  {
    title: textData.signUpPage.listOfBenefits.payouts,
    id: "payouts",
  },
  {
    title: textData.signUpPage.listOfBenefits.goodbye,
    id: "goodbye",
  },
  {
    title: textData.signUpPage.listOfBenefits.delegate,
    id: "delegate",
  },
  {
    title: textData.signUpPage.listOfBenefits.integrate,
    id: "integrate",
  },
  {
    title: textData.signUpPage.listOfBenefits.tip,
    id: "tip",
  },

  {
    title: textData.signUpPage.listOfBenefits.plan,
    id: "plan",
  },
];
