import { createSlice } from "@reduxjs/toolkit";
import {
  createScenario,
  createScenarioReload,
  createUserReplies,
  deleteScenario,
  editDialog,
  editeUserReplies,
  editScenario,
  fetchDialogById,
} from "../thunks/botsThunk";
// types
import { ActiveWindowItemDataType } from "../../pages/createBotTreePage/components/botSettingsPreview/mockDataTypes";
import {
  ChildRepliesType,
  ChildScenarioType,
  DialogByIdDataType,
  EditButtonsType,
  NewButtonsType,
} from "../types/treeBotTypes";

interface treeBotState {
  isLoading: boolean;
  isLoadingEdit: boolean;
  activeWindow: ActiveWindowItemDataType | null;
  existButtons: ChildRepliesType[];
  newButtons: NewButtonsType[];
  editButtons: EditButtonsType[];
  scenario: ChildScenarioType | null;
  dialogById: DialogByIdDataType | null;
  replyById: ChildRepliesType | null;
  parentReply: string | null;
  parentScenario: string | null;
  deleteButtons: string[];
}

const initialState: treeBotState = {
  isLoading: false,
  isLoadingEdit: false,
  activeWindow: null,
  deleteButtons: [],
  scenario: null,
  replyById: null,
  parentReply: null,
  parentScenario: null,
  dialogById: null,
  newButtons: [],
  existButtons: [],
  editButtons: [],
};
export const treeBotSlice = createSlice({
  name: "treeBot",
  initialState,
  reducers: {
    setActiveWindow: (state, action) => {
      state.activeWindow = action.payload;
    },
    setParentReply: (state, action) => {
      state.parentReply = action.payload;
    },
    setParentScenario: (state, action) => {
      state.parentScenario = action.payload;
    },
    setExistButtons: (state, action) => {
      state.existButtons = action.payload;
    },
    setReplyById: (state, action) => {
      state.replyById = action.payload;
    },
    setEditButtons: (state, action) => {
      const currentItem = state.editButtons.find(
        (item) => item.id === action.payload.id
      );
      if (currentItem) {
        state.editButtons = state.editButtons.map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
      } else {
        state.editButtons = [...state.editButtons, action.payload];
      }
      state.existButtons = state.existButtons.map((item) =>
        item.id === action.payload.id
          ? {
              ...item,
              title: action.payload.body.title,
              button_text: action.payload.body.button_text,
            }
          : item
      );
    },
    setNewButtons: (state, action) => {
      const currentItem = state.newButtons.find(
        (item) => item.id === action.payload.id
      );
      if (currentItem) {
        state.newButtons = state.newButtons.map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
      } else {
        state.newButtons = [...state.newButtons, action.payload];
      }
    },
    setDeleteExistButtons: (state, action) => {
      state.deleteButtons = [...state.deleteButtons, action.payload];
      state.existButtons = state.existButtons.filter(
        (item) => item.id !== action.payload
      );
    },
    setDeleteNewButtons: (state, action) => {
      state.newButtons = state.newButtons.filter(
        (item) => item.id !== action.payload
      );
    },
    clearNewBtn: (state, action) => {
      state.newButtons = state.newButtons.filter(
        (item) => item.id !== action.payload
      );
    },
    setScenarioActive: (state, action) => {
      state.scenario = action.payload;
    },
    clearButtons: (state) => {
      state.newButtons = initialState.newButtons;
      state.existButtons = initialState.existButtons;
      state.editButtons = initialState.editButtons;
      state.deleteButtons = initialState.deleteButtons;
    },
    clearScenario: (state) => {
      state.scenario = initialState.scenario;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createUserReplies.pending, (state, action) => {
      state.isLoadingEdit = true;
    });
    builder.addCase(createUserReplies.fulfilled, (state, action) => {
      state.isLoadingEdit = false;
    });
    builder.addCase(createScenario.fulfilled, (state, action) => {
      state.scenario = action.payload;
    });
    builder.addCase(createScenarioReload.fulfilled, (state, action) => {
      state.scenario = action.payload;
    });
    builder.addCase(editScenario.fulfilled, (state, action) => {
      if (state.scenario) {
        state.scenario = {
          ...state.scenario,
          text: action.payload.text,
          reply_type: action.payload.reply_type,
        };
      } else {
        state.scenario = action.payload;
      }
    });
    builder.addCase(deleteScenario.fulfilled, (state) => {
      state.scenario = initialState.scenario;
    });
    builder.addCase(fetchDialogById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDialogById.fulfilled, (state, action) => {
      state.dialogById = action.payload;
      state.isLoading = false;
    });
    builder.addCase(editDialog.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editDialog.fulfilled, (state, action) => {
      state.dialogById = state.dialogById
        ? { ...state.dialogById, start_message: action.payload?.start_message }
        : action.payload;
      state.isLoading = false;
    });
    builder.addCase(editeUserReplies.pending, (state, action) => {
      state.isLoadingEdit = true;
    });
    builder.addCase(editeUserReplies.fulfilled, (state, action) => {
      state.isLoadingEdit = false;
    });
  },
});

export const {
  setExistButtons,
  clearNewBtn,
  setEditButtons,
  setNewButtons,
  setActiveWindow,
  setDeleteExistButtons,
  setDeleteNewButtons,
  setScenarioActive,
  setParentReply,
  setReplyById,
  clearScenario,
  clearButtons,
} = treeBotSlice.actions;
