import React from "react";
// hooks
import { useAppSelector } from "../../../../../stores/hooks/hooks";
// data
import { textData } from "../../../../../assets/translations/en/textData";
// components
import { DialogCard } from "./dialogCard/DialogCard";

export const DialogsWrapper = () => {
    // store
  const dialogs = useAppSelector((state) => state.bots.dialogs);

  if (dialogs) {
    return (
      <div className="py-2 pl-4 lg:pl-8 h-full">
        <h3 className="font-Inter font-extrabold text-xl lg:text-3xl xl:text-5xl leading-7 text-white capitalize">
          {textData.dialogs.title}
        </h3>
        <div className="mt-10 xl:mt-20 flex flex-col gap-4 lg:gap-5">
          {dialogs.map((dialog) => (
            <DialogCard key={dialog.id} data={dialog} />
          ))}
        </div>
      </div>
    );
  }
  return null;
};
