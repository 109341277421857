// image
import bitmap from "../../../../assets/img/bitmap.png";

// data
import { textData } from "../../../../assets/translations/en/textData";
// components
import { LinkCustom } from "../../../../components/atoms/linkCustom/LinkCustom";
import React from "react";

export const SignInContent = () => {
  const bgImage = {
    background: `url(${bitmap}) no-repeat center, black 0px 0px / 100% 100% no-repeat`,
    backgroundSize: "cover",
  };
  return (
    <div
      className="grow flex flex-col h-[40vh] md:h-full justify-between relative p-8 xl:p-[49px] order-2 md:order-1"
      style={bgImage}
    >
      <LinkCustom
        link="/"
        icon="aphidLogoLarge"
        variant="default"
        className="hidden md:block"
      />
      <div>
        <div className="flex flex-col items-center gap-0 md:gap-[45px]">
          <span className="hidden md:block text-center font-Inter uppercase shadow-sm text-xs font-bold leading-[8px] tracking-[3.5px]">
            {textData.signUpPage.another}
          </span>
          <div className="md:mb-[106px] w-full sm:w-[358px] flex items-center justify-between md:py-[8px] md:pl-[30px] md:pr-[12px] bg-transparent md:bg-dark-950 rounded-5xl shadow-m">
            <span className="block shadow-sm text-md font-bold">
              {textData.signUpPage.doNotHaveAccount}
            </span>
            <LinkCustom
              variant="secondary"
              title={textData.btns.signUp}
              link="/signup"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
