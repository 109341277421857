import React from "react";
// image
import SignUpBgImage from "../../../../../assets/img/getCertified.png";
// components
import { LinkCustom } from "../../../../../components/atoms/linkCustom/LinkCustom";
// data
import { textData } from "../../../../../assets/translations/en/textData";
import { listOfBenefitsData } from "./mockData";

export const SignUpContent = () => {
  const bgImage = {
    backgroundImage: `url(${SignUpBgImage})`,
  };

  return (
    <div
      className="grow bg-no-repeat bg-center bg-cover h-[50vh] md:h-full col-start-1 col-end-21 md:col-start-1 md:col-end-12 order-2 md:order-1"
      style={bgImage}
    >
      <div className="flex flex-col items-center justify-center md:justify-between md:pt-[50px] md:pb-[166px] px-[45px]  lg:px-[80px] h-full">
        <LinkCustom
          link="/"
          icon="aphidLogoLarge"
          variant="default"
          className="hidden md:block"
        />
        <ul className="hidden md:block list-disc text-sm leading-5 font-Inter max-w-[500px]">
          {listOfBenefitsData.map((benefit) => {
            return <li key={benefit.id}>{benefit.title}</li>;
          })}
        </ul>
        <ul className="block md:hidden list-disc text-2xxs leading-[13px] font-Inter space-y-2">
          {listOfBenefitsData.slice(0, 7).map((benefit) => {
            return <li key={benefit.id}>{benefit.title}</li>;
          })}
        </ul>
        <div className="hidden md:flex w-full justify-center">
          <div className="w-[354px] xl:min-w-[500px] flex items-center justify-between py-2 pl-7 pr-3 bg-dark-200 rounded-5xl shadow-m">
            <span className="block shadow-sm text-md font-bold">
              {textData.signUpPage.haveAccount}
            </span>
            <LinkCustom
              variant="secondary"
              title={textData.btns.signIn}
              link="/signin"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
