// types
import { CreateTemplateDataType } from "./mockDataTypes";
// data
import { textData } from "../../../../assets/translations/en/textData";
// helper
import { createValidate } from "../../../../helpers/validation/validationHelper";

export const createTemplateFieldsData: CreateTemplateDataType = {
  validate: createValidate(["name", "price", "image_url"]),
  fields: [
    {
      type: "text",
      label: "",
      placeholder: textData.fields.templateName.placeholder,
      name: "name",
    },
    {
      type: "text",
      label: "",
      placeholder: textData.fields.price.placeholder,
      name: "price",
    },
  ],
  initialValues: {
    name: "",
    price: "",
    image_url: "",
  },
};
