export const textData = {
  confirm: "confirm",
  alertsMessage: {
    emailConfirm: "Link was sending to your email, you could check that",
    wrong: "Something went wrong..",
    passwordUpdated: "Password updated successfully!",
    emailConfirmationResent: "Email confirmation was resent!",
    emailConfirmation:
      "Email confirmation was sent! Please check your email address.",
    profileUpdated: "Profile updated successfully!",
    profileDeleted: "Profile deleted!",
    paymentAdded: "Payment method added successfully!",
    paymentRemoved: "Payment method removed successfully!",
    accountType: "Account type selected successfully!",
    paymentSuccess: "Template purchased successfully!",
    success: "Completed successfully!",
    bookmark: "Saved status has been changed successfully!",
    copy: "Script copied successfully!",
    deleteScenarioSuccess: "Scenario was deleted!",
    notFound: "No items found matching your request :(",
  },
  dashboardPage: {
    timeSaved: "Time Saved",
    earnings: "Earnings",
    items: {
      balance: "Balance",
      credit: "Credits",
      timesheet: "Timesheet",
    },
    summary: {
      title: "Summary",
      week: "Week",
      month: "Month",
      day: "Day",
    },
    table: {
      activityClone: "Recent Clone Activity",
      allClone: "See All",
      earnings: "Earnings",
    },
  },
  welcomePage: {
    title: "Welcome",
    description1: "Hello ",
    description2:
      ", you have taken the first step to creating your first chatbot for your website. A chatbot is a virtual assistant that operates 24/7 on your website. Use this chatbot as an automated customer support and sales agent. ",
  },
  statuses: {
    model: "model",
  },
  mobileAppComponents: {
    title:
      "The Aphid Control Panel is optimized for Desktop and Tablet only. Please download the Aphid Mobile app from the App Store below. ",
  },
  signUpPage: {
    title: "sign up",
    description: "Create your Aphid account by registering your Aphid ID",
    haveAccount: "Already have an account?",
    doNotHaveAccount: "Don’t have an account?",
    listOfBenefits: {
      connectAi:
        "Connect AI tools from the marketplace. Developed by developers. No coding required from you",
      saveTime:
        "Save time by using your personalized AI (clones) to handle lower level tasks online",
      earn: "Earn money in endless ways including automated trading bots, virtual assistant sales on websites, and more",
      payouts: "Same-day payouts",
      goodbye: "Say goodbye to schedules",
      delegate:
        "By delegating labor to clones you'll be able to spend more time with friends and family",
      integrate: "Integrate your skills into your clones",
      tip: "Get tips by adding subject matter expertise to requests",
      plan: "The plan you choose determines how often your clones work",
    },
    errorMessage: "Something went wrong. Please try again.",
    or: "or",
    another: "another you works for you",
  },

  signInPage: {
    title: "Sign In with",
    aphid: "Aphid ID",
    description: "Take the next step and sign in to your account",
    errorMessage: "Something went wrong. Please try again.",
    verificationTitle: "Phone Verification",
    verificationSubtitle: "Enter the verification code sent to",
    notReceiveCode: "I did not receive a code",
  },
  forgotPasswordPage: {
    title: "forgot password",
    description: "Reset your Aphid ID password ",
    note: "Note: If you login with Google, or Apple, you must reset your password on their website",
    needHelp: "Need Help?",
    option: "Please select an option",
    aphIdDescription:
      "If you login with Google, or Apple, you must reset your password on their website",
  },
  resetPasswordPage: {
    title: "reset password",
  },
  chooseAccountTypePage: {
    title: "Choose Account Type",
    description: "Select the account type below",
    note: {
      business:
        "Note: Choose this account if you are a Small-Medium Sized Business (SMB) or Enterprise, otherwise switch to the 'Personal' account type. If you want your Marketplace submissions to be registered to a business account instead of a personal account, choose this account type.",
      personal:
        "Note: Choose this account if you are an individual or developer, otherwise switch to the 'Business' account type if you want your Marketplace submissions to be registered to a Business account instead.",
    },
    benefitListForBusiness: {
      assist:
        "Virtual Assistant for your website. Provide 24/7 support, increase sales by 67%, and reduce operational costs by 30%",
      certified:
        "Certified Aphid Controllers assist your bots for human input during customer interactions in chatbot window on your website",
      plugins:
        "Develop plugins, aClones, and more for the Aptera Marketplace (PRO)",
      microsurveys: "Microsurveys",
    },
    benefitListForPersonal: {
      shop: "Shop for digital bots (aClones) and add-ons from the marketplace. No coding required from you",
      money:
        "Earn money in endless ways including automated trading, data entry, and more",
      save: "Save time by using your aClones to handle lower level tasks online",
      payouts: "Same-day payouts",
      say: "Say goodbye to schedules",
      delegate:
        "By delegating labor to aClones you'll be able to spend more time with friends and family",
      skills: "Integrate your skills into your aClones",
      tips: "Get tips by adding subject matter expertise to requests",
      plan: "The plan you choose determines how often your aClones work",
      plugins: " Develop plugins, aClones, and more for the Marketplace (PRO)",
    },
  },
  clones: {
    title: "active clones",
    helper: "Your chatbots will appear here when you add them.",
    card: {
      profit: "profit",
      transactions: "transactions",
      interactions: "interactions",
    },
    profit: "Profit",
    transactions: "Transactions",
    interactions: "Interactions",
    actionsTitle: "confirm your action",
    actionsDescription:
      "Choose the desired action you want to perform with this clone",
    deleteTitle: "please confirm",
    deleteDescription:
      "Are you sure you want to delete this bot? This action cannot be undone, and all data associated with the bot will be permanently removed.",
    duplicateDescription: "Are you sure you want to duplicate this bot?",
  },
  createNewClone: {
    title: "create new clone",
    titleEdit: "edit clone",
    titleChoosing: "create",
    addLogo: "Add Logo or Avatar",
    recommendText:
      "Recommended minimum size: 150x150 pixels (1:1 aspect ratio) and less than 500KB.",
    uploadImage: "upload image",
    brandColor: "brand color",
    dragFiles: "Drag files to upload",
    statusUploading: "uploading...",
    kb: "kb",
    defaultName: "your name",
    done: "done",
  },
  dialogs: {
    title: "dialogs",
    helper: "Chatbot dialogs will appear here.",
    card: {},
    actionsDescription:
      "Choose the desired action you want to perform with this dialog",
    duplicateDescription: "Are you sure you want to duplicate this dialog?",
    deleteDescription:
      "Are you sure you want to delete this dialog? This action cannot be undone, and all data associated with the dialog will be permanently removed.",
  },
  createNewDialog: {
    title: "add dialog",
    titleEdit: "edit dialog",
  },
  settings: {
    title: "Profile settings",
    edit: "Edit",
    accountInformation: "Account information",
    fullName: "Full name",
    gender: "Gender",
    date: "Date of birth",
    contactDetails: "Contact details",
    email: "Email address",
    phone: "Phone number",
    aphidId: "Aphid ID",
    loginDetails: "Login details",
    currentPassword: "Current password",
    changePassword: "Change Password",
    securityQuestions: "Security questions",
    twoStepVerification: "2-Step verification",
    subscriptionPlan: "Subscription Plan and Transaction History",
    changePlan: "Change Plan",
    countryAndLanguage: "Country and Language",
    region: "Region",
    language: "Language",
    linkedAccounts: "Linked Accounts",
    addAccount: "Add Account",
    transactionHistory: "Transaction History",
    addCard: "Add Debit Card or Credit Card",
    addPayPal: "Add PayPal",
    addApplePay: "Add Apple Pay",
    remove: "Remove",
    removePayment: "Are you sure you want to remove this method?",
    change: "Change",
    changePayment: "Change default payment",
    primary: "Primary",
    makePrimary: "Make Primary",
    addPayment: "Add Payment Method",
    deleteAccount: "Delete Account",
    deleteAccountTitle: "We hate to see you go!",
    deleteAccountSubtitle: "Are you sure you want to delete your account?",
    deleteAccountMsg:
      "Once you delete your account,  all of your settings and parameters will be lost. You will only be able to delete your account after your withdraw-able balance is $0.00 USD. You will have 14 days to reactivate your account.",
    downgradeToBasic: "Downgrade to Basic (Free)",
  },
  transactions: {
    title: "Transaction History",
    name: "Name",
    transaction: "Transaction",
    fee: "Fee",
    qty: "Qty",
    date: "Date",
    payout: "Payout",
    status: "Status",
    period: "Select period",
    noTransactions: "No transactions",
    backToSettings: "Back to Settings",
  },
  confirmEmail: {
    titleVerify: "Verify Email Address",
    subtitleVerify: "Please verify your email address sent to ",
    subtitleVerifyCheck:
      "Check Spam/Junk if you do not see the email in your inbox.",
    titleVerified: "Email Address Verified",
    subtitleVerified: "Your email address has been verified successfully.",
  },
  sideBar: {
    dashboard: "dashboard",
    clones: "clones",
    analytics: "analytics",
    activity: "activity",
    network: "network",
    hostsNetwork: "hosts",
    developerPortal: "developer portal",
    operators: "operators",
    templates: "create templates",
    settings: "settings",
    logOut: "log out",
    prompt: "Are you sure you want to log out of your profile?",
  },
  header: {
    testChatBot: "test chatbot",
    exportTemplate: "export template",
    deploy: "deploy",
    addNewBot: "add new bot",
    importBotTemplate: "import dialog template",
  },
  btns: {
    btnSelect: "select",
    btnDiscard: "discard",
    btnSave: "save",
    return: "return",
    btnNoSave: "don't save",
    addBtn: "add button...",
    example: "button",
    signIn: "sign in",
    signInGoogle: "sign in with google",
    signInApple: "sign in with apple",
    signInFacebook: "Sign In with Facebook",
    preview: "preview",
    view: "view",
    signUp: "sign up",
    logOut: "log out",
    backBtn: "back",
    nextBtn: "next",
    continueBtn: "continue",
    forgotPasswordBtn: "forgot password",
    forgotAphIdBtn: "forgot aphID",
    contactSupportBtn: "contact support",
    subscribe: "subscribe",
    submitAndContinue: "submit and continue",
    sendBtn: "send",
    getStarted: "get started",
    contactSales: "Contact Sales",
    current: "Current",
    downgrade: "Downgrade",
    purchase: "Purchase",
    addDialogTree: "add new dialog",
    editDialogTree: "edit dialog tree",
    createNewClone: "create new clone",
    createNewDialogs: "create new dialogs",
    createDialog: "create dialog",
    copyCodeBtn: "copy code",
    setupGuideBtn: "setup guide",
    importTemplateBtn: "import template",
    cancelBtn: "cancel",
    proceedBtn: "proceed",
    exportTemplate: "export template",
    yesBtn: "yes",
    editBtn: "edit",
    duplicateBtn: "duplicate",
    deleteBtn: "delete",
    openBtn: "open",
    backToClonesBtn: "back to clones",
    acknowledgeBtn: "acknowledge",
    createChatBotClone: "create chatbot clone",
    selectFromMarketplace: "select from network",
    upgradeToPro: "upgrade to pro",
    exploreBtn: "explore",
    submitToolBtn: "submit a tool",
    addMarketplace: "add",
    openInClonesMarketplace: "open in clones",
    loadMore: "load more",
    report: "report",
    submit: "submit",
    use: "use",
    yesRemove: "Yes, Remove",
    resendLink: "Resend Link",
    goToDashboard: "Go to Dashboard",
  },
  fields: {
    email: {
      label: "email",
      placeholder: "enter your email",
    },
    emailForForgotPsw: {
      label: "enter email address",
      placeholder: "enter your email",
    },
    password: {
      label: "password",
      placeholder: "enter your password",
    },
    confirmPassword: {
      label: "confirm password",
      placeholder: "confirm your password",
    },
    oldPassword: {
      label: "old password",
      placeholder: "enter your old password",
    },
    newPassword: {
      label: "new password",
      placeholder: "enter your new password",
    },
    confirmNewPassword: {
      label: "confirm new password",
      placeholder: "confirm new password",
    },
    fullName: {
      label: "full name",
      placeholder: "enter your full name",
    },
    userName: {
      label: "username",
      placeholder: "create your username",
    },
    phone_number: {
      label: "Phone number",
      placeholder: "+xxx xxx xxx xxx",
    },
    gender: {
      label: "gender",
    },
    birth_date: {
      label: "Date of birth",
    },
    nameClone: {
      placeholder: "name your bot",
    },
    descriptionClone: {
      placeholder: "description",
    },
    nameDialog: {
      placeholder: "name",
    },
    descriptionDialog: {
      placeholder: "short description",
    },
    price: {
      placeholder: "price",
    },
    templateName: {
      placeholder: "template name",
    },
  },
  deleteScenario: {
    title: "Are you sure you want to delete this node?",
  },
  properties: {
    title: "properties",
    freeText: "free text",
    freeTextDescribe: "Allow your customers to give open ended answers",
    buttons: "buttons",
    buttonsDescribe: "Let customers choose from multiple options",
    microSurveys: "micro surveys",
    microSurveysDescribe: "Get feedback from customers",
    upsell: "upsell",
    upsellDescribe: "Recommend featured products",
    blockStyle: "block style",
  },
  welcomeMessage: {
    title: "welcome message",
    placeholder:
      'Example “Hello, welcome to our website, how can we help you?"',
  },
  endMessage: {
    title: "ending message",
    smallTitle: "bot message",
    placeholder:
      "Thanks for coming to the website. Please let us know how we can assist you further. ",
  },
  buttonsWrapper: {
    title: "button",
    botMessage: "bot message:",
    userResponse: "user response",
    titleBtn: "title:",
    placeholder: "Hello, welcome to our website. What may I help you with?",
    headerPlaceholder: "DashboardPage website",
    prompt: "You haven't saved the scenario. Are you sure you want to leave?",
  },
  freeTextWrapper: {
    title: "free text",
    botMessage: "bot message:",
    placeholder: "What is your Zip Code?",
  },
  tree: {
    startMessage: "Default Welcome Message",
    botResponse: "Bot Response",
    endNode: "end node",
    nodeEnded: "node ended",
  },
  footer: {
    placeholder: "Enter your e-mail",
    subscribe: "Subscribe to updates",
    followUs: "Follow us",
    privacyPolicy: "Privacy Policy",
    license: "License",
    api: "API",
    copy: "© 2022. All rights reserved. Aphid AI Inc.",
    english: "English",
    followLinks: {
      twitter: "",
      tiktok: "",
      instagram: "",
      facebook: "",
      linkedIn: "",
      discord: "",
      youTube: "",
      telegram: "",
    },
    linksData: {
      marketplace: {
        title: "network",
        links: {
          explore: "explore",
          helpCenter: "help center",
          operatorLogin: "operator login",
          aboutUs: "about us",
          hostsStatus: "hosts status",
        },
      },
      community: {
        title: "community",
        links: {
          developers: "developers",
          events: "events",
          support: "support",
        },
      },
      // region: {
      //   title: "region",
      //   links: { global: "The global automation network" },
      // },
      downloadMobileApps: {
        title: "download the mobile app",
        links: {
          appStore1: "Download on the",
          appStore2: "app store",
          googlePlay1: "get it on",
          googlePlay2: "google play",
        },
      },
    },
  },
  deployPage: {
    heading: "where do you host Your website?",
    hireAgent: "hire agent",
    description:
      "Copy and Paste the Javascript code into your website to deploy your chatbot",
    wordpress: "wordpress",
    wix: "wix",
    shopify: "shopify",
    squarespace: "squarespace",
    other: "other",
    copied: "copied",
  },
  templatesPage: {
    modalHeading: "select the dialog where the bot template will be imported",
    selectDialog: "choose a dialog",
    heading: "Get Started with a Template",
    search: "search",
    feat: "featured",
    save: "saved",
    purchased: "purchased",
    alert: "alert",
    price: "price",
    alertMessage1: "Are you sure you want to import the",
    alertMessage2: "template",
    successImport: "has been imported successfully.",
    success: "success",
    warning: "warning",
  },
  exportTemplatePage: {
    title: "create template",
    helper: "There are currently no templates available",
  },
  testChatBotPage: {
    endChat: "Are you sure you want to end this Chat?",
    chatEnded: "end chat",
    saveTranscript: "save transcript",
    startNewChat: "start new chat",
    controlledOn: "controlled on",
    deletedSuccessfully: "Chat has been ended!",
    sureToStartNewChat: "Are you sure you want to end this Chat and start new?",
    typeMessageBtn: "Type your message...",
    startErrorMessage: "Something went wrong. Could you please try later.",
  },
  subscriptionPlansPage: {
    compare: "compare plans",
    monthly: "monthly",
    annual: "annual",
    renew: "Plan automatically renews monthly.",
    recommended: "recommended",
    basic: "basic",
    personal: "personal",
    business: "small medium - sized business",
    enterprise: "enterprise",
    listPlanBasic: {
      creds25: "25 Credits per month",
      aimarket: "AI Marketplace",
      idverif: "Human ID Verification",
    },
    listPlanPersonal: {
      creds100: "100 Credits per month",
      aimarket: "AI Marketplace",
      idverif: "Human ID Verification",
    },
    listPlanMedium: {
      creds500: "500 Credits per month",
      aimarket: "AI Marketplace",
      idverif: "Human ID Verification",
      support: "Customer Support Website Chatbot System",
    },
    listPlanEnterprise: {
      credsUnlim: "Unlimited Credits",
      aimarket: "AI Marketplace",
      idverif: "Human ID Verification",
      support: "Customer Support Website Chatbot System",
    },
    planDetails: "see full plan details",
  },
  selectMembership: {
    title: "Select Your Membership",
    subtitle: "Choose the plan that works best for your needs",
    monthly: "monthly",
    annual: "annual",
    popular: "Popular",
    basic: "Basic",
    personal: "Personal",
    medium: "Small Medium-Sized Business",
    enterprise: "Enterprise",
    select: "Select A Plan",
    standard: "Standard",
    upgraded: "Upgraded Pack",
    selectedPlan: "Selected Plan",
    billingPeriod: "Billing Period",
    price: "Price",
    month: "month",
    year: "year",
    startingAt: "Starting at",
    free: "Free",
    unlimited: "Unlimited",
    credits: "Credits (Monthly)",
    aiMarketplace: "AI Marketplace",
    aphidId: "Aphid ID",
    web3Components: "Web3 Components",
    automatedCustomer: "Automated Customer Support Website Clone Bots",
    aiChatbotAgents: "AI Chatbot Agents",
    analytics: "Analytics",
    cloneBots: "Clone bots run simultaneously and automatically",
    connectMachine: "Connect Machine and Deep Learning Models",
    dlModels: "(Gamburian, GPT, Gemini, etc.)",
    whiteGlove: "White Glove Client Success Manager Support",
    humanOperators: "Human Aphid Operators",
    downgradeNotice: "Downgrade Notice",
    selectMembership: "Select Membership",
    selectMembershipMsg: "Are you sure you want to select this membership?",
    downgradeNoticeMsg:
      "You are about to downgrade your subscription plan. Certain features and data will be disabled or removed. Your Chatbot data will remain in your account until you re-upgrade your plan. Are you sure you want to continue?",
    planRenews:
      "Plan automatically renews monthly or annually. Prices and features are subject to change.",
    requires:
      "*Requires mid-size plan upgrade to integrate Machine Learning and Deep Learning API’s and to run bots automatically.",
    disclaimer:
      "Disclaimer: Third party tools may be subject to additional pricing",
    success: "Membership successfully updated",
  },
  marketplacePage: {
    confirmRating: "Do you really want to rate it ",
    unInstallTool: "Are you sure you want to remove this tool? ",
    confirmTitle: "please confirm",
    category: "category",
    filter: "filter",
    ubxp: "ubxp",
    aphid: "aphid",
    sponsored: "sponsored",
    report: "Report",
    uninstall: "Uninstall in Clones",
    description: "description",
    rateThis: "rate this",
    loginToRate: "login to rate this",
    sourceMap: "aphid source map",
    gpt: "Chat GPT 3.5",
    ingUrl: "img.url394039",
    operatorsUsing: "Operators in Aphid Network Using this Tool",
    reviews: "Reviews",
    defaultUsername: "User name",
    rating: "rating",
    addReview: "Add review",
    free: "free",
    filters: {
      installed: "installed",
      free: "free",
      premium: "premium",
      new: "new",
      popular: "popular",
      topRated: "top rated",
    },
  },
  cloneByIdFrame: {
    title: "Disclaimer",
    terms: "Terms of Service & Conditions ",
    description1:
      "You have been routed to the Host of the tool in our automation network. We are not responsible for any third party content, pricing, or functionality. Visit our  ",
    description2: "page for more information. ",
    frame: "Iframe not found",
    notWorking: "If the above link is not working",
    redirectLink: "Continue to Redirect",
  },
  activityPage: {
    tabs: {
      tasks: "tasks",
      colony: "colony",
      getHelp: "get help",
    },
    tasksTab: {
      heading:
        "Activity displays your active Clones running tasks in the system.",
      thead: {
        first: "clone",
        second: "status",
        third: "earnings",
      },
    },
    colonyTab: {},
    itemClickedPage: {
      breadCrumb1: "Activity",
      breadCrumb2: "Transaction Details",
      orderData: {
        orderTotal: "order total",
        payout: "payout",
        host: "host",
        duration: "Duration of task",
        time: "Time of task",
        date: "date",
        viewExplorer: " View on Explorer (If applicable)",
      },
    },
  },
};
