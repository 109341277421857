import React, { ReactComponentElement } from "react";
// icon
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
type Props = {
  children: any;
  className?: string;
  title?: string;
  icon?: ReactComponentElement<any>;
  deleteCallback?: () => void;
};

export const BlackWrapper = ({
  children,
  className,
  title,
  icon,
  deleteCallback,
}: Props) => {
  return (
    <div className="fixed w-screen h-screen top-0 left-0 z-10">
      <section
        className={`overflow-y-auto scroll-none max-h-[70vh] p-[26px] lg:p-8 absolute right-8 lg:right-14 top-[112px] lg:top-[140px] min-h-content shadow-sm border border-primary-grey rounded bg-dark-200 w-[357px] xl:w-[480px] ${className}`}
      >
        <div className="flex items-center justify-between mb-4 lg:mb-8">
          <div className="flex gap-3 items-center">
            <h2 className="font-Inter font-extrabold leading-none text-xl xl:text-7xl capitalize">
              {title}
            </h2>
            {icon && icon}
          </div>
          {deleteCallback && (
            <button onClick={deleteCallback} className="w-[22px] h-[22px] xl:w-[32px] xl:h-[32px]">
              <DeleteIcon className="w-[22px] h-[22px] xl:w-[32px] xl:h-[32px]" />
            </button>
          )}
        </div>
        {children}
      </section>
    </div>
  );
};
