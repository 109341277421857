import React, { useState } from "react";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
import { brandColorData } from "./mockData";
// components
import { BadgeButton } from "../../../../../../components/atoms/badgeButton/BadgeButton";
import { getIconsComponents } from "../../../../../../helpers/iconsComponents/iconsComponents";

type Props = {
  name: string;
  avatar: string;
  setFieldValue: (value: string) => void;
  brandColor: string;
};

export const BrandColorWrapper = ({
  name,
  avatar,
  setFieldValue,
  brandColor,
}: Props) => {
  // state
  const [brandColorOpen, setBrandColorOpen] = useState(false);
  return (
    <div className="mt-1">
      <h5 className="font-Inter text-start font-extrabold text-xs xl:text-lg leading-6 text-white mb-3 capitalize">
        {textData.createNewClone.brandColor}
      </h5>
      <div className="flex justify-between items-center relative">
        <BadgeButton handleClick={() => setBrandColorOpen(true)}>
          <div className="flex items-center gap-2.5">
            <div
              className={`h-4 w-4 xl:w-6 xl:h-6 hover:opacity-80 rounded-xs xl:rounded-sm ${brandColorData[brandColor]}`}
            />
            <p className="font-Inter font-normal text-xs xl:text-md leading-3 text-white">
              #{brandColor}
            </p>
          </div>
        </BadgeButton>
        <div
          className={`py-1 xl:py-1.5 flex items-center gap-2 px-2 xl:px-3 rounded-tl-2lg ${brandColorData[brandColor]}`}
        >
          <div className="w-[21px] h-[21px] xl:w-[30px] xl:h-[30px] rounded-full overflow-hidden">
            <img src={avatar} alt="" className="object-cover w-full h-full" />
          </div>
          <p className="font-Inter font-bold text-xs xl:text-md leading-3 text-white capitalize">
            {name}
          </p>
        </div>
        {brandColorOpen && (
          <div className="absolute left-[100px] top-0 translate-y-[-50%] z-10 p-5 bg-dark-200 rounded border border-primary-grey flex items-center gap-5">
            <div className="flex items-center gap-[2px]">
              {Object.keys(brandColorData).map((color) => (
                <button
                  type="button"
                  className={`w-6 h-6 hover:opacity-80 rounded-sm ${brandColorData[color]}`}
                  key={color}
                  onClick={() => {
                    setFieldValue(color);
                    setBrandColorOpen(false);
                  }}
                />
              ))}
            </div>
            <button onClick={() => setBrandColorOpen(false)}>
              {getIconsComponents({
                name: "close",
                className: "fill-primary-light",
              })}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
