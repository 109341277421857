import React, { useEffect, useState } from "react";
// hooks
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
// data
import { textData } from "../../../../assets/translations/en/textData";
// components
import { TreeTemplateCard } from "./treeTemplateCard/TreeTemplateCard";
import { ButtonCustom } from "../../../../components/atoms/button/ButtonCustom";
import { PreviewTreeTemplateWrapper } from "./previewTreeTemplateWrapper/PreviewTreeTemplateWrapper";
// store
import {
  getTemplateById,
  getTemplatesList,
  templateSetSaved,
} from "../../../../stores/thunks/templatesThunk";
import { clearTemplateById } from "../../../../stores/slice/templatesSlice";

export const TreeTemplateWrapper = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // store
  const templatesData = useAppSelector(
    (state) => state.templates.templatesData
  );
  const templateById = useAppSelector((state) => state.templates.templateById);
  const [openPreview, setOpenPreview] = useState(false);
  // effect
  useEffect(() => {
    dispatch(getTemplatesList());
    return () => {
      dispatch(clearTemplateById());
    };
  }, [dispatch]);
  if (openPreview && templateById) {
    return (
      <PreviewTreeTemplateWrapper
        returnCallback={() => setOpenPreview(false)}
      />
    );
  }
  if (templatesData) {
    return (
      <section className="w-full max-w-[780px] xl:w-full xl:max-w-[1080px] h-[75vh] xl:h-[80vh] md:px-[15px] lg:px-[40px] xl:px-[70px] py-[54px] bg-dark-200 border border-primary-grey rounded flex flex-col">
        {templatesData.length > 0 ? (
          <>
            <div className="flex items-center justify-between mb-[20px]">
              <h3 className="font-Inter font-extrabold text-lg md:text-xl xl:text-5xl leading-8">
                {textData.templatesPage.heading}
              </h3>
              <input
                type="search"
                name="search"
                id="search"
                onChange={(e) => console.log(e.target.value)}
                className="w-[180px] md:w-[230px] xl:w-[324px] h-[36px] md:h-[47px] py-2.5 md:py-3.5 px-4 md:px-6 bg-black border border-primary-grey rounded-2lg placeholder:capitalize"
                placeholder={textData.templatesPage.search}
              />
            </div>
            <div className="flex justify-between mb-4 xl:mb-7">
              <h6 className="font-Inter font-extrabold text-xs xl:text-lg leading-6 capitalize">
                {textData.templatesPage.feat}
              </h6>
            </div>
            <div className="flex-grow overflow-y-auto scrollbar pr-2.5 lg:pr-1">
              <div className="grid grid-cols-4 gap-4 place-items-center lg:place-items-start">
                {templatesData.map((card) => (
                  <TreeTemplateCard
                    key={card.id}
                    card={card}
                    onCardClick={() => {
                      dispatch(getTemplateById(card.id));
                    }}
                    selectedCardId={templateById?.id || null}
                    onBookmarkClick={() =>
                      dispatch(
                        templateSetSaved({
                          id: card.id,
                          body: { is_saved: !card.is_saved },
                        })
                      )
                    }
                  />
                ))}
              </div>
            </div>
            <div className="flex justify-center gap-4 mt-[51px]">
              <ButtonCustom
                variant="primary"
                title={textData.btns.cancelBtn}
                handleClick={() => {
                  navigate(`/clones`);
                  dispatch(clearTemplateById());
                }}
              />
              <ButtonCustom
                variant="violet"
                title={textData.btns.preview}
                handleClick={() => {
                  setOpenPreview(true);
                }}
                disabled={!templateById?.id}
              />
              <ButtonCustom
                variant="secondary"
                title={textData.btns.importTemplateBtn}
                handleClick={() => {
                  navigate(`/template/${templateById?.id}`);
                }}
                disabled={!templateById?.id}
                className="text-m font-semibold leading-4"
              />
            </div>
          </>
        ) : (
          <div className="h-full flex justify-center mt-[124px]">
            <p className="font-Inter font-extrabold text-lg leading-5 text-dark-600">
              {textData.exportTemplatePage.helper}
            </p>
          </div>
        )}
      </section>
    );
  }
  return null;
};
