import React, { useEffect, useRef } from "react";
// hooks
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../stores/hooks/hooks";
// data
import { textData } from "../../../../../../assets/translations/en/textData";
// components
import { MarketplaceItem } from "./marketplaceItem/MarketplaceItem";
import { ButtonCustom } from "../../../../../../components/atoms/button/ButtonCustom";
import { Spinner } from "../../../../../../components/atoms/spinner/Spinner";
import { UninstallToolModal } from "./uninstallToolModal/UninstallToolModal";
// thunks
import {
  fetchFavoriteTools,
  fetchInstalledTools,
  fetchMarketplaceData,
} from "../../../../../../stores/thunks/marketplaceThunk";
// slice
import { increasePaginationPage } from "../../../../../../stores/slice/marketplaceSlice";

export const MarketplaceContent = () => {
  // ref
  const ref = useRef(null);
  // hooks
  const dispatch = useAppDispatch();
  // store
  const pagination = useAppSelector((state) => state.marketplace.pagination);
  const toolsList = useAppSelector((state) => state.marketplace.toolsList);
  const selectedCategory = useAppSelector(
    (state) => state.marketplace.selectedCategory
  );
  const isLoading = useAppSelector((state) => state.marketplace.isLoading);
  const count = useAppSelector((state) => state.marketplace.count);
  const searchValue = useAppSelector((state) => state.marketplace.searchValue);
  const toolById = useAppSelector((state) => state.marketplace.toolById);
  const pathForRequest = useAppSelector(
    (state) => state.marketplace.pathForRequest
  );

  // helpers
  const scrollToMyRef = () => {
    if (ref && ref.current) {
      // @ts-ignore
      ref.current?.scrollIntoView();
    }
  };
  // effect
  useEffect(() => {
    scrollToMyRef();
  }, [toolById]);

  useEffect(() => {
    dispatch(
      fetchMarketplaceData({
        path: pathForRequest,
        params: {
          offset: pagination.offset,
          limit: pagination.limit,
          tags: selectedCategory.join(","),
          search: searchValue || undefined,
        },
      })
    );
  }, [selectedCategory, pagination, pathForRequest, searchValue]);

  useEffect(() => {
    dispatch(fetchFavoriteTools());
    dispatch(fetchInstalledTools());
  }, [dispatch]);

  if (isLoading && toolsList && toolsList.length === 0) {
    return (
      <div className="flex items-center justify-center w-full fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
        <Spinner />
      </div>
    );
  }

  if (toolsList && toolsList.length > 0 && count) {
    return (
      <div className="mt-[60px] mb-[70px]">
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-5 pl-[126px] pr-[77px] xxl:px-[100px]">
          {toolsList.map((item, index) => (
            <MarketplaceItem
              key={item.id}
              index={index}
              data={item}
              customRef={ref}
            />
          ))}
        </div>
        <div className="flex items-center justify-center mt-[70px]">
          {count > toolsList.length ? (
            <ButtonCustom
              spinner={isLoading}
              variant="primary"
              title={textData.btns.loadMore}
              className="xl:px-20"
              handleClick={() => {
                dispatch(increasePaginationPage());
              }}
            />
          ) : null}
        </div>
        <UninstallToolModal />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center">
      {textData.alertsMessage.notFound}
    </div>
  );
};
