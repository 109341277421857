import { createSlice } from "@reduxjs/toolkit";
// types
import {
  ClonesDataItemTypes,
  ClonesDataTypes,
  DialogsDataItemTypes,
  DialogsDataTypes,
} from "../types/botsTypes";
// thunks
import {
  checkDialogLimit,
  createClone,
  createDialog,
  fetchClones,
  fetchClonesLimits,
  fetchDialogs,
  uploadAvatar,
} from "../thunks/botsThunk";

interface chatUserState {
  isLoading: boolean;
  isLoadingFile: boolean;
  dialogs: DialogsDataTypes;
  clones: ClonesDataTypes;
  cloneById: ClonesDataItemTypes | null;
  dialogById: DialogsDataItemTypes | null;
  editCloneModalOpen: boolean;
  deleteCloneModalOpen: boolean;
  editDialogsModalOpen: boolean;
  deleteDialogModalOpen: boolean;
  duplicateCloneModalOpen: boolean;
  duplicateDialogModalOpen: boolean;
  choosingModalOpen: boolean;
  uploadFileModalOpen: boolean;
  avatarUrl: any;
  activeClone: string | null;
  importTemplateData: string | null;
  importDialogTemplate: string;
  clonesLimits: { limit: boolean };
}

const initialState: chatUserState = {
  isLoading: false,
  isLoadingFile: false,
  dialogs: [],
  clones: [],
  cloneById: null,
  dialogById: null,
  editCloneModalOpen: false,
  deleteCloneModalOpen: false,
  editDialogsModalOpen: false,
  deleteDialogModalOpen: false,
  choosingModalOpen: false,
  uploadFileModalOpen: false,
  duplicateCloneModalOpen: false,
  duplicateDialogModalOpen: false,
  avatarUrl: null,
  activeClone: null,
  importTemplateData: null,
  importDialogTemplate: "",
  clonesLimits: { limit: false },
};
export const botsSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    setCloneModalOpen: (state, action) => {
      state.editCloneModalOpen = action.payload;
    },
    setDeleteCloneModalOpen: (state, action) => {
      state.deleteCloneModalOpen = action.payload;
    },
    setDuplicateCloneModalOpen: (state, action) => {
      state.duplicateCloneModalOpen = action.payload;
    },
    setDialogsModalOpen: (state, action) => {
      state.editDialogsModalOpen = action.payload;
    },
    setDeleteDialogModalOpen: (state, action) => {
      state.deleteDialogModalOpen = action.payload;
    },
    setDuplicateDialogModalOpen: (state, action) => {
      state.duplicateDialogModalOpen = action.payload;
    },
    setActiveClone: (state, action) => {
      state.activeClone = action.payload;
    },
    clearSelectedAvatar: (state) => {
      state.avatarUrl = initialState.avatarUrl;
    },
    setChoosingModalOpen: (state, action) => {
      state.choosingModalOpen = action.payload;
    },
    setUploadFileModalOpen: (state, action) => {
      state.uploadFileModalOpen = action.payload;
    },
    setCloneById: (state, action) => {
      state.cloneById = action.payload;
    },
    setDialogById: (state, action) => {
      state.dialogById = action.payload;
    },
    setDataImportTemplate: (state, action) => {
      state.importTemplateData = action.payload;
    },
    setDialogImportTemplate: (state, action) => {
      state.importDialogTemplate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createDialog.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createDialog.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchDialogs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDialogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dialogs = action.payload;
    });
    builder.addCase(createClone.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createClone.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchClones.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchClones.fulfilled, (state, action) => {
      state.isLoading = false;
      state.clones = action.payload;
    });
    builder.addCase(fetchClonesLimits.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchClonesLimits.fulfilled, (state, action) => {
      state.isLoading = false;
      state.clonesLimits = action.payload;
    });
    builder.addCase(checkDialogLimit.fulfilled, (state, action) => {
      if (action.payload) {
        state.clones = state.clones.map((item) =>
          item.id === action.payload?.id
            ? { ...item, is_tree_dialogs_limited: action.payload.data }
            : item
        );
      }
    });
    builder.addCase(uploadAvatar.pending, (state) => {
      state.isLoadingFile = true;
    });
    builder.addCase(uploadAvatar.fulfilled, (state, action) => {
      state.isLoadingFile = false;
      state.avatarUrl = action.payload.image_url;
    });
  },
});

export const {
  setCloneModalOpen,
  setDeleteCloneModalOpen,
  setDialogsModalOpen,
  setDeleteDialogModalOpen,
  setChoosingModalOpen,
  setUploadFileModalOpen,
  clearSelectedAvatar,
  setCloneById,
  setDialogById,
  setActiveClone,
  setDataImportTemplate,
  setDialogImportTemplate,
  setDuplicateCloneModalOpen,
  setDuplicateDialogModalOpen,
} = botsSlice.actions;
