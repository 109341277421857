import { createSlice } from "@reduxjs/toolkit";
import {
  forgotPassword,
  signInUser,
  signUpUser,
} from "../thunks/userAuthThunk";
import {UserSignInType} from "../types/userAuthTypes";

interface authUserState {
  isLoading: boolean;
  logOutShow: boolean;
  isLoadingSignIn: boolean;
  twoStepVerificationModalOpen: boolean;
  credentials: UserSignInType | null;
  emailToVerify: string;
  phoneNumber: string;
  token: string;
  accountType: 0 | 1 | null;
}

const initialState: authUserState = {
  isLoading: false,
  logOutShow: false,
  isLoadingSignIn: false,
  twoStepVerificationModalOpen: false,
  credentials: null,
  emailToVerify: "",
  phoneNumber: "",
  token: "",
  accountType: null,
};
export const userAuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLogOutShow: (state, action) => {
      state.logOutShow = action.payload;
    },
    saveAccountType: (state, action) => {
      state.accountType = action.payload;
    },
    setTwoStepVerificationModalOpen: (state, action) => {
      state.twoStepVerificationModalOpen = action.payload;
    },
    setCredentials: (state, action) => {
      state.credentials = action.payload;
    },
    setEmailToVerify: (state, action) => {
      state.emailToVerify = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUpUser.pending, (state) => {
      // state.isLoading = true;
    });
    builder.addCase(signUpUser.fulfilled, (state, action) => {
      // state.isLoading = false;
    });
    builder.addCase(signInUser.pending, (state) => {
      state.isLoadingSignIn = true;
    });
    builder.addCase(signInUser.fulfilled, (state, action) => {
      state.isLoadingSignIn = false;
      state.token = action.payload;
    });
    builder.addCase(forgotPassword.pending, (state, action) => {
      // state.isLoading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      // state.isLoading = false;
    });
  },
});

export const { setToken, setLogOutShow, saveAccountType, setTwoStepVerificationModalOpen, setCredentials, setEmailToVerify, setPhoneNumber } = userAuthSlice.actions;
