// types
import { FooterDataType } from "./mockDataType";
// data
import { textData } from "../../../assets/translations/en/textData";
import { createValidate } from "../../../helpers/validation/validationHelper";

export const footerData: FooterDataType = {
  followLinks: [
    {
      path: "https://www.twitter.com/AphidAI/",
      icon: "twitter",
      title: "",
      local: false,
    },
    {
      path: "https://www.facebook.com/aphidcorporation/",
      icon: "facebook",
      title: "",
      local: false,
    },
    {
      path: "https://www.linkedin.com/company/aphid/",
      icon: "linkedIn",
      title: "",
      local: false,
    },
    {
      path: "https://discord.com/invite/mNHaVkm/",
      icon: "discord",
      title: "",
      local: false,
    },
    {
      path: "https://t.me/AphidAI/",
      icon: "telegram",
      title: "",
      local: false,
    },
    {
      path: "https://www.instagram.com/aphid_ai/",
      icon: "instagram",
      title: "",
      local: false,
    },
    {
      path: "https://www.youtube.com/@AphidAI/",
      icon: "youtube",
      title: "",
      local: false,
    },
    {
      path: "https://www.tiktok.com/@aphid/",
      icon: "tiktok",
      title: "",
      local: false,
    },
  ],
  data: [
    {
      title: textData.footer.linksData.marketplace.title,
      links: [
        // {
        //   path: "/",
        //   icon: null,
        //   title: textData.footer.linksData.marketplace.links.explore,
        //   local: true,
        // },
        {
          path: "https://www.aphid.com/help-center",
          icon: null,
          title: textData.footer.linksData.marketplace.links.helpCenter,
          local: false,
        },
        // {
        //   path: "/",
        //   icon: null,
        //   title: textData.footer.linksData.marketplace.links.operatorLogin,
        //   local: true,
        // },
        {
          path: "https://www.aphid.com/company",
          icon: null,
          title: textData.footer.linksData.marketplace.links.aboutUs,
          local: false,
        },
        // {
        //   path: "/",
        //   icon: null,
        //   title: textData.footer.linksData.marketplace.links.hostsStatus,
        //   local: true,
        // },
      ],
    },
    {
      title: textData.footer.linksData.community.title,
      links: [
        {
          path: "https://www.aphid.com/developers",
          icon: null,
          title: textData.footer.linksData.community.links.developers,
          local: false,
        },
        // {
        //   path: "/",
        //   icon: null,
        //   title: textData.footer.linksData.community.links.events,
        //   local: true,
        // },
        {
          path: "https://www.aphid.com/help-center",
          icon: null,
          title: textData.footer.linksData.community.links.support,
          local: false,
        },
      ],
    },
  ],
  privacyLinks: [
    {
      path: "/",
      icon: null,
      title: textData.footer.privacyPolicy,
      local: true,
    },
    {
      path: "/",
      icon: null,
      title: textData.footer.license,
      local: true,
    },
    {
      path: "/",
      icon: null,
      title: textData.footer.api,
      local: true,
    },
  ],
  field: {
    name: "email",
    label: "",
    placeholder: textData.footer.placeholder,
    type: "text",
  },
  validate: createValidate(["email"]),
  initialValues: {
    email: "",
  },
};
