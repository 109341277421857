import React from "react";
// components
import { MarketplaceTypesFilter } from "./marketplaceTypesFilter/MarketplaceTypesFilter";
import { MarketplaceAdBlock } from "./marketplaceAdBlock/MarketplaceAdBlock";
import { MarketplaceContent } from "./marketplaceContent/MarketplaceContent";
// styles
import "./MarketplacePage.css";

export const MarketplacePageWrapper = () => {
  return (
    <section>
      <MarketplaceTypesFilter />
      {/* temporarily removed */}
      {/* <MarketplaceAdBlock /> */}
      <MarketplaceContent />
    </section>
  );
};
