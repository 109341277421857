import React, { useState } from "react";
// image
import tasksLogo from "../../../assets/icons/tasks-list.svg";
import colonyLogo from "../../../assets/icons/colony.svg";
import getHelpLogo from "../../../assets/icons/help.svg";
// data
import { activityStepsTooltipsData } from "./activityTaskTableItem/mockData";
import { textData } from "../../../assets/translations/en/textData";
// components
import { TasksTabContent } from "./tasksTabContent/TasksTabContent";
import { ColonyTabContent } from "./colonyTabContent/ColonyTabContent";
import { TooltipComponent } from "../../../components/atoms/tooltip/Tooltip";
// hooks
import { useTour } from "../../../helpers/hooks/useTour/useTour";
// helper
import { getIconsComponents } from "../../../helpers/iconsComponents/iconsComponents";

export const ActivityPageWrapper = () => {
  // state
  const [activeTab, setActiveTab] = useState<"tasks" | "colony">("tasks");
  // hooks
  const { runTour, handleJoyrideCallback, resetTour } = useTour(
    "seenActivityPageTooltips",
    "sidebarTourFinished"
  );

  const renderContent = () => {
    switch (activeTab) {
      case "tasks":
        return <TasksTabContent />;
      case "colony":
        return <ColonyTabContent />;
      default:
        return <TasksTabContent />;
    }
  };

  return (
    <div className="flex min-h-[90vh] h-full flex-col bg-dark-200 rounded-4xl relative">
      <TooltipComponent
        runTour={runTour}
        steps={activityStepsTooltipsData}
        handleJoyrideCallback={handleJoyrideCallback}
      />
      <div className="flex justify-between items-center p-4">
        <div className="flex gap-x-8 mx-auto absolute left-1/2 transform -translate-x-1/2">
          <button
            className={`font-Inter text-2xxs leading-[150%] capitalize flex flex-col items-center justify-between hover:opacity-80 ${
              activeTab === "tasks" ? "text-dark-100" : "text-primary-light"
            }`}
            onClick={() => setActiveTab("tasks")}
          >
            <img
              src={tasksLogo}
              alt="Tasks"
              className="h-[29px] w-[29px] mb-2"
            />
            {textData.activityPage.tabs.tasks}
          </button>
          <button
            className={`font-Inter text-2xxs leading-[150%] capitalize flex flex-col items-center justify-between hover:opacity-80 ${
              activeTab === "colony" ? "text-dark-100" : "text-primary-light"
            }`}
            onClick={() => setActiveTab("colony")}
          >
            <img
              src={colonyLogo}
              alt="Colony"
              className="h-[23px] w-[34px] relative top-[5px]"
            />
            {textData.activityPage.tabs.colony}
          </button>
        </div>
        <div className="flex gap-x-6 ml-auto pr-[40px] lg:pr-[90px]">
          <button className="hover:opacity-80" onClick={resetTour}>
            {getIconsComponents({ name: "question", className: "h-[25px] w-[25px]" })}
          </button>
          <button className="flex flex-col items-center text-xs font-bold text-white text-center font-Inter capitalize hover:opacity-80">
            <img src={getHelpLogo} alt="Help" className="" />
            {textData.activityPage.tabs.getHelp}
          </button>
        </div>
      </div>
      <div className="pl-[44px] pr-0 py-4 flex-grow bg-dark-200">
        {renderContent()}
      </div>
    </div>
  );
};
