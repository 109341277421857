import React, {Fragment, useEffect} from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../stores/hooks/hooks";
// store
// data
import { textData } from "../../../assets/translations/en/textData";
// components
import { ModalWrapper } from "../../../components/molecules/modalWrapper/ModalWrapper";
import {ButtonCustom} from "../../../components/atoms/button/ButtonCustom";
import {
  setChangePaymentModalOpen,
  setDowngradeNoticeModalOpen,
  setNoPaymentModalOpen, setSelectMembershipModalOpen
} from "../../../stores/slice/userProfileSlice";
import {setMembershipProfile} from "../../../stores/thunks/userProfileThunk";
import {useNavigate} from "react-router-dom";
import {planMap} from "../components/mockData";
import {getIconsComponents} from "../../../helpers/iconsComponents/iconsComponents";

export const SelectMembershipModal = () => {
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // store
  const selectMembershipModalOpen = useAppSelector((state) => state.userProfile.selectMembershipModalOpen);
  const membershipList = useAppSelector((state) => state.payments.membershipList);
  const defaultPaymentMethodById = useAppSelector((state) => state.payments.defaultPaymentMethodById);
  const billingPeriod = useAppSelector((state) => state.userProfile.membershipBillingPeriod);
  const plan = useAppSelector((state) => state.userProfile.membershipSelectedPlan);

  useEffect(() => {
    if (!selectMembershipModalOpen) {
      console.log(plan, billingPeriod);
    }
  }, [selectMembershipModalOpen]);


  const setMembership = (plan: string, billingPeriod: string) => {
    if (defaultPaymentMethodById) {
      dispatch(setMembershipProfile({
        body: {
          membership: membershipList && membershipList[plan][billingPeriod]?.id,
          payment_method: defaultPaymentMethodById
        },
        navigate: navigate,
      }));
    }
  };

  const getPrice = (plan: string) => {
    const initial_price = membershipList && membershipList[plan][billingPeriod]?.initial_price;
    return <span>{initial_price && <span className="line-through text-dark-170">${initial_price}</span>} ${membershipList && membershipList[plan][billingPeriod]?.price}</span>;
  }

  return (
    <Fragment>
      <ModalWrapper
        open={selectMembershipModalOpen}
        onClose={() => dispatch(setSelectMembershipModalOpen(false))}
        className="w-[983px] h-[468px] flex flex-col"
        title=""
      >
        <div className="flex flex-col gap-2">
          <button
            className="p-[14px] absolute top-[20px] right-[30px] border-primary-grey border-[1px] z-[1] rounded-full"
            onClick={() => dispatch(setSelectMembershipModalOpen(false))}>
            {getIconsComponents({name: 'close', className: "fill-primary-light"})}
          </button>
          <h3 className="font-Inter text-center text-secondary-green font-extrabold text-xl leading-6 mb-10">
            {textData.selectMembership.selectMembership}
          </h3>
          <p className="font-Roboto text-xs m-center pb-8 w-[362px]">{textData.selectMembership.selectMembershipMsg}</p>
          <p className="text-sm font-bold">{textData.selectMembership.selectedPlan}: <span className="font-bold">{membershipList && plan && membershipList[plan][billingPeriod]?.display_name}</span></p>
          <p className="text-sm font-bold">{textData.selectMembership.billingPeriod}: <span className="font-bold capitalize">{billingPeriod}</span></p>
          <p className="text-sm font-bold">{textData.selectMembership.price}: <span className="font-Roboto font-medium">{plan && getPrice(plan)}</span></p>
          <div className="flex gap-8 justify-center mt-8">
            <ButtonCustom
              variant="primary"
              title={textData.btns.btnDiscard}
              handleClick={() => {
                dispatch(setSelectMembershipModalOpen(false));
              }}
            />
            <ButtonCustom
              variant="secondary"
              disabled={!plan}
              title={textData.btns.continueBtn}
              handleClick={() => {
                plan && setMembership(plan, billingPeriod)
              }}
            />
          </div>
        </div>
      </ModalWrapper>
    </Fragment>
  );
};
