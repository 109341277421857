// hook
import { useState } from "react";
import { useAppDispatch } from "../../../../stores/hooks/hooks";
import { useNavigate } from "react-router-dom";
// data
import { selectedTypeMockData } from "./mockData";
import { textData } from "../../../../assets/translations/en/textData";
// components
import { AccountTypeCard } from "./accountTypeCard/AccountTypeCard";
import { ButtonCustom } from "../../../../components/atoms/button/ButtonCustom";
// thunk
import { setAccountTypeUserProfile } from "../../../../stores/thunks/userProfileThunk";

export const ChooseAccountTypeWrapper = () => {
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // state
  const [tooltip, setTooltip] = useState<0 | 1 | null>(null);
  const [selectedCard, setSelectedCard] = useState<0 | 1 | null>(null);
  // helpers
  const handleClick = () => {
    if (selectedCard !== null) {
      dispatch(
        setAccountTypeUserProfile({
          body: { account_type: selectedCard },
          navigate: navigate,
        })
      );
    }
  };
  return (
    <section>
      <div className="flex flex-col items-center gap-2 mb-9">
        <h1 className="font-Inter font-extrabold text-8xl leading-10">
          {textData.chooseAccountTypePage.title}
        </h1>
        <p className="font-Inter text-white text-opacity-50 text-sm text- leading-5">
          {textData.chooseAccountTypePage.description}
        </p>
      </div>

      <div>
        <div className="flex gap-6 mb-[123px]">
          {selectedTypeMockData.map(
            (typeData) =>
              ((tooltip !== null && tooltip === typeData.value) ||
                tooltip === null) && (
                <AccountTypeCard
                  key={typeData.value}
                  data={typeData}
                  tooltip={tooltip}
                  setTooltip={setTooltip}
                  handleSelectCard={setSelectedCard}
                  isSelected={selectedCard === typeData.value}
                />
              )
          )}
        </div>

        {tooltip === null && (
          <div className="flex justify-between ">
            <ButtonCustom
              variant="primary"
              title={textData.btns.backBtn}
              className="py-4 px-[80px]"
            />
            <ButtonCustom
              variant="secondary"
              type="button"
              title={textData.btns.nextBtn}
              className="py-4 px-[80px]"
              handleClick={handleClick}
              disabled={selectedCard === null}
            />
          </div>
        )}
      </div>
    </section>
  );
};
