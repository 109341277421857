import React, { useEffect } from "react";
// hooks
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../stores/hooks/hooks";
// store
import {
  setDeleteDialogModalOpen,
  setDialogById,
} from "../../../../../stores/slice/botsSlice";
import { deleteDialog } from "../../../../../stores/thunks/botsThunk";
// data
import { textData } from "../../../../../assets/translations/en/textData";
// components
import { ModalWrapper } from "../../../../../components/molecules/modalWrapper/ModalWrapper";
import { ButtonCustom } from "../../../../../components/atoms/button/ButtonCustom";

export const DeleteDialogModal = () => {
  // hooks
  const dispatch = useAppDispatch();
  // store
  const openModal = useAppSelector((state) => state.bots.deleteDialogModalOpen);
  const dialogById = useAppSelector((state) => state?.bots?.dialogById);
  // effect
  useEffect(() => {
    if (!openModal) {
      dispatch(setDialogById(null));
    }
  }, [openModal]);

  return (
    <ModalWrapper
      open={openModal}
      onClose={() => {
        dispatch(setDeleteDialogModalOpen(false));
      }}
      title={textData.clones.deleteTitle}
      className="pt-[55px] pb-[44px] pl-[101px] pr-[120px]"
    >
      <h3 className="font-Inter text-start font-normal text-lg leading-6 text-white my-10">
        {textData.dialogs.deleteDescription}
      </h3>
      <div className="flex gap-8 justify-center mt-10">
        <ButtonCustom
          variant="primary"
          title={textData.btns.btnDiscard}
          handleClick={() => {
            dispatch(setDeleteDialogModalOpen(false));
          }}
        />
        <ButtonCustom
          variant="secondary"
          title={textData.btns.deleteBtn}
          handleClick={() => {
            if (dialogById) {
              dispatch(deleteDialog({dialog:dialogById.id, clone:dialogById.bots[0]}));
            }
            dispatch(setDialogById(null));
            dispatch(setDeleteDialogModalOpen(false));
          }}
        />
      </div>
    </ModalWrapper>
  );
};
