import React from "react";
// route
import { Route, Routes, Navigate } from "react-router-dom";
// components
import ClonesPage from "../pages/clonesPage/ClonesPage";
import DashboardPage from "../pages/dashboardPage/DashboardPage";
import SignUpPage from "../pages/signUpPage/SignUpPage";
import SignInPage from "../pages/signInPage/SignInPage";
import CreateBotTreePage from "../pages/createBotTreePage/CreateBotTreePage";
import ForgotPasswordCommonPage from "../pages/forgotPassword/forgotPasswordCommonPage/ForgotPasswordCommonPage";
import ForgotPasswordPage from "../pages/forgotPassword/forgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "../pages/resetPasswordPage/ResetPasswordPage";
import DeployPage from "../pages/deployPage/DeployPage";
import SetupGuidePage from "../pages/setupGuidePage/SetupGuidePage";
import ChooseAccountTypePage from "../pages/chooseAccountTypePage/ChooseAccountTypePage";
import TreeTemplatesPage from "../pages/treeTemplatesPage/TreeTemplatesPage";
import TemplateAlertPage from "../pages/templateAlertPage/TemplateAlertPage";
import ExportTemplatePage from "../pages/exportTemplatePage/ExportTemplatePage";
import SubscriptionPlansPage from "../pages/subscriptionPlans/SubscriptionPlans";
import ClonesWelcomePage from "../pages/clonesWelcomePage/ClonesWelcomePage";
import CloneIFramePage from "../pages/cloneIFramePage/CloneIFramePage";
import MarketplaceItemPage from "../pages/marketplace/marketplaceIItemPage/MarketplaceItemPage";
import MarketplacePage from "../pages/marketplace/marketplacePage/MarketplacePage";
import SettingsPage from "../pages/settingsPage/SettingsPage";
import SelectMembership from "../pages/selectMembership/selectMembership";
import SelectBusinessPlan from "../pages/selectBusinessPlan/selectBusinessPlan";
import TransactionsPage from "../pages/transactionsPage/TransactionsPage";
import ConfirmEmailPage from "../pages/confirmEmail/ConfirmEmailPage";
import ActivityPage from "../pages/activityPage/ActivityPage";
import ActivityCloneItemPage from "../pages/activityPage/components/activityCloneItemPage/ActivityCloneItemPage";
import { NotFoundPage } from "../pages/404Page/NotFoundPage";

type PropsType = {
  token: string;
};

export const useRoute = ({ token }: PropsType) => {
  if (token) {
    return (
      <Routes>
        <Route path="/" element={<Navigate replace to="/clones" />} />
        <Route path="/dashboard" element={<DashboardPage isAuth />} />
        <Route path="/settings" element={<SettingsPage isAuth />} />
        <Route path="/network" element={<MarketplacePage isAuth />} />
        <Route path="/network/:id" element={<MarketplaceItemPage isAuth />} />
        <Route path="/clones/welcome" element={<ClonesWelcomePage isAuth />} />
        <Route path="/choose-account" element={<ChooseAccountTypePage />} />
        <Route path="/clones" element={<ClonesPage />} />
        <Route path="/clones/:id" element={<CloneIFramePage />} />
        <Route path="/dialog">
          <Route path=":id" element={<CreateBotTreePage />} />
        </Route>
        <Route path="/deploy/:id" element={<DeployPage />} />
        <Route
          path="/deploy/:id/setup-guide/:slug"
          element={<SetupGuidePage />}
        />
        <Route path="template" element={<TreeTemplatesPage />} />
        <Route path="template/:slug" element={<TemplateAlertPage />} />
        <Route path="/create-template/:id/" element={<ExportTemplatePage />} />
        <Route path="/subscription-plans" element={<SubscriptionPlansPage />} />
        <Route path="/select-membership" element={<SelectMembership />} />
        <Route path="/select-business-plan" element={<SelectBusinessPlan />} />
        <Route path="/transactions" element={<TransactionsPage isAuth />} />
        <Route path="/confirm-email" element={<ConfirmEmailPage />} />
        <Route path="/activity" element={<ActivityPage />} />
        <Route path="/activity/:id" element={<ActivityCloneItemPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/signup" />} />
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route
        path="/forgot-password-common"
        element={<ForgotPasswordCommonPage />}
      />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/confirm-email" element={<ConfirmEmailPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
