import React from "react";
// types
import {
  FieldCustomTypes,
  FieldVariantClassTypes,
  FieldVariantTypes,
} from "./fieldCustomTypes";

type Props = {
  field: FieldCustomTypes;
  value: string;
  setFieldValue: (value: string) => void;
  variant: FieldVariantTypes;
  handleBlur?: () => void;
  className?: string;
  rows?: number;
  max?: number;
};

export const FieldCustom = ({
  field,
  setFieldValue,
  value,
  variant,
  className,
  handleBlur,
  rows = 3,
  max,
}: Props) => {
  const variantClassData: FieldVariantClassTypes = {
    light: "bg-white py-8 text-black",
    dark: "bg-secondary-dark border border-primary-grey py-5 text-white",
    darker: "bg-dark-750 px-6 py-6 border border-dark-150 shadow-sm",
  };
  return (
    <div className="flex flex-col gap-3 xl:gap-4">
      {field.label && (
        <label className="font-Inter font-semibold text-md xl:text-xl leading-7 text-white capitalize">
          {field.label}
        </label>
      )}
      <textarea
        name={field.name}
        maxLength={max}
        value={value}
        onChange={(e: any) => setFieldValue(e.target.value)}
        rows={rows}
        placeholder={field.placeholder}
        onBlur={handleBlur}
        className={`px-5 rounded-3xl w-full placeholder:font-Inter placeholder:font-normal 
        placeholder:text-sm text-sm xl:text-md xl:placeholder:text-md placeholder:leading-5 placeholder:text-primary-light focus:outline-none resize-none ${variantClassData[variant]} ${className}`}
      />
    </div>
  );
};
