import React, { useMemo } from "react";
// type
import { ChatMessagesListItemType } from "../../../../../../../stores/types/chatUserTypes";

type Props = {
  data: ChatMessagesListItemType;
  avatar?: string;
};

export const BotMessageItem = ({ data, avatar }: Props) => {
  // old version
  const result = useMemo(() => {
    const punctuationMarks = [
      ",",
      ":",
      "!",
      "?",
      "'",
      "'",
      "\n",
      "|",
      "@",
      "#",
      "$",
      "%",
      ";",
      "-",
      ")",
      "(",
      "*",
      "&",
      "^",
      "%",
      "+",
      "_",
    ];
    const linkMessage = data.text
      .split(" ")
      .find((item) => item.includes("http"));
    if (linkMessage) {
      const theLastLetter = linkMessage.slice(-1);
      if (punctuationMarks.includes(theLastLetter)) {
        return (
          <div className="inline-block">
            <span>{data.text.split(linkMessage)[0]}</span>
            <a className="text-blue-300" href={linkMessage} target="_blank">
              {linkMessage.slice(0, -1)}
            </a>
            <span>{data.text.split(linkMessage.slice(0, -1))[1]}</span>
          </div>
        );
      } else {
        return (
          <div>
            <p>{data.text.split(linkMessage)[0]}</p>
            <a className="text-blue-300" href={linkMessage} target="_blank">
              {linkMessage}
            </a>
            <p>{data.text.split(linkMessage)[1]}</p>
          </div>
        );
      }
    } else {
      return data.text;
    }
  }, [data]);

  // const result = useMemo(() => {
  //   // regexp pattern to find links
  //   const regex = /(https?):\/\/[^\s,]+(,|$)/g;
  //   // find all matches in the text
  //   const matches = data.text.match(regex);
  //
  //   // if no matches return the original text
  //   if (!matches) {
  //     return data.text;
  //   }
  //
  //   // tracks the current index in the text when handle matches
  //   let currentIndex = 0;
  //   // array to store JSX elements
  //   const elements = matches.map((match, index) => {
  //     // process each match
  //     const linkMessage = match.replace(/,$/, "");
  //     // check if the match is a link
  //     const isLink =
  //       linkMessage.startsWith("http://") || linkMessage.startsWith("https://");
  //     // if it has a comma sign - should be added after the match
  //     const comma = match.endsWith(",") ? ", " : "";
  //
  //     // extract text before the current match
  //     const textBefore = data.text.substring(
  //       currentIndex,
  //       data.text.indexOf(match)
  //     );
  //     currentIndex = data.text.indexOf(match) + match.length;
  //
  //     // return JSX for each match
  //     return (
  //       <React.Fragment key={index}>
  //         {textBefore && <p>{textBefore}</p>}
  //         {isLink ? (
  //           <a
  //             className="text-blue-200/[0.77]"
  //             href={linkMessage}
  //             target="_blank"
  //           >
  //             {linkMessage}
  //           </a>
  //         ) : (
  //           <span>{linkMessage}</span>
  //         )}
  //         {comma && <span>{comma}</span>}
  //       </React.Fragment>
  //     );
  //   });
  //
  //   // extract the last fragment of text
  //   const lastText = data.text.substring(currentIndex);
  //   if (lastText) {
  //     elements.push(<p key={matches.length}>{lastText}</p>);
  //   }
  //
  //   // return div containing all JSX elements
  //   return <div>{elements}</div>;
  // }, [data.text]);

  if (data.author)
    return (
      <div className="flex items-center justify-center">
        <div className={`w-full flex justify-end mt-[30px]`}>
          <div
            className={`bg-primary-grey border border-primary-grey overflow-hidden text-ellipsis ${
              data.error ? "text-scarlet" : "text-primary-light"
            } p-5 rounded-3xl relative  w-[340px]`}
          >
            {result}
          </div>
        </div>
      </div>
    );
  return (
    <div className="flex items-center justify-center">
      <div className={`w-full flex justify-start items-center mt-[30px]`}>
        <div className="w-[34px] min-w-[34px] min-h-[34px] h-[34px] rounded-full overflow-hidden">
          <img src={avatar} alt="" className="w-full h-full object-cover" />
        </div>
        <div
          className={`bg-secondary-dark border border-primary-grey overflow-hidden text-ellipsis ${
            data.error ? "text-scarlet" : "text-primary-light"
          } w-[340px] p-5 rounded-3xl ml-2 relative`}
        >
          {result}
        </div>
      </div>
    </div>
  );
};
