import React, {useEffect, useState} from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
// store
// data
// components
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {confirmPaymentMethod} from "../../../../stores/thunks/paymentsThunk";
import {ButtonCustom} from "../../../../components/atoms/button/ButtonCustom";
import {textData} from "../../../../assets/translations/en/textData";
import {setAddPaymentModalOpen} from "../../../../stores/slice/userProfileSlice";
import {Spinner} from "../../../../components/atoms/spinner/Spinner";
import {displayAlert} from "../../../../stores/slice/alertSlice";

export const StripeElements = ({clientSecret} : {clientSecret: string}) => {
  // hooks
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  // store
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

  }, []);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href
      },
      redirect: 'if_required'
    });

    if(result.setupIntent) {
      dispatch(confirmPaymentMethod({setup_intent_id: result.setupIntent.id}))
    }

    setIsLoading(false);

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      dispatch(
        displayAlert({
          type: "error",
          text: result.error.message || textData.alertsMessage.wrong,
        })
      );
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement/>
      <div className="flex gap-8 justify-around mt-10">
        <ButtonCustom
          variant="primary"
          title={textData.btns.btnDiscard}
          handleClick={() => {
            dispatch(setAddPaymentModalOpen(false));
          }}
        />
        <ButtonCustom
          disabled={!stripe}
          variant="secondary"
          type="submit"
          title={textData.btns.yesBtn}
        />
      </div>
      {isLoading && <Spinner page/>}
    </form>
  )
    ;
};
