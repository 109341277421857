import React, { useState } from "react";
// types
import {
  FieldFormikAsType,
  FieldFormikTypes,
  FieldVariantClassTypes,
  FieldVariantTypes,
} from "./fieldFormikTypes";
// icons data
import { signUpIconsFieldsData } from "./mockData";
// components
import { Field } from "formik";

type Props = {
  field: FieldFormikTypes;
  value: string;
  setFieldValue: (e: any) => void;
  variant: FieldVariantTypes;
  className?: string;
  labelClass?: string;
  classNameWrapper?: string;
  showIcon?: boolean;
  readOnly?: boolean;
  touched?: boolean;
  errors?: string;
  as?: FieldFormikAsType;
};

export const FieldFormik = ({
  field,
  setFieldValue,
  value,
  variant,
  className = "",
  labelClass,
  classNameWrapper = "",
  showIcon = true,
  readOnly = false,
  touched,
  errors,
  as = "input",
}: Props) => {
  //state
  const [showPassword, setShowPassword] = useState(false);
  const isPasswordField = field.name === "password" || field.name === "old_password";
  const isFieldNotEmpty = value?.length > 0;
  const iconData = signUpIconsFieldsData[field.name];
  const { icon, switchIcon } = iconData || {};

  const getFieldIcon = () => {
    const icons = {
      active: showPassword ? switchIcon?.active || icon.active : icon.active,
      inactive: showPassword
        ? switchIcon?.inactive || icon.inactive
        : icon.inactive,
    };

    return isFieldNotEmpty ? icons.active : icons.inactive;
  };

  const variantClassData: FieldVariantClassTypes = {
    light: "bg-white py-8 ",
    dark: `bg-secondary-dark border border-primary-grey px-4 xl:px-5 rounded-[17px] ${
      as === "input" ? "py-2 xl:py-[14px]" : "py-2 xl:py-3"
    }`,
    borderless: "px-0 xl:px-0 border-0 rounded-[0px]"
  };
  return (
    <div className={`flex flex-col gap-1.5 xl:gap-4 ${classNameWrapper}`}>
      {field.label && (
        <label
          className={`font-Inter font-semibold text-xl text-white capitalize ${labelClass}`}
        >
          {field.label}
        </label>
      )}
      <div className="flex flex-col gap-2 relative">
        <Field
          as={as}
          name={field.name}
          value={value}
          autoComplete="on"
          readOnly={readOnly}
          type={showPassword ? "text" : field.type}
          onChange={setFieldValue}
          placeholder={field.placeholder}
          rows={3}
          className={`w-full text-primary-light placeholder:font-Inter placeholder:font-normal 
        placeholder:text-xs xl:placeholder:text-md placeholder:leading-5 placeholder:text-primary-light placeholder:capitalize 
        focus:outline-none resize-none ${variantClassData[variant]} ${className}`}
        />
        {iconData && showIcon && (
          <button
            className={`absolute left-full top-1/2 translate-y-[-50%] translate-x-[-200%] ${
              isPasswordField ? "" : "pointer-events-none"
            }`}
            onClick={() => {
              isPasswordField && setShowPassword((prev) => !prev);
            }}
            type="button"
          >
            {getFieldIcon()}
          </button>
        )}
        {touched && errors && (
          <span className="font-Inter font-normal text-xs xl:text-sm text-scarlet absolute left-0 bottom-0 translate-y-full">
            {errors}
          </span>
        )}
      </div>
    </div>
  );
};
