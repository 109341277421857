// store
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchChatData,
  fetchChatDataById,
  sendChatMessage,
} from "../thunks/chatUserThunk";
// types
import {
  BotListItemType,
  ChatMessagesListItemType,
} from "../types/chatUserTypes";
import { ChildScenarioType } from "../types/treeBotTypes";
// helpers
import {getStorageName} from "../../helpers/storageName/storageName";

interface chatUserState {
  isLoading: boolean;
  isLoadingSendMessage: boolean;
  currentScenario: ChildScenarioType | null;
  messagesList: ChatMessagesListItemType[];
  bot: BotListItemType | null;
  chatId: string | null;
}

const initialState: chatUserState = {
  isLoading: false,
  isLoadingSendMessage: false,
  currentScenario: null,
  messagesList: [],
  bot: null,
  chatId: null,
};
export const chatUserSlice = createSlice({
  name: "chatUser",
  initialState,
  reducers: {
    saveMessages: (state, action) => {
      state.messagesList.push(action.payload);
    },
    clearScenario: (state) => {
      state.currentScenario = initialState.currentScenario;
    },
    setIsLoadingMessage: (state, action) => {
      state.isLoadingSendMessage = action.payload;
    },
    clearMessagesList: (state) => {
      state.messagesList = initialState.messagesList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChatData.pending, (state) => {
      state.isLoadingSendMessage = true;
    });
    builder.addCase(fetchChatData.fulfilled, (state, action) => {
      if (action.payload) {
        localStorage.setItem(getStorageName.chatBotId, action.payload.id);
        state.chatId = action.payload.id;
        state.currentScenario = action.payload.current_scenario;
        state.messagesList = action.payload.messages;
        state.bot = action.payload.bot;
      }
    });
    builder.addCase(fetchChatDataById.pending, (state) => {
      state.isLoadingSendMessage = true;
    });
    builder.addCase(fetchChatDataById.fulfilled, (state, action) => {
      state.isLoadingSendMessage = false;
      if (action.payload) {
        state.chatId = action.payload.id;
        state.currentScenario = action.payload.current_scenario;
        state.messagesList = action.payload.messages;
        state.bot = action.payload.bot;
      }
    });
    builder.addCase(sendChatMessage.pending, (state) => {
      state.isLoadingSendMessage = true;
    });
    builder.addCase(sendChatMessage.fulfilled, (state, action) => {
      state.isLoadingSendMessage = false;
      if (action.payload) {
        chatUserSlice.caseReducers.saveMessages(state, {
          ...action,
          payload: {
            author: 0,
            text: action.payload.text,
            buttons: null,
          },
        });
        if (action.payload.id) {
          state.currentScenario = action.payload;
        } else {
          state.currentScenario = initialState.currentScenario;
        }
      }
    });
  },
});

export const {
  saveMessages,
  clearScenario,
  clearMessagesList,
  setIsLoadingMessage,
} = chatUserSlice.actions;
