import React, {useEffect, useState} from "react";
// data
import { textData } from "../../../../assets/translations/en/textData";
import { subscriptionPlanMockData } from "./mockData";
// icon
import AphIdLogo from "../../../../assets/icons/aphidLogo.svg";
// components
import { ButtonCustom } from "../../../../components/atoms/button/ButtonCustom";
import { SubscriptionPlanCard } from "./subscriptionPlanCard/SubscriptionPlanCard";
// types
import { SlugItemType } from "./mockDataType";
// hooks
import { useNavigate } from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../stores/hooks/hooks";
//slice
import {
  setDowngradeNoticeModalOpen,
  setMembershipBillingPeriod,
  setMembershipSelectedPlan,
  setNoPaymentModalOpen,
  setSelectMembershipModalOpen
} from "../../../../stores/slice/userProfileSlice";
// helpers
import {billingPeriodMap} from "../../../selectMembership/components/mockData";
import {setMembershipProfile} from "../../../../stores/thunks/userProfileThunk";
import {DowngradeNoticeModal} from "../../../selectMembership/downgradeNoticeModal/downgradeNoticeModal";
import {SelectMembershipModal} from "../../../selectMembership/selectMembershipModal/selectMembershipModal";
import {NoPaymentModal} from "../../../settingsPage/components/noPaymentModal/noPaymentModal";
import {fetchMembershipList} from "../../../../stores/thunks/paymentsThunk";

export const SubscriptionPlansWrapper = () => {
  // state
  const [selectedCard, setSelectedCard] = useState<SlugItemType>('basic');
  const membershipList = useAppSelector((state) => state.payments.membershipList);
  const defaultPaymentMethodById = useAppSelector((state) => state.payments.defaultPaymentMethodById);
  const membership = useAppSelector((state) => state.userProfile.profile?.membership);
  const [billingPeriod, setBillingPeriod] = useState(billingPeriodMap[membership?.billing_period === 1 ? 1 : 0]);
  // hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchMembershipList());
  }, [dispatch]);

  const setSelectedMembership = ((plan: string, billingPeriod: string) => {
    dispatch(setMembershipSelectedPlan(plan));
    plan !== 'basic' ? dispatch(setMembershipBillingPeriod(billingPeriod)) : billingPeriod = 'permanent';
    if (membershipList && membership && membershipList[plan][billingPeriod]!.plan < membership.plan) {
      dispatch(setDowngradeNoticeModalOpen(true));
    } else
    if(!defaultPaymentMethodById && plan !== 'basic') {
      dispatch(setNoPaymentModalOpen(true));
    } else if (plan === 'basic') {
      dispatch(setMembershipProfile({
        body: {
          membership: membershipList && membershipList[plan][billingPeriod]!.id,
        },
        navigate: navigate,
      }));
    } else {
      dispatch(setSelectMembershipModalOpen(true));
    }
  });

  return (
    <section className="w-full">
      <div className="flex items-end justify-between px-[54px] lg:px-0">
        <img
          src={AphIdLogo}
          alt="aphid logo"
          className="relative w-[73px] xl:w-[176px] xl:h-[67px] h-[28px]"
        />
        <h4 className="font-Inter font-extrabold text-xs xl:text-5xl xl:leading-8 capitalize">
          {textData.subscriptionPlansPage.compare}
        </h4>
        <div className="text-center">
          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" checked={billingPeriod === 'monthly'}
                   onChange={() => {
                     const value = billingPeriod === 'annual' ? 'monthly' : 'annual';
                     setBillingPeriod(value);
                     dispatch(setMembershipBillingPeriod(value));
                   }}
                   className="sr-only peer"/>
            <div className="relative
            w-20 h-6 bg-secondary-green rounded-full
            peer peer-checked:after:-translate-x-14 rtl:peer-checked:after:translate-x-14 peer-checked:after:border-white
            after:content-[''] after:absolute after:top-[2px] after:end-[2px] after:bg-white after:border-white
            after:shadow-toggle after:border after:rounded-full
            after:h-5 after:w-5 after:transition-all peer-checked:bg-secondary-green"></div>
          </label>
          <div className="flex text-sm mt-2">
            <button
              className={`font-Roboto text-sm ${billingPeriod === 'monthly' && "font-bold text-secondary-green"} capitalize mr-2 tracking-[1px]`}>
              {textData.selectMembership.monthly}
            </button>
            <span> | </span>
            <button
              className={`font-Roboto text-sm ${billingPeriod === 'annual' && "font-bold text-secondary-green"} capitalize ml-2 tracking-[1px]`}>
              {textData.selectMembership.annual}
            </button>
          </div>
        </div>
      </div>
      <p
        className="text-right font-Roboto font-normal text-xxs xl:text-sm text-dark-470 leading-5 tracking-[1px] mt-2 mr-[54px] lg:mr-0">
        {textData.subscriptionPlansPage.renew}
      </p>
      <div>
        <div className="flex h-full gap-2 mt-[60px] overflow-x-auto md:overflow-x-hidden">
          {subscriptionPlanMockData.map((card) => {
            return (
              <SubscriptionPlanCard
                key={card.planName}
                data={card}
                isSelected={selectedCard === card.slug}
                handleSelectCard={() => setSelectedCard(card.slug)}
              />
            );
          })}
        </div>
      </div>
      <div className="flex justify-center mt-20">
        <ButtonCustom
          variant="secondary"
          type="button"
          title={textData.btns.getStarted}
          disabled={!selectedCard}
          handleClick={() => {
            setSelectedMembership(selectedCard, billingPeriod);
          }}
        />
      </div>
      <DowngradeNoticeModal />
      <SelectMembershipModal />
      <NoPaymentModal />
    </section>
  );
};
