import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import { DashboardItems } from "./components/dashboardItems/DashboardItems";
import { DashboardContent } from "./components/dashboardContent/DashboardContent";
import { TooltipComponent } from "../../components/atoms/tooltip/Tooltip";
// data
import { dashboardTooltipsData } from "./components/dashboardItems/mockData";
// styles
import "./DashboardPage.css";
// hooks
import { useTour } from "../../helpers/hooks/useTour/useTour";
// helper
import { getIconsComponents } from "../../helpers/iconsComponents/iconsComponents";

type Props = {
  isAuth: boolean;
};
const DashboardPage = ({ isAuth }: Props) => {
  // return (
  //   <Layout
  //     sideBar={false}
  //     classNameWrapper="h-screen flex justify-center items-center pl-[56px] bg-black"
  //   >
  //     <div className="flex flex-col items-center justify-center max-w-[363px] xl:max-w-full">
  //       <h2 className="font-Inter font-extrabold text-lg xl:text-5xl leading-7 text-white mb-[93px] xl:mb-[168px]">
  //         {textData.welcomePage.title}
  //       </h2>
  //       <p className="font-Inter font-normal text-xs xl:text-2xl leading-5 text-white mb-[60px] xl:mb-[180px] max-w-[652px] tracking-[1.1px]">
  //         There will be dashboard
  //       </p>
  //       <LinkCustom
  //         variant="secondary"
  //         link="/clones"
  //         title={textData.btns.getStarted}
  //       />
  //     </div>
  //   </Layout>
  // );

  // hooks
  const { runTour, handleJoyrideCallback, resetTour } = useTour(
    "seenDashboardTooltips",
    "sidebarTourFinished"
  );
  return (
    <Layout classNameWrapper="min-h-screen py-[60px]">
      <TooltipComponent
        runTour={runTour}
        steps={dashboardTooltipsData}
        handleJoyrideCallback={handleJoyrideCallback}
        additionalStylesClass="dashboard-tooltip"
      />
      <div className="max-w-[1024px] m-center px-3 relative">
        <button
          className="hover:opacity-80 absolute right-7 -top-10 z-10"
          onClick={resetTour}
        >
          {getIconsComponents({
            name: "question",
            className: "h-[25px] w-[25px]",
          })}
        </button>
        <DashboardItems />
        <DashboardContent />
      </div>
    </Layout>
  );
};
export default DashboardPage;
