import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  OAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import { setToken } from "./stores/slice/userAuthSlice";
import { getStorageName } from "./helpers/storageName/storageName";
const firebaseConfig = {
  apiKey: "AIzaSyDGu8GoXdxvq5_uyJ5XHoLfssnVUXgLxew",
  authDomain: "aphid-staging.firebaseapp.com",
  databaseURL: "https://aphid-staging-default-rtdb.firebaseio.com",
  projectId: "aphid-staging",
  storageBucket: "aphid-staging.appspot.com",
  messagingSenderId: "1087066125394",
  appId: "1:1087066125394:web:8416855143387409dfdb8b",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const aAuthProvider = new OAuthProvider("apple.com");
const facebookProvider = new FacebookAuthProvider();
facebookProvider.setCustomParameters({ display: 'popup' });
aAuthProvider.addScope("email");
aAuthProvider.addScope("name");

// facebookProvider.addScope("user_birthday");

const commonPart = async (res, navigate, dispatch, provider) => {
  if (res["_tokenResponse"].idToken) {
    navigate("/");
    localStorage.setItem(getStorageName.serviceAuth, "true");
    localStorage.setItem(getStorageName.accessToken, res["_tokenResponse"].idToken);
    localStorage.setItem(getStorageName.refreshToken, res["_tokenResponse"].refreshToken);
    dispatch(setToken(res["_tokenResponse"].idToken));
  }
  const user = res.user;
  const q = query(collection(db, "users"), where("uid", "==", user.uid));
  const docs = await getDocs(q);
  if (docs.docs.length === 0) {
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name: user.displayName,
      authProvider: provider,
      email: user.email,
    });
  }
};

const signInWithGoogle = async (navigate, dispatch) => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    await commonPart(res, navigate, dispatch, "google");
  } catch (err) {
    console.error(err);
  }
};
const signInWithApple = async (navigate, dispatch) => {
  try {
    const res = await signInWithPopup(auth, aAuthProvider);
    await commonPart(res, navigate, dispatch, "apple");
  } catch (err) {
    console.error(err);
  }
};
const signInWithFacebook = async (navigate, dispatch) => {
  try {
    const res = await signInWithPopup(auth, facebookProvider);
    await commonPart(res, navigate, dispatch, "facebook");
  } catch (err) {
    console.error(err);
  }
};

export { auth, db, signInWithGoogle, signInWithApple, signInWithFacebook };
