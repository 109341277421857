import React from "react";
import "./botLoader.css";
export const BotLoader = () => {
  return (
    <div className="loading-container">
      <div className="loading-dot-first"></div>
      <div className="loading-dot-second"></div>
      <div className="loading-dot-third"></div>
    </div>
  );
};
