import { SetupGuideServicesDataType } from "./mockDataType";

export const setupGuideServicesData: SetupGuideServicesDataType = {
  wordpress: [
    "Open wordpress folder path: wp-content/themes/YOUR_THEME/functions.php (open file with help text editor);",
    "Click copy code on the prev page;",
    "Enter the copied function to functions.php",
    "Open wordpress folder path: wp-content/themes/YOUR_THEME/template-parts/content;",
    "Enter container to the body (any available place, example: site-header.php (open file with help text editor) <div id='aph_uniq_id_chat-bot'></div>;",
    "Run your wordpress site.",
  ],
  wix: [
    'Copy script by button: "Copy code"',
    "Go to Settings in your site's wix dashboard;",
    "Click the Custom Code tab in the Advanced section;",
    "Click + Add Custom Code at the top right;",
    "Paste the code snippet in the text box;",
    "Enter a name for your code; (Tip: Give it a name that is easy to recognize so you can quickly identify it later)",
    "Select an option under Add Code to Pages: All pages: This adds the code to all of your site's pages, including any new pages that you create in the future. Choose whether to load the code only once per visit, or on each page your visitor opens. Choose specific pages: Use the drop-down menu to select the relevant pages. Choose Body - start;",
    "Go to site Editor;",
    "Site/View Site."

  ],
  shopify: [
    "Go to your Shopify admin site;",
    "Click on “Customize”;",
    "Click on “Edit code”;",
    "Click on “{/} theme.liquid”;",
    "Find  </body> section;",
    'Copy script by button: "Copy code";',
    "Enter to the body <div id='aph_uniq_id_chat-bot'></div> and script;",
    "Save and refresh your site to see the results.",
  ],
  squarespace: [
    "Go to Settings > Developers Tools > Code Injection;",
    "Find  </body> section;",
    "Enter to the body or footer <div id='aph_uniq_id_chat-bot'></div> and script;",
    "Click Save and refresh your site to see the results.",
  ],
  other: [
    'Copy script by button: "Copy code";',
    "Create a container in the root html file <div id='aph_uniq_id_chat-bot'></div>;",
    "Run your application.",
  ],
};
