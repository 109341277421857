import React from "react";
// components
import { Layout } from "../../components/organisms/layout/Layout";
import {SelectMembershipWrapper} from "./components/SelectMembershipWrapper";

const SelectMembership = () => {
  return (
    <Layout
      sideBar={false}
      noPadding
      className="bg-black w-full"
    >
      <SelectMembershipWrapper />
    </Layout>
  );
};

export default SelectMembership;
