import { createAsyncThunk } from "@reduxjs/toolkit";
// api
import userProfileApi from "../api/userProfileApi";
// types
import {
  SetUserAccountDataType,
  UserChangePasswordDataType,
  UserChangeProfileDataType,
  UserConfirmEmailDataType,
  UserDeleteDataType,
  UserProfileDataType,
  UserResendEmailConfirmationDataType,
  UserSetMembershipDataType,
} from "../types/userProfileTypes";
// data
import { textData } from "../../assets/translations/en/textData";
// alert
import { displayAlert } from "../slice/alertSlice";
import {saveAccountType, setEmailToVerify, setToken} from "../slice/userAuthSlice";
import { setDefaultPaymentMethodById } from "../slice/paymentsSlice";
import {
  setDeleteAccountModalOpen,
  setDowngradeNoticeModalOpen,
  setSelectMembershipModalOpen
} from "../slice/userProfileSlice";
import {getStorageName} from "../../helpers/storageName/storageName";
import {processMsg} from "../../helpers/utilityFunctions/utilityFunctions";

export const fetchUserProfile = createAsyncThunk(
  "userProfile/fetchUserProfile",
  async (arg, thunkAPI) => {
    try {
      const response = await userProfileApi.fetchUserProfile();
      thunkAPI.dispatch(setDefaultPaymentMethodById(response.data.default_payment_method));
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }
);
export const editUserProfile = createAsyncThunk(
  "userProfile/editUserProfile",
  async (data: UserChangeProfileDataType, thunkAPI) => {
    try {
      const response = await userProfileApi.editUserProfile(data);
      if (response.status === 200) {
        thunkAPI.dispatch(
          displayAlert({
            type: "success",
            text: textData.alertsMessage.profileUpdated,
          })
        );
        data.resetForm();
      }
      return response.data;
    } catch (e) {
      console.log(e);
      // thunkAPI.dispatch(fetchUserProfile());
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: processMsg(e.response.data) || textData.alertsMessage.wrong,
        })
      );
    }
  }
);
export const deleteUserProfile = createAsyncThunk(
  "userProfile/deleteUserProfile",
  async (data: UserDeleteDataType, thunkAPI) => {
    try {
      const response = await userProfileApi.deleteUserProfile();
      if (response.status === 200) {
        thunkAPI.dispatch(setDeleteAccountModalOpen(false));
        thunkAPI.dispatch(
          displayAlert({
            type: "success",
            text: textData.alertsMessage.profileUpdated,
          })
        );
        localStorage.removeItem(getStorageName.refreshToken);
        localStorage.removeItem(getStorageName.accessToken);
        localStorage.removeItem(getStorageName.serviceAuth);
        thunkAPI.dispatch(setToken(""));
        data.navigate("/signin");
      }
      return response.data;
    } catch (e) {
      console.log(e);
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: processMsg(e.response.data) || textData.alertsMessage.wrong,
        })
      );
    }
  }
);
export const setMembershipProfile = createAsyncThunk(
  "userProfile/setMembershipProfile",
  async ({ body, navigate }: UserSetMembershipDataType, thunkAPI) => {
    try {
      const response = await userProfileApi.setMembershipProfile(body);
      thunkAPI.dispatch(fetchUserProfile());
      navigate('/clones');
      thunkAPI.dispatch(setDowngradeNoticeModalOpen(false));
      thunkAPI.dispatch(setSelectMembershipModalOpen(false));
      thunkAPI.dispatch(
        displayAlert({
          type: "success",
          text: textData.selectMembership.success
        })
      );
      return response.data;
    } catch (e) {
      console.log(e);
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: processMsg(e.response.data) || textData.alertsMessage.wrong,
        })
      );
    }
  }
);
export const changePasswordUserProfile = createAsyncThunk(
  "userProfile/changePasswordUserProfile",
  async (data: UserChangePasswordDataType, thunkAPI) => {
    try {
      const response = await userProfileApi.changePasswordUserProfile(data);
      if (response.status === 200) {
        thunkAPI.dispatch(
          displayAlert({
            type: "success",
            text: textData.alertsMessage.passwordUpdated,
          })
        );
      }

      return response.data;
    } catch (e) {
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: processMsg(e.response.data) || textData.alertsMessage.wrong,
        })
      );
    }
  }
);

export const confirmEmail = createAsyncThunk(
  "userProfile/confirmEmail",
  async (data: UserConfirmEmailDataType, thunkAPI) => {
    try {
      const response = await userProfileApi.confirmEmail(data);
      thunkAPI.dispatch(setEmailToVerify(''));
      localStorage.setItem(getStorageName.accessToken, response.data.access);
      localStorage.setItem(getStorageName.refreshToken, response.data.refresh);
      thunkAPI.dispatch(setToken(response.data.access));

      return response.data;
    } catch (e) {
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: processMsg(e.response.data) || textData.alertsMessage.wrong,
        })
      );
    }
  }
);
export const resendEmailConfirmation = createAsyncThunk(
  "userProfile/resendEmailConfirmation",
  async (data: UserResendEmailConfirmationDataType, thunkAPI) => {
    try {
      const response = await userProfileApi.resendEmailConfirmation(data);
      if (response.status === 200) {
        thunkAPI.dispatch(
          displayAlert({
            type: "success",
            text: textData.alertsMessage.emailConfirmationResent,
          })
        );
      }

      return response.data;
    } catch (e) {
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: processMsg(e.response.data) || textData.alertsMessage.wrong,
        })
      );
    }
  }
);
export const setAccountTypeUserProfile = createAsyncThunk(
  "userProfile/setAccountTypeUserProfile",
  async ({ body, navigate }: SetUserAccountDataType, thunkAPI) => {
    try {
      const response = await userProfileApi.setAccountTypeUserProfile(body);
      if (response.status === 200) {
        thunkAPI.dispatch(fetchUserProfile());
        thunkAPI.dispatch(
          displayAlert({
            type: "success",
            text: textData.alertsMessage.accountType,
          })
        );
        thunkAPI.dispatch(saveAccountType(response.data.account_type));

        navigate("/subscription-plans");
      }
      return response.data;
    } catch (e: any) {
      console.log(e);
      thunkAPI.dispatch(
        displayAlert({
          type: "error",
          text: e.response.data.error || textData.alertsMessage.wrong,
        })
      );
    }
  }
);
