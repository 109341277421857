import { createSlice } from "@reduxjs/toolkit";
// types
import {
  MembershipDataListType, MembershipDataType,
} from "../types/membershipType";
// thunks
import {
  addPaymentMethod,
  fetchMembershipList,
  fetchPaymentMethods,
  fetchTransactionList,
  getStripePublicKey, removePaymentMethod
} from "../thunks/paymentsThunk";
import {billingPeriodMap, planMap} from "../../pages/selectMembership/components/mockData";
import {TransactionListFromDateType, TransactionsDataType} from "../types/transactionType";
import {PaymentMethodType, PaymentsType} from "../types/paymentsType";

interface paymentsState {
  isLoading: boolean;
  stripePublicKey: string | null;
  stripeClientSecret: string | null;
  paymentMethodType: PaymentMethodType;
  paymentMethodById: string | null;
  defaultPaymentMethodById: string | null;
  paymentMethods: PaymentsType[] | null;
  membershipList: MembershipDataListType | null;
  transactionList: TransactionsDataType | null;
  transactionListPage: number;
  transactionListSize: number;
  transactionListFromDate: TransactionListFromDateType | null;
  transactionListOrderField: string | null;
  transactionListOrderDirection: 'asc' | 'desc';
}

const initialState: paymentsState = {
  isLoading: false,
  stripePublicKey: null,
  stripeClientSecret: null,
  paymentMethodType: 0,
  paymentMethodById: null,
  defaultPaymentMethodById: null,
  paymentMethods: null,
  membershipList: null,
  transactionList: null,
  transactionListPage: 1,
  transactionListSize: 6,
  transactionListFromDate: null,
  transactionListOrderField: null,
  transactionListOrderDirection: 'asc'
};

const groupBy = function(xs: any[], key: string | number) {
  return xs.reduce(function(rv, x) {
    console.time('reduce');
    (rv[x[key]] = rv[x[key]] || []).push(x);
    console.timeEnd('reduce');
    return rv;
  }, {});
};

function processData(objects: MembershipDataType[]): Record<string, Record<string, MembershipDataType | null>> {
  const groupedObjects: Record<string, Record<string, MembershipDataType | null>> = {};
  objects.forEach(obj => {
    const planKey = planMap[obj.plan];

    if (!groupedObjects[planKey]) {
      groupedObjects[planKey] = {};
    }

    const billingPeriodKey = billingPeriodMap[obj.billing_period];

    if (!groupedObjects[planKey][billingPeriodKey]) {
      groupedObjects[planKey][billingPeriodKey] = null;
    }

    groupedObjects[planKey][billingPeriodKey] = {...obj};
  });

  return groupedObjects;
}

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setPaymentMethodById: (state, action) => {
      state.paymentMethodById = action.payload;
    },
    setDefaultPaymentMethodById: (state, action) => {
      state.defaultPaymentMethodById = action.payload;
    },
    setPaymentMethodType: (state, action) => {
      state.paymentMethodType = action.payload;
    },
    setTransactionListPage: (state, action) => {
      state.transactionListPage = action.payload;
    },
    setTransactionListFromDate: (state, action) => {
      state.transactionListFromDate = action.payload;
    },
    setTransactionListOrderField: (state, action) => {
      state.transactionListOrderField = action.payload;
    },
    setTransactionListOrderDirection: (state, action) => {
      state.transactionListOrderDirection = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMembershipList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMembershipList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.membershipList = processData(action.payload);
    });
    builder.addCase(fetchTransactionList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTransactionList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactionList = action.payload;
    });
    builder.addCase(fetchPaymentMethods.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPaymentMethods.fulfilled, (state, action) => {
      state.isLoading = false;
      state.paymentMethods = action.payload;
    });
    builder.addCase(getStripePublicKey.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getStripePublicKey.fulfilled, (state, action) => {
      state.isLoading = false;
      state.stripePublicKey = action.payload.public_key;
    });
    builder.addCase(addPaymentMethod.pending, (state) => {
      state.isLoading = true;
      state.stripeClientSecret = null;
    });
    builder.addCase(addPaymentMethod.fulfilled, (state, action) => {
      state.isLoading = false;
      state.stripeClientSecret = action.payload.client_secret;
    });
    builder.addCase(removePaymentMethod.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removePaymentMethod.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {setPaymentMethodById, setDefaultPaymentMethodById, setPaymentMethodType, setTransactionListPage, setTransactionListFromDate, setTransactionListOrderField, setTransactionListOrderDirection } = paymentsSlice.actions;
