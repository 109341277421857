import React, {Fragment, useEffect} from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
// data
import { textData } from "../../../../assets/translations/en/textData";
// components
import { ModalWrapper } from "../../../../components/molecules/modalWrapper/ModalWrapper";
import { ButtonCustom } from "../../../../components/atoms/button/ButtonCustom";
import {setRemovePaymentModalOpen} from "../../../../stores/slice/userProfileSlice";
import {removePaymentMethod} from "../../../../stores/thunks/paymentsThunk";
import {paymentMethodMap} from "../mappings";
import PaypalIcon from "../../../../assets/icons/paypal.svg";
import CardIcon from "../../../../assets/icons/card.svg";
import VisaIcon from "../../../../assets/icons/visa.svg";
import {Spinner} from "../../../../components/atoms/spinner/Spinner";
import MasterCardIcon from "../../../../assets/icons/mastercard.svg";
import {setPaymentMethodById} from "../../../../stores/slice/paymentsSlice";
import {fetchUserProfile} from "../../../../stores/thunks/userProfileThunk";

export const RemovePaymentModal = () => {
  // hooks
  const dispatch = useAppDispatch();
  // store
  const removePaymentModalOpen = useAppSelector((state) => state.userProfile.removePaymentModalOpen);
  const paymentMethodId = useAppSelector((state) => state.payments.paymentMethodById);
  const paymentMethods = useAppSelector((state) => state.payments.paymentMethods);
  const paymentMethod = paymentMethods?.find((p) => p.id === paymentMethodId);
  // state
  const isLoading = useAppSelector((state) => state.payments.isLoading);

  useEffect(() => {
    if (!removePaymentModalOpen) {
      dispatch(setPaymentMethodById(null));
    }
  }, [removePaymentModalOpen]);

  return (
    <Fragment>
      <ModalWrapper
        open={removePaymentModalOpen}
        onClose={() => dispatch(setRemovePaymentModalOpen(false))}
        title=""
      >
        {paymentMethod && <div className="flex flex-col">
            <h3 className="font-Inter text-center font-extrabold text-xl leading-6 text-white mb-10">
              {textData.settings.removePayment}
            </h3>
            <p className="my-2">{paymentMethodMap[paymentMethod.payment_method_type]}</p>
            <div className="flex justify-center items-center w-[46px] h-[46px] m-center mb-4 border border-dark-250 rounded-full">
              {paymentMethod.payment_method_type === 1 ?
                <img src={PaypalIcon} alt="paypal" className="h-fit"/> :
                paymentMethod.card_brand === 'visa' ?
                  <img src={VisaIcon} alt="visa" className="h-fit"/> :
                paymentMethod.card_brand === 'mastercard' ?
                  <img src={MasterCardIcon} alt="mastercard" className="h-fit"/> :
                  <img src={CardIcon} alt="card" className="h-fit"/>
              }
            </div>
            <div className="flex justify-center">
              {paymentMethod.card_last_numbers && <p>...{paymentMethod.card_last_numbers}</p>}
              {paymentMethod.paypal_payer_email && <p>{paymentMethod.paypal_payer_email}</p>}
            </div>
            <div className="flex gap-8 justify-around mt-10">
                <ButtonCustom
                    variant="primary"
                    title={textData.btns.btnDiscard}
                    handleClick={() => {
                      dispatch(setRemovePaymentModalOpen(false));
                    }}
                />
                <ButtonCustom
                    variant="red"
                    title={textData.btns.yesRemove}
                    handleClick={() => {
                      dispatch(removePaymentMethod(paymentMethod.id))
                    }}
                />
            </div>
        </div>}
        {isLoading && <Spinner absolute />}
      </ModalWrapper>
    </Fragment>
  );
};
