// hooks
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
// data
import { textData } from "../../../../assets/translations/en/textData";
import { deployPageIconsData } from "../../../deployPage/components/deployPageWrapper/mockData";
import { setupGuideServicesData } from "./mockData";
import { getIconsComponents } from "../../../../helpers/iconsComponents/iconsComponents";

export const SetupGuidePageWrapper = () => {
  // navigate
  const params = useParams();
  const { slug } = params;
  const navigate = useNavigate();
  const navigateToPrevPage = () => {
    navigate(-1);
  };
  // memo
  const selectedIcon = useMemo(() => {
    return deployPageIconsData.find((item) => item.slug === slug)?.icon;
  }, [slug]);

  return (
    <section className="max-w-[890px] relative flex flex-col items-baseline pt-[46px] pr-[66px] pb-[78px] pl-[48px] border-[1px] border-primary-grey rounded-3xl shadow-sm">
      <button
        className="p-[14px] absolute top-[-20px] right-[15px] bg-dark-300 border-primary-grey border-[1px] rounded-full"
        onClick={navigateToPrevPage}
      >
        {getIconsComponents({ name: "close", className: "fill-primary-light" })}
      </button>
      {selectedIcon && <img src={selectedIcon} alt="host icon" />}
      <h2 className="font-Inter text-m leading-5 capitalize mt-8 mb-16">
        {textData.btns.setupGuideBtn}
      </h2>
      <p className="mb-5 font-Inter text-left text-xl font-normal leading-6">
        To use a chat bot in your application, you need to:
      </p>
      <ol className="list-decimal mt-3 font-Inter text-left text-md font-normal leading-6 select-text">
        {slug
          ? setupGuideServicesData[slug].map((item, index) => (
              <li key={index + slug}>{item}</li>
            ))
          : setupGuideServicesData["other"].map((item, index) => (
              <li key={index + "other"}>{item}</li>
            ))}
      </ol>
    </section>
  );
};
