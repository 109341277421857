import React, { useEffect, useState } from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../../../../stores/hooks/hooks";
// store
import {
  clearNewBtn, setDeleteExistButtons, setDeleteNewButtons,
  setEditButtons,
  setNewButtons
} from "../../../../../../../stores/slice/treeBotSlice";
// data
import { textData } from "../../../../../../../assets/translations/en/textData";
// components
import { BadgeButton } from "../../../../../../../components/atoms/badgeButton/BadgeButton";
import { BadgeField } from "./badgeField/BadgeField";
import { Spinner } from "../../../../../../../components/atoms/spinner/Spinner";

export const ButtonsRepliesWrapper = () => {
  const dispatch = useAppDispatch();
  // store
  const existButtons = useAppSelector((state) => state.treeBot.existButtons);
  const newButtons = useAppSelector((state) => state.treeBot.newButtons);
  const activeWindow = useAppSelector((state) => state.treeBot.activeWindow);
  const scenario = useAppSelector((state) => state.treeBot.scenario);
  const isLoading = useAppSelector((state) => state.treeBot.isLoadingEdit);
  // state
  const [btnText, setBtnText] = useState<string>("");
  const [btnId, setBtnId] = useState<string | null>(null);
  const [btnDefaultShow, setBtnDefaultShow] = useState(false);
  // effect
  useEffect(() => {
    if (btnId) {
      if (existButtons) {
        setBtnText(existButtons.find((item) => item.id === btnId)?.title || "");
      } else if (newButtons) {
        setBtnText(
          newButtons.find((item) => item.id === btnId)?.body.title || ""
        );
      }
    }
  }, [btnId, existButtons, newButtons]);

  useEffect(() => {
    if (activeWindow === "buttons") {
      setBtnDefaultShow(true);
    } else {
      setBtnDefaultShow(false);
    }
  }, [activeWindow]);
  // helpers
  const handleNewBtn = (id: string) => {
    if (btnText && scenario) {
      dispatch(
        setNewButtons({
          id,
          body: {
            parent_scenario: scenario.id,
            title: btnText,
            button_text: btnText,
            keywords: [],
          },
        })
      );
    } else {
      dispatch(clearNewBtn(id));
    }
    setBtnDefaultShow(false);
    setBtnId(null);
  };

  const handleAddNewBtn = () => {
    setBtnId(null);
    setBtnText("");
    if (!btnDefaultShow) {
      setBtnDefaultShow(true);
    }
  };
  if (scenario) {
    return (
      <div>
        {isLoading ? (
          <div className="flex items-center justify-center w-full">
            <Spinner />
          </div>
        ) : (
          <div className="flex items-center gap-3 flex-wrap">
            {existButtons.map((btn) => (
              <BadgeField
                key={btn.id}
                icon="delete"
                iconCallback={() => dispatch(setDeleteExistButtons(btn.id))}
                value={btn.id === btnId ? btnText : btn.title}
                handleChange={(value: string) => setBtnText(value)}
                handleBlur={() =>
                  dispatch(
                    setEditButtons({
                      id: btn.id,
                      body: {
                        parent_scenario: scenario.id,
                        title: btnText,
                        button_text: btnText,
                        keywords: [],
                      },
                    })
                  )
                }
                handleFocus={() => {
                  setBtnId(btn.id);
                }}
              />
            ))}
            {newButtons.map((btn) => (
              <BadgeField
                key={btn.id}
                icon="delete"
                iconCallback={() => dispatch(setDeleteNewButtons(btn.id))}
                value={btn.id === btnId ? btnText : btn.body.title}
                handleChange={(value: string) => setBtnText(value)}
                handleBlur={() => handleNewBtn(btn.id)}
                handleFocus={() => setBtnId(btn.id)}
              />
            ))}
            {btnDefaultShow && (
              <BadgeField
                value={btnId ? "" : btnText}
                handleChange={(value: string) => setBtnText(value)}
                disabled={!scenario}
                handleBlur={() =>
                  handleNewBtn((Math.random() * 100).toString())
                }
                handleFocus={() => {}}
              />
            )}
            <BadgeButton
              handleClick={handleAddNewBtn}
              title={textData.btns.addBtn}
              variant="light"
              disabled={!scenario}
            />
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="flex items-center gap-3 flex-wrap">
      <BadgeField
        value={btnId ? "" : btnText}
        handleChange={(value: string) => setBtnText(value)}
        disabled={!scenario}
      />
      <BadgeButton
        handleClick={handleAddNewBtn}
        title={textData.btns.addBtn}
        variant="light"
        disabled={!scenario}
      />
    </div>
  );
};
