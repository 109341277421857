import React, { useMemo } from "react";

type Props = {
  data: string;
};
export const TagBadge = ({ data }: Props) => {
    // memo
  const content = useMemo(() => {
    return data.replace("#", "").replace("-", " ");
  }, [data]);
  return (
    <div className="h-fit w-full overflow-hidden py-1 px-3 border border-dark-170/[.60] rounded-sm flex items-center justify-center ">
      <p className="whitespace-nowrap overflow-hidden text-ellipsis font-Inter leading-[300%] text-white font-bold text-xxxs capitalize">
        {content}
      </p>
    </div>
  );
};
