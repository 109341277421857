// types
import { ModalWrapperBtnType } from "../../molecules/modalWrapper/modalWrapperTypes";
// data
import { textData } from "../../../assets/translations/en/textData";

export const uploadFilesBtnData: ModalWrapperBtnType = [
  {
    variant: "primary",
    title: textData.btns.btnDiscard,
    type: "button",
    callbackName: "discard",
  },
  {
    variant: "secondary",
    title: textData.btns.btnSave,
    type: "button",
    callbackName: "save",
  },
];

