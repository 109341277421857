import React from "react";
// types
import {
  BadgeFilterVariantType,
  InnerBadgeIconVariantType,
  VariantStylesType,
} from "./badgeFilterType";
// data
import { innerBadgeIcon } from "./mockData";
// helpers
import { getIconsComponents } from "../../../../../../../helpers/iconsComponents/iconsComponents";

type Props = {
  handleClick: () => void;
  title?: string;
  variant?: BadgeFilterVariantType;
  icon?: InnerBadgeIconVariantType;
};

export const BadgeFilter = ({
  title,
  handleClick,
  icon,
  variant = "dark",
}: Props) => {
  const variantStyles: VariantStylesType = {
    light: "text-primary-light border-primary-grey ",
    dark: "text-dark-400 border-primary-grey ",
  };
  return (
    <div
      className={`relative w-[120px] flex items-center gap-1.5 border border-primary-grey  py-2 pl-3 rounded bg-transparent font-Inter font-bold text-xs leading-[150%] 
 ${variantStyles[variant]}`}
    >
      {icon && innerBadgeIcon[icon]}
      <p className="capitalize overflow-hidden text-ellipsis whitespace-nowrap flex-1">
        {title}
      </p>
      <button
        type="button"
        onClick={handleClick}
        className="absolute bg-dark-200 right-0 top-0 translate-y-[-50%]  border border-primary-grey rounded-full translate-x-1/2 w-[46px] h-[46px] flex items-center justify-center"
      >
        {getIconsComponents({ name: "delete", className: "fill-white" })}
      </button>
    </div>
  );
};
