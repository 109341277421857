import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
// helper
import {
  auth,
  signInWithApple,
  signInWithFacebook,
  signInWithGoogle,
} from "../../../../firebase";
// formik
import { Formik } from "formik";
// data
import { textData } from "../../../../assets/translations/en/textData";
import { signInData } from "./mockData";
// types
import { SignInValuesType } from "./mockDataTypes";
// store
import { signInUser } from "../../../../stores/thunks/userAuthThunk";
// components
import { FieldFormik } from "../../../../components/atoms/fieldFormik/FieldFormik";
import { ButtonCustom } from "../../../../components/atoms/button/ButtonCustom";
import { LinkCustom } from "../../../../components/atoms/linkCustom/LinkCustom";
// icon
import IdLogo from "../../../../assets/icons/alphaIdColored.svg";
import { Separator } from "../../../../components/atoms/separator/Separator";

export const SignInForm = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.userAuth.isLoadingSignIn);
  const navigate = useNavigate();

  return (
    <section className="grow justify-center px-[30px] pt-[25px] lg:pt-[50px] xl:pt-[77px] lg:pr-[79px] xl:pr-[110px] pb-[95px] xl:pb-[162px] lg:pl-[106px] xl:pl-[146px] bg-black order-1 md:order-2">
      <LinkCustom
        link="/"
        icon="aphidLogoLarge"
        variant="default"
        className="flex items-center justify-center md:hidden mb-8"
      />
      <Formik
        validationSchema={signInData.validate}
        initialValues={signInData.initialValues}
        onSubmit={(values: SignInValuesType, { resetForm }) =>
          dispatch(signInUser({ ...values, navigate, resetForm }))
        }
      >
        {({ handleSubmit, values, setFieldValue, errors, touched }) => (
          <form onSubmit={handleSubmit} className="xl:max-w-[470px]">
            <div>
              <div className="flex items-center gap-2 xl:gap-5 mb-1 xl:mb-2">
                <h1 className="font-Inter font-extrabold text-6xl xl:text-8xl leading-none xl:leading-7 text-white capitalize">
                  {textData.signInPage.title}
                </h1>
                <img src={IdLogo} alt="colored logo" />
              </div>
              <p className="text-primary-light text-xxs xl:text-sm  mb-6 xl:mb-8">
                {textData.signInPage.description}
              </p>
              <div className="flex flex-col gap-4 xl:gap-3 mb-[55px] xl:mb-20">
                {signInData.fields.map((field) => (
                  <FieldFormik
                    key={field.name + field.label}
                    field={field}
                    value={values[field.name]}
                    setFieldValue={(e) =>
                      setFieldValue(field.name, e.target.value)
                    }
                    variant="dark"
                    touched={touched[field.name]}
                    errors={errors[field.name]}
                    labelClass="text-xs xl:text-md font-medium leading-4"
                    classNameWrapper="gap-[10px]"
                    className="bg-transparent pt-2 pb-2 xl:pt-3 xl:pb-3 pr-5 pl-5 text-white text-sm leading-5 font-bold placeholder:text-sm placeholder:font-bold"
                  />
                ))}
              </div>
              <ButtonCustom
                spinner={isLoading}
                variant="aphid"
                type="submit"
                className="w-full"
              />
              <LinkCustom
                variant="default"
                title={textData.btns.forgotPasswordBtn + "?"}
                link="/forgot-password"
                className="block font-Roboto text-xxs text-center xl:text-sm font-medium underline leading-[7px] xl:leading-3 mt-7 xl:mt-10"
              />
            </div>
            <Separator className="mt-8 xl:mt-[46px] mb-5 xl:mb-[31px]" />
            <ButtonCustom
              variant="noBg"
              type="button"
              handleClick={() => signInWithGoogle(navigate, dispatch)}
              icon="google"
              title={textData.btns.signInGoogle}
              className="w-full border border-primary-grey"
            />
            <ButtonCustom
              variant="noBg"
              type="button"
              handleClick={() => signInWithApple(navigate, dispatch)}
              icon="apple"
              title={textData.btns.signInApple}
              className="w-full border border-primary-grey mt-3"
            />
            <ButtonCustom
              variant="noBg"
              type="button"
              handleClick={() => signInWithFacebook(navigate, dispatch)}
              icon="facebookBlue"
              title={textData.btns.signInFacebook}
              className="normal-case w-full border border-primary-grey mt-3"
            />
          </form>
        )}
      </Formik>
    </section>
  );
};
