import { createValidate } from "../../../helpers/validation/validationHelper";
//types
import { ProfileDataType } from "./mockDataTypes";
import { textData } from "../../../assets/translations/en/textData";

export const profileData: ProfileDataType = {
  validate: createValidate(["full_name", "email", "username"]),
  fields: [
    {
      type: "text",
      label: textData.fields.fullName.label,
      placeholder: textData.fields.fullName.placeholder,
      name: "full_name",
    },
    {
      type: "select",
      label: textData.fields.gender.label,
      placeholder: "",
      name: "gender",
    },
    {
      type: "date",
      label: textData.fields.birth_date.label,
      placeholder: "",
      name: "birth_date",
    },
    {
      type: "email",
      label: textData.fields.email.label,
      placeholder: textData.fields.email.placeholder,
      name: "email",
    },
    {
      type: "text",
      label: textData.fields.phone_number.label,
      placeholder: textData.fields.phone_number.placeholder,
      name: "phone_number",
    },
    {
      type: "text",
      label: textData.settings.aphidId,
      placeholder: textData.fields.userName.placeholder,
      name: "username",
    },
    {
      type: "select",
      label: textData.settings.twoStepVerification,
      placeholder: "",
      name: "enable_two_step_auth",
    },
    {
      type: "text",
      label: textData.settings.region,
      placeholder: "",
      name: "city",
    },
  ],
  initialValues: {
    full_name: "",
    gender: undefined,
    birth_date: "",
    email: "",
    username: "",
    phone_number: "",
    enable_two_step_auth: false,
    city: ""
  },
};

