import React, {Fragment, useEffect} from "react";
// hooks
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
// store
// data
import { textData } from "../../../../assets/translations/en/textData";
// components
import { ModalWrapper } from "../../../../components/molecules/modalWrapper/ModalWrapper";
import {setChangePaymentModalOpen, setNoPaymentModalOpen} from "../../../../stores/slice/userProfileSlice";
import {Spinner} from "../../../../components/atoms/spinner/Spinner";
import {setDefaultPaymentMethodById} from "../../../../stores/slice/paymentsSlice";
import PaypalIcon from "../../../../assets/icons/paypal.svg";
import VisaIcon from "../../../../assets/icons/visa.svg";
import CardIcon from "../../../../assets/icons/card.svg";
import {paymentMethodMap} from "../mappings";
import MasterCardIcon from "../../../../assets/icons/mastercard.svg";
import {useNavigate} from "react-router-dom";
import {getIconsComponents} from "../../../../helpers/iconsComponents/iconsComponents";

export const NoPaymentModal = () => {
  // store
  const noPaymentModalOpen = useAppSelector((state) => state.userProfile.noPaymentModalOpen);
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Fragment>
      <ModalWrapper
        open={noPaymentModalOpen}
        onClose={() => dispatch(setNoPaymentModalOpen(false))}
        className="w-[1024px] h-[326px] flex flex-col"
        title=""
      >
        <div className="flex flex-col">
          <h3 className="font-Inter text-center font-extrabold text-xl leading-6 text-white mb-10">
            {textData.settings.addPayment}
          </h3>
          <button
            className="p-[14px] absolute top-[20px] right-[30px] border-primary-grey border-[1px] z-[1] rounded-full"
            onClick={() => dispatch(setNoPaymentModalOpen(false))}>
            {getIconsComponents({name: 'close', className: "fill-primary-light"})}
          </button>
          <p className="text-md">Please add a payment method in <button className="text-md text-blue-500" onClick={() => {
            dispatch(setNoPaymentModalOpen(false));
            navigate("/settings")
          }}>Settings</button> &gt; Linked Accounts to proceed
          </p>
        </div>
      </ModalWrapper>
    </Fragment>
  );
};
