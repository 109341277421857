import React, { useEffect, useState } from "react";
// hooks
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks/hooks";
import { fetchMarketplaceByIdData } from "../../../../stores/thunks/marketplaceThunk";
// image
import image from "../../../../assets/img/signinBg.png";
import aphid from "../../../../assets/img/aphidLogoGreen.png";
// helper
import { getIconsComponents } from "../../../../helpers/iconsComponents/iconsComponents";
// data
import { textData } from "../../../../assets/translations/en/textData";
// components
import { LinkCustom } from "../../../../components/atoms/linkCustom/LinkCustom";
import { Spinner } from "../../../../components/atoms/spinner/Spinner";

export const CloneIFramePageWrapper = () => {
  // hooks
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  // state
  const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(true);
  // store
  const toolById = useAppSelector((state) => state.marketplace.toolById);
  const isLoading = useAppSelector((state) => state.marketplace.isLoading);
  // useEffect
  useEffect(() => {
    if (id) {
      dispatch(fetchMarketplaceByIdData({ id: Number(id) }));
    }
  }, [dispatch, id]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full">
        <Spinner page />
      </div>
    );
  }
  return (
    <section className={`pt-8 flex flex-col h-screen`}>
      <div className="w-full max-w-[1440px] m-center flex items-center justify-between mb-6 pl-[44px] lg:pl-[60px]">
        <div className="flex gap-[35px] items-center">
          <LinkCustom
            link={`..`}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            icon="chevronRight"
            variant="default"
            classNameIcon="w-6 h-6 xl:w-10 xl:h-10 fill-white"
          />
          <LinkCustom
            link={`..`}
            onClick={(e) => {
              e.preventDefault();
              navigate(+1);
            }}
            icon="chevronRight"
            variant="default"
            classNameIcon="w-6 h-6 xl:w-10 xl:h-10 rotate-180 fill-white"
          />
        </div>
        <div className="flex items-center gap-[46px]">
          <LinkCustom
            variant="custom"
            link={`/clones`}
            icon="aphidCo"
            classNameIcon="w-[25px] h-[25px] xl:w-10 xl:h-10"
          />
          <div className="w-[3px] h-[3px] xl:w-1.5 xl:h-1.5 bg-greenGradient rounded-full" />
          <div className="w-1.5 h-1.5 xl:w-[9px] xl:h-[9px] bg-greenGradient rounded-full" />
          <div className="w-[9px] h-[9px] xl:w-3 xl:h-3 bg-greenGradient rounded-full" />
          <div className="flex-2 lg:w-[60px] lg:h-[60px] w-[36px] h-[36px] rounded-full overflow-hidden">
            <img
              src={toolById?.image_tool || image}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        <div className="flex items-center justify-center w-[36px] h-[36px] xl:w-[60px] xl:h-[60px] rounded-full overflow-hidden" />
      </div>
      {toolById && toolById.link ? (
        <div className="flex-1 relative">
          <iframe className="w-full h-full" src={toolById.link}></iframe>
          {isDisclaimerVisible && (
            <button
              onClick={() => setIsDisclaimerVisible(false)}
              className="absolute bg-dark-200 right-6 bottom-0 border border-primary-grey rounded-full translate-y-1/2 w-[46px] h-[46px] flex items-center justify-center"
            >
              {getIconsComponents({ name: "close", className: "fill-white" })}
            </button>
          )}
        </div>
      ) : (
        <div className="flex-1 relative flex items-center justify-center">
          <p className="font-Inter text-sm font-bold leading-[150%] text-white mb-2">
            {textData.cloneByIdFrame.frame}
          </p>
          {isDisclaimerVisible && (
            <button
              onClick={() => setIsDisclaimerVisible(false)}
              className="absolute bg-dark-200 right-6 bottom-0 translate-y-1/2 border border-primary-grey rounded-full w-[46px] h-[46px] flex items-center justify-center"
            >
              {getIconsComponents({ name: "close", className: "fill-white" })}
            </button>
          )}
        </div>
      )}
      {isDisclaimerVisible && (
        <div className="max-w-[1440px] m-center pl-[34px] pr-10 mt-10 mb-[60px] flex items-center gap-[50px] justify-between">
          <div>
            {getIconsComponents({
              name: "aphidCo",
              className: "w-[50px] h-[50px]",
            })}
          </div>
          <div>
            <h4 className="font-Inter text-sm font-bold leading-[150%] text-white mb-2">
              {textData.cloneByIdFrame.title}
            </h4>
            <div className="inline-block font-Roboto text-xxs font-normal leading-[150%] text-primary-light">
              <span className="w-fit">
                {textData.cloneByIdFrame.description1}
              </span>
              <LinkCustom
                variant="custom"
                link="/terms"
                title={textData.cloneByIdFrame.terms}
                className="text-blue-500 font-Roboto text-xxs font-normal leading-[150%] w-fit mr-0.5"
              />
              <span className="w-fit">
                {textData.cloneByIdFrame.description2}
              </span>
            </div>
          </div>
          {toolById && (
            <div className="flex items-center gap-3 flex-col flex-1">
              <span className="text-xs font-Roboto text-white">
                {textData.cloneByIdFrame.notWorking}
              </span>
              <LinkCustom
                variant="secondary"
                link={toolById.link}
                local={false}
                title={textData.cloneByIdFrame.redirectLink}
                className="min-w-[218px] !normal-case"
              />
            </div>
          )}
        </div>
      )}
    </section>
  );
};
